import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { authorListAtPaper } from '../../atoms/authorListAtPaper';
import { INITIAL_MATCH_MODAL_STATE, matchModalState } from '../../atoms/matchModalState';
import { XenurePaper } from '../../models/xenurePaper';
import AuthorStackList from '../modifyModal/authorStackList';
import { savePaper } from './actions';
import AnomalyWarning from './anomalyWarning';
import AuthorList from './authorList';
import ControlBar from './controlBar';
import MatchStatus from './matchStatus';
import { venueDraftState } from '../../atoms/venueDraft';
import { useAnomalyDetection } from '../../hooks/useAnomalyDetection';
import { selectedReportState } from '../../atoms/selectedReport';
import { mutate } from 'swr';
import { CandidatePaper } from '../../models/paper';

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1.4fr;
  grid-template-rows: 1fr;
  gap: 0 16px;
  grid-template-areas: '. .';
`;

const AuthorSelectBox: FC<{ paper: XenurePaper }> = ({ paper }) => {
  const [selectedReport] = useRecoilState(selectedReportState);
  const [{ selectedRaw, docType, subType }, setMatchModalState] = useRecoilState(matchModalState);
  const [{ journal, conference, conferenceInstance, removeConference, removeJournal }] = useRecoilState(
    venueDraftState
  );
  const [authorList] = useRecoilState(authorListAtPaper);
  const { data: anomalyDetection } = useAnomalyDetection({ paperId: paper.id, reportId: selectedReport!.id });

  if (!selectedReport) return null;

  async function handleSave() {
    if (!paper) return alert('no selected paper');
    if (!authorList) return alert('no author list');
    if (!selectedReport) return alert('no report');

    await savePaper({
      authors: Array.from(authorList?.authorList.values() || []),
      reportId: String(selectedReport.id),
      journalId: journal?.id ? journal?.id : paper.journal?.id,
      conferenceId: conference?.id ? conference.id : paper.conference?.id,
      conferenceInstanceId: conferenceInstance?.id ? conferenceInstance.id : paper.conference_instance?.id,
      removeJournal: removeJournal,
      removeConference: removeConference,
      paperId: paper.id,
      rawId: selectedRaw?.id,
      docType,
      subType,
    });

    if (selectedRaw) {
      mutate(
        `/admin/reports/${selectedReport.id}/candidates`,
        (rawPapers: CandidatePaper[] | undefined) => {
          if (!rawPapers) return [];
          const i = rawPapers.findIndex((raw) => raw.id.toString() === selectedRaw.id.toString());
          return [...rawPapers.slice(0, i), { ...rawPapers[i], match_confirmed: true }, ...rawPapers.slice(i + 1)];
        },
        false
      );
      mutate(
        '/admin/candidates/pending',
        (rawPapers: CandidatePaper[] | undefined) => {
          if (!rawPapers) return [];
          const i = rawPapers.findIndex((raw) => raw.id.toString() === selectedRaw.id.toString());
          return [...rawPapers.slice(0, i), { ...rawPapers[i], match_confirmed: true }, ...rawPapers.slice(i + 1)];
        },
        false
      );
    }

    setMatchModalState(INITIAL_MATCH_MODAL_STATE);
  }

  return (
    <>
      <Box my={4} textAlign="center" fontWeight="bold" fontSize="18px">
        Select Authors
      </Box>
      <Container>
        <Box>
          <AuthorStackList publicationId={paper.id} report={selectedReport} />
        </Box>
        <Box>
          <Box my={2}>
            <MatchStatus publicationId={paper.id} />
          </Box>
          <Box my={4}>{anomalyDetection && <AnomalyWarning anomalyDetection={anomalyDetection} />}</Box>
          <AuthorList paper={paper} reportId={selectedReport.id} />
          <Box my={4}>
            <ControlBar onClickSave={handleSave} paperId={paper.id} />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AuthorSelectBox;
