export function parsePage(page: string | number | undefined): number {
  if (!page) return 1;

  if (typeof page === 'number') {
    return page;
  }

  const parsedPage = parseInt(page, 10);
  if (isNaN(parsedPage)) return 1;
  return parsedPage;
}
