import { Box, Button, Flex, FormLabel, Input, Select, Spinner } from '@chakra-ui/react';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { searchReport } from '../../api/report';
import { addReportToGroup, updateMappedReportProfessorship } from '../../api/reportGroup';
import { useReports } from '../../hooks/useReports';
import { Report } from '../../models/report';
import { Professorship } from '../../types/proferssorship';
import KeyValueBox from '../keyValueBox';
import { useReportsInGroup } from '../../hooks/useReportsInGroup';
import { useRemoveReportFromGroup } from '../../hooks/useRemoveReportFromGroup';

const ReportItem: FC<{
  report: Report;
  reportGroupId: string;
  isSelected: boolean;
  initialProfessorship?: Professorship;
}> = ({ report, reportGroupId, isSelected, initialProfessorship }) => {
  const queryClient = useQueryClient();
  const [professorship, setProfessorship] = useState<Professorship>(
    initialProfessorship ?? report.professorship ?? 'FULL'
  );
  const { mutate: selectReport, isLoading: isSelectingReport } = useMutation(
    `/admin/groups/${reportGroupId}/reports`,
    ({ reportId, professorship }: { reportId: string | number; professorship: Professorship }) =>
      addReportToGroup({ reportGroupId, reportId, professorship }),
    {
      onSuccess: () => {
        setTimeout(async () => {
          await queryClient.invalidateQueries(`/admin/groups/${reportGroupId}/reports`);
        }, 1000);
      },
    }
  );
  const { mutate: removeFromGroup } = useRemoveReportFromGroup({ reportGroupId });
  const { mutateAsync: updateProfessorship } = useMutation(updateMappedReportProfessorship);

  function toggleSelect() {
    if (isSelected) {
      removeFromGroup(report.id);
    } else {
      selectReport({ reportId: report.id, professorship });
    }
  }

  async function handleChangeProfessorship(e: ChangeEvent<HTMLSelectElement>) {
    const newProfessorship = e.currentTarget.value as Professorship;
    setProfessorship(newProfessorship);
    if (isSelected) {
      try {
        await updateProfessorship({ reportGroupId, reportId: report.id, professorship: newProfessorship });
      } catch (err) {
        alert(err.response?.data?.error?.message || err.message);
      }
    }
  }

  return (
    <Flex key={report.id} p={2} cursor="pointer" mt={2} justifyContent="space-between">
      <Box fontSize="sm">
        <Box my={1} />
        <KeyValueBox title="Name" value={report.name} valueStyle={{ fontWeight: 'bold', color: '#444' }} />
        <Box my={1} />
        <KeyValueBox title="Id" value={report.id} />
        <Box my={1} />
        <KeyValueBox title="Affiliation" value={report.affiliation?.name} />
        <Box my={1} />
        <KeyValueBox title="Email" value={report.email} />
        <Box my={1} />
        <KeyValueBox title="Professorship" value={report.professorship} />
        <Box my={1} />
        <KeyValueBox title="Note" value={report.system_note} />
      </Box>
      <Flex>
        <Select size="xs" value={professorship} onChange={handleChangeProfessorship} alignItems="center">
          <option value="FULL">FULL</option>
          <option value="ASSOCIATE">ASSOCIATE</option>
          <option value="ASSISTANT">ASSISTANT</option>
          <option value="EMERITUS">EMERITUS</option>
          <option value="ADJUNCT">ADJUNCT</option>
          <option value="UNKNOWN">UNKNOWN</option>
        </Select>
        <Button
          ml={2}
          size="sm"
          onClick={toggleSelect}
          colorScheme={isSelected ? 'red' : 'teal'}
          disabled={isSelectingReport}
        >
          {isSelected ? 'Unselect' : 'Select'}
        </Button>
      </Flex>
    </Flex>
  );
};

const SelectableReportList: FC<{ reportGroupId: string }> = ({ reportGroupId }) => {
  const [value, setValue] = useState('');
  const [searchedReports, setSearchedReports] = useState<Report[]>([]);
  const { data: selectedReports } = useReportsInGroup({ reportGroupId, size: 10 });
  const [isSearching, setIsSearching] = useState(false);
  const { isLoading: isLoadingAllReports, data: allReports } = useReports({ size: 10 });

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (value.length > 0) {
        setIsSearching(true);
        setSearchedReports(await searchReport(value));
        setIsSearching(false);
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
      setIsSearching(false);
    };
  }, [value]);

  const reportMap = new Map<string | number, Report>();
  searchedReports.forEach((rg) => reportMap.set(rg.id, rg));
  allReports?.content.forEach((report) => reportMap.set(report.id, report));
  const reportList = reportMap.values();

  return (
    <Box maxH="md" overflowY="auto">
      <FormLabel>Search report</FormLabel>
      <Input value={value} onChange={(e) => setValue(e.currentTarget.value)} placeholder="Tyler Shin" />
      {(isLoadingAllReports || isSearching) && (
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          <Spinner />
        </Box>
      )}
      {reportMap.size === 0 && <Box>There is no report yet.</Box>}
      {reportMap.size > 0 &&
        Array.from(reportList).map((report) => {
          const groupReport = selectedReports?.content.find((selectedReport) => selectedReport.report.id === report.id);
          return (
            <ReportItem
              report={report}
              reportGroupId={reportGroupId}
              key={report.id}
              isSelected={!!groupReport}
              initialProfessorship={groupReport?.professorship}
            />
          );
        })}
    </Box>
  );
};
export default SelectableReportList;
