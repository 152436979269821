import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { XenurePaper } from '../../models/xenurePaper';

const FosBox: FC<{ paper: XenurePaper }> = ({ paper }) => {
  return (
    <Box>
      <Box my={4} textAlign="center" fontWeight="bold" fontSize="18px">
        Fos List
      </Box>
      <Box d="flex" flexWrap="wrap">
        {paper.fos_list?.map((fos) => {
          return (
            <Box key={fos.id} mr={1} p={1} border="1px solid #dedede" borderRadius="4px">
              {fos.name}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default FosBox;
