import { Box, Divider, Flex, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface Props {
  personalReportId: number;
  dataId: string;
}
interface ListItemProps {
  item: string;
  count: string | number;
}
interface PerformanceData {
  pub_cnt: number;
  cit_cnt: number;
  journal_pub_cnt: number;
  journal_cit_cnt: number;
  avg_if: number;
  personal_if: number;
  hindex: number;
  year: number;
  h5index: number;
  jcr_top10_pub_cnt: number;
  jcr_top25_pub_cnt: number;
  jv_top10_pub_cnt: number;
  jv_top25_pub_cnt: number;
}
const ListItem: FC<ListItemProps> = ({ item, count }) => {
  return (
    <Flex>
      <Box fontSize="16px" lineHeight="16px" fontWeight="500" p="10px 14px" bg="#EBEEFD" w="100%">
        {item}
      </Box>
      <Flex
        color="white"
        bg="#1A237E"
        w="114px"
        h="36px"
        justifyContent="center"
        alignItems="center"
        fontSize="16px"
        lineHeight="17px"
        fontWeight="900"
      >
        {count}
      </Flex>
    </Flex>
  );
};

const StatListPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData } = useReportRawData({ personalReportId, dataId });

  const data = rawData?.report_json_data ? (JSON.parse(rawData?.report_json_data) as PerformanceData) : null;

  if (!data) {
    return null;
  }

  return (
    <PersonalAnalyticsLayout>
      <Flex flexDir="column" height="100%">
        <Box fontSize="42px" lineHeight="42px" fontWeight="bold" color="#536DFE">
          연구자의 연구 성과
        </Box>
        <Flex flex="1 0 auto" flexDir="column" mt="90px" justifyContent="space-between">
          <Box>
            <VStack
              pos="relative"
              spacing="10px"
              alignItems="stretch"
              p="30px 15px 24px 15px"
              border="1px solid rgba(83, 109, 254, 0.5)"
              borderRadius="5px"
            >
              <Box
                pos="absolute"
                bg="#536DFE"
                borderRadius="5px"
                color="white"
                p="10px 26px"
                fontSize="15px"
                lineHeight="16px"
                fontWeight="bold"
                left="15px"
                top="-15.5px"
              >
                Total
              </Box>
              <ListItem item="Publications" count={data.pub_cnt.toLocaleString()} />
              <ListItem item="Citations" count={data.cit_cnt.toLocaleString()} />
              <ListItem item="h-index" count={data.hindex.toLocaleString()} />
              <ListItem item="Personal Impact Factor" count={data.personal_if.toFixed(2)} />
              <ListItem item="Avg Impact Factor" count={data.avg_if.toFixed(2)} />
            </VStack>
            <VStack
              pos="relative"
              spacing="10px"
              alignItems="stretch"
              mt="52px"
              p="30px 15px 24px 15px"
              border="1px solid rgba(83, 109, 254, 0.5)"
              borderRadius="5px"
            >
              <Box
                pos="absolute"
                bg="#536DFE"
                borderRadius="5px"
                color="white"
                p="10px 26px"
                fontSize="15px"
                lineHeight="16px"
                fontWeight="bold"
                left="15px"
                top="-15.5px"
              >
                Lead Authored
              </Box>
              <ListItem item="Publications" count="317" />
              <ListItem item="Citations" count="317" />
              <ListItem item="h-index" count="317" />
              <ListItem item="Personal Impace Factor" count="317" />
              <ListItem item="Avg Impact Factor" count="317" />
            </VStack>
          </Box>
          <Box mb="43px" fontSize="11px" lineHeight="16px">
            <Divider mb="14px" borderTop="1px solid rgba(0,0,0,0.3)" />
            <li>Lear Author는 1저자 혹은 교신저자로 쓴 논문만을 포함합니다</li>
            <li>Personal Impact Factor 는 2021.06.01를 기준으로 계산되었습니다.</li>
          </Box>
        </Flex>
      </Flex>
    </PersonalAnalyticsLayout>
  );
};

export default StatListPage;
