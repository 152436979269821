import { Button, Link } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { issueFastTrackSignUpToken } from '../../api/fastTrack';
import produce from 'immer';
import { AxiosError } from 'axios';
import { FastTrackReport, FastTrackToken } from '../../models/fastTrackReport';

const IssueSignupTokenButton: FC<{ token: FastTrackToken | null; fastTrackReport: FastTrackReport }> = ({
  token,
  fastTrackReport,
}) => {
  const queryClient = useQueryClient();
  const { mutate: issueToken, isLoading } = useMutation(
    '/admin/fast-track/reports',
    async (id: string | number) => {
      if (window.confirm('Do you really want to issue a new token?')) {
        return await issueFastTrackSignUpToken(id);
      }
    },
    {
      onError: (error: AxiosError) => {
        alert(error.response?.data?.error?.message || error.message);
      },
      onSuccess: (fastTrackReport) => {
        if (!fastTrackReport) return;
        const reports = queryClient.getQueryData<FastTrackReport[]>(['/admin/fast-track/reports']) ?? [];
        const index = reports.findIndex((item) => item.report.id === fastTrackReport.report.id);
        if (index > -1) {
          const fastTrackReports = produce(reports, (draft) => {
            draft[index] = fastTrackReport;
          });

          queryClient.setQueryData(['/admin/fast-track/reports'], fastTrackReports);
        }
      },
    }
  );

  if (token) {
    return (
      <>
        <Link href={`https://www.xenure.com/sign-up/invite?token=${token.token}`} d="block" isExternal color="blue">
          {token.token}
        </Link>
        <Button
          mt={2}
          size="sm"
          colorScheme="green"
          disabled={isLoading}
          onClick={() => issueToken(fastTrackReport.report.id)}
        >
          Issue Sign Up Token
        </Button>
      </>
    );
  }

  return (
    <Button size="sm" colorScheme="green" disabled={isLoading} onClick={() => issueToken(fastTrackReport.report.id)}>
      Issue Sign Up Token
    </Button>
  );
};
export default IssueSignupTokenButton;
