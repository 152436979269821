import { useMutation, useQueryClient } from 'react-query';
import { removePaperFromReport } from '../api/report';
import { XenurePaper } from '../models/xenurePaper';

export function useRemoveReportPaper(reportId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    `/reports/${reportId}/papers`,
    async (paper: XenurePaper) => {
      await removePaperFromReport({ reportId, paperId: paper.id });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/reports/${reportId}/papers`);
      },
    }
  );
}
