import { Box, HStack, Text } from '@chakra-ui/react';
import React, { CSSProperties, FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePersonalReport } from '../../../hooks/usePersonalReport';
import { useReportRawData } from '../../../hooks/useReportRawData';
import _ from 'lodash';

const ThStyle: CSSProperties = {
  border: '1px solid #B9B9B9',
  padding: '10px 8px',
  background: '#536DFE',
  color: 'white',
  fontSize: '12px',
  lineHeight: '17px',
  fontWeight: 'bold',
};

const TdStyle: CSSProperties = {
  padding: '10px 8px',
  border: '1px solid #B9B9B9',
  fontSize: '12px',
  lineHeight: '17px',
  fontWeight: 'bold',
};

interface Props {
  personalReportId: number;
  dataId: string;
}

interface ResearcherData {
  pub_cnt: number;
  cit_cnt: number;
  journal_pub_cnt: number;
  journal_cit_cnt: number;
  avg_if: number;
  yearmonth: string;
  row_num: number;
  hindex: number;
  year: number;
  h5index: number;
  jcr_top10_pub_cnt: number;
  jcr_top25_pub_cnt: number;
  jv_top10_pub_cnt: number;
  jv_top25_pub_cnt: number;
  personal_2y_if: number;
  personal_5y_if: number;
  main_fos: string[];
  major_journal: string[];
  rep_id: string;
  author_display_name: string;
  affiliation_display_name: string;
}

const SimilarCareerResearcherTablePage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: personalReport } = usePersonalReport(personalReportId);

  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as ResearcherData[];

  if (isLoading) {
    return <Box>Loading ...</Box>;
  }

  if (!rawData) return null;

  return (
    <PersonalAnalyticsLayout page={22}>
      <Box fontSize="42px" lineHeight="42px" fontWeight="bold" color="#536DFE" mb="24px">
        유사 연구자 분석
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="24px">
        상위 1% 유사 연구자 중 연구 나이가 비슷한 연구자
      </Box>
      <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="12px">
        Purpose
      </Box>
      <HStack spacing="16px" alignItems="flex-start">
        <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap">
          {' '}
          연구 나이가 비슷하면서 유사도 기준 최상위 연구자 5명과 h-index 기준 최상위 연구자 5명에 대한 데이터입니다.
        </Box>
        <Box
          w="100%"
          pos="relative"
          mt="20px"
          bg="#FCEDF0"
          borderRadius="5px"
          p="24px 16px 16px 16px"
          fontSize="12px"
          lineHeight="21px"
        >
          <Box
            pos="absolute"
            top="-13px"
            left="10px"
            bg="#D3625C"
            borderRadius="5px"
            p="6px 10px"
            fontSize="15px"
            lineHeight="15px"
            color="white"
            fontWeight="bold"
          >
            지표 산출 방법
          </Box>
          <Text>
            <b>연구 나이</b>의 경우 ± 2년을 해서 계산했습니다. 예를 들어 분석 대상 연구자의 연구 나이가 25년이라면,
            23~27 년의 연구 나이를 가진 경우 비슷한 연구 나이를 가졌다고 계산했습니다
          </Text>
        </Box>
      </HStack>
      <table style={{ textAlign: 'center', width: '100%', marginTop: '30px', tableLayout: 'auto' }}>
        <thead>
          <tr>
            <th style={ThStyle}>이름</th>
            <th style={ThStyle}>연구 기관</th>
            <th style={ThStyle}>출판 논문 수</th>
            <th style={ThStyle}>피인용수</th>
            <th style={ThStyle}>h-index</th>
            <th style={ThStyle}>h5-index</th>
            <th style={ThStyle}>Avg-IF</th>
            <th style={ThStyle}>2Y Personal-IF</th>
            <th style={ThStyle}>5Y Personal-IF</th>
          </tr>
        </thead>
        <tbody>
          {_.uniqBy(data, 'author_display_name').map((datum, i) => (
            <tr key={i} style={{ background: i === 0 ? '#EBEEFD' : 'transparent' }}>
              <td style={{ ...TdStyle, fontWeight: 500 }}>
                {i === 0 ? personalReport?.report.name : datum.author_display_name}
              </td>
              <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>
                {i === 0 ? personalReport?.report.affiliation?.name : datum.affiliation_display_name}
              </td>
              <td style={TdStyle}>{datum.pub_cnt.toLocaleString()}</td>
              <td style={TdStyle}>{datum.cit_cnt.toLocaleString()}</td>
              <td style={TdStyle}>{datum.hindex.toLocaleString()}</td>
              <td style={TdStyle}>{datum.h5index.toLocaleString()}</td>
              <td style={TdStyle}>{datum.avg_if?.toFixed(2)}</td>
              <td style={TdStyle}>{datum.personal_2y_if?.toFixed(2) ?? 'N/A'}</td>
              <td style={TdStyle}>{datum.personal_5y_if?.toFixed(2) ?? 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </PersonalAnalyticsLayout>
  );
};

export default SimilarCareerResearcherTablePage;
