import { Box, HStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePaperListCitationText } from '../../../hooks/usePaperListCitationText';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface PaperInformation {
  paper_id: string;
  paper_title: string;
  date: string;
  '2021_cit_cnt': number;
  cit_cnt: number;
}

interface Props {
  personalReportId: number;
  dataId: string;
}
const RemarkablePaperListPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as PaperInformation[];
  const { data: citationTextList } = usePaperListCitationText(data?.map((datum) => datum.paper_id));

  if (isLoading) {
    return null;
  }

  if (!citationTextList) {
    return null;
  }

  return (
    <Box fontFamily="'Noto Sans KR', sans-serif;" w="794px" margin="0 auto" p="70px 80px 0 80px">
      <Box fontWeight="bold" fontSize="21px" lineHeight="21px" mb="32px">
        주요 연구 분야 내 주목 할 만한 연구
      </Box>
      <Box pos="relative" alignItems="flex-start">
        {Object.entries(citationTextList).map(([paperId, citationText]) => {
          const paper = data.find((raw) => raw.paper_id.toString() === paperId.toString());

          return (
            <Box
              key={paperId}
              fontSize="12px"
              lineHeight="21px"
              pos="relative"
              mt="24px"
              style={{ pageBreakInside: 'avoid' }}
            >
              <Box>{citationText}</Box>
              <HStack mt={1} fontSize="10px">
                <Box p={1} border="1px solid #dedede" borderRadius="5px" style={{ pageBreakInside: 'avoid' }}>
                  발행년도: <b>{new Date(paper!.date).getFullYear()}</b>
                </Box>
                <Box p={1} border="1px solid #dedede" borderRadius="5px" style={{ pageBreakInside: 'avoid' }}>
                  전체 피인용수: <b>{paper?.cit_cnt}</b>
                </Box>
                <Box p={1} border="1px solid #dedede" borderRadius="5px" style={{ pageBreakInside: 'avoid' }}>
                  2021년 피인용수: <b>{paper?.['2021_cit_cnt']}</b>
                </Box>
              </HStack>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RemarkablePaperListPage;
