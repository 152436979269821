import { atom } from 'recoil';
import { DOC_TYPE, SUB_DOC_TYPE } from '../constants/articleType';
import { CandidatePaper } from '../models/paper';
import { Report } from '../models/report';
import { User } from '../models/user';

export enum MATCHING_STEP {
  SELECT_PAPER,
  MODIFY_PAPER_INFORMATION,
}

interface State {
  isOpen: boolean;
  step: MATCHING_STEP;
  selectedRaw: CandidatePaper | null;
  selectedPaperId: string | undefined;
  selectedReport: Report | null;
  selectedUser: User | null;
  docType: DOC_TYPE | null;
  subType: SUB_DOC_TYPE | null;
}

export const INITIAL_MATCH_MODAL_STATE: State = {
  isOpen: false,
  step: MATCHING_STEP.SELECT_PAPER,
  selectedRaw: null,
  selectedPaperId: undefined,
  selectedReport: null,
  selectedUser: null,
  docType: null,
  subType: null,
};

export const matchModalState = atom<State>({
  key: 'matchModalState',
  default: INITIAL_MATCH_MODAL_STATE,
});
