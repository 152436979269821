import { Box } from '@chakra-ui/react';
import React, { ChangeEvent, FC } from 'react';
import { useRecoilState } from 'recoil';
import { matchModalState } from '../../atoms/matchModalState';
import { DOC_TYPE, SUB_DOC_TYPE } from '../../constants/articleType';
import { XenurePaper } from '../../models/xenurePaper';
import DocTypeSelector from '../docTypeSelector';
import SubTypeSelector from '../subTypeSelector';

export interface ArticleTypeSelectorProps {
  paper: XenurePaper;
  onChange?: (e: ChangeEvent<HTMLSelectElement>, type: 'DOC' | 'SUB') => void;
}

const ArticleTypeSelector: FC<ArticleTypeSelectorProps> = ({ paper }) => {
  const [{ docType, subType }, setMatchState] = useRecoilState(matchModalState);

  function handleChange(selectType: DOC_TYPE | SUB_DOC_TYPE, type: 'DOC' | 'SUB') {
    if (type === 'DOC') {
      setMatchState((prev) => ({ ...prev, docType: selectType as DOC_TYPE }));
    } else {
      setMatchState((prev) => ({ ...prev, subType: selectType as SUB_DOC_TYPE }));
    }
  }

  return (
    <Box>
      <Box my={4} textAlign="center" fontWeight="bold" fontSize="18px">
        Select Document Type
      </Box>
      <Box d="flex">
        <Box>
          <DocTypeSelector
            currentDocType={docType || paper.doc_type}
            onChange={(e) => handleChange(e.currentTarget.value as DOC_TYPE, 'DOC')}
          />
        </Box>
        <Box ml={3}>
          <SubTypeSelector
            currentDocType={subType || paper.sub_type}
            onChange={(e) => handleChange(e.currentTarget.value as SUB_DOC_TYPE, 'SUB')}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ArticleTypeSelector;
