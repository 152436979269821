import React, { FC } from 'react';
import { DisplayProcessingStatus } from '../../models/report';
import { Badge } from '@chakra-ui/react';

const DisplayProcessingStatusBadge: FC<{ status: DisplayProcessingStatus | undefined }> = ({ status }) => {
  switch (status) {
    case 'IDLE':
      return (
        <Badge size="sm" colorScheme="green">
          IDLE
        </Badge>
      );

    case 'REQUIRED':
      return (
        <Badge size="sm" colorScheme="yellow">
          REQUIRED
        </Badge>
      );

    case 'PROCESSING':
      return (
        <Badge size="sm" colorScheme="gray">
          PROCESSING
        </Badge>
      );

    default: {
      return <div>N/A</div>;
    }
  }
};

export default DisplayProcessingStatusBadge;
