import React, { FC, useEffect, useRef } from 'react';
import _ from 'lodash';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const COLORS = [
  am4core.color('#0019A1'),
  am4core.color('#001FC9'),
  am4core.color('#1134F4'),
  am4core.color('#3251FF'),
  am4core.color('#536DFE'),
];

interface Props {
  researcherData: {
    rep_id: string;
    active_career_years: number;
  }[];
}

const CareerYearHistogram: FC<Props> = ({ researcherData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const chart = am4core.create(chartRef.current, am4charts.XYChart);
    chart.cursor = new am4charts.XYCursor();

    const data = _.chain(researcherData)
      .groupBy((datum) => Math.floor(datum.active_career_years / 10))
      .map((data, key) => {
        const count = data.length;
        const careerRange = Number(key) * 10;
        return { careerRange, count };
      })
      .value();

    const youth = data
      .filter((datum) => datum.careerRange < 50)
      .map((datum, i) => ({ ...datum, lineColor: COLORS[i] }));
    const ob = data.filter((datum) => datum.careerRange >= 50);
    const sumObCount = ob.reduce((prev, curr) => {
      return prev + curr.count;
    }, 0);

    chart.data = [...youth, { careerRange: '50+', count: sumObCount, lineColor: am4core.color('#536DFE') }];

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'careerRange';
    categoryAxis.title.text = 'Career Range';
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.title.fontSize = '10px';
    categoryAxis.fontSize = '10px';

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.title.text = 'Career Years';
    valueAxis.title.fontSize = '10px';
    valueAxis.fontSize = '10px';

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = 'Career Years';
    series.dataFields.valueX = 'count';
    series.dataFields.categoryY = 'careerRange';
    series.tooltipText = '{categoryY}: [b]{valueX}[/]';
    series.columns.template.height = am4core.percent(50);

    series.columns.template.adapter.add('fill', (_, target) => {
      return (target as any).dataItem?.dataContext?.lineColor;
    });

    return () => {
      chart.dispose();
    };
  }, [researcherData]);

  return (
    <>
      <div ref={chartRef} style={{ width: '634px', height: '320px' }} />
    </>
  );
};

export default CareerYearHistogram;
