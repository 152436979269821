import Fetcher from '../fetcher';
import { SCINAPSE_API_ORIGIN } from '../../constants';
import { SelectableAffiliation } from '../../components/affiliationInput';

export async function searchAffiliation(query: string) {
  const res = await Fetcher.instance.get<{
    data: { content: SelectableAffiliation[] };
  }>(SCINAPSE_API_ORIGIN + '/complete/affiliation', { params: { q: query } });

  return res.data.data.content;
}