import { Box, Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useSetRecoilState } from 'recoil';
import { VenueSearchResult } from '../../models/venue';
import { Conference, Journal } from '../../models/xenurePaper';
import { venueDraftState } from '../../atoms/venueDraft';

const TableDivision = styled.td`
  border: 1px solid #dedede;
`;

function convert2Journal(venue: VenueSearchResult): Journal {
  return {
    id: venue.id,
    title: venue.title,
    title_abbrev: venue.abbrev,
    issn: null,
    web_page: null,
    impact_factor: venue.impact_factor,
    paper_count: venue.paper_count,
    citation_count: venue.citation_count,
    is_supported: true,
  };
}

function convert2Conference(venue: VenueSearchResult): Conference {
  return {
    id: venue.id,
    name: venue.title,
    name_abbrev: venue.abbrev,
    paper_count: venue.paper_count,
    citation_count: venue.citation_count,
    h5_index: null,
    impact_factor: venue.impact_factor,
    acceptance_rate: null,
    is_supported: true,
  };
}

const FoundVenueItem: FC<{ venue: VenueSearchResult; onSelect: () => void }> = ({ venue, onSelect }) => {
  const setVenueDraftState = useSetRecoilState(venueDraftState);

  return (
    <tr
      onClick={() => {
        if (venue.type === 'JOURNAL') {
          const selectedJournal = convert2Journal(venue);
          setVenueDraftState((prev) => ({ ...prev, journal: selectedJournal }));
          onSelect();
        } else if (venue.type === 'CONFERENCE') {
          const selectedConference = convert2Conference(venue);
          setVenueDraftState((prev) => ({
            ...prev,
            conference: selectedConference,
            conferenceInstance: null,
          }));
          onSelect();
        } else {
          alert(`지원하지 않는 Venue Type 입니다: ${venue.type}`);
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      <TableDivision>{venue.type}</TableDivision>
      <TableDivision>{venue.id}</TableDivision>
      <TableDivision>{venue.title}</TableDivision>
      <TableDivision>{venue.abbrev}</TableDivision>
      <TableDivision>{venue.impact_factor}</TableDivision>
    </tr>
  );
};

const FoundVenueTable: FC<{ venues: VenueSearchResult[]; onSelect: () => void }> = ({ venues, onSelect }) => {
  if (!venues || !venues.length) return null;

  return (
    <>
      <table style={{ border: '1px solid #dedede', width: '100%' }}>
        <thead style={{ backgroundColor: '#333', color: 'white ' }}>
          <tr>
            <th>type</th>
            <th>id</th>
            <th>title</th>
            <th>abbreviation</th>
            <th>IF</th>
          </tr>
        </thead>
        <tbody>
          {venues.map((v) => (
            <FoundVenueItem key={v.id} venue={v} onSelect={onSelect} />
          ))}
        </tbody>
      </table>
      <Box d="flex" mt={1} justifyContent="center">
        <Button size="sm" onClick={onSelect}>
          CLOSE
        </Button>
      </Box>
    </>
  );
};

export default FoundVenueTable;
