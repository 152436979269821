import { atom } from 'recoil';
import { User } from '../models/user';

interface EditUserModalState {
  isOpen: boolean;
  user: User | null;
}

export const editUserModalState = atom<EditUserModalState>({
  key: 'editUserModal',
  default: { isOpen: false, user: null },
});
