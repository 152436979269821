import React, { FC, useState } from 'react';
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import { ReportGroup } from '../../models/reportGroup';
import ReportGroupForm from '../reportGroupForm';

interface Props {
  onSubmit: (values: Omit<ReportGroup, 'id' | 'mapped_report_count' | 'total_report_count'>) => Promise<void>;
}

const CreateReportGroupPopover: FC<Props> = ({ onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <Button onClick={() => setIsOpen((prev) => !prev)} colorScheme="teal" size="sm">
          Create new report group
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Create new report group</PopoverHeader>
        <PopoverBody>
          <ReportGroupForm
            onSubmit={async (values) => {
              await onSubmit(values);
              setIsOpen(false);
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CreateReportGroupPopover;
