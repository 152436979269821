import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import { createReportGroup } from '../api/reportGroup';
import { ReportGroup } from '../models/reportGroup';
import { PageableData } from '../types/pageableContent';

export function useCreateReportGroup() {
  const queryClient = useQueryClient();

  return useMutation(
    `/admin/groups`,
    (values: Omit<ReportGroup, 'id' | 'mapped_report_count' | 'total_report_count'>) => createReportGroup(values),
    {
      onSuccess: (reportGroup) => {
        queryClient
          .getQueryCache()
          .findAll(['/admin/groups'])
          .forEach(({ queryKey }) => {
            const oldData = queryClient.getQueryData<PageableData<ReportGroup>>(queryKey);
            queryClient.setQueryData<PageableData<ReportGroup>>(
              queryKey,
              produce(oldData!, (draft) => {
                draft.content.unshift(reportGroup);
              })
            );
          });
      },
    }
  );
}
