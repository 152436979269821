import React, { FC } from 'react';
import { usePersonalReport } from '../../../hooks/usePersonalReport';
import RepresentativePaperListPage from '../layouts/representativePaperListPage';
import RemarkablePaperListPage from './remarkablePaperListPage';

interface Props {
  personalReportId: number;
}

const PrintResultAppendixPage: FC<Props> = ({ personalReportId }) => {
  const { data: personalReport } = usePersonalReport(personalReportId);

  if (!personalReport) {
    return null;
  }

  return (
    <>
      <RepresentativePaperListPage
        title="유사 분야 연구자의 대표 논문"
        personalReportId={personalReportId}
        dataId="top10_similar_fields_authors_represent_papers"
      />
      <RepresentativePaperListPage
        title="유사 연구자 중 연구 나이가 비슷한 연구자의 대표 논문"
        personalReportId={personalReportId}
        dataId="top10_similar_same_career_authors_represent_papers"
      />
      <RemarkablePaperListPage personalReportId={personalReportId} dataId="remarkable_papers_with_main_fos" />
    </>
  );
};

export default PrintResultAppendixPage;
