import React, { FC } from 'react';
import { usePersonalReport } from '../../../hooks/usePersonalReport';
import AboutPage from '../layouts/aboutPage';
import Chapter1Page from '../layouts/chapter1Page';
import Chapter2Page from '../layouts/chapter2Page';
import Chapter3Page from '../layouts/chapter3Page';
import Chapter4Page from '../layouts/chapter4Page';
import Chapter5Page from '../layouts/chapter5Page';
import Chapter6Page from '../layouts/chapter6Page';
import Chapter7Page from '../layouts/chapter7Page';
import CoverPage from '../layouts/coverPage';
import RecommendPaperListPage from '../layouts/recommendPaperListPage';
import TableOfContents from '../layouts/tableOfContents';
import AllCitationTablePage from './allCitationTablePage';
import PerformanceRadarPage from './performanceRadarPage';
import CareerYearPage from './careerYearPage';
import LeadCitationTablePage from './leadCitationTablePage';
import MainFOSWordCloudPage from './mainFOSWordCloud';
import MultiIFTrendGraphPage from './multiIFTrendGraphPage';
import PerformanceCitationTrend from './performanceCitationTrend';
import PerformanceListPage from './performanceListPage';
import PerformanceTrendAvgIfPubCntH5Index from './performanceTrendAvgIfPubCntH5Index';
import PerformanceTrendJCR1025PubCnt from './performanceTrendJCR1025PubCnt';
import PerformanceTrendJVTopPubCnt from './performanceTrendJVTopPubCnt';
import ComparisonChapterPage from './recommendPaperListPage';
import ResearcherAnalysisPage from './researcherAnalysisPage';
import SimilarCareerResearcherPerformanceListPage from './similarCareerResearcherPerformanceListPage';
import SimilarCareerResearcherTablePage from './similarCareerResearcherTablePage';
import SimilarResearcherPerformanceListPage from './similarResearcherPerformanceListPage';
import SimpleChapterPage from './simpleChapterPage';
import TopJournalPage from './topJournalPage';
import JournalFOSTrendPage from './journalFOSTrendPage';
import GeoPage from '../layouts/geoPage';
import Chapter8Page from '../layouts/chapter8Page';
import PerformanceAvgJVPercentileAvgIF from './performanceAvgJVPercentileAvgIF';

interface Props {
  personalReportId: number;
}

const PrintResultContentsPage: FC<Props> = ({ personalReportId }) => {
  const { data: personalReport } = usePersonalReport(personalReportId);

  if (!personalReport) {
    return null;
  }

  return (
    <>
      <CoverPage name={personalReport.report.name} />
      <CoverPage name={personalReport.report.name} />
      <AboutPage />
      <TableOfContents personalReportId={personalReportId} />
      <SimpleChapterPage />
      <Chapter1Page />
      <PerformanceListPage personalReportId={personalReportId} dataId="personal_performance_total_and_lead" />
      <Chapter2Page />
      <PerformanceTrendAvgIfPubCntH5Index
        personalReportId={personalReportId}
        dataId="performance_trend_avg_if_h5index_pub_cnt"
      />
      <PerformanceTrendJCR1025PubCnt
        personalReportId={personalReportId}
        dataId="performance_trend_journal_pub_cnt_jcr_top10_pub_cnt_jcr_top20_pub_cnt"
      />
      <MultiIFTrendGraphPage personalReportId={personalReportId} dataId="performance_trend_if_2y_5y_all_lead" />
      <PerformanceCitationTrend
        personalReportId={personalReportId}
        dataId="performance_trend_avg_6months_cit_cnt_avg_12months_cit_cnt"
      />
      <PerformanceAvgJVPercentileAvgIF
        personalReportId={personalReportId}
        dataId="performance_trend_avg_jv_percent_rank_avg_if"
      />
      <PerformanceTrendJVTopPubCnt
        personalReportId={personalReportId}
        dataId="performance_trend_journal_jv_top10_pub_cnt_jv_top20_pub_cnt"
      />
      <Chapter3Page />
      <AllCitationTablePage personalReportId={personalReportId} dataId="my_main_total_paper_info" />
      <LeadCitationTablePage personalReportId={personalReportId} dataId="my_main_lead_paper_info" />
      <Chapter4Page />
      <MainFOSWordCloudPage personalReportId={personalReportId} dataId="main_fos" />
      <ResearcherAnalysisPage personalReportId={personalReportId} dataId="top10_similar_fields_authors_info" />
      <GeoPage
        personalReportId={personalReportId}
        dataId="all_similar_author_geo_distribution"
        title="유사 연구자 분석"
        subtitle="국가 분포"
        purpose={` 상위 1%의 유사성을 가진 연구자들의 국가, 기관의 분포에 대해서 시각적으로 표현하였습니다.`}
      />
      <CareerYearPage personalReportId={personalReportId} dataId="all_similar_fields_authors_career_distribution" />
      <SimilarResearcherPerformanceListPage
        personalReportId={personalReportId}
        dataId="all_similar_author_avg_performance"
      />
      <SimilarCareerResearcherTablePage
        personalReportId={personalReportId}
        dataId="top10_similar_fields_same_career_authors_info"
      />
      <GeoPage
        personalReportId={personalReportId}
        dataId="all_similar_same_career_author_geo_distribution"
        title="비슷한 연구 나이의 유사 연구자 분석"
        subtitle="국가 분포"
        purpose={` 상위 1%의 유사성을 가진 연구자들의 국가, 기관의 분포에 대해서 시각적으로 표현하였습니다.`}
      />
      <SimilarCareerResearcherPerformanceListPage
        personalReportId={personalReportId}
        dataId="all_similar_same_career_author_avg_performance"
      />
      <ComparisonChapterPage personalReportId={personalReportId} />
      <PerformanceRadarPage
        personalReportId={personalReportId}
        dataId="all_similar_author_avg_performance"
        subtitle="대상 연구자 vs. 전체 유사 연구자"
      />
      <PerformanceRadarPage
        personalReportId={personalReportId}
        dataId="top_similar_author_avg_performance"
        subtitle="대상 연구자 vs. 상위 30% 유사 연구자"
      />
      <PerformanceRadarPage
        personalReportId={personalReportId}
        dataId="all_similar_same_career_author_avg_performance"
        subtitle="대상 연구자 vs. 전체 비슷한 연구 나이의 유사 연구자"
      />
      <PerformanceRadarPage
        personalReportId={personalReportId}
        dataId="top_similar_same_career_author_avg_performance"
        subtitle="대상 연구자 vs. 상위 30% 비슷한 연구 나이 유사 연구자"
      />
      <Chapter5Page />
      <TopJournalPage personalReportId={personalReportId} dataId="published_top_journal" />
      <JournalFOSTrendPage
        personalReportId={personalReportId}
        dataId="my_published_journal_main_fos_percent_by_year"
        subtitle="출판 저널의 주요 연구 주제에 대한 트렌드"
      />
      <JournalFOSTrendPage
        personalReportId={personalReportId}
        dataId="major_journal_main_fos_percent_by_year"
        subtitle="유명 저널의 주요 연구 주제에 대한 트렌드"
      />
      <Chapter6Page />
      <GeoPage
        personalReportId={personalReportId}
        dataId="collaboration_info_by_coauthor"
        title="연구자의 연구 영향력 분석"
        subtitle="공동저자 관계로 살펴본 연구 영향력 분석"
        purpose={` 연구자와 함께 협력해본 적 있는 연구자, 연구 기관을 살펴봅니다. 이를 통해 어떤 국가와 어떤 기관에 영향력을 주고 받았는지 확인합니다.`}
      />
      <GeoPage
        personalReportId={personalReportId}
        dataId="collaboration_info_by_citation"
        title="연구자의 연구 영향력 분석"
        subtitle="피인용 관계로 살펴본 연구 영향력 분석"
        purpose={` 연구자의 논문을 인용한 전체 연구자들의 국가 분포를 확인합니다. 이를 통해 전 세계적으로 연구 영향력이 어떻게 확장되고 있는지 확인할 수 있습니다.`}
      />
      <Chapter7Page />
      <RecommendPaperListPage personalReportId={personalReportId} dataId="remarkable_papers_with_main_fos" />
      <Chapter8Page personalReportId={personalReportId} />
    </>
  );
};

export default PrintResultContentsPage;
