import useSWR from 'swr';
import { getAuthorship } from '../api/publication';

interface Params {
  paperId: string;
}

export function useAuthorship({ paperId }: Params) {
  const { data: authorship, isValidating: isLoading, mutate } = useSWR(
    `/admin/publications/${paperId}/authorship`,
    async () => {
      return await getAuthorship(paperId);
    }
  );

  return { authorship, isLoading, mutate };
}
