import { Box, Button, Stack, Textarea, useClipboard } from '@chakra-ui/react';
import React, { FC } from 'react';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { useReportRawData } from '../../../hooks/useReportRawData';
import { Page } from '../../../models/page';
import KeyValueBox from '../../keyValueBox';
import AxisBreakOptionSelector from '../axisBreakOptionSelector';
import CommentInput from '../commentInput';

interface Props {
  personalReportId: number;
  page: Page;
}

function getStringifiedData(rawData: string | undefined) {
  if (!rawData) return '';
  return JSON.stringify(parseStringifiedJSONRawData(rawData), null, 2);
}

const PageDescription: FC<Props> = ({ page, personalReportId }) => {
  const { data: rawData, error } = useReportRawData({ personalReportId, dataId: page.data_id });

  const { hasCopied, onCopy } = useClipboard(getStringifiedData(rawData?.report_json_data));
  const hasAxisBreakOption =
    page.data_id === 'my_published_journal_main_fos_percent_by_year' ||
    page.data_id === 'major_journal_main_fos_percent_by_year';

  return (
    <Box>
      <Box fontSize="20px" fontWeight="bold">
        {page.sequence} Page
      </Box>
      <Stack spacing="8px">
        <KeyValueBox title="Data Id" value={page.data_id} />
        <KeyValueBox title="Graph Type" value={page.graph_type} />
        <KeyValueBox title="Layout type" value={page.layout_type} />
        <KeyValueBox title="Description" value={page.description} />
        <KeyValueBox
          title="RawData"
          valueStyle={{ width: '100%' }}
          value={
            <Box pos="relative">
              <Textarea
                bg="#dedede"
                value={error ? '데이터가 존재하지 않습니다.' : getStringifiedData(rawData?.report_json_data)}
                isReadOnly
                resize="none"
                rows={10}
              />
              <Button pos="absolute" bottom="0" right="100%" size="xs" colorScheme="blackAlpha" onClick={onCopy}>
                {hasCopied ? 'Copied' : 'Copy'}
              </Button>
            </Box>
          }
        />
      </Stack>
      <Box mt={2}>
        {hasAxisBreakOption && <AxisBreakOptionSelector personalReportId={personalReportId} dataId={page.data_id} />}
        <CommentInput personalReportId={personalReportId} dataId={page.data_id} />
      </Box>
    </Box>
  );
};

export default PageDescription;
