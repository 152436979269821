import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { updateReportGroup } from '../../api/reportGroup';
import { ReportGroup } from '../../models/reportGroup';
import ReportGroupForm from '../reportGroupForm';

const EditReportGroupModal: FC<{ reportGroup: ReportGroup }> = ({ reportGroup }) => {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: update } = useMutation(
    `/admin/groups/${reportGroup.id}`,
    (values: Partial<ReportGroup> & { id: string | number }) => updateReportGroup(values),
    {
      onSuccess: (reportGroup) => {
        queryClient.setQueryData<ReportGroup>(`/admin/groups/${reportGroup.id}`, reportGroup);
      },
    }
  );

  return (
    <>
      <Button size="sm" onClick={() => setIsOpen((prev) => !prev)} colorScheme="teal">
        Edit
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReportGroupForm
              onSubmit={async (values) => {
                await update({ ...values, id: reportGroup.id });
                setIsOpen(false);
              }}
              initialValues={reportGroup}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditReportGroupModal;
