import { Box, Button, Container, Flex, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { getReport, removeReport } from '../../api/report';
import ReportAssociatedBox from '../../components/reportAssociatedBox';
import ReportEditModal from '../../components/reportEditModal';
import SimpleMatchCandidatePaperModal from '../../components/simpleMatchCandidatePaperModal';
import XenurePublicationList from '../../components/xenurePublicationList';
import { useRemoveReportPaper } from '../../hooks/useRemoveReportPaper';
import { XenurePaper } from '../../models/xenurePaper';
import ImportPapersFromAuthorButton from '../../components/importPapersFromAuthorButton';
import ReportSummary from '../../components/reportSummary';
import RawPaperTable from '../../components/rawPaperTable';
import ModifyModal from '../../components/modifyModal';
import { MATCHING_STEP, matchModalState } from '../../atoms/matchModalState';
import ImportPublicationFromCitationTextForm, {
  ImportPublicationFromCitationTextFormValues,
} from '../../components/citationTextForm';
import { FormikHelpers } from 'formik/dist/types';
import { useAddCandidatePapers } from '../../hooks/useAddCandidatePapers';
import { selectedReportState } from '../../atoms/selectedReport';
import ReportStatus from '../../components/reportStatus';

const ReportDetail: FC = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { reportId } = useParams<{ reportId: string }>();
  const setMatchModalState = useSetRecoilState(matchModalState);
  const { data: report, isLoading } = useQuery([`/admin/reports/${reportId}`], () => getReport(reportId), {
    enabled: !!reportId,
  });
  const { mutateAsync: addCandidatePapers } = useAddCandidatePapers(reportId);
  const { mutateAsync: requestRemoveReport } = useMutation(removeReport, {
    onSuccess: () => {
      queryClient.invalidateQueries('/admin/reports');
      history.push('/reports');
    },
  });

  const { mutate: removePaper } = useRemoveReportPaper(reportId);

  const setSelectedReport = useSetRecoilState(selectedReportState);
  useEffect(() => {
    if (report) {
      setSelectedReport(report || null);
    }
  }, [report, setSelectedReport]);

  function handleClickXenurePaperRemoveBtn(paper: XenurePaper) {
    if (window.confirm(`정말 ${paper.title}을(를) 삭제하시겠습니까?`)) {
      removePaper(paper);
    }
  }

  function handleClickEditXenurePaperButton(paper: XenurePaper) {
    setMatchModalState((prev) => ({
      ...prev,
      isOpen: true,
      step: MATCHING_STEP.MODIFY_PAPER_INFORMATION,
      selectedPaperId: paper.id,
    }));
  }

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={8}>
        <Spinner />
      </Box>
    );
  }

  if (!report) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={8}>
        There is no report.
      </Box>
    );
  }

  return (
    <>
      <Container maxW="container.lg" py={2}>
        <Box my={8}>
          <Flex justifyContent="space-between">
            <Box>
              <ReportSummary report={report} />
            </Box>
            <Flex flexDir="column" alignItems="flex-end">
              <ImportPapersFromAuthorButton reportId={reportId} />
              <ReportEditModal report={report} />
              <Button
                mt={2}
                colorScheme="red"
                onClick={async () => {
                  if (window.confirm('Really delete it?')) {
                    await requestRemoveReport(reportId);
                  }
                }}
              >
                Delete
              </Button>
            </Flex>
          </Flex>
        </Box>
        <ReportStatus report={report} />
        <Box my={3}>
          <ReportAssociatedBox reportId={report.id} />
        </Box>
        <Tabs isFitted variant="enclosed" isLazy>
          <TabList mb="1em">
            <Tab>Candidate Publications</Tab>
            <Tab>Mapped Publications</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ImportPublicationFromCitationTextForm
                onSubmit={async (
                  values: ImportPublicationFromCitationTextFormValues,
                  actions: FormikHelpers<ImportPublicationFromCitationTextFormValues>
                ) => {
                  const { citationStr } = values;
                  try {
                    await addCandidatePapers(citationStr);
                    actions.resetForm();
                    alert(`Succeed to insert paper values to ${report.name}`);
                  } catch (err) {
                    alert(err.message);
                    actions.setSubmitting(false);
                  }
                }}
              />
              <RawPaperTable report={report} />
            </TabPanel>
            <TabPanel>
              <XenurePublicationList
                reportId={reportId}
                onClickRemoveButton={handleClickXenurePaperRemoveBtn}
                onClickModifyButton={handleClickEditXenurePaperButton}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
      <ModifyModal />
      <SimpleMatchCandidatePaperModal report={report} />
    </>
  );
};

export default ReportDetail;
