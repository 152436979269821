import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';
import { useReportRawData } from '../../../hooks/useReportRawData';

const Chapter8Page: FC<{ personalReportId: number }> = ({ personalReportId }) => {
  const { data: similarCareerData } = useReportRawData({
    personalReportId,
    dataId: 'top10_similar_fields_same_career_authors_info',
  });

  return (
    <PersonalAnalyticsLayout>
      <ChapterJumbotron title="Appendix" chapterTitle="Chapter 08." />
      <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap" maxW="417px" mt="46px">
        <Box>
          <b>유사 분야 연구자의 대표 논문</b>
        </Box>
        {!!similarCareerData?.report_json_data && (
          <Box>
            <b>유사 연구자 중 연구 나이가 비슷한 연구자의 대표 논문</b>
          </Box>
        )}
        <Box>
          <b>주요 연구 분야 내 주목 할 만한 연구</b>
        </Box>
      </Box>
      <Box mt="117px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default Chapter8Page;
