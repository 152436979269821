import React, { FC } from 'react';
import { Box, Flex, HStack, ListItem, UnorderedList } from '@chakra-ui/react';
import { useReportRawData } from '../../../hooks/useReportRawData';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { usePageComment } from '../../../hooks/usePageComment';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import PerformanceCitationTrendGraph, {
  ChartData,
} from '../../../components/personalAnalytics/graphs/performanceCitationTrendGraph';
import _ from 'lodash';
import {
  PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH,
  PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH,
} from '../../../constants/personalReport';

interface Props {
  personalReportId: number;
  dataId: string;
}

const PerformanceCitationTrend: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const chartData = parseStringifiedJSONRawData(rawData?.report_json_data) as ChartData[];

  if (!chartData) return null;
  const chartDataForComments = chartData.filter(
    ({ year }) => year >= PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH && year <= PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH
  );
  const chartLength = chartDataForComments.length;
  const predefinedComments = [
    `최근 ${chartLength}년간 평균적으로 ${_.meanBy(chartDataForComments, 'avg_6months_cit_cnt').toFixed(
      2
    )}회 출판 직후 6개월 동안 인용되었습니다.`,
    `최근 ${chartLength}년간 평균적으로 ${_.meanBy(chartDataForComments, 'avg_12months_cit_cnt').toFixed(
      2
    )}회의 출판 직후 1년 동안 인용되었습니다.`,
  ];

  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout page={11}>
      <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
        개인 연구 트랜드
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
        출판 직후의 피인용수
      </Box>
      <Flex justifyContent="center" alignItems="center" mb="34px">
        <PerformanceCitationTrendGraph chartData={chartData} />
      </Flex>
      <HStack spacing="16px" alignItems="flex-start" mt="42px">
        <Box>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px" whiteSpace="pre-wrap">
            {' '}
            각 연도 별로 출판된 논문이 출판 직후 6개월, 12개월 동안 받은 피인용수의 추이입니다. 단순 피인용수를 가지고
            연도 별 추이를 보게 되는 경우, 시간이 지난 시점에서는 과 거의 논문이 더 높게 나오는 경향이 있습니다. 이
            분석에서는 이러한 부분을 보정하기 위해 연도 별로 각 논문이 출판된 시점으로부터 6개월, 12개월 동안 이루어진
            피인용만 산출하여 추이 그래프를 제공합니다. 각 연도 별로 개인이 출판한 논문의 질을 간접적으로 가늠할 수
            있습니다.
          </Box>
          <UnorderedList
            p="13px 15px"
            fontSize="12px"
            lineHeight="21px"
            bg="#EBEEFD"
            padding="15px 13px"
            listStylePosition="inside"
            m="0"
          >
            {comments?.map((comment, i) => (
              <ListItem key={i}>{comment}</ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box
          pos="relative"
          w="201px"
          borderRadius="5px"
          p="26px 17px 22px 17px"
          fontSize="12px"
          lineHeight="21px"
          flex="1 0 auto"
          wordBreak="break-all"
        ></Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default PerformanceCitationTrend;
