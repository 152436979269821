import { atom } from 'recoil';
import { User } from '../models/user';
import { Report } from '../models/report';

interface SelectedUserState {
  user: User;
  report: Report | null;
}

export const selectedUser = atom<SelectedUserState | null>({
  key: 'selectedAuthorUser',
  default: null,
});
