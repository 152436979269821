import { Box, Button } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { authorListAtPaper as authorListAtPaperState } from '../../atoms/authorListAtPaper';
import { XenurePaper } from '../../models/xenurePaper';
import { useAnomalyDetection } from '../../hooks/useAnomalyDetection';
import ScrappedAuthorList from './scrappedAuthorList';
import AuthorTable from './authorTable';
import produce from 'immer';
import { useAuthorship } from '../../hooks/useAuthorship';
import { AiOutlineReload } from 'react-icons/ai';

interface Props {
  paper: XenurePaper;
  reportId: string;
}

const AuthorList: FC<Props> = ({ paper, reportId }) => {
  const [authorListAtPaper, setAuthorListAtPaper] = useRecoilState(authorListAtPaperState);
  const { isLoading: isLoadingAnomalyDetection, data: anomalyDetection } = useAnomalyDetection({
    paperId: paper.id,
    reportId: reportId,
  });
  const { authorship, isLoading: isLoadingAuthorship, mutate: reloadAuthorship } = useAuthorship({ paperId: paper.id });

  useEffect(() => {
    if (isLoadingAnomalyDetection || isLoadingAuthorship) return;

    if (!anomalyDetection?.valid || !authorship?.match_success) {
      if (!paper.is_touched) {
        window.open(`https://sci-hub.se/${paper.doi}`, 'scihubwindow', 'location=yes,width=1920,height=1080');
      }
    }

    if (anomalyDetection?.matched_author) {
      setAuthorListAtPaper((prev) =>
        produce(prev, (draft) => {
          const targetAuthorId = anomalyDetection.matched_author.id;
          if (draft && draft.authorList.get(targetAuthorId)) {
            draft.authorList.get(targetAuthorId)!.is_myself = true;
          }
        })
      );
    }
  }, [anomalyDetection, authorship, paper, isLoadingAnomalyDetection, isLoadingAuthorship, setAuthorListAtPaper]);

  useEffect(() => {
    if (!authorship?.scrapped_authors || isLoadingAuthorship) return;

    if (authorship.scrapped_authors?.length > 0) {
      setAuthorListAtPaper((prev) =>
        produce(prev, (draft) => {
          authorship.scrapped_authors.forEach((scrappedAuthor) => {
            if (!scrappedAuthor.matched_author) return;
            if (!draft?.authorList.get(scrappedAuthor.matched_author.author_id)) return;
            draft.authorList.get(scrappedAuthor.matched_author.author_id)!.is_first_author = scrappedAuthor.is_first;
            draft.authorList.get(scrappedAuthor.matched_author.author_id)!.is_corr_author = scrappedAuthor.is_corr;
          });
        })
      );
    }
  }, [authorship, isLoadingAuthorship, paper, setAuthorListAtPaper]);

  useEffect(() => {
    if (isLoadingAnomalyDetection || !anomalyDetection) return;

    if (anomalyDetection?.matched_author) {
      setAuthorListAtPaper((authorList) =>
        produce(authorList, (draft) => {
          if (!draft) return;
          const targetAuthor = draft.authorList.get(anomalyDetection.matched_author.id);
          if (!targetAuthor) return;
          draft.authorList.set(targetAuthor.id, { ...targetAuthor, is_myself: true });
        })
      );
    }
  }, [paper, isLoadingAnomalyDetection, anomalyDetection, setAuthorListAtPaper]);

  return (
    <Box>
      <Box d="flex" justifyContent="flex-end" mb={2}>
        <Button
          leftIcon={<AiOutlineReload />}
          size="sm"
          onClick={() => reloadAuthorship()}
          isLoading={isLoadingAuthorship}
          colorScheme="blue"
        >
          Reload Authorship
        </Button>
      </Box>
      <Box d="flex">
        <Box flex={1}>
          <AuthorTable
            authorList={authorListAtPaper?.authorList || null}
            correspondingAuthorPreMatched={!!authorship?.corr_matched}
            firstAuthorPreMatched={!!authorship?.cofi_matched}
            itsmePreMatched={!!anomalyDetection?.found_matched_author}
            itsmeWarning={
              !!anomalyDetection?.detected_coauthor_anomaly || !!anomalyDetection?.detected_affiliation_anomaly
            }
          />
        </Box>
        <Box flex={1} ml={2}>
          <ScrappedAuthorList authors={authorship?.scrapped_authors} isLoading={isLoadingAuthorship} />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthorList;
