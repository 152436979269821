import { Column, useTable } from 'react-table';
import React, { FC, useCallback, useMemo } from 'react';
import { Author } from '../../models/author';
import styled from '@emotion/styled';
import AuthorTableRow from './authorTableRow';
import { useRecoilState } from 'recoil';
import { authorListAtPaper } from '../../atoms/authorListAtPaper';
import produce from 'immer';
import { AuthorDraggableItemTypes } from '../modifyModal/draggableTypes';
import { DragObjectWithType, useDrop } from 'react-dnd';

const Styles = styled.div`
  padding: 1rem;
  table {
    position: relative;
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
    }
  }
`;

interface Props {
  authorList: Map<string, Author> | null;
  itsmePreMatched: boolean;
  firstAuthorPreMatched: boolean;
  correspondingAuthorPreMatched: boolean;
  itsmeWarning: boolean;
}

const AuthorTable: FC<Props> = ({
  authorList,
  firstAuthorPreMatched,
  correspondingAuthorPreMatched,
  itsmePreMatched,
  itsmeWarning,
}) => {
  const [authorListAtPaperInfo, setAuthorListAtPaper] = useRecoilState(authorListAtPaper);

  const [{ isOver }, drop] = useDrop({
    accept: AuthorDraggableItemTypes.BUBBLE_AUTHOR,
    drop: (dropItem: DragObjectWithType & { author: Author }) => {
      if (!authorListAtPaperInfo) return;
      const targetAuthor = dropItem.author;
      const authorList: Map<string, Author> = produce(authorListAtPaperInfo?.authorList, (draft) => {
        draft.set(targetAuthor.id, targetAuthor);
      });
      setAuthorListAtPaper(
        produce(authorListAtPaperInfo, (draft) => {
          draft.authorList = authorList;
        })
      );
    },
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  });

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const original = Array.from(authorListAtPaperInfo?.authorList.values() || []);

      const switchedAuthors = produce(original, (draft) => {
        const dragItem = draft[dragIndex];
        draft[dragIndex] = draft[hoverIndex];
        draft[hoverIndex] = dragItem;
      });

      const finalMap = new Map<string, Author>();
      switchedAuthors.forEach((author) => finalMap.set(author.id, author));
      setAuthorListAtPaper(
        produce(authorListAtPaperInfo, (draft) => {
          if (!draft) return;
          draft.authorList = finalMap;
        })
      );
    },
    [authorListAtPaperInfo, setAuthorListAtPaper]
  );

  const data = useMemo(() => Array.from(authorList?.values() || []), [authorList]);
  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Information',
          columns: [
            { Header: 'Name', accessor: 'name', Cell: ({ value }) => <b>{value}</b> },
            { Header: 'info', accessor: (row: Author) => `${row.id} - ${row.affiliation?.name} - ${row.hindex}` },
          ],
        },
        {
          Header: 'Map State',
          columns: [
            { id: 'me', Header: 'Me', accessor: (row: Author) => (row.is_myself ? '✔' : '') },
            { id: 'first', Header: 'First', accessor: (row: Author) => (row.is_first_author ? '✔' : '') },
            { id: 'corr', Header: 'Corr', accessor: (row: Author) => (row.is_corr_author ? '✔' : '') },
          ],
        },
      ] as Column<Author>[],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <Styles>
      <table {...getTableProps()} ref={drop}>
        {isOver && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 1,
              opacity: 0.5,
              backgroundColor: 'yellow',
            }}
          />
        )}
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <AuthorTableRow
                key={row.id}
                rowProps={row.getRowProps()}
                row={row}
                itsmePreMatched={itsmePreMatched}
                firstAuthorPreMatched={firstAuthorPreMatched}
                correspondingAuthorPreMatched={correspondingAuthorPreMatched}
                itsmeWarning={itsmeWarning}
                moveCard={moveCard}
              />
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
};

export default AuthorTable;
