import { Box, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

const InformationBox: FC<{ doi: string | undefined; paperId: string }> = ({ doi, paperId }) => {
  const DOI_URL = doi && doi.includes('doi.org') ? doi : `http://doi.org/${doi}`;
  const toast = useToast();

  return (
    <>
      {doi && (
        <button
          style={{ color: 'orange' }}
          onClick={() => {
            navigator.clipboard.writeText(doi).then(() => {
              toast({
                title: 'copied!',
                status: 'success',
                duration: 1000,
                isClosable: true,
              });
            });
          }}
        >
          COPY DOI
        </button>
      )}
      {DOI_URL && (
        <a href={DOI_URL} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', marginLeft: '12px' }}>
          DOI
        </a>
      )}
      <a
        href={`https://scinapse.io/papers/${paperId}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'magenta', marginLeft: '12px' }}
      >
        Scinapse
      </a>
      {doi && (
        <a
          href={`https://sci-hub.se/${doi}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'red', marginLeft: '12px' }}
        >
          HUB
        </a>
      )}
    </>
  );
};

const ContaminatedBox: FC<{ touched: boolean; doi: string; paperId: string }> = ({ doi, touched, paperId }) => {
  if (touched) {
    return (
      <Box p={2} d="flex" justifyContent="space-between" border="1px solid #dedede" borderRadius="4px">
        <Box d="flex" alignItems="center">
          <Box as="span">이전에 편집한 적이 있던 페이퍼입니다.</Box>
          <CheckIcon size="24px" color="green.500" />
        </Box>
        <Box>
          <InformationBox doi={doi} paperId={paperId} />
        </Box>
      </Box>
    );
  }

  return (
    <Box p={2} d="flex" justifyContent="space-between" border="1px solid #dedede" borderRadius="4px">
      <Box d="flex" alignItems="center">
        <Box as="span">새로 편집하는 페이퍼입니다.</Box>
        <CloseIcon ml={3} size="24px" color="red.500" />
      </Box>
      <Box>
        <InformationBox doi={doi} paperId={paperId} />
      </Box>
    </Box>
  );
};

export default ContaminatedBox;
