import React, { FC, useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_animated);

const COLOR_PRESET = [
  '#536DFE',
  '#001DBC',
  '#2E1DEF',
  '#6945ED',
  '#A367DC',
  '#C767DC',
  '#DC67AB',
  '#DC6967',
  '#EF884F',
  '#EFAF4F',
];

interface PieChartData {
  category: string;
  value: number;
}

interface Props {
  data: PieChartData[];
  maxCount?: number;
}

const PieChart: FC<Props> = ({ data, maxCount = 10 }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;
    const chart = am4core.create(chartRef.current, am4charts.PieChart);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.slices.template.propertyFields.fill = 'color';

    const chartData = data;
    chartData.sort((a, b) => b.value - a.value);
    const topCharts = chartData.slice(0, maxCount);
    const others = chartData.slice(maxCount);
    const otherPubCnt = others.reduce((prev, curr) => {
      return curr.value + prev;
    }, 0);

    chart.data = [...topCharts, { journal_name: 'Others', pub_cnt: otherPubCnt }].map((datum, i) => ({
      ...datum,
      color: COLOR_PRESET[i],
    }));
    pieSeries.labels.template.fontSize = '10px';
    pieSeries.labels.template.maxWidth = 180;
    pieSeries.labels.template.wrap = true;
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'category';

    return () => {
      chart.dispose();
    };
  }, [data, maxCount]);

  return <div ref={chartRef} style={{ width: '634px', height: '320px' }} />;
};

export default PieChart;
