import { User } from '../models/user';

declare global {
  interface Window {
    dataLayer?: DataLayerObject[];
  }
}

interface DataLayerObject {
  event?: EventName;
  data?: EventData;
  user?: UserProperty;
}

interface EventData {
  context?: EventContext;
  position?: EventPosition;

  [key: string]: any;
}

interface UserProperty {
  id: number;
  affiliation_id?: string;
  affiliation_name?: string;
}

export enum EventName {
  SET_USER_PROPERTY = 'set_user_property',
  LOGIN = 'login',
  LOGOUT = 'logout',
}

export enum EventContext {
  DEFAULT = 'default',
}

export enum EventPosition {
  HEADER = 'header',
  FOOTER = 'footer',
  SIDEBAR = 'sidebar',
  MODAL = 'modal',
}

function getDataLayer() {
  return (window.dataLayer = window.dataLayer || []);
}

function push(data: DataLayerObject) {
  getDataLayer().push(data);
}

function event(event: EventName, data?: EventData) {
  push({ event, data });
}

function data(data: EventData) {
  push({ data });
}

function setUserProperty(user?: User) {
  if (!user || !Number(user.id)) {
    return;
  }

  const userProperty: UserProperty = {
    id: Number(user.id),
    affiliation_id: user.affiliation_id,
    affiliation_name: user.affiliation_name,
  };

  push({ event: EventName.SET_USER_PROPERTY, user: userProperty });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  event,
  data,
  setUserProperty,
};
