import React, { FC, useRef } from 'react';
import { XenurePaper } from '../../models/xenurePaper';
import { Box, Heading, Text, Button, Flex } from '@chakra-ui/react';
import StatusBox from '../statusBox';

interface Props {
  publication: XenurePaper;
  onClickRemoveButton: (paper: XenurePaper) => void;
  onClickModifyButton: (paper: XenurePaper) => void;
}

const PublicationItem: FC<Props> = ({ publication, onClickModifyButton, onClickRemoveButton }) => {
  const btnRef = useRef<HTMLButtonElement | null>(null);

  if (!publication) return null;

  const hasMySelf = publication.authors.some((a) => a.is_myself);
  const firstAuthors = publication.authors.filter((a) => a.is_first_author).map((a) => a.name);
  const correspondingAuthors = publication.authors.filter((a) => a.is_corr_author).map((a) => a.name);

  return (
    <Box border="1px solid #ddd" p={4}>
      <Heading as="h2" size="md">
        {`${publication.id} - ${publication.title}`}
      </Heading>
      <Box my={2}>
        {publication.authors.map((a, i) => (
          <Text ml={i === 0 ? 0 : 2} as="span" key={a.id}>
            {a.name}
          </Text>
        ))}
      </Box>
      <Box mb={2}>
        <Box as="span" mr={2}>
          JOURNAL: {publication.journal?.title && `${publication.journal?.title}(${publication.journal?.title_abbrev})`}
        </Box>
        <StatusBox valid={publication.journal?.is_supported} />
      </Box>
      {publication.conference?.name && (
        <Box mb={2}>
          <Box as="span" mr={2}>
            CONFERENCE: {`${publication.conference.name}(${publication.conference.name_abbrev || ''})`}
          </Box>
          <StatusBox valid={publication.conference.is_supported} />
        </Box>
      )}
      <Flex my={2}>
        <Box mr={3}>
          has myself:{' '}
          <Box as="b" color={hasMySelf ? 'green' : 'red'}>
            {hasMySelf.toString().toUpperCase()}
          </Box>
        </Box>
        <Box mr={3}>
          first author(s):{' '}
          <Box as="b" color={firstAuthors.length > 0 ? 'green' : 'red'}>
            {firstAuthors.length > 0 ? firstAuthors.join(', ') : 'NULL'}
          </Box>
        </Box>
        <Box mr={3}>
          corresponding author(s):{' '}
          <Box as="b" color={correspondingAuthors.length > 0 ? 'green' : 'red'}>
            {correspondingAuthors.length > 0 ? correspondingAuthors.join(', ') : 'NULL'}
          </Box>
        </Box>
      </Flex>
      <Button ref={btnRef} onClick={() => onClickModifyButton(publication)} mt={2}>
        수정하기
      </Button>
      <Button ml={2} onClick={() => onClickRemoveButton(publication)} colorScheme="red" mt={2}>
        삭제하기
      </Button>
    </Box>
  );
};

export default PublicationItem;
