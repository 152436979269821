import Fetcher from './fetcher';
import { LoginResult } from '../types/login';

export async function checkAuthentication() {
  const res = await Fetcher.instance.get<{ data: LoginResult }>('/auth/check');
  return res.data.data;
}

interface LoginParams {
  email: string;
  password: string;
}
export async function login({ email, password }: LoginParams) {
  const res = await Fetcher.instance.post<{ data: LoginResult }>('/auth/login', { email, password });
  Fetcher.setAccessToken(res.data.data.token);
  return res.data.data;
}

export async function logout() {
  await Fetcher.instance.post('/auth/logout', null);
  Fetcher.removeAccessToken();
}
