import { Box, Button, FormControl, FormErrorMessage, HStack, Input } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useHistory } from 'react-router';

interface FormValues {
  query: string;
}

const SearchUserForm: FC = () => {
  const history = useHistory();

  return (
    <Box>
      <Formik<FormValues>
        initialValues={{ query: '' }}
        onSubmit={({ query }, { setSubmitting }) => {
          setSubmitting(false);
          history.push(`/users?query=${query}`);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <HStack spacing={2}>
              <Field name="query">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl>
                    <Input {...field} type="text" size="sm" id="query" placeholder="Mill Shin or 12343231" />
                    <FormErrorMessage>{form.errors.query}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button type="submit" size="sm" colorScheme="blue" isLoading={isSubmitting}>
                Search
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SearchUserForm;
