import React, { FC } from 'react';
import { ConferenceInstance } from '../../models/xenurePaper';
import { Badge, Box } from '@chakra-ui/react';

const ConferenceInstanceBox: FC<{ conferenceInstance: ConferenceInstance }> = ({ conferenceInstance }) => {
  return (
    <Box>
      <Box>
        <span>{conferenceInstance.name}</span>
        {
          <Badge ml={1} fontSize="0.5rem" colorScheme="pink">
            INSTANCE
          </Badge>
        }
      </Box>
      <Box>
        <Box>Type: Conference Instance</Box>
        <Box>ID: {conferenceInstance.id}</Box>
        <Box>Start Date: {conferenceInstance.start_date}</Box>
        <Box>End Date: {conferenceInstance.end_date}</Box>
        <Box>Location: {conferenceInstance.location}</Box>
      </Box>
    </Box>
  );
};

export default ConferenceInstanceBox;
