import { atom } from 'recoil';
import { Analytics } from '../models/analytics';
import { Page } from '../models/page';
import { PersonalReport } from '../models/personalReport';
import { Template } from '../models/template';

interface PersonalAnalyticsState {
  analytics: Analytics | null;
  personalReport: PersonalReport | null;
  template: Template | null;
  selectedPage: Page | null;
}

export const INITIAL_PERSONAL_ANALYTICS_STATE: PersonalAnalyticsState = {
  analytics: null,
  personalReport: null,
  template: null,
  selectedPage: null,
};

export const personalAnalyticsState = atom<PersonalAnalyticsState>({
  key: 'personalAnalyticsState',
  default: INITIAL_PERSONAL_ANALYTICS_STATE,
});
