import { Container, Spinner, Box, Flex, Text, Button } from '@chakra-ui/react';
import { stringify } from 'qs';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from '../../components/pagination';
import ReportTable from '../../components/reportTable';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useReports } from '../../hooks/useReports';
import CreateReportModal from '../../components/createReportModal';
import SearchReportInput from '../../components/searchReportInput';
import { ReportDataStatus, ReportFilterType } from '../../models/report';
import StatusFilter from '../../components/reportListFilters/statusFilter';
import ConfirmedFilter from '../../components/reportListFilters/confirmedFilter';
import UserMappedFilter from '../../components/reportListFilters/userMappedFilter';
import GroupMappedFilter from '../../components/reportListFilters/groupMappedFilter';
import { useMutation, useQueryClient } from 'react-query';
import { invalidateAPICache } from '../../api/report';

const ReportListPage: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const queryClient = useQueryClient();
  const { page: rawPage, query, ic, status, um, gm } = queryParams;
  const page = parseInt(rawPage ?? 1);
  const { data: reports, isLoading } = useReports({
    size: 20,
    page,
    query,
    status: status as ReportDataStatus | undefined,
    isConfirmedOnly: (ic as ReportFilterType) || null,
    userMapped: (um as ReportFilterType) || null,
    groupMapped: (gm as ReportFilterType) || null,
  });
  const { mutate: requestInvalidateAPICache } = useMutation(invalidateAPICache, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['/admin/reports']);
      }, 500);
    },
  });

  return (
    <>
      <Container maxW="container.xl">
        <Flex justifyContent="space-between" mt={2}>
          <Flex>
            <SearchReportInput
              initialQuery={query || ''}
              onSearch={(query: string) => {
                const newQueryParams = { ...queryParams, query };
                history.push({
                  pathname: location.pathname,
                  search: stringify(newQueryParams, { addQueryPrefix: true }),
                });
              }}
            />
          </Flex>
          <CreateReportModal />
        </Flex>
        <Flex mt={2}>
          <ConfirmedFilter />
          <StatusFilter />
        </Flex>
        <Flex mt={2}>
          <UserMappedFilter />
        </Flex>
        <Flex mt={2}>
          <GroupMappedFilter />
        </Flex>
      </Container>
      <Box p={4} mx={8}>
        <Flex my={8} justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Text fontSize="2xl">Total reports</Text>
            <Text fontSize="xl" fontWeight="bold" ml={2}>
              {reports?.total_elements}
            </Text>
          </Flex>
          <Button onClick={() => requestInvalidateAPICache()}>Invalidate API cache</Button>
        </Flex>
        {isLoading && <Spinner />}
        {reports && <ReportTable reports={reports.content} />}
      </Box>
      <Container maxW="container.lg" py={2}>
        {reports && (
          <Flex justifyContent="center" my={8}>
            <Pagination
              makeLinkAddress={(page) => {
                const newParams = { ...queryParams, page };
                return { pathname: location.pathname, search: stringify(newParams, { addQueryPrefix: true }) };
              }}
              currentPage={page}
              isFirst={reports.first}
              isLast={reports.last}
              lastPage={reports.total_pages}
            />
          </Flex>
        )}
      </Container>
    </>
  );
};

export default ReportListPage;
