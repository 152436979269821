import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import React, { FC, memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { searchReport } from '../../api/report';
import { getReportName } from '../../helpers/getReportName';
import { User } from '../../models/user';
import CreateReportModal from '../createReportModal';
import { ReportCreateInitialData } from '../reportDetailForm';

interface Props {
  user: User;
  onAssignReport: (reportId: string) => void;
  onUnassignReport: (reportId: string) => void;
  onReloadUser: () => void;
}

const UserReportModal: FC<Props> = memo(({ user, onAssignReport, onUnassignReport, onReloadUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [realQuery, setRealQuery] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRealQuery(query);
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [query]);

  const { data: reports, isLoading } = useQuery(
    [`/admin/reports/search`, { query: realQuery }],
    () => searchReport(realQuery),
    { enabled: realQuery.length > 0 }
  );

  function handleCloseModal() {
    setIsOpen(false);
  }

  function generateInitialData(user: User): ReportCreateInitialData {
    return {
      name: user.first_name + ' ' + user.last_name,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      affiliation: { id: user.affiliation_id, name: user.affiliation_name },
      system_note: user.system_note,
      user_id: user.id,
    };
  }

  return (
    <>
      {user.report ? (
        <HStack>
          <Button size="xs" colorScheme="yellow" onClick={() => setIsOpen(true)}>
            {getReportName(user.report)}
          </Button>
          <Box as={Link} to={`/reports/${user.report.id}`}>
            🔍
          </Box>
        </HStack>
      ) : (
        <Button size="xs" variant="outline" colorScheme="gray" onClick={() => setIsOpen(true)}>
          리포트 연결하기
        </Button>
      )}
      <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${user.first_name} ${user.last_name} Report Assign`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack textAlign="center" spacing={2}>
              <Box fontWeight="bold" borderBottom="1px">
                Current Report
              </Box>
              {user.report ? (
                <Box color="blue.500" as={Link} to={`/reports/${user.report.id}`}>
                  {getReportName(user.report)}
                </Box>
              ) : (
                <VStack>
                  <Box>N/A</Box>
                  <CreateReportModal
                    buttonProps={{ size: 'xs' }}
                    initialData={generateInitialData(user)}
                    onSubmit={() => onReloadUser()}
                  />
                </VStack>
              )}
              {user.report && (
                <Button
                  size="xs"
                  colorScheme="red"
                  onClick={() => {
                    if (window.confirm('Really unassign report?')) {
                      onUnassignReport(user.report.id);
                    }
                  }}
                >
                  해제하기
                </Button>
              )}
            </VStack>
            <Box mt={8}>
              <Box mb={1}>Search report to assign</Box>
              <Input onChange={(e) => setQuery(e.currentTarget.value)} value={query} placeholder="Changshing jo" />
              {isLoading && (
                <Box p={4} d="flex" justifyContent="center" alignItems="center">
                  <Spinner />
                </Box>
              )}
              {!isLoading && query.length > 0 && reports && (
                <VStack spacing={2} alignItems="initial">
                  {reports.map((report) => (
                    <Box key={report.id} d="flex" justifyContent="space-between" alignItems="center" p={1}>
                      <Box>{getReportName(report)}</Box>
                      <Button size="xs" colorScheme="green" onClick={() => onAssignReport(report.id)}>
                        SELECT
                      </Button>
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default UserReportModal;
