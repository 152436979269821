import React, { FC } from "react";
import Gallery from "react-photo-gallery";

const CatsPage: FC = () => {
  const photos = Array(350)
    .fill(1)
    .map(() => {
      const randomWidth = Math.floor(Math.random() * 800);
      const randomHeight = Math.floor(Math.random() * 600);

      return {
        src: `https://placekitten.com/${randomWidth}/${randomHeight}`,
        width: randomWidth,
        height: randomHeight,
      };
    });

  return <Gallery photos={photos} />;
};

export default CatsPage;
