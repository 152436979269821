import { Box, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';

const Chapter6Page: FC = () => {
  return (
    <PersonalAnalyticsLayout>
      <ChapterJumbotron title="연구자의 연구 영향력 분석" chapterTitle="Chapter 06." />
      <HStack spacing="16px" alignItems="flex-start" mt="48px">
        <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap" w="100%">
          {' '}
          연구자의 연구 영향력에 대해서 분석합니다. 먼저 공동 저자로 참여한 연구자들의 국가 분포를 확인한 뒤, 추가적으로
          연구자의 논문을 인용한 전체 연구자들의 국가 분포를 분석합니다. 이를 통해 어떤 국가와 협업을 하고 있는지와
          관심을 받고 있는지를 시각적으로 확인할 수 있습니다.
        </Box>
        <Box
          w="201px"
          flexShrink={0}
          pos="relative"
          mt="20px"
          bg="#FCEDF0"
          borderRadius="5px"
          p="24px 16px 16px 16px"
          fontSize="12px"
          lineHeight="21px"
        >
          <Box
            pos="absolute"
            top="-13px"
            left="10px"
            bg="#D3625C"
            borderRadius="5px"
            p="6px 10px"
            fontSize="15px"
            lineHeight="15px"
            color="white"
            fontWeight="bold"
          >
            핵심지표
          </Box>
          <Text fontWeight={700}>저널 내 연도별 주요 연구 주제 출판 비율</Text>
          <Text whiteSpace="pre-wrap">
            {` 출판사의 전체 출판 논문들 중 특정 연구 주제(Fields of Study)가 포함된 논문의 비율을 계산한다. 수치는 백분율로 표시한다.`}
          </Text>
        </Box>
      </HStack>
      <Box mt="117px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default Chapter6Page;
