import { useQueryClient, useMutation } from 'react-query';
import { importCandidatePapers } from '../api/report';

export function useAddCandidatePapers(reportId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    `/admin/reports/${reportId}/candidates`,
    (citationText: string) => importCandidatePapers({ reportId, citationText }),
    {
      onSuccess: (pendingPublications) => {
        queryClient.setQueryData(`/admin/reports/${reportId}/candidates`, pendingPublications);
      },
    }
  );
}
