import React from 'react';
import { SWRConfig } from 'swr';
import { RecoilRoot } from 'recoil';
import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { enableMapSet } from 'immer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AxiosError } from 'axios';

enableMapSet();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: 0, refetchOnWindowFocus: false /* staleTime: Infinity */ },
    mutations: {
      onError: (err) => {
        if (err instanceof Error) {
          if ('response' in err) {
            alert((err as AxiosError).response?.data?.error?.message);
          } else {
            alert(err.message);
          }
        }
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
          <SWRConfig value={{ revalidateOnFocus: false, dedupingInterval: 5000 }}>
            <App />
          </SWRConfig>
        </ChakraProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
