import { Box, FormLabel } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import { getPersonalReportList } from '../../api/reportGroup';
import { personalAnalyticsState } from '../../atoms/personalAnalytics';

const PersonalReportSelect: FC = () => {
  const [{ analytics, personalReport }, setPersonalAnalytics] = useRecoilState(personalAnalyticsState);

  const { data: personalReports } = useQuery(
    [`/admin/analytics/${analytics?.id}/personal-report`],
    () => getPersonalReportList(analytics!.id),
    { enabled: !!analytics }
  );

  const options = personalReports?.map((personalReport) => ({
    value: personalReport.id,
    label: `${personalReport.report.id} ${personalReport.report.name}`,
  }));

  return (
    <Box>
      <FormLabel>Personal Report</FormLabel>
      <Select
        options={options}
        onChange={(option) => {
          const selectedPersonalReport = personalReports?.find((p) => p.id === option?.value)!;
          setPersonalAnalytics((prev) => ({ ...prev, personalReport: selectedPersonalReport }));
        }}
        value={
          personalReport
            ? { value: personalReport.id, label: `${personalReport.report.id} ${personalReport.report.name}` }
            : null
        }
      />
    </Box>
  );
};

export default PersonalReportSelect;
