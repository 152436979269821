import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import { Report } from '../../models/report';

const ReportTable: FC<{ reports: Report[] | undefined }> = ({ reports }) => {
  const history = useHistory();
  const columns = React.useMemo(
    () => [
      { Header: 'Id', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'First Name', accessor: 'first_name' },
      { Header: 'Last Name', accessor: 'last_name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'korean name', accessor: 'kor_name' },
      { Header: 'User id', accessor: 'user_id' },
      { Header: 'Affiliation', accessor: 'affiliation.name' },
      { Header: 'Paper count', accessor: 'paper_count' },
      { Header: 'status?', accessor: 'data_status' },
      { Header: 'target?', accessor: 'is_target' },
      { Header: 'system?', accessor: 'is_system' },
      { Header: 'source urls', accessor: 'sources_urls' },
      { Header: 'note', accessor: 'system_note' },
      { Header: 'Group Count', accessor: 'group_count' },
      { Header: 'User Count', accessor: 'user_count' },
      { Header: 'Professorship', accessor: 'professorship' },
    ],
    []
  ) as Array<Column<Report>>;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: reports ?? [],
  });

  if (!reports?.length) {
    return <Box>해당하는 조건의 Report가 없습니다.</Box>;
  }

  return (
    <Table size="sm" {...getTableProps()} style={{ tableLayout: 'auto' }}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((col) => (
              <Th {...col.getHeaderProps()}>{col.render('Header')}</Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Tr
              {...row.getRowProps()}
              onClick={() => history.push(`/reports/${row.values.id}`)}
              cursor="pointer"
              bg={i % 2 === 0 ? 'gray.100' : 'white'}
            >
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default ReportTable;
