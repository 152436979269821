import React, { FC } from 'react';
import { createReport } from '../../api/report';
import { useMutation, useQueryClient } from 'react-query';
import ReportDetailForm, { ReportCreateInitialData } from '../reportDetailForm';

const CreateReportForm: FC<{ onSubmit?: () => void; initialData?: ReportCreateInitialData }> = ({
  onSubmit,
  initialData,
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: requestCreateReport } = useMutation(createReport, {
    onSuccess: () => {
      setTimeout(async () => {
        await queryClient.invalidateQueries('/admin/reports');
      }, 1000);
    },
  });

  return (
    <ReportDetailForm
      initialData={initialData}
      onSubmit={async (values) => {
        try {
          await requestCreateReport({ ...values, affiliation_id: values.affiliation.id });
          onSubmit?.();
        } catch (err) {
          alert(err.response?.data?.error?.message || err.message);
        }
      }}
    />
  );
};

export default CreateReportForm;
