import React, { FC } from 'react';
import { Box, Flex, HStack, UnorderedList, ListItem } from '@chakra-ui/react';
import { useReportRawData } from '../../../hooks/useReportRawData';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { usePageComment } from '../../../hooks/usePageComment';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import PerformanceAvgJVPercentileAvgIFGraph, {
  ChartData,
} from '../../../components/personalAnalytics/graphs/performanceAvgJVPercentileAvgIFGraph';
import _ from 'lodash';
import {
  PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH,
  PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH,
} from '../../../constants/personalReport';

interface Props {
  personalReportId: number;
  dataId: string;
}

const PerformanceAvgJVPercentileAvgIF: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const chartData = parseStringifiedJSONRawData(rawData?.report_json_data) as ChartData[];

  if (!chartData) return null;

  const chartDataForComments = chartData.filter(
    ({ year }) => year >= PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH && year <= PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH
  );
  const chartLength = chartDataForComments.length;
  const predefinedComments = [
    `출판했던 저널에서 평균적으로 상위 ${Math.round(
      Number(_.meanBy(chartDataForComments, 'avg_jv_percent_rank')) * 100
    )}%의 논문을 작성하는 것으로 나타납니다.`,
    `최근 ${chartLength}년간 평균적으로 ${_.meanBy(chartDataForComments, 'avg_if').toFixed(
      2
    )}의 평균 Impact Factor를 가지고 있습니다.`,
  ];
  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout>
      <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
        개인 연구 트랜드
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
        연도별 평균 Impact Factor 및 저널 내 인용 Percentile Score
      </Box>
      <Flex justifyContent="center" alignItems="center" mb="34px">
        <PerformanceAvgJVPercentileAvgIFGraph chartData={chartData} />
      </Flex>
      <HStack spacing="16px" alignItems="flex-start" mt="42px">
        <Box>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px">
            {
              '연도별로 출판한 논문들이 개별 저널의 같은 볼륨 내에서 어느정도 피인용 순위를 가지는 가를 나타낸 지표입니다. 저널의 평균 IF와 저널 내 인용 percentile 값을 확인합니다. 연구자가 출판하는 저널의 퀄리티 및 저널 내에서의 논문 퀄리티를 확인할 수 있습니다'
            }
          </Box>
          <UnorderedList
            p="13px 15px"
            fontSize="12px"
            lineHeight="21px"
            bg="#EBEEFD"
            padding="15px 13px"
            listStylePosition="inside"
            m="0"
          >
            {comments?.map((comment, i) => (
              <ListItem key={i}>{comment}</ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box
          pos="relative"
          w="201px"
          bg="#FCEDF0"
          borderRadius="5px"
          p="26px 17px 22px 17px"
          fontSize="12px"
          lineHeight="21px"
          flex="1 0 auto"
          wordBreak="break-all"
        >
          <Box
            pos="absolute"
            left="16px"
            top="-14px"
            fontSize="15px"
            lineHeight="16px"
            p="6px 12px"
            borderRadius="5px"
            bg="#D3625C"
            color="white"
            wordBreak="break-all"
          >
            대표 지표
          </Box>
          <Box fontWeight="bold">Percentile</Box>
          <Box>
            Percentile은 지표들을 크기대로 나 열했을 때 Percent rank를 매긴 것 이며, 백분위로 어디쯤 위치해있는 지
            확인할 수 있는 지표입니다.
          </Box>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default PerformanceAvgJVPercentileAvgIF;
