import { useMutation, useQueryClient } from 'react-query';
import { removeReportFromGroup } from '../api/reportGroup';

interface Params {
  reportGroupId: string | number;
}
export function useRemoveReportFromGroup({ reportGroupId }: Params) {
  const queryClient = useQueryClient();

  return useMutation(
    `/admin/groups/${reportGroupId}/reports`,
    async (reportId: string | number) => {
      await removeReportFromGroup({ reportGroupId, reportId });
      return reportId;
    },
    {
      onSuccess: (reportId) => {
        setTimeout(async () => {
          await queryClient.invalidateQueries(`/admin/reports/${reportId}/associated`);
          await queryClient.invalidateQueries(`/admin/groups/${reportGroupId}/reports`);
        }, 1000);
      },
    }
  );
}
