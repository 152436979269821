import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { Analytics } from '../../models/analytics';

type FormValues = Omit<Analytics, 'id' | 'status' | 'stat'>;

interface Props {
  initialValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
}
const AddAnalyticsModal: FC<Props> = ({ onSubmit, initialValues }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen((prev) => !prev)} colorScheme="teal">
        {initialValues ? 'Edit analytics' : 'Create new analytics'}
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{initialValues ? 'Edit analytics' : 'Create new analytics'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik<FormValues>
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  await onSubmit(values);
                  setIsOpen(false);
                } catch (err) {
                  setSubmitting(false);
                  alert(err.message);
                }
              }}
              initialValues={{
                title: initialValues?.title ?? '',
                customer: initialValues?.customer ?? '',
                requirements: initialValues?.requirements ?? '',
                start_date: initialValues?.start_date ?? '',
                due_date: initialValues?.due_date ?? '',
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field name="title">
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.title && form.touched.title}>
                        <FormLabel>Title</FormLabel>
                        <Input {...field} placeholder="title" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="customer">
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.customer && form.touched.customer}>
                        <FormLabel>Customer</FormLabel>
                        <Input {...field} placeholder="customer" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="requirements">
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.requirements && form.touched.requirements}>
                        <FormLabel>Requirements</FormLabel>
                        <Input {...field} placeholder="requirements" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="start_date">
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.start_date && form.touched.start_date}>
                        <FormLabel>Start Date</FormLabel>
                        <Input {...field} placeholder="Start Date" type="date" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="due_date">
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.due_date && form.touched.due_date}>
                        <FormLabel>Due Date</FormLabel>
                        <Input {...field} placeholder="Start Date" type="date" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button type="submit" mt={4} colorScheme="teal" isFullWidth disabled={isSubmitting}>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAnalyticsModal;
