import {
  Box,
  Container,
  Flex,
  Progress,
  Spinner,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { differenceInHours, format, formatDistanceToNowStrict } from 'date-fns';
import produce from 'immer';
import React, { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { createAnalytics, CreateAnalyticsParams, getAnalyticsList } from '../../api/reportGroup';
import AddAnalyticsModal from '../../components/addAnalyticsModal';
import Pagination from '../../components/pagination';
import { getCompleteProgress } from '../../helpers/getCompleteProgress';
import { Analytics } from '../../models/analytics';
import { PageableData } from '../../types/pageableContent';
import { useQueryParams } from '../../hooks/useQueryParams';
import { parsePage } from '../../helpers/parsePage';

function getTimeProgress(startDate: Date, fromDate: Date) {
  const timeRange = differenceInHours(fromDate, startDate);
  const currentPosition = differenceInHours(new Date(), startDate);
  return currentPosition / timeRange;
}

const Styles = styled.div`
  a {
    display: table-row;

    &:hover {
      background-color: #f4f4f5;
    }
  }
`;

const AnalyticsPage: FC = () => {
  const location = useLocation();
  const { page } = useQueryParams();
  const { isLoading, data: analyticsResponse } = useQuery(['/admin/analytics', { page }], () =>
    getAnalyticsList({ page: parsePage(page) })
  );

  const queryClient = useQueryClient();
  const { mutateAsync: requestNewAnalytics } = useMutation(
    ['/admin/analytics', { page }],
    (values: CreateAnalyticsParams) => createAnalytics(values),
    {
      onSuccess: (analytics) => {
        const oldData = queryClient.getQueryData<PageableData<Analytics>>(['/admin/analytics', { page }]);

        queryClient.setQueryData(
          ['/admin/analytics', { page }],
          produce(oldData, (draft) => {
            draft?.content.unshift(analytics);
          })
        );
      },
    }
  );

  if (isLoading) {
    return (
      <Container maxW="container.xl">
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          <Spinner />
        </Box>
      </Container>
    );
  }

  if (!analyticsResponse) {
    return (
      <Container maxW="container.xl">
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          <Text fontSize="xl">There isn't any analytics yet.</Text>
          <Box mt={4}>
            <AddAnalyticsModal
              onSubmit={async (values: CreateAnalyticsParams) => {
                await requestNewAnalytics(values);
              }}
            />
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl">
      <Text fontSize="4xl" fontWeight="bold">
        Analytics List
      </Text>
      <Flex my={2} justifyContent="flex-end">
        <AddAnalyticsModal
          onSubmit={async (values: CreateAnalyticsParams) => {
            await requestNewAnalytics(values);
          }}
        />
      </Flex>
      <Styles>
        <Table variant="simple">
          <TableCaption>Go Team!</TableCaption>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Title</Th>
              <Th>Customer</Th>
              <Th>Requirements</Th>
              <Th>Timeline</Th>
              <Th>Status</Th>
              <Th>Stat</Th>
            </Tr>
          </Thead>
          <Tbody>
            {analyticsResponse.content.map((analytics) => (
              <Link to={`/analytics/${analytics.id}`} key={analytics.id}>
                <Td>{analytics.id}</Td>
                <Td>{analytics.title}</Td>
                <Td>{analytics.customer}</Td>
                <Td>{analytics.requirements}</Td>
                <Td>
                  <Progress
                    max={1}
                    value={getTimeProgress(new Date(analytics.start_date), new Date(analytics.due_date))}
                    hasStripe
                  />
                  <Text fontSize="sm" textAlign="center">{`${format(
                    new Date(analytics.start_date),
                    'yyyy-MM-dd'
                  )} ~ ${format(new Date(analytics.due_date), 'yyyy-MM-dd')} (${formatDistanceToNowStrict(
                    new Date(analytics.due_date),
                    { unit: 'day' }
                  )} left)`}</Text>
                </Td>
                <Td>{analytics.status}</Td>
                <Td>
                  <Progress
                    max={1}
                    min={0}
                    value={getCompleteProgress(analytics.stat?.mapped_report_count, analytics.stat?.total_report_count)}
                    hasStripe
                  />
                  {analytics.stat?.mapped_report_count ?? 0} / {analytics.stat?.total_report_count ?? 0}
                </Td>
              </Link>
            ))}
          </Tbody>
        </Table>
      </Styles>
      <Flex justifyContent="center" my={8}>
        <Pagination
          currentPage={analyticsResponse.number + 1}
          isFirst={analyticsResponse.first}
          isLast={analyticsResponse.last}
          lastPage={analyticsResponse.total_pages}
          makeLinkAddress={(page: number) => `${location.pathname}?page=${page}`}
        />
      </Flex>
    </Container>
  );
};

export default AnalyticsPage;
