import { Box, Grid, HStack, ListItem, UnorderedList } from '@chakra-ui/react';
import React, { FC, Fragment, useMemo } from 'react';
import JournalTrendLine, { ChartData } from '../../../components/personalAnalytics/graphs/journalFOSTrendGraph';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { useReportRawData } from '../../../hooks/useReportRawData';
import _ from 'lodash';
import { usePageComment } from '../../../hooks/usePageComment';
import { usePersonalReport } from '../../../hooks/usePersonalReport';
import { getAxisBreakOptionFromPersonalReportMatadata } from '../../../helpers/getAxisBreakOptionFromPersonalReportMetadata';

const MAXIMUM_SINGLE_GRAPH_FOR_FIRST_PAGE = 4;
const MAXIMUM_SINGLE_GRAPH_FOR_DESCRIPTION_PAGE = 6;

interface Props {
  personalReportId: number;
  dataId: string;
  subtitle: string;
}

function sortAndFilterData(chartData: ChartData[]) {
  const data = chartData;
  data.sort((a, b) => a.year - b.year);
  return data.filter((datum) => datum.year > 2010 && datum.year < 2021);
}

function filterZeroPercentData(chartData: ChartData[]) {
  if (!chartData || chartData.length === 0) {
    return chartData;
  }
  const journalWithSumOfPercentByYear = chartData.reduce((acc, val) => {
    return { ...acc, [val.journal_id]: acc[val.journal_id] || 0 + val.percent_by_year };
  }, {} as { [key: string]: number });
  const filterOutJournalIds = Object.entries(journalWithSumOfPercentByYear)
    .filter(([_, sumOfPercentByYear]) => sumOfPercentByYear === 0)
    .map(([journalId, _]) => journalId);
  return chartData.filter((data) => !filterOutJournalIds.includes(data.journal_id));
}

const DescriptionSection: FC<{ personalReportId: number; dataId: string }> = ({ personalReportId, dataId }) => {
  const { data: comment } = usePageComment({ personalReportId, dataId });
  const comments = comment?.comment.split('\n');

  return (
    <HStack mt="16px" alignItems="flex-start">
      {dataId === 'my_published_journal_main_fos_percent_by_year' && (
        <Box w="33.3%" flexShrink={0}>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px" whiteSpace="pre-wrap">
            {' '}
            연구자가 출판한 저널 중 IF가 높은 상위 10개 저널의 주요 연구 주제의 출판율을 확인합니다.
          </Box>
        </Box>
      )}
      {!!comments?.length && (
        <UnorderedList
          flex={1}
          maxW="66.6%"
          p="13px 15px"
          fontSize="12px"
          lineHeight="21px"
          bg="#EBEEFD"
          padding="15px 13px"
          listStylePosition="inside"
          m="0"
          mt="20px"
        >
          {comments.map((comment, i) => (
            <ListItem key={i}>{comment}</ListItem>
          ))}
        </UnorderedList>
      )}
    </HStack>
  );
};

const JournalFOSTrendPage: FC<Props> = ({ personalReportId, dataId, subtitle }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const parsedChartData = parseStringifiedJSONRawData(rawData?.report_json_data);
  const chartData = filterZeroPercentData(parsedChartData);
  const { data: personalReport } = usePersonalReport(personalReportId);

  const calculatedData = useMemo(() => {
    if (!chartData) return null;
    const sortedData = sortAndFilterData(chartData);

    const convertedData = sortedData.map((datum) => ({
      ...datum,
      year: datum.year,
      percent_by_year: datum.percent_by_year * 100,
    }));

    const groupedData = _.chain(convertedData)
      .groupBy('year')
      .map((data, key) => {
        const obj: any = { year: key };
        data.forEach((datum) => {
          obj[datum.journal_id] = datum.percent_by_year;
        });
        return obj;
      })
      .value();

    return {
      groupedData,
      uniqJournalIds: Array.from(new Set(sortedData.map((datum) => datum.journal_id))),
    };
  }, [chartData]);

  if (isLoading) {
    return null;
  }

  if (!calculatedData || !personalReport) {
    return null;
  }

  const { groupedData, uniqJournalIds } = calculatedData;

  if (uniqJournalIds.length < 2) {
    return (
      <PersonalAnalyticsLayout>
        <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
          저널 관련 분석
        </Box>
        <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
          {subtitle}
        </Box>
        <JournalTrendLine
          chartData={{ groupedData, uniqJournalIds, rawData: chartData }}
          axisBreakOption={getAxisBreakOptionFromPersonalReportMatadata(personalReport, dataId)}
        />
        <Grid templateColumns="1fr 1fr" gap="16px">
          {uniqJournalIds.map((_, i) => (
            <Fragment key={i}>
              <JournalTrendLine chartData={{ groupedData, uniqJournalIds, rawData: chartData }} activeIndex={i} />
            </Fragment>
          ))}
        </Grid>
        <DescriptionSection personalReportId={personalReportId} dataId={dataId} />
      </PersonalAnalyticsLayout>
    );
  }

  const graphPageCount = Math.max(
    Math.ceil(
      (uniqJournalIds.length - MAXIMUM_SINGLE_GRAPH_FOR_FIRST_PAGE) / MAXIMUM_SINGLE_GRAPH_FOR_DESCRIPTION_PAGE
    ),
    1
  );

  const pageArr = [];
  const leftGraphs = uniqJournalIds.slice(MAXIMUM_SINGLE_GRAPH_FOR_FIRST_PAGE);
  for (let i = 0; i < graphPageCount; i++) {
    const page = (
      <PersonalAnalyticsLayout key={i}>
        <Grid templateColumns="1fr 1fr" gap="16px">
          {leftGraphs
            .slice(MAXIMUM_SINGLE_GRAPH_FOR_DESCRIPTION_PAGE * i, MAXIMUM_SINGLE_GRAPH_FOR_DESCRIPTION_PAGE * (i + 1))
            .map((_, j) => (
              <Fragment key={j}>
                <JournalTrendLine
                  chartData={{ groupedData, uniqJournalIds, rawData: chartData }}
                  activeIndex={MAXIMUM_SINGLE_GRAPH_FOR_FIRST_PAGE + MAXIMUM_SINGLE_GRAPH_FOR_DESCRIPTION_PAGE * i + j}
                />
              </Fragment>
            ))}
        </Grid>
        {i === graphPageCount - 1 && <DescriptionSection personalReportId={personalReportId} dataId={dataId} />}
      </PersonalAnalyticsLayout>
    );
    pageArr.push(page);
  }

  return (
    <>
      <PersonalAnalyticsLayout>
        <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
          저널 관련 분석
        </Box>
        <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
          {subtitle}
        </Box>
        <JournalTrendLine
          chartData={{ groupedData, uniqJournalIds, rawData: chartData }}
          axisBreakOption={getAxisBreakOptionFromPersonalReportMatadata(personalReport, dataId)}
        />
        <Grid templateColumns="1fr 1fr" gap="16px">
          {uniqJournalIds.slice(0, MAXIMUM_SINGLE_GRAPH_FOR_FIRST_PAGE).map((_, i) => (
            <Fragment key={i}>
              <JournalTrendLine chartData={{ groupedData, uniqJournalIds, rawData: chartData }} activeIndex={i} />
            </Fragment>
          ))}
        </Grid>
      </PersonalAnalyticsLayout>
      {pageArr}
    </>
  );
};

export default JournalFOSTrendPage;
