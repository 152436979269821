import { Box, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';

const Chapter4Page: FC = () => {
  return (
    <PersonalAnalyticsLayout page={16}>
      <ChapterJumbotron title="동일 분야 연구자 분석" chapterTitle="Chapter 04." />
      <HStack spacing="16px" alignItems="flex-start" mt="48px">
        <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap" w="100%">
          {' '}
          연구자의 주요 분야를 정의한 후 동일 분야 연구를 하는 연구자를 찾아 비교, 분석합니다.
          {'\n '}
          비교 지표로 앞서 사용한 연구 성과 지표들을 주로 사용합니다.
          {'\n '}
          비슷한 분야의 뛰어난 연구자들은 어디 소속의 누구인지 살펴보고, 대표 논문들을 확인하여 잠재적인 협업 가능성을
          찾아봅니다.
        </Box>
        <Box
          w="100%"
          pos="relative"
          mt="20px"
          bg="#FCEDF0"
          borderRadius="5px"
          p="24px 16px 16px 16px"
          fontSize="12px"
          lineHeight="21px"
        >
          <Box
            pos="absolute"
            top="-13px"
            left="10px"
            bg="#D3625C"
            borderRadius="5px"
            p="6px 10px"
            fontSize="15px"
            lineHeight="15px"
            color="white"
            fontWeight="bold"
          >
            지표 산출 방법
          </Box>
          <Text whiteSpace="pre-wrap">
            Pluto가 가진 Fields of Study 데이터를 이용하여 연구자의 세부 분야를 확인합니다.
            {'\n'}
            유사성을 크게 빈도수와 비율, 두 부분으로 나누어서 200점 만점의 유사 점수(Similarity Score)를 계산하였습니다.
            {'\n'}
            이후 상위 1%의 유사성을 가진 연구자 집단을 추출하였습니다.
          </Text>
        </Box>
      </HStack>
      <Box mt="117px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default Chapter4Page;
