import React, { FC } from 'react';
import { Field, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spinner } from '@chakra-ui/react';
import { FormikHelpers } from 'formik/dist/types';

export interface PendingPaperFormValues {
  reason: string;
}

interface Props {
  onSubmit: (params: PendingPaperFormValues, formikHelpers: FormikHelpers<PendingPaperFormValues>) => Promise<void>;
  onCancel: () => void;
}

const PendingPaperForm: FC<Props> = ({ onSubmit, onCancel }) => {
  return (
    <Formik<PendingPaperFormValues>
      initialValues={{ reason: '' }}
      onSubmit={onSubmit}
      validate={({ reason }) => {
        const errors: FormikErrors<{ reason: string }> = {};
        if (!reason) {
          errors.reason = 'Please insert reason';
        }
        return errors;
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box
            pos="absolute"
            bottom="-24px"
            right={0}
            minW="320px"
            bg="white"
            p={3}
            zIndex={2}
            borderRadius={4}
            border="1px solid #eee"
          >
            {isSubmitting && <Spinner />}
            {!isSubmitting && (
              <Field name="reason">
                {({ field, form }: FieldProps<string, { reason: string }>) => (
                  <FormControl isInvalid={!!form.errors.reason && form.touched.reason}>
                    <FormLabel htmlFor="reason">보류 사유</FormLabel>
                    <Input {...field} id="reason" placeholder="보류 사유를 작성해주십시오." />
                    <FormErrorMessage>{form.errors.reason}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
            <Box mt={4} d="flex" justifyContent="flex-end">
              <Button type="button" size="sm" mr={2} onClick={onCancel} colorScheme="gray">
                닫기
              </Button>
              <Button type="submit" isDisabled={isSubmitting} size="sm" colorScheme="blue">
                제출
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PendingPaperForm;
