import React, { FC } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Divider,
  Flex,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteAnalytics, editAnalytics, getAnalytics, getAnalyticsReportGroups } from '../../api/reportGroup';
import { Analytics } from '../../models/analytics';
import AddAnalyticsModal from '../../components/addAnalyticsModal';
import ReportGroupModal from '../../components/reportGroupModal';
import { useRemoveReportGroupFromAnalytics } from '../../hooks/useRemoveReportGroupFromAnalytics';
import ReportGroupStatTable from '../../components/reportGroupStatTable';
import { getCompleteProgress } from '../../helpers/getCompleteProgress';

const ReportGroupList: FC<{ analyticsId: string }> = ({ analyticsId }) => {
  const { isLoading, data: analyticsReportGroups } = useQuery(`/admin/analytics/${analyticsId}/groups`, () =>
    getAnalyticsReportGroups(analyticsId)
  );

  const { mutate: removeFromAnalytics, isLoading: isRemoving } = useRemoveReportGroupFromAnalytics({ analyticsId });

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={8}>
        <Spinner />
      </Box>
    );
  }

  if (!analyticsReportGroups?.content.length) {
    return (
      <>
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="2xl" fontWeight="bold" letterSpacing="tight" mb={2}>
            Report Group List
          </Text>
          <ReportGroupModal analyticsId={analyticsId} />
        </Flex>
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          <Text fontSize="xl">There isn't any report group yet.</Text>
        </Box>
      </>
    );
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" py={2}>
        <Text fontSize="2xl" fontWeight="bold" letterSpacing="tight" mb={2}>
          Report Group List
        </Text>
        <ReportGroupModal analyticsId={analyticsId} />
      </Flex>
      <List spacing={3}>
        {analyticsReportGroups.content.map((arg, i) => (
          <ListItem
            key={arg.group.id}
            backgroundColor={i % 2 === 0 ? '#efefef' : 'white'}
            p={2}
            borderRadius="4px"
            d="flex"
            justifyContent="space-between"
          >
            <Box>
              <Text fontWeight="bold" as={Link} to={`/report-groups/${arg.group.id}`} textColor="blue.500">
                {arg.group.name}
              </Text>
              {arg.group.affiliation ? `(${arg.group.affiliation.name})` : ''}
              <Text>type: {arg.group.type}</Text>
              <Text>note: {arg.group.note}</Text>
              <Box my={2}>
                <ReportGroupStatTable reportGroupStat={arg.stat} />
              </Box>
            </Box>
            <Flex alignItems="flex-end" flexDir="column">
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" colorScheme="blackAlpha">
                  Source URLs
                </MenuButton>
                <MenuList>
                  <MenuItem>{arg.group.source_urls}</MenuItem>
                </MenuList>
              </Menu>
              <Button
                colorScheme="red"
                size="sm"
                d="block"
                mt={1}
                disabled={isRemoving}
                onClick={() => {
                  if (window.confirm('Really delete?')) {
                    removeFromAnalytics(arg.group.id);
                  }
                }}
              >
                Remove from analytics
              </Button>
            </Flex>
          </ListItem>
        ))}
      </List>
    </>
  );
};

const AnalyticsDetail: FC = () => {
  const history = useHistory();
  const { analyticsId } = useParams<{ analyticsId: string }>();
  const queryClient = useQueryClient();
  const { isLoading, data: analytics } = useQuery(`/admin/analytics/${analyticsId}`, () => getAnalytics(analyticsId));
  const { mutateAsync: putAnalytics } = useMutation(
    `/admin/analytics/${analyticsId}`,
    (values: Partial<Analytics>) => editAnalytics({ ...values, id: parseInt(analyticsId) }),
    {
      onSuccess: (analytics) => {
        queryClient.setQueryData(`/admin/analytics/${analyticsId}`, analytics);
      },
    }
  );

  if (isLoading) {
    return (
      <Container maxW="container.xl">
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          <Spinner />
        </Box>
      </Container>
    );
  }

  if (!analytics) {
    return (
      <Container maxW="container.xl">
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          <Text fontSize="xl">There isn't any analytics yet.</Text>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxW="container.lg">
      <Breadcrumb fontWeight="medium" fontSize="xs" my={2}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/analytics">
            Analytics
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>
            {analytics.title}({analyticsId})
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt={6} pos="relative">
        <Flex pos="absolute" right={0} top={0}>
          <AddAnalyticsModal
            initialValues={analytics}
            onSubmit={async (values: Partial<Analytics>) => {
              await putAnalytics(values);
            }}
          />
          <Button
            d="block"
            colorScheme="red"
            ml={2}
            onClick={async () => {
              if (window.confirm('Really delete?')) {
                await deleteAnalytics(analytics.id);
                history.push('/analytics');
              }
            }}
          >
            Delete analytics
          </Button>
        </Flex>
        <Text fontSize="4xl" fontWeight="bold" letterSpacing="tight" lineHeight="1">
          {analytics.title}
        </Text>
        <Box mt={2}>
          <Text>Customer: {analytics.customer}</Text>
          <Text>Requirements: {analytics.requirements}</Text>
          <Text>Order date: {format(new Date(analytics.start_date), 'yyyy/MM/dd')}</Text>
          <Text>Due Date: {format(new Date(analytics.due_date), 'yyyy/MM/dd')}</Text>
          <Text>Status: {analytics.status}</Text>
          <Flex alignItems="center">
            <Box mr={2}>Progress:</Box>
            <Box w={32}>
              <Progress
                max={1}
                min={0}
                value={getCompleteProgress(analytics.stat?.mapped_report_count, analytics.stat?.total_report_count)}
                hasStripe
              />
            </Box>
            <Box ml={2}>
              {analytics.stat?.mapped_report_count ?? 0} / {analytics.stat?.total_report_count ?? 0}
            </Box>
            <Box ml={2}>
              {(((analytics.stat?.mapped_report_count ?? 0) / (analytics.stat?.total_report_count ?? 0)) * 100).toFixed(
                2
              )}
              %
            </Box>
          </Flex>
        </Box>
      </Box>
      <Divider my={4} />
      <Box>
        <ReportGroupList analyticsId={analyticsId} />
      </Box>
    </Container>
  );
};

export default AnalyticsDetail;
