import { Box, Flex, Spinner } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useMappedAnalytics } from '../../hooks/useMappedAnalytics';

interface Props {
  reportGroupId: string | number;
}

const MappedAnalytics: FC<Props> = ({ reportGroupId }) => {
  const { isLoading, data: analyticsList } = useMappedAnalytics(reportGroupId);

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={8}>
        <Spinner />
      </Box>
    );
  }

  if (!analyticsList?.length) {
    return <Box>There is no relationship with analytics yet.</Box>;
  }

  return (
    <Flex ml={-2}>
      {analyticsList.map((analytics) => (
        <Link to={`/analytics/${analytics.id}`} key={analytics.id}>
          <Box p={2} borderRadius="4px" border="1px solid #dedede" ml={2} _hover={{ backgroundColor: '#efefef' }}>
            {`#${analytics.id} ${analytics.title}`}
          </Box>
        </Link>
      ))}
    </Flex>
  );
};

export default MappedAnalytics;
