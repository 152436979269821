import { Box, Spinner } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ScinapsePaper } from '../../../models/paper';
import FoundPaperItem from './foundPaperItem';

const PaperSearchResult: FC<{
  isLoading: boolean;
  foundPapers: ScinapsePaper[] | undefined;
  onSelectPaper: (paper: ScinapsePaper) => void;
}> = ({ isLoading, foundPapers, onSelectPaper }) => {
  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" p={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Box mb={3} fontWeight="bold" fontSize="18px">
        Paper Search Result
      </Box>
      <Box>
        {foundPapers?.map((paper) => (
          <FoundPaperItem paper={paper} key={paper.id} onSelectPaper={onSelectPaper} />
        ))}
      </Box>
    </>
  );
};

export default PaperSearchResult;
