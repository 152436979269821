import React, { FC } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Spinner,
  Text,
  Heading,
} from '@chakra-ui/react';
import useSWR from 'swr';
import { User } from '../../models/user';
import { getSigningData } from '../../api/user';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  user: User | null;
}

const SignInDataModal: FC<Props> = ({ isOpen, onClose, user }) => {
  const { data, error } = useSWR(
    user ? `/admin/users/${user.id}/signing` : null,
    async () => await getSigningData(user!.id)
  );
  const isLoading = !data && !error;

  let content: JSX.Element;
  if (isLoading) {
    content = (
      <Box d="flex" justifyContent="center" alignItems="center" minH="400px">
        <Spinner size="xl" />
      </Box>
    );
  } else {
    content = (
      <Box>
        <Box>
          <Heading mb={2} as="h2" size="md">
            추천인
          </Heading>
          <Text>{data?.referrer}</Text>
        </Box>
        <Box my={6}>
          <Heading mb={2} as="h2" size="md">
            Citation Text
          </Heading>
          <Text whiteSpace="pre-wrap">{data?.paper_source}</Text>
        </Box>
      </Box>
    );
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{user?.first_name + ' ' + user?.last_name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignInDataModal;
