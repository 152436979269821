import { Box, Button, Container, Flex, Spinner, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { getReportGroup, deleteReportGroup, getReportGroupStat } from '../../api/reportGroup';
import ReportGroupStatTable from '../../components/reportGroupStatTable';
import EditReportGroupModal from '../../components/editReportGroupModal';
import GroupReportList from '../../components/groupReportList';
import GroupReportListModal from '../../components/groupReportListModal';
import KeyValueBox from '../../components/keyValueBox';
import MappedAnalytics from '../../components/mappedAnalytics';
import { useMappedAnalytics } from '../../hooks/useMappedAnalytics';
import { useQueryParams } from '../../hooks/useQueryParams';
import { Professorship } from '../../types/proferssorship';

const ProfessorshipBox: FC<{ reportGroupId: string }> = ({ reportGroupId }) => {
  const { data: reportGroupStat, isLoading } = useQuery(`/admin/groups/${reportGroupId}/stat`, () =>
    getReportGroupStat(reportGroupId)
  );

  if (isLoading || !reportGroupStat) {
    return null;
  }

  return <ReportGroupStatTable reportGroupStat={reportGroupStat} />;
};

const ReportGroupDetail: FC = () => {
  const history = useHistory();
  const { page: rawPage, mn: rawMN, ps: rawPS } = useQueryParams();
  const page = parseInt(rawPage ?? 1);
  const mappingNeeded = rawMN === 'true';
  const professorshipFilter = rawPS as Professorship | undefined;
  const { reportGroupId } = useParams<{ reportGroupId: string }>();
  const { data: reportGroup, isLoading } = useQuery(`/admin/groups/${reportGroupId}`, () =>
    getReportGroup(reportGroupId)
  );
  const { mutate: deleteGroup } = useMutation(
    `/admin/groups/${reportGroupId}`,
    () => deleteReportGroup(reportGroupId),
    {
      onSuccess: () => {
        history.goBack();
      },
    }
  );
  const { data: mappedAnalyticsList } = useMappedAnalytics(reportGroupId);

  if (isLoading) {
    return (
      <Container minW="container.lg">
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          <Spinner />
        </Box>
      </Container>
    );
  }

  if (!reportGroup) {
    return (
      <Container minW="container.lg">
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          There is no report group yet.
        </Box>
      </Container>
    );
  }

  return (
    <Container minW="container.lg" py={4}>
      <Flex justifyContent="space-between">
        <Box>
          <Text fontSize="3xl" fontWeight="bold" letterSpacing="tight">
            {reportGroup.name}
          </Text>
          <KeyValueBox title="Affiliation" value={reportGroup.affiliation?.name} />
          <KeyValueBox title="Type" value={reportGroup.type} />
          <KeyValueBox title="Note" value={reportGroup.note} />
          <KeyValueBox
            title="Status"
            value={`${reportGroup.mapped_report_count ?? 0} / ${reportGroup.total_report_count ?? 0} (mapped / total)`}
          />
          <KeyValueBox title="Source URLs" value={reportGroup.source_urls} />
        </Box>
        <Flex flexDir="column" alignItems="flex-end">
          <EditReportGroupModal reportGroup={reportGroup} />
          <Button
            colorScheme="red"
            size="sm"
            mt={1}
            disabled={mappedAnalyticsList && mappedAnalyticsList.length > 0}
            onClick={() => {
              if (window.confirm('Really DELETE this report group? (리포트 그룹 자체가 삭제됩니다.)')) {
                deleteGroup();
              }
            }}
          >
            Delete
          </Button>
        </Flex>
      </Flex>
      {/* -- Mapped analytics section -- */}
      <Box my={8}>
        <Text fontSize="2xl" fontWeight="bold" mb={2}>
          Related Analytics
        </Text>
        <MappedAnalytics reportGroupId={reportGroupId} />
      </Box>
      <Box my={8}>
        <Text fontSize="2xl" fontWeight="bold" letterSpacing="tight" mb={2}>
          Professorship status
        </Text>
        <ProfessorshipBox reportGroupId={reportGroupId} />
      </Box>
      <Box my={8}>
        <Flex justifyContent="space-between">
          <Text fontSize="2xl" fontWeight="bold" letterSpacing="tight" mb={2}>
            Report List
          </Text>
          <GroupReportListModal reportGroup={reportGroup} />
        </Flex>
        <GroupReportList
          reportGroupId={reportGroupId}
          page={page}
          mappingNeededOnly={mappingNeeded}
          professorship={professorshipFilter}
        />
      </Box>
    </Container>
  );
};

export default ReportGroupDetail;
