import React, { FC } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { Report } from '../../models/report';
import KeyValueBox from '../keyValueBox';

const ReportSummary: FC<{ report: Report }> = ({ report }) => {
  return (
    <>
      <Text fontSize="4xl" fontWeight="bold" letterSpacing="tight">
        #{report.id} {report.name} {report.kor_name ? `(${report.kor_name})` : ''}
      </Text>
      <VStack spacing={1} align="flex-start" mt={4}>
        <KeyValueBox titleWidth={48} title="Name" value={report.name} />
        <KeyValueBox titleWidth={48} title="korean name" value={report.kor_name} />
        <KeyValueBox titleWidth={48} title="Email" value={report.email} />
        <KeyValueBox titleWidth={48} title="Professorship" value={report.professorship} />
        <KeyValueBox titleWidth={48} title="Job Title" value={report.job_title} />
        <KeyValueBox
          titleWidth={48}
          title="Affiliation"
          value={report.affiliation && `#${report.affiliation?.id} ${report.affiliation?.name}`}
        />
        <KeyValueBox titleWidth={48} title="Note" value={report.system_note} />
        <KeyValueBox titleWidth={48} title="Paper count" value={report.paper_count} />
        <KeyValueBox titleWidth={48} title="Left candidate count" value={report.left_candidate_count} />
        <KeyValueBox titleWidth={48} title="Source URLs" value={report.sources_urls} />
      </VStack>
    </>
  );
};

export default ReportSummary;
