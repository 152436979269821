import React, { FC } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';

interface Props {
  initialQuery: string;
  onSearch: (query: string) => void;
}

const SearchReportInput: FC<Props> = ({ initialQuery, onSearch }) => {
  return (
    <Formik<{ query: string }>
      onSubmit={({ query }) => {
        onSearch(query);
      }}
      initialValues={{ query: initialQuery }}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <Flex>
            <Field name="query">
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={!!form.errors.query && !!form.touched.query}>
                  <Input {...field} id="query" placeholder="Tyler Shin" />
                  <FormErrorMessage>{form.errors.query}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button ml={2} disabled={isSubmitting}>
              검색
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default SearchReportInput;
