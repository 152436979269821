import { atom } from 'recoil';
import { CandidatePaper } from '../models/paper';

export enum MATCH_STEP {
  SELECT_PAPER,
  EDIT_PAPER,
}

interface SimpleMatchCandidatePaperModalState {
  isOpen: boolean;
  step: MATCH_STEP;
  candidatePaper: CandidatePaper | null;
  originalPaperId: string | null;
}

export const INITIAL_SIMPLE_MATCH_CANDIDATE_PAPER_MODAL_STATE: SimpleMatchCandidatePaperModalState = {
  isOpen: false,
  step: MATCH_STEP.SELECT_PAPER,
  candidatePaper: null,
  originalPaperId: null,
};

export const simpleMatchCandidatePaperModalState = atom<SimpleMatchCandidatePaperModalState>({
  key: 'simpleMatchCandidatePaperModalState',
  default: INITIAL_SIMPLE_MATCH_CANDIDATE_PAPER_MODAL_STATE,
});
