import React, { FC } from 'react';
import { Box, Checkbox } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { venueDraftState } from '../../atoms/venueDraft';

const RemoveVenue: FC = () => {
  const [{ removeJournal, removeConference }, setVenueDraft] = useRecoilState(venueDraftState);

  return (
    <>
      <Box>Venue 정보 삭제하기</Box>
      <Checkbox
        isChecked={removeJournal}
        onChange={(e) => setVenueDraft((prev) => ({ ...prev, removeJournal: e.currentTarget.checked }))}
      >
        Journal 정보 삭제하기
      </Checkbox>
      <Checkbox
        ml={2}
        isChecked={removeConference}
        onChange={(e) => setVenueDraft((prev) => ({ ...prev, removeConference: e.currentTarget.checked }))}
      >
        Conference 정보 삭제하기
      </Checkbox>
    </>
  );
};

export default RemoveVenue;
