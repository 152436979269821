import React, { FC, useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
export interface ChartData {
  year: number;
  pub_cnt: number;
  cit_cnt: number;
  journal_pub_cnt: number;
  journal_cit_cnt: number;
  avg_if: number;
  jcr_top10_pub_cnt: number;
  jcr_top25_pub_cnt: number;
  jv_top10_pub_cnt: number;
  jv_top25_pub_cnt: number;
  avg_6months_cit_cnt: number;
  avg_12months_cit_cnt: number;
  h5index: number;
  avg_jv_percent_rank: number;
}

interface Props {
  chartData: ChartData[];
}

const PerformanceCitationTrendGraph: FC<Props> = ({ chartData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    let data = chartData;

    data.sort((a, b) => a.year - b.year);

    const chart = am4core.create(chartRef.current, am4charts.XYChart);
    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.legend.fontSize = '12px';

    chart.data = data
      .filter((datum) => datum.year > 2010 && datum.year < 2021)
      .map((datum) => ({ ...datum, year: new Date(datum.year, 0) }));

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.date = 'year';
    dateAxis.dateFormats.setKey('year', 'yyyy');
    dateAxis.title.text = 'YEAR';
    dateAxis.title.fontSize = '10px';
    dateAxis.fontSize = '10px';
    dateAxis.renderer.minGridDistance = 30;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'COUNT';
    valueAxis.title.fontSize = '10px';
    valueAxis.fontSize = '10px';
    valueAxis.renderer.minGridDistance = 18;
    valueAxis.min = 0;

    const series = chart.series.push(new am4charts.LineSeries());
    series.name = 'Average 6M Citation Count';
    series.dataFields.valueY = 'avg_6months_cit_cnt';
    series.dataFields.dateX = 'year';
    series.tooltipText = '{dateX}: [b]{valueY}[/]';
    series.strokeWidth = 4;
    series.stroke = am4core.color('#536DFE');

    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.name = 'Average 12M Citation Count';
    series2.dataFields.valueY = 'avg_12months_cit_cnt';
    series2.dataFields.dateX = 'year';
    series2.tooltipText = '{dateX}: [b]{valueY}[/]';
    series2.strokeWidth = 4;
    series2.stroke = am4core.color('#A0B8FF');

    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return <div ref={chartRef} style={{ width: '634px', height: '320px' }} />;
};

export default PerformanceCitationTrendGraph;
