import { Button, Link } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { issueFastTrackSampleToken } from '../../api/fastTrack';
import produce from 'immer';
import { FastTrackReport, FastTrackToken } from '../../models/fastTrackReport';
import SendColdMailButton from './sendColdMailButton';

const IssueSampleTokenButton: FC<{ token: FastTrackToken | null; fastTrackReport: FastTrackReport }> = ({
  token,
  fastTrackReport,
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: issueToken, isLoading } = useMutation(
    '/admin/fast-track/reports',
    async (id: string | number) => {
      if (window.confirm('Do you really want to issue a new token?')) {
        return await issueFastTrackSampleToken(id);
      }
    },
    {
      onSuccess: (fastTrackReport) => {
        if (!fastTrackReport) return;
        const candidates = queryClient.getQueryData<FastTrackReport[]>(['/admin/fast-track/reports']) ?? [];
        const index = candidates.findIndex((oldReport) => oldReport.report.id === fastTrackReport.report.id);
        if (index > -1) {
          const fastTrackReports = produce(candidates, (draft) => {
            draft[index] = fastTrackReport;
          });

          queryClient.setQueryData(['/admin/fast-track/reports'], fastTrackReports);
        }
      },
    }
  );

  if (token) {
    return (
      <>
        <Link href={`https://www.xenure.com/landing?token=${token.token}`} d="block" isExternal color="blue">
          {token.token}
        </Link>
        <Button
          mt={2}
          mr={2}
          size="sm"
          colorScheme="green"
          disabled={isLoading}
          onClick={() => issueToken(fastTrackReport.report.id)}
        >
          Issue
        </Button>
        <SendColdMailButton report={fastTrackReport.report} />
      </>
    );
  }

  return (
    <>
      <Button size="sm" colorScheme="green" disabled={isLoading} onClick={() => issueToken(fastTrackReport.report.id)}>
        Issue
      </Button>
    </>
  );
};
export default IssueSampleTokenButton;
