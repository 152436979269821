import React, { FC } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Button } from '@chakra-ui/react';
import { CandidatePaper } from '../../models/paper';
import { mutate } from 'swr';
import { matchModalState } from '../../atoms/matchModalState';
import PaperStatusIcon from '../candidatePaperItem/paperStatusIcon';
import { PendingPaperFormValues } from '../candidatePaperItem/pendingPaperForm';
import { FormikHelpers } from 'formik/dist/types';
import PendingCandidatePaperButton from '../candidatePaperItem/pendingCandidatePaperButton';
import { makeCandidatePaperPendingState } from '../../api/candidates';
import { selectedReportState } from '../../atoms/selectedReport';

/**
 * @deprecated Use candidatePaperTable
 */
const RawPaperItem: FC<{
  raw: CandidatePaper;
  onClickRemove: (paper: CandidatePaper) => void;
}> = ({ raw: p, onClickRemove }) => {
  const setMatchModalState = useSetRecoilState(matchModalState);
  const [selectedReport] = useRecoilState(selectedReportState);

  async function handleSubmitPendingPaper(
    { reason }: PendingPaperFormValues,
    { setSubmitting }: FormikHelpers<PendingPaperFormValues>
  ) {
    if (!selectedReport) throw new Error('You should select a user first');

    await makeCandidatePaperPendingState({ candidatePaperId: p.id, reason });

    await mutate(
      `/admin/reports/${selectedReport.id}/candidates`,
      (rawPapers: CandidatePaper[]) => {
        const i = rawPapers?.findIndex((raw) => raw.id === p.id);
        return [
          ...rawPapers.slice(0, i),
          { ...rawPapers[i], match_pending: true, pending_reason: reason },
          ...rawPapers.slice(i + 1),
        ];
      },
      false
    );
    setSubmitting(false);
  }

  return (
    <>
      <tr
        style={{
          margin: '12px 0',
          border: '1px solid #ddd',
          borderRadius: '4px',
        }}
      >
        <td>{p.id}</td>
        <td>
          <PaperStatusIcon paper={p} />
        </td>
        <td>{!!p.match_paper ? `${p.match_paper.title} (paper_id: ${p.match_paper.id})` : p.ner_title}</td>
        <td>{p.source.slice(0, 100)}</td>
        <td>{p.match_score?.toFixed(2)}</td>
        <td>{p.match_paper?.journal?.title || 'NOT FOUND'}</td>
        <td>{p.pending_reason || p.match_error || ''}</td>
        <td style={{ padding: '8px 0' }}>
          <Button
            onClick={() => {
              setMatchModalState((prev) => ({ ...prev, isOpen: true, selectedRaw: p }));
            }}
            mb={2}
          >
            매치하기
          </Button>
          <PendingCandidatePaperButton onSubmit={handleSubmitPendingPaper} />
          <Button onClick={() => onClickRemove(p)} colorScheme="red">
            삭제하기
          </Button>
        </td>
      </tr>
    </>
  );
};

export default RawPaperItem;
