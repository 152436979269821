import React, { FC, useEffect } from 'react';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { INITIAL_MATCH_MODAL_STATE, MATCHING_STEP, matchModalState } from '../../atoms/matchModalState';
import ModifyPaperModal from '../modifyPaperModal';
import SelectPaperModal from '../selectPaperModal';
import { ScinapsePaper } from '../../models/paper';
import { INITIAL_VENUE_DRAFT_STATE, venueDraftState } from '../../atoms/venueDraft';

const ModifyModal: FC = () => {
  const [matchState, setMatchState] = useRecoilState(matchModalState);
  const setVenueDraftState = useSetRecoilState(venueDraftState);
  const { step, isOpen, selectedRaw } = matchState;

  async function handleSelectPaper(paper: ScinapsePaper) {
    setMatchState((prev) => ({ ...prev, step: MATCHING_STEP.MODIFY_PAPER_INFORMATION, selectedPaperId: paper.id }));
  }

  useEffect(() => {
    if (!isOpen) {
      // when modal is closed
      setMatchState(INITIAL_MATCH_MODAL_STATE);
      setVenueDraftState(INITIAL_VENUE_DRAFT_STATE);
    }
  }, [isOpen, setMatchState, setVenueDraftState]);

  return (
    <Modal
      onClose={() => setMatchState(INITIAL_MATCH_MODAL_STATE)}
      isOpen={isOpen}
      size="full"
      scrollBehavior="outside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {step === MATCHING_STEP.SELECT_PAPER && selectedRaw && (
        <SelectPaperModal candidatePaper={selectedRaw} onSelectPaper={handleSelectPaper} />
      )}
      {step === MATCHING_STEP.MODIFY_PAPER_INFORMATION && <ModifyPaperModal />}
    </Modal>
  );
};

export default ModifyModal;
