import React, { FC } from 'react';
import { Box, Flex, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useReportRawData } from '../../../hooks/useReportRawData';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { usePageComment } from '../../../hooks/usePageComment';
import PubCntAvgIfH5IndexGraph, {
  ChartData,
} from '../../../components/personalAnalytics/graphs/pubCntAvgIfH5IndexGraph';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import _ from 'lodash';
import {
  PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH,
  PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH,
} from '../../../constants/personalReport';

interface Props {
  personalReportId: number;
  dataId: string;
}

const PerformanceTrendAvgIfPubCntH5Index: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const chartData = parseStringifiedJSONRawData(rawData?.report_json_data) as ChartData[];
  if (!chartData) return null;

  const chartDataForComments = chartData.filter(
    ({ year }) => year >= PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH && year <= PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH
  );
  const chartLength = chartDataForComments.length;
  const predefinedComments = [
    `최근 ${chartLength}년간 평균적으로 ${_.meanBy(chartDataForComments, 'pub_cnt').toFixed(
      2
    )}편의 논문을 작성하였습니다.`,
    `최근 ${chartLength}년간 평균적으로 ${_.meanBy(chartDataForComments, 'avg_if').toFixed(
      2
    )}의 저널 Impact factor를 가지고 있습니다.`,
    `최근 ${chartLength}년간 평균적으로 ${_.meanBy(chartDataForComments, 'h5index').toFixed(
      2
    )}의 h5-index를 나타내고 있습니다.`,
  ];

  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout page={8}>
      <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
        개인 연구 트랜드
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
        종합 지표 분석
      </Box>
      <Flex justifyContent="center" alignItems="center" mb="34px">
        <PubCntAvgIfH5IndexGraph chartData={chartData} />
      </Flex>
      <HStack spacing="16px" alignItems="flex-start" mt="42px">
        <Box>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px" whiteSpace="pre-wrap">
            {' '}
            대표 지표 평균 Impact Factor, h5-index 그리고 출판수에 대한 결과입니다.
          </Box>
          <UnorderedList
            p="13px 15px"
            fontSize="12px"
            lineHeight="21px"
            bg="#EBEEFD"
            padding="15px 13px"
            listStylePosition="inside"
            m="0"
          >
            {comments?.map((comment, i) => (
              <ListItem key={i}>{comment}</ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box
          pos="relative"
          w="201px"
          bg="#FCEDF0"
          borderRadius="5px"
          p="26px 17px 22px 17px"
          fontSize="12px"
          lineHeight="21px"
          flex="1 0 auto"
          wordBreak="break-all"
        >
          <Box
            pos="absolute"
            left="16px"
            top="-14px"
            fontSize="15px"
            lineHeight="16px"
            p="6px 12px"
            borderRadius="5px"
            bg="#D3625C"
            color="white"
            wordBreak="break-all"
          >
            대표 지표
          </Box>
          <Box fontWeight="bold">h5-index</Box>
          <Text>
            2016년부터 2020년까지 발표된 논문에 대해서 계산된 h-index를 의미합니다. 최근 5년간 얼마나 활발하게 연구를
            수행하였는지를 양적/질적인 측면을 모두 고려하여 확인할 수 있는 지표입니다.
          </Text>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default PerformanceTrendAvgIfPubCntH5Index;
