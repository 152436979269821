import { FormControl, FormErrorMessage, FormLabel, Input, Select, VStack, Button } from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { User } from '../../models/user';

type FormValues = Pick<User, 'first_name' | 'last_name' | 'email' | 'status' | 'channel' | 'system_note'>;

interface Props {
  initialValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
}

const UserForm: FC<Props> = ({ initialValues, onSubmit }) => {
  return (
    <Formik<FormValues>
      initialValues={{
        first_name: initialValues?.first_name ?? '',
        last_name: initialValues?.last_name ?? '',
        email: initialValues?.email ?? '',
        status: initialValues?.status || null,
        channel: initialValues?.channel || null,
        system_note: initialValues?.system_note ?? '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (err) {
          alert(err.response?.data?.error?.message || err.message);
        }
        setSubmitting(false);
      }}
    >
      {() => {
        return (
          <Form>
            <VStack spacing={2}>
              <Field name="first_name">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl isInvalid={!!form.errors.first_name && form.touched.first_name}>
                    <FormLabel>First Name</FormLabel>
                    <Input {...field} placeholder="First name" />
                    <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="last_name">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl isInvalid={!!form.errors.last_name && form.touched.last_name}>
                    <FormLabel>Last Name</FormLabel>
                    <Input {...field} placeholder="LastName" />
                    <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl isInvalid={!!form.errors.email && form.touched.email}>
                    <FormLabel>Email</FormLabel>
                    <Input {...field} placeholder="Email" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="status">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl isInvalid={!!form.errors.status && form.touched.status}>
                    <FormLabel>Status</FormLabel>
                    <Select {...field} value={field.value || ''}>
                      <option value="">-</option>
                      <option value="SAMPLE">SAMPLE</option>
                      <option value="IN_TRANSITION">IN TRANSITION</option>
                      <option value="PENDING">PENDING</option>
                      <option value="CONFIRMED">CONFIRMED</option>
                    </Select>
                    <FormErrorMessage>{form.errors.status}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="channel">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl isInvalid={!!form.errors.channel && form.touched.channel}>
                    <FormLabel>Channel</FormLabel>
                    <Select {...field} value={field.value || ''}>
                      <option value="">-</option>
                      <option value="ORGANIC">ORGANIC</option>
                      <option value="TEMP">TEMP</option>
                      <option value="SAMPLE">SAMPLE</option>
                      <option value="CONSULTING">CONSULTING</option>
                    </Select>
                    <FormErrorMessage>{form.errors.channel}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="system_note">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl isInvalid={!!form.errors.system_note && form.touched.system_note}>
                    <FormLabel>System Note</FormLabel>
                    <Input {...field} placeholder="System Note" />
                    <FormErrorMessage>{form.errors.system_note}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button type="submit" colorScheme="teal">
                Change
              </Button>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserForm;
