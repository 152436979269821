import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';

const InformationBox: FC<{ title: string; content: string | undefined | null }> = ({ title, content }) => {
  if (!content) return <Box />;

  return (
    <Box border="1px solid #dedede" borderRadius="4px" ml={2} _first={{ marginLeft: 0 }} flex="1">
      <Box p={2} borderBottom="1px solid #dedede" textAlign="center">
        {title}
      </Box>
      <Box p={2}>{content}</Box>
    </Box>
  );
};

export default InformationBox;
