export function parseStringifiedJSONRawData(rawData: any) {
  if (!rawData) return null;

  try {
    if (Array.isArray(rawData)) {
      return rawData.map((datum) => JSON.parse(datum));
    }

    return JSON.parse(rawData);
  } catch (err) {
    console.error(err);
  }
}
