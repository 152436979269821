import {
  Box,
  VStack,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
} from '@chakra-ui/react';
import produce from 'immer';
import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { updateGraphMetadata } from '../../../api/personalReport';
import { getAxisBreakOptionFromPersonalReportMatadata } from '../../../helpers/getAxisBreakOptionFromPersonalReportMetadata';
import { usePersonalReport } from '../../../hooks/usePersonalReport';
import KeyValueBox from '../../keyValueBox';
import { AxisBreakOption } from '../types';

const AxisBreakOptionSelector: FC<{ personalReportId: number; dataId: string }> = ({ personalReportId, dataId }) => {
  const queryClient = useQueryClient();
  const [include, setInclude] = useState(false);
  const [axisInputValues, setAxisInputValues] = useState<AxisBreakOption>({
    startValue: 0,
    endValue: 0,
    breakSize: 0,
  });
  const { data: personalReport } = usePersonalReport(personalReportId);

  const { mutate: updateMetadata } = useMutation(updateGraphMetadata, {
    onSuccess: (report) => {
      queryClient.setQueryData([`/admin/personal-report/${personalReportId}`], report);
    },
  });

  useEffect(() => {
    if (!personalReport) return;
    const axisOption = getAxisBreakOptionFromPersonalReportMatadata(personalReport, dataId);
    if (!axisOption) return;
    setAxisInputValues(axisOption);
  }, [dataId, personalReport]);

  async function handleSaveOption() {
    const rawPrevMetadata = personalReport!.graph_meta_data;
    let prevMetadata: { [dataId: string]: any } = {};
    if (rawPrevMetadata) {
      prevMetadata = JSON.parse(rawPrevMetadata);
    }

    if (!prevMetadata[dataId]) {
      prevMetadata[dataId] = {};
    }

    const newMetadata = {
      ...prevMetadata,
      [dataId]: {
        ...prevMetadata[dataId],
        axisBreak: axisInputValues,
      },
    };

    updateMetadata({ personalReportId, graphMetaData: JSON.stringify(newMetadata) });
  }

  function handleChangeOption(option: keyof AxisBreakOption, value: number) {
    setAxisInputValues(
      produce(axisInputValues, (draft) => {
        draft[option] = value;
      })
    );
  }

  return (
    <Box p={2} my={2}>
      <Box fontWeight="bold" mb={1}>
        Axis Break Settings
      </Box>
      <Checkbox
        isChecked={include}
        onChange={(e) => {
          if (!e.target.checked) {
            setAxisInputValues({ startValue: 0, endValue: 0, breakSize: 0 });
          }
          setInclude(e.target.checked);
        }}
        mb={2}
      >
        Include axis break
      </Checkbox>
      {include && (
        <VStack spacing="8px" alignItems="flex-start">
          <KeyValueBox
            title="start"
            value={
              <NumberInput
                value={axisInputValues.startValue}
                onChange={(value) => handleChangeOption('startValue', Number(value))}
                size="sm"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            }
          />
          <KeyValueBox
            title="end"
            value={
              <NumberInput
                value={axisInputValues.endValue}
                onChange={(value) => handleChangeOption('endValue', Number(value))}
                size="sm"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            }
          />
          <KeyValueBox
            title="break size"
            value={
              <NumberInput
                value={axisInputValues.breakSize}
                onChange={(value) => handleChangeOption('breakSize', Number(value))}
                precision={3}
                step={0.001}
                size="sm"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            }
          />
        </VStack>
      )}
      <Button d="block" colorScheme="blue" onClick={handleSaveOption} size="sm" mt={2}>
        Apply
      </Button>
    </Box>
  );
};

export default AxisBreakOptionSelector;
