import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';

const Chapter7Page: FC = () => {
  return (
    <PersonalAnalyticsLayout>
      <ChapterJumbotron title="주요 연구 분야 내 주목 할 만한 논문" chapterTitle="Chapter 07." />
      <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap" maxW="417px" mt="46px">
        {' '}
        연구자의 주요 연구 분야 내 유망 연구분야를 탐색합니다. 어떤 논문들이 트렌드를 주도하고 있으며 저자들은 누구인지,
        어떤 소속인지 살펴봅니다. 이를 통해서 잠재적인 연구 분야를 발굴하거나 향후 협업 가능성을 찾아볼 수 있습니다.
      </Box>
      <Box mt="117px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default Chapter7Page;
