import { Box, Spinner, List, ListItem, Flex, Badge, Text, Button, Select, Checkbox } from '@chakra-ui/react';
import { formatDistanceToNowStrict } from 'date-fns';
import produce from 'immer';
import { parse, stringify } from 'qs';
import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { updateMappedReportProfessorship } from '../../api/reportGroup';
import { useRemoveReportFromGroup } from '../../hooks/useRemoveReportFromGroup';
import { useReportsInGroup } from '../../hooks/useReportsInGroup';
import { GroupReport } from '../../models/reportGroup';
import { Professorship } from '../../types/proferssorship';
import KeyValueBox from '../keyValueBox';
import { PageableData } from '../../types/pageableContent';

interface Props {
  reportGroupId: string;
  page: number;
  mappingNeededOnly: boolean | undefined;
  professorship: Professorship | undefined;
}
const GroupReportList: FC<Props> = ({ reportGroupId, page, mappingNeededOnly, professorship }) => {
  const location = useLocation();
  const history = useHistory();
  const params = {
    reportGroupId,
    size: 1000,
    page,
    mappingNeededOnly,
    professorship,
  };
  const { isLoading, data: groupReports } = useReportsInGroup(params);
  const { mutate: removeFromGroup, isLoading: isRemoving } = useRemoveReportFromGroup({ reportGroupId });
  const queryClient = useQueryClient();
  const { mutate: updateProfessorship } = useMutation(updateMappedReportProfessorship, {
    onMutate: async ({ reportId, professorship }) => {
      const queryKey = [`/admin/groups/${reportGroupId}/reports`, params];
      await queryClient.cancelQueries(queryKey);
      const oldData = queryClient.getQueryData<PageableData<GroupReport>>(queryKey);

      queryClient.setQueryData(
        queryKey,
        produce(oldData, (draft) => {
          const i = draft?.content.findIndex((gr) => gr.report.id === reportId);
          if (typeof i !== 'undefined' && i > -1) {
            draft!.content[i].professorship = professorship;
          }
        })
      );

      return { oldData };
    },
    onError: (err: any, _, context: any) => {
      alert(err.response?.data?.error?.message || err.message);
      queryClient.setQueryData(`/admin/groups/${reportGroupId}/reports`, context.oldData);
    },
  });

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={8}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      <Flex my={4} border="1px solid #dedede" py={4} px={2} borderRadius="4px">
        <Checkbox
          size="md"
          defaultIsChecked={!!mappingNeededOnly}
          onChange={() => {
            history.push({
              pathname: location.pathname,
              search: stringify(
                { ...parse(location.search, { ignoreQueryPrefix: true }), mn: !mappingNeededOnly },
                { addQueryPrefix: true }
              ),
            });
          }}
        >
          Mapping needed
        </Checkbox>
        <Flex ml={8} alignItems="center">
          <Text>Professorship</Text>
          <Select
            value={professorship ?? ''}
            w={48}
            ml={2}
            onChange={(e) => {
              history.push({
                pathname: location.pathname,
                search: stringify(
                  { ...parse(location.search, { ignoreQueryPrefix: true }), ps: e.currentTarget.value },
                  { addQueryPrefix: true }
                ),
              });
            }}
          >
            <option value="">ALL</option>
            <option value="FULL">FULL</option>
            <option value="ASSOCIATE">ASSOCIATE</option>
            <option value="ASSISTANT">ASSISTANT</option>
            <option value="EMERITUS">EMERITUS</option>
            <option value="ADJUNCT">ADJUNCT</option>
            <option value="UNKNOWN">UNKNOWN</option>
          </Select>
        </Flex>
      </Flex>
      <List>
        {!groupReports?.content.length && (
          <Box textAlign="center" mt={4}>
            List is empty.
          </Box>
        )}
        {groupReports?.content.map((groupReport) => (
          <ListItem key={groupReport.report.id} my={2} p={2} border="1px solid #efefef" borderRadius="4px" d="block">
            <Flex justifyContent="space-between">
              <Box>
                <Flex alignItems="center">
                  <Text
                    as={Link}
                    textColor="blue.500"
                    to={`/reports/${groupReport.report.id}`}
                    fontWeight="bold"
                    fontSize="lg"
                  >
                    {groupReport.report.name}
                  </Text>
                  <Badge fontSize="0.6rem" colorScheme="green" ml={1}>
                    {groupReport.report.data_status ?? 'NONE'}
                  </Badge>
                  {!!groupReport.report.last_confirmed_at && (
                    <Badge fontSize="0.6rem" colorScheme="purple" ml={1}>
                      CONFIRMED
                    </Badge>
                  )}
                  <Badge fontSize="0.6rem" colorScheme="blue" ml={1}>
                    {groupReport.professorship}
                  </Badge>
                  {groupReport.left_candidate_count > 0 && (
                    <Badge fontSize="0.6rem" colorScheme="red" ml={1}>
                      Mapping Needed
                    </Badge>
                  )}
                </Flex>
                <Flex>
                  <Box>
                    <KeyValueBox titleWidth={32} title="Id" value={groupReport.report.id} />
                    <KeyValueBox titleWidth={32} title="Affiliation" value={groupReport.report.affiliation?.name} />
                    <KeyValueBox titleWidth={32} title="Email" value={groupReport.report.email} />
                    <KeyValueBox titleWidth={32} title="Source URLs" value={groupReport.report.sources_urls} />
                    <KeyValueBox titleWidth={32} title="Memo" value={groupReport.report.system_note} />
                  </Box>
                  <Box ml={4}>
                    {groupReport.report.data_processed_at && (
                      <Text>
                        Data processed at:{' '}
                        {formatDistanceToNowStrict(new Date(groupReport.report.data_processed_at), {
                          addSuffix: true,
                          unit: 'day',
                        })}
                      </Text>
                    )}
                    <KeyValueBox titleWidth={56} title="Paper Count" value={groupReport.report.paper_count} />
                    <KeyValueBox
                      titleWidth={56}
                      title="Jounral paper count"
                      value={groupReport.report.paper_count_journal}
                    />
                    <KeyValueBox
                      titleWidth={56}
                      title="Conference paper count"
                      value={groupReport.report.paper_count_conference}
                    />
                    <KeyValueBox
                      titleWidth={56}
                      title="Left candidate paper count"
                      value={groupReport.left_candidate_count}
                    />
                    <KeyValueBox
                      titleWidth={56}
                      title="Last confirmed at"
                      value={groupReport.report.last_confirmed_at}
                    />
                  </Box>
                </Flex>
              </Box>
              <Flex>
                <Select
                  size="sm"
                  value={groupReport.professorship}
                  onChange={(e) =>
                    updateProfessorship({
                      reportId: groupReport.report.id,
                      reportGroupId: reportGroupId,
                      professorship: e.currentTarget.value as Professorship,
                    })
                  }
                  alignItems="center"
                >
                  <option value="FULL">FULL</option>
                  <option value="ASSOCIATE">ASSOCIATE</option>
                  <option value="ASSISTANT">ASSISTANT</option>
                  <option value="EMERITUS">EMERITUS</option>
                  <option value="ADJUNCT">ADJUNCT</option>
                  <option value="UNKNOWN">UNKNOWN</option>
                </Select>
                <Button
                  onClick={() => {
                    if (window.confirm('really unselect this report from group?')) {
                      removeFromGroup(groupReport.report.id);
                    }
                  }}
                  size="sm"
                  colorScheme="red"
                  disabled={isRemoving}
                  ml={4}
                >
                  Unselect
                </Button>
              </Flex>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default GroupReportList;
