import React, { FC } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useAuthorship } from '../../hooks/useAuthorship';
import { PDFSource, WebSource } from '../../models/authorship';
import CollapsedCandidateList from './collapsedCandidateList';
import { default as CheckBox } from '../statusBox';

interface Props {
  publicationId: string;
}

const StatusBoxWrapper: FC<{ title: string; content: JSX.Element }> = ({ title, content }) => {
  return (
    <Box border="1px solid #dedede" borderRadius="4px">
      <Box borderBottom="1px solid #dedede">
        <Text p={2} fontSize="lg" fontWeight="bold">
          {title}
        </Text>
      </Box>
      <Box p={2}>{content}</Box>
    </Box>
  );
};

const StatusBox: FC<{
  source: WebSource | PDFSource | null;
  reverseNotFoundCofiColor: boolean;
  reverseNotFoundCorrColor: boolean;
}> = ({ source, reverseNotFoundCofiColor, reverseNotFoundCorrColor }) => {
  if (!source) return <Box>별 볼 일 없는 놈</Box>;

  function getColor(foundSign: 'NOT_FOUND' | 'MAYBE' | 'FOUND' | undefined, reverse: boolean) {
    if (foundSign === 'MAYBE') return '#F6E05E';
    if (foundSign === 'FOUND') return '#38A169';
    if (reverse) return '#38A169';
    return '#E53E3E';
  }

  if (source.source_type === 'PDF') {
    return (
      <Box>
        <Box>
          조회 성공: <CheckBox valid={source.get_source} />
        </Box>
        <Box>
          <Box>
            CoFirst 정보 존재:{' '}
            <Text as="span" color={getColor(source.cofi_authorship?.found_sign, reverseNotFoundCofiColor)}>
              {source.cofi_authorship?.found_sign || 'NOT FOUND'}
            </Text>
          </Box>
        </Box>
        <Box>
          <Box>
            Corresponding 정보 존재:{' '}
            <Text as="span" color={getColor(source.corr_authorship?.found_sign, reverseNotFoundCorrColor)}>
              {source.corr_authorship?.found_sign || 'NOT FOUND'}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        조회 성공: <CheckBox valid={source.get_source} />
      </Box>
      <Box>
        알려진 출처: <CheckBox valid={source.known_source} />
      </Box>
      <Box>출처: {source.source_name}</Box>
      <Box>
        <Box>
          CoFirst 정보 존재:{' '}
          <Text as="span" color={getColor(source.cofi_authorship?.found_sign, reverseNotFoundCofiColor)}>
            {source.cofi_authorship?.found_sign || 'NOT FOUND'}
          </Text>
        </Box>
        CoFirst 정보 매핑 성공: <CheckBox valid={!!source.cofi_authorship?.match_sign} />
      </Box>
      <Box>
        <Box>
          Corresponding 정보 존재:{' '}
          <Text as="span" color={getColor(source.corr_authorship?.found_sign, reverseNotFoundCorrColor)}>
            {source.corr_authorship?.found_sign || 'NOT FOUND'}
          </Text>
        </Box>
        Corresponding 정보 매핑 성공: <CheckBox valid={!!source.corr_authorship?.match_sign} />
      </Box>
    </Box>
  );
};

const MatchStatus: FC<Props> = ({ publicationId }) => {
  const { authorship, isLoading } = useAuthorship({ paperId: publicationId });

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={4}>
        <Spinner />
      </Box>
    );
  }

  if (!authorship) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={4}>
        모종의 이유로 authorship 정보를 받아오는데 실패하였습니다.
      </Box>
    );
  }

  return (
    <>
      <Box d="flex" alignItems="center" justifyContent="space-between" p={2} border="1px solid #dedede" mb={2}>
        <Box as="span">
          매치 성공: <CheckBox valid={authorship.match_success} />
        </Box>
        <Box as="span">
          Cofirst 매치 성공: <CheckBox valid={authorship.cofi_matched} />
        </Box>
        <Box as="span">
          Corresponding 매치 성공: <CheckBox valid={authorship.corr_matched} />
        </Box>
      </Box>
      <Box d="flex">
        <Box flex={1}>
          <StatusBoxWrapper
            title="WEB"
            content={
              <StatusBox
                source={authorship.web}
                reverseNotFoundCofiColor={authorship.cofi_matched}
                reverseNotFoundCorrColor={authorship.corr_matched}
              />
            }
          />
          <Box mt={2} d="flex" alignItems="flex-start" justifyContent="space-between">
            <CollapsedCandidateList
              buttonText="Toggle co-first clue"
              candidates={authorship.web?.cofi_authorship?.sign_candidates}
            />
            <CollapsedCandidateList
              buttonText="Toggle corresponding clue"
              candidates={authorship.web?.corr_authorship?.sign_candidates}
            />
          </Box>
        </Box>
        <Box flex={1} ml={2}>
          <StatusBoxWrapper
            title="PDF"
            content={
              <StatusBox
                source={authorship.pdf}
                reverseNotFoundCofiColor={authorship.cofi_matched}
                reverseNotFoundCorrColor={authorship.corr_matched}
              />
            }
          />
          <Box mt={2} d="flex" alignItems="flex-start" justifyContent="space-between">
            <CollapsedCandidateList
              buttonText="Toggle co-first clue"
              candidates={authorship.pdf?.cofi_authorship?.sign_candidates}
            />
            <CollapsedCandidateList
              buttonText="Toggle corresponding clue"
              candidates={authorship.pdf?.corr_authorship?.sign_candidates}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MatchStatus;
