import { Analytics } from '../models/analytics';
import { PageComment } from '../models/comment';
import { PageRawData, PersonalReport } from '../models/personalReport';
import { ReportGroup, AnalyticsGroup, GroupReport, ReportGroupStat } from '../models/reportGroup';
import { PageableResponse } from '../types/pageableContent';
import { Professorship } from '../types/proferssorship';
import Fetcher from './fetcher';

interface GetAnalyticsListParams {
  page?: number;
  size?: number;
}

export async function getAnalyticsList({ page = 0, size = 10 }: GetAnalyticsListParams) {
  const res = await Fetcher.instance.get<PageableResponse<Analytics>>(`/admin/analytics`, {
    params: { size, page: page - 1 },
  });

  return res.data.data;
}

export type CreateAnalyticsParams = Omit<Analytics, 'id' | 'status' | 'stat'>;

export async function createAnalytics(values: CreateAnalyticsParams) {
  const res = await Fetcher.instance.post<{ data: Analytics }>(`/admin/analytics`, values);
  return res.data.data;
}

export async function getAnalytics(id: string | number) {
  const res = await Fetcher.instance.get<{ data: Analytics }>(`/admin/analytics/${id}`);

  return res.data.data;
}

export async function editAnalytics(values: Partial<Analytics>) {
  const res = await Fetcher.instance.put<{ data: Analytics }>(`/admin/analytics/${values.id}`, values);

  return res.data.data;
}

export async function deleteAnalytics(id: string | number) {
  await Fetcher.instance.delete(`/admin/analytics/${id}`);
}

interface GetReportGroupsParams {
  page: number;
  size: number;
}

export async function getReportGroups({ page, size }: GetReportGroupsParams) {
  const res = await Fetcher.instance.get<PageableResponse<ReportGroup>>(`/admin/groups`, {
    params: { size, page: page - 1 },
  });

  return res.data.data;
}

export async function getAnalyticsReportGroups(analyticsId: string | number) {
  const res = await Fetcher.instance.get<PageableResponse<AnalyticsGroup>>(`/admin/analytics/${analyticsId}/groups`, {
    params: { size: 100 },
  });

  return res.data.data;
}

export async function createReportGroup(values: Partial<ReportGroup>) {
  const params = { ...values, affiliation_id: values.affiliation?.id };

  delete params.affiliation;

  const res = await Fetcher.instance.post<{ data: ReportGroup }>(`/admin/groups`, params);

  return res.data.data;
}

export async function deleteReportGroup(reportGroupId: string | number) {
  await Fetcher.instance.delete(`/admin/groups/${reportGroupId}`);
}

export async function searchReportGroup(value: string) {
  const res = await Fetcher.instance.get<{ data: ReportGroup[] }>(`/admin/groups/search`, {
    params: { query: value },
  });

  return res.data.data;
}

interface ReportGroupToAnalyticsParams {
  analyticsId: string | number;
  reportGroupId: string | number;
}

export async function addReportGroupToAnalytics({ analyticsId, reportGroupId }: ReportGroupToAnalyticsParams) {
  const res = await Fetcher.instance.post<{ data: AnalyticsGroup }>(
    `/admin/analytics/${analyticsId}/groups/${reportGroupId}`,
    { aid: analyticsId, gid: reportGroupId }
  );

  return res.data.data;
}

export async function removeReportGroupFromAnalytics({ analyticsId, reportGroupId }: ReportGroupToAnalyticsParams) {
  const res = await Fetcher.instance.delete(`/admin/analytics/${analyticsId}/groups/${reportGroupId}`, {
    params: { aid: analyticsId, gid: reportGroupId },
  });

  return res.data.data;
}

export async function getReportGroup(reportGroupId: string | number) {
  const res = await Fetcher.instance.get<{ data: ReportGroup }>(`/admin/groups/${reportGroupId}`);

  return res.data.data;
}

export async function updateReportGroup(values: Partial<ReportGroup> & { id: string | number }) {
  const params = { ...values, affiliation_id: values.affiliation?.id };

  delete params.affiliation;

  const res = await Fetcher.instance.put<{ data: ReportGroup }>(`/admin/groups/${values.id}`, params);

  return res.data.data;
}

export async function getMappedAnalytics(reportGroupId: string | number) {
  const res = await Fetcher.instance.get<{ data: Analytics[] }>(`/admin/groups/${reportGroupId}/mapped-analytics`, {
    withCredentials: true,
  });

  return res.data.data;
}

export interface GetGroupReportsParams {
  reportGroupId: string;
  mappingNeededOnly?: boolean;
  professorship?: Professorship | null;
  page?: number;
  size?: number;
}

export async function getGroupReports({
  reportGroupId,
  mappingNeededOnly = false,
  professorship = null,
  page = 1,
  size = 100,
}: GetGroupReportsParams) {
  const res = await Fetcher.instance.get<PageableResponse<GroupReport>>(`/admin/groups/${reportGroupId}/reports`, {
    params: { mn: mappingNeededOnly, ps: professorship, size, page: page - 1 },
    withCredentials: true,
  });

  return res.data.data;
}

interface UpsertReportToGroupParams {
  reportGroupId: string | number;
  reportId: string | number;
  professorship: Professorship;
}

export async function addReportToGroup({ reportGroupId, reportId, professorship }: UpsertReportToGroupParams) {
  const res = await Fetcher.instance.post<{ data: GroupReport }>(`/admin/groups/${reportGroupId}/reports/${reportId}`, {
    gid: reportGroupId,
    rid: reportId,
    professorship,
  });

  return res.data.data;
}

interface RemoveReportFromGroupParams {
  reportGroupId: string | number;
  reportId: string | number;
}

export async function removeReportFromGroup({ reportGroupId, reportId }: RemoveReportFromGroupParams) {
  const res = await Fetcher.instance.delete<{ data: { ok: true } }>(
    `/admin/groups/${reportGroupId}/reports/${reportId}`,
    { params: { gid: reportGroupId, rid: reportId }, withCredentials: true }
  );

  return res.data.data;
}

export async function updateMappedReportProfessorship({
  reportGroupId,
  reportId,
  professorship,
}: UpsertReportToGroupParams) {
  const res = await Fetcher.instance.put<{ data: GroupReport }>(
    `/admin/groups/${reportGroupId}/reports/${reportId}/professorship`,
    { professorship }
  );

  return res.data.data;
}

export async function getReportGroupStat(reportGroupId: string) {
  const res = await Fetcher.instance.get<{ data: ReportGroupStat }>(`/admin/groups/${reportGroupId}/stat`);

  return res.data.data;
}

export async function importPapersFromAuthorId({ reportId, authorIds }: { reportId: string; authorIds: string[] }) {
  await Fetcher.instance.post<{ data: { ok: true } }>(`/admin/reports/${reportId}/papers/import`, {
    author_ids: authorIds,
  });
}

export async function getPersonalReportList(analyticsId: number) {
  const res = await Fetcher.instance.get<{ data: PersonalReport[] }>(`/admin/analytics/${analyticsId}/personal-report`);
  return res.data.data;
}

export async function getPageRawData({ personalReportId, dataId }: { personalReportId: number; dataId: string }) {
  const res = await Fetcher.instance.get<{ data: PageRawData }>(`/admin/personal-report/${personalReportId}/data`, {
    params: { data_id: dataId },
  });

  return res.data.data;
}

export async function getComment({ personalReportId, dataId }: { personalReportId: number; dataId: string }) {
  const res = await Fetcher.instance.get<{ data: PageComment }>(`/admin/personal-report/${personalReportId}/comment`, {
    params: { data_id: dataId },
  });
  return res.data.data;
}

export async function postComment({
  personalReportId,
  dataId,
  comment,
}: {
  personalReportId: number;
  dataId: string;
  comment: string;
}) {
  const res = await Fetcher.instance.post<{ data: PageComment }>(`/admin/personal-report/${personalReportId}/comment`, {
    comment,
    data_id: dataId,
  });
  return res.data.data;
}
