import { CandidatePaper } from '../models/paper';
import Fetcher from './fetcher';

export async function removeCandidatePaper(candidatePaperId: number) {
  await Fetcher.instance.delete(`admin/candidates/${candidatePaperId}`);
}

interface MakeCandidatePaperPendingStateParams {
  reason: string;
  candidatePaperId: number;
}
export async function makeCandidatePaperPendingState({
  reason,
  candidatePaperId,
}: MakeCandidatePaperPendingStateParams) {
  await Fetcher.instance.post(`/admin/candidates/${candidatePaperId}/pending`, { reason });
}

export async function removeMapping(candidatePaperId: number) {
  await Fetcher.instance.delete(`/admin/candidates/${candidatePaperId}/pre-match`);
}

export async function getCandidatePapers() {
  const res = await Fetcher.instance.get<{ data: CandidatePaper[] }>(`/admin/candidates/pending`);
  return res.data.data;
}
