import React, { FC } from 'react';
import { XenurePaper } from '../../models/xenurePaper';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReportBasedAnomalyDetectionResponse } from '../../models/anomalyDetection';
import { useRecoilState } from 'recoil';
import { authorListAtPaper } from '../../atoms/authorListAtPaper';
import { Author } from '../../models/author';
import produce from 'immer';

interface Props {
  paper: XenurePaper;
  isLoadingAnomalyDetection: boolean;
  anomalyDetection?: ReportBasedAnomalyDetectionResponse;
}

const AuthorItemWrapper = styled.div<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? '#63b3ed' : '')};
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;

  & + & {
    margin-top: 8px;
  }
`;

const SimpleAuthorSelectBox: FC<Props> = ({ anomalyDetection, isLoadingAnomalyDetection }) => {
  const [authorList, setAuthorList] = useRecoilState(authorListAtPaper);

  function setIsMine(targetAuthor: Author) {
    setAuthorList((prev) =>
      produce(prev, (draft) => {
        if (!draft) return;
        draft.authorList.forEach((author) => {
          author.is_myself = author.id === targetAuthor.id;
        });
      })
    );
  }

  if (isLoadingAnomalyDetection) {
    return <div>저자 이상치 조사 중 ...</div>;
  }

  const authors = Array.from(authorList?.authorList.values() || []).map((author) => (
    <AuthorItemWrapper key={author.id} isSelected={author.is_myself} onClick={() => setIsMine(author)}>
      <Box fontWeight="bold">
        {author.name}({author.id})
      </Box>
      {author.affiliation && (
        <Box>
          {author.affiliation.name}({author.affiliation.id})
        </Box>
      )}
    </AuthorItemWrapper>
  ));

  return <div>{authors}</div>;
};

export default SimpleAuthorSelectBox;
