import { useQuery } from 'react-query';
import { getReportGroups } from '../api/reportGroup';

interface Params {
  page?: number;
  size?: number;
}

export function useReportGroups(params?: Params) {
  const page = params?.page ?? 1;
  const size = params?.size ?? 100;

  return useQuery(['/admin/groups', { page, size }], () => getReportGroups({ page, size }));
}
