import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Journal, Conference, ConferenceInstance } from '../../models/xenurePaper';
import JournalBox from './journalBox';
import ConferenceBox from './conferenceBox';
import ConferenceInstanceBox from './conferenceInstanceBox';

interface Props {
  journal: Journal | null;
  conference: Conference | null;
  conferenceInstance: ConferenceInstance | null;
  removeJournal: boolean;
  removeConference: boolean;
}

function getStyle(disable: boolean) {
  return {
    opacity: disable ? 0.5 : 1,
  };
}

const VenueStatusBox: FC<Props> = ({ journal, conference, conferenceInstance, removeConference, removeJournal }) => {
  return (
    <Box>
      {journal && (
        <Box mb={4} style={getStyle(removeJournal)}>
          <JournalBox journal={journal} />
        </Box>
      )}
      {conference && (
        <Box mb={4} style={getStyle(removeConference)}>
          <ConferenceBox conference={conference} />
        </Box>
      )}
      {conferenceInstance && (
        <Box style={getStyle(removeConference)}>
          <ConferenceInstanceBox conferenceInstance={conferenceInstance} />
        </Box>
      )}
    </Box>
  );
};
export default VenueStatusBox;
