import React, { FC } from 'react';
import { Box, Flex, HStack, ListItem, UnorderedList } from '@chakra-ui/react';
import { useReportRawData } from '../../../hooks/useReportRawData';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { usePageComment } from '../../../hooks/usePageComment';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import PerformanceTrendJVTopPubCntGraph, {
  ChartData,
} from '../../../components/personalAnalytics/graphs/performanceTrendJVTopPubCntGraph';
import _ from 'lodash';
import {
  PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH,
  PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH,
} from '../../../constants/personalReport';

interface Props {
  personalReportId: number;
  dataId: string;
}

const PerformanceTrendJVTopPubCnt: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const chartData = parseStringifiedJSONRawData(rawData?.report_json_data) as ChartData[];

  if (!chartData) return null;

  const chartDataForComments = chartData.filter(
    ({ year }) => year >= PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH && year <= PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH
  );
  const chartLength = chartDataForComments.length;
  const predefinedComments = [
    `최근 ${chartLength}년간 JV TOP 10%에 든 논문이 평균적으로 ${_.meanBy(
      chartDataForComments,
      'jv_top10_pub_cnt'
    ).toFixed(2)}편으로 확인되었습니다.`,
    `최근 ${chartLength}년간 JV TOP 25%에 든 논문이 평균적으로 ${_.meanBy(
      chartDataForComments,
      'jv_top25_pub_cnt'
    ).toFixed(2)}편으로 확인되었습니다.`,
    `JV TOP 25% 중 ${Math.round(
      (_.sumBy(chartDataForComments, 'jv_top10_pub_cnt') * 100) / _.sumBy(chartDataForComments, 'jv_top25_pub_cnt')
    )}%의 논문이 JV TOP 10%에 속하고 있습니다.`,
  ];

  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout page={12}>
      <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
        개인 연구 트랜드
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
        저널 내 상위 인용 페이퍼
      </Box>
      <Flex justifyContent="center" alignItems="center" mb="34px">
        <PerformanceTrendJVTopPubCntGraph chartData={chartData} />
      </Flex>
      <HStack spacing="16px" alignItems="flex-start" mt="42px">
        <Box>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px" whiteSpace="pre-wrap">
            {' '}
            동일 시기에 출판된 논문들과 비교하여 얼마나 좋은 퍼포먼스를 기록하였는지를 확인할 수 있는 지표입니다.
            {'\n '}
            해당 볼륨 내에서 출판된 논문 기준 인용 상위 10%, 인용 상위 25% 안에 드는 논문의 수입니다.
          </Box>
          <UnorderedList
            p="13px 15px"
            fontSize="12px"
            lineHeight="21px"
            bg="#EBEEFD"
            padding="15px 13px"
            listStylePosition="inside"
            m="0"
          >
            {comments?.map((comment, i) => (
              <ListItem key={i}>{comment}</ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box
          pos="relative"
          w="201px"
          bg="#FCEDF0"
          borderRadius="5px"
          p="26px 17px 22px 17px"
          fontSize="12px"
          lineHeight="21px"
          flex="1 0 auto"
          wordBreak="break-all"
        >
          <Box
            pos="absolute"
            left="16px"
            top="-14px"
            fontSize="15px"
            lineHeight="16px"
            p="6px 12px"
            borderRadius="5px"
            bg="#D3625C"
            color="white"
            wordBreak="break-all"
          >
            대표 지표
          </Box>
          <Box fontWeight="bold">JV(Journal Volume) 상위 N%</Box>
          <Box>
            저널의 Volume에 출판된 논문들 중 피인용수를 기준으로 상위 몇 %에 위치하는지 보여줍니다. 본 지표를 통해 같은
            시기, 동일 분야의 비슷한 수준의 논문들과 출판 이후의 임팩트에 대해 비교 분석할 수 있습니다.
          </Box>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default PerformanceTrendJVTopPubCnt;
