import { Box, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';

const Chapter5Page: FC = () => {
  return (
    <PersonalAnalyticsLayout>
      <ChapterJumbotron title="저널 관련 분석" chapterTitle="Chapter 05." />
      <HStack spacing="16px" alignItems="flex-start" mt="48px">
        <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap" w="100%">
          {' '}
          연구자가 연구 기간 동안 출판했던 저널들에 대해서 분석합니다. 또한 특정 연구 분야에서 저명한 저널 혹은 특정
          분야에 친화적인 저널을 찾아서 잠재적으로 출판 가능성이 있는 저널을 발굴합니다. 특히, 저널 내 연도별 주요 연구
          주제 출판 비율 트렌드를 확인하여 저널 내 분야의 최근 인기도 등을 확인합니다.
        </Box>
        <Box
          w="201px"
          flexShrink={0}
          pos="relative"
          mt="20px"
          bg="#FCEDF0"
          borderRadius="5px"
          p="24px 16px 16px 16px"
          fontSize="12px"
          lineHeight="21px"
        >
          <Box
            pos="absolute"
            top="-13px"
            left="10px"
            bg="#D3625C"
            borderRadius="5px"
            p="6px 10px"
            fontSize="15px"
            lineHeight="15px"
            color="white"
            fontWeight="bold"
          >
            핵심지표
          </Box>
          <Text fontWeight={700}>저널 내 연도별 주요 연구 주제 출판 비율</Text>
          <Text whiteSpace="pre-wrap">
            출판사의 전체 출판 논문들 중 특정 연구 주제(Fields of Study)가 포함된 논문의 비율을 계산합니다. 수치는
            백분율로 표시합니다.
          </Text>
        </Box>
      </HStack>
      <Box mt="117px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default Chapter5Page;
