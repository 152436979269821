import { Badge, Box, Flex, Spinner, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getReportAssociatedData } from '../../api/report';

const ReportAssociatedBox: FC<{ reportId: string }> = ({ reportId }) => {
  const { data: reportAssociatedData, isLoading } = useQuery([`/admin/reports/${reportId}`, '/associated'], () =>
    getReportAssociatedData(reportId)
  );

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={8}>
        <Spinner />
      </Box>
    );
  }

  if (!reportAssociatedData) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={8}>
        There is no report associated data.
      </Box>
    );
  }

  return (
    <Flex border="1px solid #dedede" justifyContent="space-between" p={4}>
      <Box>
        <Text mb={2} fontSize="xl" borderBottom="1px solid #dedede">
          Associated Report Groups
        </Text>
        <Flex>
          {reportAssociatedData.groups.length === 0 && <Box>N/A</Box>}
          {reportAssociatedData.groups.map((groupAndProfessorship) => (
            <Box
              key={groupAndProfessorship.group.id}
              p={2}
              border="1px solid #dedede"
              borderRadius="4px"
              as={Link}
              to={`/report-groups/${groupAndProfessorship.group.id}`}
              mx={2}
            >
              {`#${groupAndProfessorship.group.id} ${groupAndProfessorship.group.name}`}
              <Badge size="sm" ml={1}>
                {groupAndProfessorship.professorship}
              </Badge>
            </Box>
          ))}
        </Flex>
      </Box>
      <Box>
        <Text mb={2} fontSize="xl" borderBottom="1px solid #dedede">
          Associated User
        </Text>
        <Box>
          {reportAssociatedData.user
            ? `# ${reportAssociatedData.user.id} ${reportAssociatedData.user.first_name} ${reportAssociatedData.user.last_name}`
            : 'N/A'}
        </Box>
      </Box>
    </Flex>
  );
};

export default ReportAssociatedBox;
