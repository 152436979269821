import { Box, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';

const Chapter2Page: FC = () => {
  return (
    <PersonalAnalyticsLayout page={7}>
      <ChapterJumbotron title="최근 10년 연도별 연구성과 확인" chapterTitle="Chapter 02." />
      <HStack spacing="16px" alignItems="flex-start" mt="48px">
        <Box fontSize="12px" lineHeight="21px" w="100%" whiteSpace="pre-wrap">
          {' '}
          2011년부터 2020년까지 최근 10년 동안의 연도별 연구 성과를 확인합니다. 확인 지표로는 출판수(Publications),
          Impact Factor, JCR(Journal Citation Reports), JV Top 10%, 논문의 출판 직후의 피인용수 등이 있습니다. 각
          데이터를 심층 분석하여 연도별 연구 성과를 확인하고 새로운 인사이트를 찾아냅니다.
        </Box>
        <Box
          w="100%"
          pos="relative"
          mt="20px"
          bg="#FCEDF0"
          borderRadius="5px"
          p="24px 16px 16px 16px"
          fontSize="12px"
          lineHeight="21px"
        >
          <Box
            pos="absolute"
            top="-13px"
            left="10px"
            bg="#D3625C"
            borderRadius="5px"
            p="6px 10px"
            fontSize="15px"
            lineHeight="15px"
            color="white"
            fontWeight="bold"
          >
            핵심지표
          </Box>
          <Text fontWeight={700}>JV(Journal Volume) 상위 N%</Text>
          <Text>
            저널의 Volume에 출판된 논문들 중 피인용수를 기준으로 상위 몇 %에 위치하는지 보여줍니다. 본 지표를 통해 같은
            시기, 동일 분야의 비슷한 수준의 논문들과 출판 이후의 임팩트에 대해 비교 분석할 수 있습니다.
          </Text>
          <Text fontWeight={700} mt="30px">
            h5-index
          </Text>
          <Text>
            2016년부터 2020년까지 발표된 논문에 대해서 계산된 h-index를 의미합니다. 최근 5년간 얼마나 활발하게 연구를
            수행하였는지를 양적/질적인 측면을 모두 고려하여 확인할 수 있는 지표입니다.
          </Text>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default Chapter2Page;
