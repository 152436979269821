import { Box, Divider, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useSWR from 'swr';
import { matchModalState } from '../../atoms/matchModalState';
import { XENURE_API_ENDPOINT } from '../../constants';
import ArticleTypeSelector from '../articleTypeSelector';
import AuthorSelectBox from './authorSelectBox';
import ContaminatedBox from './contaminatedBox';
import { authorListAtPaper } from '../../atoms/authorListAtPaper';
import { Author } from '../../models/author';
import VenueSelectBox from '../venueSelectBox';
import FosBox from './fosBox';
import { getPaper } from '../../api/publication';

const ModifyPaperModal: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const setAuthorList = useSetRecoilState(authorListAtPaper);
  const [{ selectedPaperId, selectedRaw }] = useRecoilState(matchModalState);

  const { data: selectedPaper } = useSWR(
    selectedPaperId ? `${XENURE_API_ENDPOINT}/publications/${selectedPaperId}` : null,
    async () => {
      if (!selectedPaperId) return;

      setIsLoading(true);
      try {
        const paper = await getPaper({ paperId: selectedPaperId });
        const authorMap = new Map<string, Author>();
        paper.authors.forEach((author) => authorMap.set(author.id, author));
        setAuthorList({ paperId: paper.id, authorList: authorMap });
        setIsLoading(false);
        return paper;
      } catch (err) {
        alert(err.message);
        setIsLoading(false);
      }
    }
  );

  if (!selectedPaper) return null;

  if (isLoading) {
    return (
      <ModalContent>
        <ModalHeader textAlign="center">MODIFY PAPER</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={8} d="flex" justifyContent="center" alignItems="center">
            <Spinner />
          </Box>
        </ModalBody>
      </ModalContent>
    );
  }

  return (
    <ModalContent>
      <ModalHeader textAlign="center">MODIFY PAPER</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {selectedRaw && (
          <Box>
            <Box fontWeight="bold">ORIGIN TEXT</Box>
            <Box>{selectedRaw.source}</Box>
            <Divider my={4} />
          </Box>
        )}
        <Box>
          <Box fontWeight="bold">SELECTED PAPER</Box>
          <Box>
            {selectedPaper.title} ({selectedPaper.year}) - {selectedPaper.id}
          </Box>
        </Box>
        <Divider my={4} />
        <ContaminatedBox touched={!!selectedPaper.is_touched} doi={selectedPaper.doi} paperId={selectedPaper.id} />
        <Divider my={4} />
        <VenueSelectBox paper={selectedPaper} />
        <Divider my={4} />
        <Box
          d="grid"
          gridTemplateColumns="0.6fr 1.4fr"
          gridTemplateRows="1fr"
          style={{ gap: '0px 16px' }}
          gridTemplateAreas=". ."
        >
          <ArticleTypeSelector paper={selectedPaper} />
          <FosBox paper={selectedPaper} />
        </Box>
        <Divider my={4} />
        <AuthorSelectBox paper={selectedPaper} />
      </ModalBody>
    </ModalContent>
  );
};

export default ModifyPaperModal;
