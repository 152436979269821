import Axios from 'axios';
import { SCINAPSE_API_ORIGIN } from '../../constants';
import { ScinapsePaper } from '../../models/paper';

export async function findPapers(query: string): Promise<ScinapsePaper[]> {
  const paperId = Number(query);

  if (Number.isInteger(paperId) && paperId > 0) {
    const res = await Axios.get<ScinapsePaper>(`${SCINAPSE_API_ORIGIN}/papers/${paperId}`);
    return [res.data];
  } else {
    const res = await Axios.get<{ data: { content: ScinapsePaper[] } }>(SCINAPSE_API_ORIGIN + '/search', {
      params: { q: query },
    });
    return res.data?.data?.content as ScinapsePaper[];
  }
}
