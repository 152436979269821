import { Container, Box } from '@chakra-ui/layout';
import { Button, Flex, Spinner } from '@chakra-ui/react';
import { parse } from 'qs';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Pagination from '../../components/pagination';
import ReportGroupItem from '../../components/reportGroupItem';
import CreateReportGroupPopover from '../../components/reportGroupModal/createReportGroupPopover';
import SearchReportGroupInput from '../../components/searchReportGroupInput';
import { useCreateReportGroup } from '../../hooks/useCreateReportGroup';
import { useReportGroups } from '../../hooks/useReportGroups';

const ReportGroupListPage: FC = () => {
  const location = useLocation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  const page = queryParams.page ? parseInt(queryParams.page as string) : 1;
  const { data: reportGroups, isLoading } = useReportGroups({ size: 10, page });
  const { mutate: createGroup } = useCreateReportGroup();

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" p={8}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Container maxW="container.lg" py={4}>
      <Flex justifyContent="flex-end" my={4}>
        <CreateReportGroupPopover onSubmit={async (values) => createGroup(values)} />
      </Flex>
      <SearchReportGroupInput />
      {reportGroups?.content.map((rg) => (
        <Flex p={2} key={rg.id} justifyContent="space-between">
          <ReportGroupItem reportGroup={rg} />
          <Box>
            <Button as={Link} to={`/report-groups/${rg.id}`} size="sm" colorScheme="teal">
              DETAIL
            </Button>
          </Box>
        </Flex>
      )) ?? (
        <Container maxW="container.lg">
          <Box>There is no report groups yet.</Box>
          <Button>Make report groups</Button>
        </Container>
      )}
      {reportGroups && (
        <Flex justifyContent="center" mt={8}>
          <Pagination
            currentPage={reportGroups.number + 1}
            isFirst={reportGroups.first}
            isLast={reportGroups.last}
            lastPage={reportGroups.total_pages}
            makeLinkAddress={(page: number) => `${location.pathname}?page=${page}`}
          />
        </Flex>
      )}
    </Container>
  );
};

export default ReportGroupListPage;
