import React, { FC, useEffect, useRef, memo } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { AxisBreakOption } from '../types';

am4core.useTheme(am4themes_animated);

export interface ChartData {
  journal_id: string;
  journal_name: string;
  year: number;
  impact_factor: number;
  matched_paper_cnt: number;
  paper_cnt: number;
  percent_by_year: number;
}

interface Props {
  chartData: {
    rawData: ChartData[];
    groupedData: any[];
    uniqJournalIds: string[];
  };
  activeIndex?: number;
  axisBreakOption?: AxisBreakOption;
}

const COLORS = [
  am4core.color('#EAD413'),
  am4core.color('#82E108'),
  am4core.color('#00BFA5'),
  am4core.color('#536DFE'),
  am4core.color('#1A237E'),
  am4core.color('#7C4DFF'),
  am4core.color('#D300F5'),
  am4core.color('#F50057'),
  am4core.color('#F59300'),
  am4core.color('#909090'),
  am4core.color('#9D5705'),
  am4core.color('#007127'),
  am4core.color('#36CDEE'),
];

const JournalTrendLine: FC<Props> = memo(({ chartData, activeIndex, axisBreakOption }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const isFullDataChart = typeof activeIndex === 'undefined';

  useEffect(() => {
    if (!chartRef.current) return;

    const { groupedData, uniqJournalIds, rawData } = chartData;

    const chart = am4core.create(chartRef.current, am4charts.XYChart);
    chart.zoomOutButton.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.fontSize = '9px';
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;

    if (isFullDataChart) {
      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 6;
      markerTemplate.height = 1;
    } else {
      chart.legend.align = 'center';
      chart.legend.contentAlign = 'center';
    }

    chart.cursor = new am4charts.XYCursor();

    chart.data = groupedData;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.date = 'year';
    dateAxis.title.text = 'YEAR';
    dateAxis.title.fontSize = '8px';
    dateAxis.fontSize = '8px';
    dateAxis.renderer.minGridDistance = 20;
    dateAxis.baseInterval = { timeUnit: 'year', count: 1 };

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Publication Ratio';
    valueAxis.title.fontSize = '8px';
    valueAxis.fontSize = '8px';
    valueAxis.renderer.minGridDistance = 15;

    if (isFullDataChart && axisBreakOption) {
      const axisBreak = valueAxis.axisBreaks.create();
      axisBreak.startValue = axisBreakOption.startValue;
      axisBreak.endValue = axisBreakOption.endValue;
      axisBreak.breakSize = axisBreakOption.breakSize;
    }

    uniqJournalIds.map((journalId, i) => {
      const series = chart.series.push(new am4charts.LineSeries());
      const exampleData = rawData.find((datum) => datum.journal_id === journalId);

      series.name = exampleData?.journal_name || '';
      series.dataFields.valueY = journalId.toString();
      series.dataFields.dateX = 'year';
      series.tooltipText = '{dateX.formatDate("yyyy")}: [b]{valueY.formatNumber("#.00")}[/]';
      series.strokeWidth = 2;
      if (series.tooltip) {
        series.tooltip.fontSize = '12px';
      }

      series.stroke = COLORS[i];

      if (!isFullDataChart) {
        series.hiddenInLegend = activeIndex !== i;
        series.hidden = activeIndex !== i;
      }

      return series;
    });

    return () => {
      chart.dispose();
    };
  }, [chartData, activeIndex, isFullDataChart, axisBreakOption]);

  if (!isFullDataChart) {
    return <div ref={chartRef} style={{ width: '100%', height: '200px' }} />;
  }

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
});

export default JournalTrendLine;
