import React, { FC } from 'react';
import { CandidatePaper } from '../../models/paper';
import { CheckCircleIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';

const PaperStatusIcon: FC<{ paper: CandidatePaper }> = ({ paper }) => {
  if (paper.match_pending) return <WarningTwoIcon color="yellow.300" />;
  if (paper.match_confirmed) return <CheckCircleIcon color="green.500" />;
  return <WarningIcon color="red.500" />;
};

export default PaperStatusIcon;
