import { Box } from '@chakra-ui/react';
import React, { CSSProperties, FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';

const ThStyle: CSSProperties = {
  border: '1px solid #B9B9B9',
  padding: '10px 8px',
  background: '#536DFE',
  color: 'white',
};

const TdStyle: CSSProperties = {
  padding: '10px 8px',
  border: '1px solid #B9B9B9',
  fontSize: '12px',
  lineHeight: '17px',
  fontWeight: 'bold',
};

const TablePage: FC = () => {
  return (
    <PersonalAnalyticsLayout>
      <Box fontSize="42px" lineHeight="42px" fontWeight="bold" color="#536DFE" mb="6px">
        유사 연구자 분석
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="16px">
        개인 연구자 Impact Factor
      </Box>
      <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="12px">
        Purpose
      </Box>
      <Box fontSize="12px" lineHeight="21px">
        데이터로 확인한 결과 317편의 전체 논문이 있었고, 216편의 Lead-authored 논문이 확인되었습니다.
      </Box>
      <table style={{ textAlign: 'center', width: '100%', marginTop: '30px' }}>
        <thead>
          <tr>
            <th style={ThStyle}>이름</th>
            <th style={ThStyle}>연구 기관</th>
            <th style={ThStyle}>출판 논문 수</th>
            <th style={ThStyle}>피인용수</th>
            <th style={ThStyle}>h-index</th>
            <th style={ThStyle}>Avg-IF</th>
            <th style={ThStyle}>Personal-IF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ ...TdStyle, fontWeight: 500 }}>yards</td>
            <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>metres (m)</td>
            <td style={TdStyle}>0.91444</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
          </tr>
          <tr>
            <td style={{ ...TdStyle, fontWeight: 500 }}>yards</td>
            <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>metres (m)</td>
            <td style={TdStyle}>0.91444</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
          </tr>
          <tr>
            <td style={{ ...TdStyle, fontWeight: 500 }}>yards</td>
            <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>metres (m)</td>
            <td style={TdStyle}>0.91444</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
          </tr>
        </tbody>
      </table>
    </PersonalAnalyticsLayout>
  );
};

export default TablePage;
