import React, { FC, useRef } from 'react';
import produce from 'immer';
import { authorListAtPaper, AuthorListAtPaperState } from '../../atoms/authorListAtPaper';
import { TableRowProps, Row, Cell } from 'react-table';
import { Author } from '../../models/author';
import { useRecoilState } from 'recoil';
import { useDrag, useDrop, DropTargetMonitor, DragObjectWithType, XYCoord } from 'react-dnd';
import { AuthorDraggableItemTypes } from '../modifyModal/draggableTypes';

interface Props {
  rowProps: TableRowProps;
  row: Row<Author>;
  itsmePreMatched: boolean;
  firstAuthorPreMatched: boolean;
  correspondingAuthorPreMatched: boolean;
  itsmeWarning: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

function getBackgroundColor(
  cell: Cell<any>,
  itsmePreMatched: boolean,
  correspondingAuthorPreMatched: boolean,
  firstAuthorPreMatched: boolean,
  itsmeWarning: boolean
) {
  if (cell.column.id === 'me' && itsmeWarning) return '#FAF089';
  if (cell.column.id === 'me' && itsmePreMatched) return '#9AE6B4';
  if (cell.column.id === 'corr' && correspondingAuthorPreMatched) return '#9AE6B4';
  if (cell.column.id === 'first' && firstAuthorPreMatched) return '#9AE6B4';
  return 'transparent';
}

const AuthorTableRow: FC<Props> = ({
  rowProps,
  row,
  itsmePreMatched,
  correspondingAuthorPreMatched,
  firstAuthorPreMatched,
  itsmeWarning,
  moveCard,
}) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [authorListInfo, setAuthorListAtPaper] = useRecoilState(authorListAtPaper);
  const authorList = authorListInfo?.authorList;

  const [, drop] = useDrop({
    accept: AuthorDraggableItemTypes.AUTHOR,
    hover(item: DragObjectWithType & { row: Row<Author> }, monitor: DropTargetMonitor) {
      if (!ref.current) return;

      const dragIndex = item.row.index;
      const hoverIndex = row.index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.row.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: AuthorDraggableItemTypes.AUTHOR, row: row },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <tr
      {...rowProps}
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps([
              {
                style: {
                  cursor:
                    cell.column.id === 'me' || cell.column.id === 'corr' || cell.column.id === 'first'
                      ? 'pointer'
                      : 'normal',
                  backgroundColor: getBackgroundColor(
                    cell,
                    itsmePreMatched,
                    correspondingAuthorPreMatched,
                    firstAuthorPreMatched,
                    itsmeWarning
                  ),
                },
              },
            ])}
            onClick={() => {
              const author = cell.row.original;
              if (cell.column.id === 'me') {
                const newAuthorMap = produce(authorList, (draft) => {
                  if (!draft) return authorList;
                  draft.forEach((a) => (a.is_myself = false));
                  draft.get(author.id)!.is_myself = true;
                });

                setAuthorListAtPaper((prev) => {
                  return {
                    ...prev,
                    authorList: newAuthorMap,
                  } as AuthorListAtPaperState;
                });
              }
              if (cell.column.id === 'first') {
                const newAuthorMap = produce(authorList, (draft) => {
                  if (!draft) return;
                  draft.get(author.id)!.is_first_author = !draft.get(author.id)!.is_first_author;
                });
                setAuthorListAtPaper((prev) => {
                  return {
                    ...prev,
                    authorList: newAuthorMap,
                  } as AuthorListAtPaperState;
                });
              }
              if (cell.column.id === 'corr') {
                const newAuthorMap = produce(authorList, (draft) => {
                  if (!draft) return;
                  if (!draft.get(author.id)) return;
                  draft.get(author.id)!.is_corr_author = !draft.get(author.id)!.is_corr_author;
                });
                setAuthorListAtPaper((prev) => {
                  return {
                    ...prev,
                    authorList: newAuthorMap,
                  } as AuthorListAtPaperState;
                });
              }
            }}
          >
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
};

export default AuthorTableRow;
