import { Box, HStack, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePageComment } from '../../../hooks/usePageComment';
import { usePaperListCitationText } from '../../../hooks/usePaperListCitationText';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface Props {
  personalReportId: number;
  dataId: string;
}

interface PaperInformation {
  paper_id: string;
  paper_title: string;
  date: string;
  '2021_cit_cnt': number;
  cit_cnt: number;
}

const RecommendPaperListPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as PaperInformation[];

  const { data: citationTextList } = usePaperListCitationText(data?.map((datum) => datum.paper_id));

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const maxShowCount = 5;
  const predefinedComments = [
    `주요 논문을 추출한 결과 ${data.length}개의 논문이 발견되었습니다`,
    `가장 주목할만한 논문 ${Math.min(data.length, maxShowCount)}개의 결과를 위 표에 표시하였습니다.`,
  ];
  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...inputComments, ...predefinedComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout>
      <Box fontWeight="bold" fontSize="36px" lineHeight="36px" color="#536DFE" mb="32px">
        주요 연구 분야 내 주목 할 만한 논문
      </Box>
      <Box fontSize="22px" lineHeight="22px" fontWeight="bold" mb="24px">
        주요 논문
      </Box>
      <HStack spacing="16px" alignItems="flex-start">
        <Box>
          <VStack fontSize="12px" lineHeight="21px" spacing="12px">
            {citationTextList &&
              Object.values(citationTextList)
                .slice(0, 5)
                .map((citationText, i) => (
                  <Box key={i} whiteSpace="pre-wrap">
                    <Text d="inline" fontWeight="bold">{`${i + 1}. `}</Text>
                    <Text d="inline" fontSize="10px">
                      {citationText}
                    </Text>
                  </Box>
                ))}
          </VStack>
          {!!comments?.length && (
            <UnorderedList
              p="13px 15px"
              fontSize="12px"
              lineHeight="21px"
              bg="#EBEEFD"
              padding="15px 13px"
              listStylePosition="inside"
              mt="48px"
              mx="0"
            >
              {comments?.map((comment, i) => (
                <ListItem key={i}>{comment}</ListItem>
              ))}
            </UnorderedList>
          )}
        </Box>
        <Box flexShrink={0}>
          <Box
            pos="relative"
            w="201px"
            bg="#FCEDF0"
            borderRadius="5px"
            p="26px 17px 22px 17px"
            fontSize="12px"
            lineHeight="21px"
            flex="1 0 auto"
            wordBreak="break-all"
          >
            <Box
              pos="absolute"
              left="16px"
              top="-14px"
              fontSize="15px"
              lineHeight="16px"
              p="6px 12px"
              borderRadius="5px"
              bg="#D3625C"
              color="white"
              wordBreak="break-all"
            >
              대표 지표
            </Box>
            <Box fontWeight="bold">지표 산출 방법</Box>
            <Box whiteSpace="pre-wrap">
              {' '}
              2가지 기준을 가지고 데이터를 산출하였습니다.
              {'\n '}첫 번째는 2021년에 많이 인용된 논문만 살펴봅니다. 아주 최근에 트렌드를 이끄는 논문을 살펴보기
              위함입니다.
              {'\n '}두 번째는 2018년 4월부터 2020 년 9월 사이에 발표된 논문들 중에 2020년 4Q와 2021년 1Q 사이의
              피인용수를 확인해 그 증가량을 살펴봅니다.
              {'\n '}이 2가지 조건을 통해 절대적인 피인용수 및 상승세를 고려하여 트렌드를 주도하는 논문을
              추출하였습니다.
            </Box>
          </Box>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default RecommendPaperListPage;
