import React, { FC } from 'react';
import { Box, Button, Flex, Select, Stack, Text, VStack } from '@chakra-ui/react';
import KeyValueBox from './keyValueBox';
import { AnalysisLabelingLevel, AnalysisLabelingStatus, Report } from '../models/report';
import { useMutation, useQueryClient } from 'react-query';
import {
  confirmReportLabeling,
  markReportDisplayConfirm,
  markVIPReport,
  updateAnalysisEligible,
  updateAnalysisLabelingLevel,
  updateAnalysisLabelingStatus,
  updateAnalysisMaintenanceTarget,
  updateDisplayEligible,
  updateDisplayProcessingTarget,
} from '../api/report';
import { format, parseJSON } from 'date-fns';

const ReportStatus: FC<{ report: Report }> = ({ report }) => {
  const queryClient = useQueryClient();
  const updateReportCache = (newReport: Report) => {
    queryClient.setQueryData([`/admin/reports/${report.id}`], newReport);
  };
  const { mutate: toggleDisplayProcessingTarget } = useMutation(updateDisplayProcessingTarget, {
    onSuccess: updateReportCache,
  });
  const { mutate: toggleDisplayEligible } = useMutation(updateDisplayEligible, {
    onSuccess: updateReportCache,
  });
  const { mutate: requestConfirmReportLabeling } = useMutation(confirmReportLabeling, {
    onSuccess: updateReportCache,
  });
  const { mutate: confirmReportDisplayConfirm } = useMutation(markReportDisplayConfirm, {
    onSuccess: updateReportCache,
  });
  const { mutate: updateLabelingStatus } = useMutation(updateAnalysisLabelingStatus, {
    onSuccess: updateReportCache,
  });
  const { mutate: updateLabelingLevel } = useMutation(updateAnalysisLabelingLevel, {
    onSuccess: updateReportCache,
  });
  const { mutate: updateMaintenanceTarget } = useMutation(updateAnalysisMaintenanceTarget, {
    onSuccess: updateReportCache,
  });
  const { mutate: toggleAnalysisEligible } = useMutation(updateAnalysisEligible, {
    onSuccess: updateReportCache,
  });
  const { mutate: markVIP } = useMutation(markVIPReport, {
    onSuccess: updateReportCache,
  });

  return (
    <Stack my={8} spacing={4} direction="row">
      <Box p={4} borderRadius="md" borderWidth="1px" w="100%">
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Text fontSize="2xl">Analytics Status</Text>
          <Button
            mt={2}
            size="sm"
            colorScheme="green"
            onClick={() => {
              if (window.confirm('Really confirm it?')) {
                requestConfirmReportLabeling(report.id);
              }
            }}
          >
            Confirm labeling
          </Button>
        </Flex>
        <VStack spacing={1} align="flex-start">
          <KeyValueBox
            titleWidth={72}
            title="Analytics Labeling Status"
            value={
              <Select
                size="sm"
                value={report.analysis_labeling_status || ''}
                onChange={(e) => {
                  updateLabelingStatus({
                    reportId: report.id,
                    status: e.currentTarget.value as AnalysisLabelingStatus,
                  });
                }}
              >
                <option value="">N/A</option>
                <option value="REQUIRED">REQUIRED</option>
                <option value="LABELING">LABELING</option>
                <option value="IDLE">IDLE</option>
              </Select>
            }
          />
          <KeyValueBox
            titleWidth={72}
            title="Analytics Labeling Level"
            value={
              <Select
                size="sm"
                value={report.analysis_labeling_level || ''}
                onChange={(e) => {
                  updateLabelingLevel({
                    reportId: report.id,
                    level: e.currentTarget.value as AnalysisLabelingLevel,
                  });
                }}
              >
                <option value="">N/A</option>
                <option value="ADHOC">ADHOC</option>
                <option value="PUBLICATION">PUBLICATION</option>
                <option value="AUTHORSHIP">AUTHORSHIP</option>
              </Select>
            }
          />
          <KeyValueBox
            titleWidth={72}
            title="Analytics Labeled at"
            value={
              report.analysis_labeled_at ? format(parseJSON(report.analysis_labeled_at), 'yyyy-MM-d H:m:s') : 'N/A'
            }
          />
          <KeyValueBox
            titleWidth={72}
            title="Analytics maintenance target?"
            value={
              <Select
                size="sm"
                value={
                  typeof report.analysis_maintenance_target === 'boolean'
                    ? report.analysis_maintenance_target.toString()
                    : ''
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  updateMaintenanceTarget({ reportId: report.id, enable: value === 'true' });
                }}
              >
                <option value="">N/A</option>
                <option value="true">TRUE</option>
                <option value="false">FALSE</option>
              </Select>
            }
          />
          <KeyValueBox
            titleWidth={72}
            title="Analysis Eligible"
            value={
              <Select
                size="sm"
                value={typeof report.analysis_eligible === 'boolean' ? report.analysis_eligible.toString() : ''}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  toggleAnalysisEligible({ reportId: report.id, enable: value === 'true' });
                }}
              >
                <option value="">N/A</option>
                <option value="true">TRUE</option>
                <option value="false">FALSE</option>
              </Select>
            }
          />
          <Button
            colorScheme="pink"
            onClick={() => {
              if (window.confirm('Update fields for VIP Report? (for confirmed user)')) {
                markVIP(report.id);
              }
            }}
          >
            Mark VIP
          </Button>
        </VStack>
      </Box>
      <Box p={4} borderRadius="md" borderWidth="1px" w="100%">
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Text fontSize="2xl">Display Status</Text>
          <Button
            mt={2}
            size="sm"
            colorScheme="green"
            onClick={() => {
              if (window.confirm('Really confirm it?')) {
                confirmReportDisplayConfirm(report.id);
              }
            }}
          >
            Confirm display
          </Button>
        </Flex>
        <VStack spacing={1} align="flex-start">
          <KeyValueBox
            titleWidth={72}
            title="Display Processing Target"
            value={
              <Select
                size="sm"
                value={
                  typeof report.display_processing_target === 'boolean'
                    ? report.display_processing_target.toString()
                    : ''
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  toggleDisplayProcessingTarget({ reportId: report.id, enable: value === 'true' });
                }}
              >
                <option value="">N/A</option>
                <option value="true">TRUE</option>
                <option value="false">FALSE</option>
              </Select>
            }
          />
          <KeyValueBox titleWidth={72} title="Display Processing Status" value={report.display_processing_status} />
          <KeyValueBox titleWidth={72} title="Display Processed at" value={report.display_processed_at} />
          <KeyValueBox
            titleWidth={72}
            title="Display Eligible"
            value={
              <Select
                size="sm"
                value={typeof report.display_eligible === 'boolean' ? report.display_eligible.toString() : ''}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  toggleDisplayEligible({ reportId: report.id, enable: value === 'true' });
                }}
              >
                <option value="">N/A</option>
                <option value="true">TRUE</option>
                <option value="false">FALSE</option>
              </Select>
            }
          />
          <Button as="a" href={`https://www.xenure.com/overview?pri=${report.id}`} colorScheme="green" target="_blank">
            Check Report
          </Button>
        </VStack>
      </Box>
    </Stack>
  );
};

export default ReportStatus;
