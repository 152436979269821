import React, { FC, useState } from 'react';
import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import CreateReportForm from '../createReportForm';
import { ReportCreateInitialData } from '../reportDetailForm';

interface Props {
  initialData?: ReportCreateInitialData;
  buttonProps?: ButtonProps;
  onSubmit?: () => void;
}

const CreateReportModal: FC<Props> = ({ initialData, buttonProps, onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button colorScheme="teal" onClick={() => setIsOpen((prev) => !prev)} {...buttonProps}>
        Create new report
      </Button>
      <Modal onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreateReportForm
              initialData={initialData}
              onSubmit={() => {
                setIsOpen(false);
                onSubmit?.();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateReportModal;
