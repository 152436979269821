import React, { FC } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Textarea } from '@chakra-ui/react';
import AffiliationInput, { SelectableAffiliation } from '../affiliationInput';
import { Professorship } from '../../types/proferssorship';
import { FastTrackStatus, Report } from '../../models/report';
import { Affiliation } from '../../models/affiliation';

export interface ReportDetailFormValues {
  name: string;
  first_name: string;
  last_name: string;
  affiliation: { id: string; name: string };
  system_note: string;
  source_urls: string;
  kor_name: string;
  job_title: string;
  email: string;
  user_id: string;
  professorship: Professorship | null;
  fast_track_status: FastTrackStatus | null;
}

export interface ReportCreateInitialData {
  name?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  affiliation?: Affiliation;
  system_note?: string;
  user_id?: string;
}

interface Props {
  report?: Report;
  initialData?: ReportCreateInitialData;
  onSubmit: (values: ReportDetailFormValues) => void;
}

const ReportDetailForm: FC<Props> = ({ report, onSubmit, initialData }) => {
  return (
    <Formik<ReportDetailFormValues>
      initialValues={{
        name: initialData?.name ?? report?.name ?? '',
        first_name: initialData?.first_name ?? report?.first_name ?? '',
        last_name: initialData?.last_name ?? report?.last_name ?? '',
        kor_name: report?.kor_name ?? '',
        job_title: report?.job_title ?? '',
        email: initialData?.email ?? report?.email ?? '',
        system_note: initialData?.system_note ?? report?.system_note ?? '',
        source_urls: report?.sources_urls ?? '',
        affiliation: initialData?.affiliation || {
          id: report?.affiliation?.id ?? '',
          name: report?.affiliation?.name ?? '',
        },
        professorship: report?.professorship || null,
        user_id: initialData?.user_id ?? report?.user_id ?? '',
        fast_track_status: report?.fast_track_status || null,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          onSubmit(values);
        } catch (err) {
          setSubmitting(false);
          alert(err.response?.data?.error?.message || err.message);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field name="name">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.name && form.touched.name}>
                <FormLabel>Name</FormLabel>
                <Input {...field} placeholder="name" />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="first_name">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.first_name && form.touched.first_name}>
                <FormLabel>First Name</FormLabel>
                <Input {...field} placeholder="first_name" />
                <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="last_name">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.last_name && form.touched.last_name}>
                <FormLabel>Last Name</FormLabel>
                <Input {...field} placeholder="last_name" />
                <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="kor_name">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.kor_name && form.touched.kor_name}>
                <FormLabel>Korean name</FormLabel>
                <Input {...field} placeholder="홍길동" />
                <FormErrorMessage>{form.errors.kor_name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="user_id">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.user_id && form.touched.user_id}>
                <FormLabel>User Id</FormLabel>
                <Input {...field} placeholder="777" />
                <FormErrorMessage>{form.errors.user_id}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="affiliation">
            {({ form }: FieldProps<{ id: string; name: string }, ReportDetailFormValues>) => {
              return (
                <FormControl isInvalid={!!form.errors.affiliation?.id && !!form.touched.affiliation?.id} isRequired>
                  <FormLabel htmlFor="searchAffiliation">Search Affiliation</FormLabel>
                  <AffiliationInput
                    initialValue={form.values.affiliation.name}
                    onBlur={() => form.setFieldTouched('affiliation.id')}
                    onSelect={(affiliation: SelectableAffiliation) => {
                      form.setFieldValue('affiliation', {
                        id: affiliation.affiliation_id,
                        name: affiliation.keyword,
                      });
                    }}
                  />
                  <FormErrorMessage>{form.touched.affiliation?.id && form.errors.affiliation?.id}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="professorship">
            {({ form }: FieldProps<{ id: string; name: string }, ReportDetailFormValues>) => {
              return (
                <FormControl isInvalid={!!form.errors.professorship && !!form.touched.professorship}>
                  <FormLabel htmlFor="searchAffiliation">Professorship</FormLabel>
                  <Select
                    size="sm"
                    value={form.values.professorship || ''}
                    onBlur={() => form.setFieldTouched('professorship')}
                    onChange={(e) => form.setFieldValue('professorship', e.currentTarget.value)}
                    alignItems="center"
                  >
                    <option value={''}>NULL</option>
                    <option value="FULL">FULL</option>
                    <option value="ASSOCIATE">ASSOCIATE</option>
                    <option value="ASSISTANT">ASSISTANT</option>
                    <option value="EMERITUS">EMERITUS</option>
                    <option value="ADJUNCT">ADJUNCT</option>
                    <option value="UNKNOWN">UNKNOWN</option>
                  </Select>
                  <FormErrorMessage>{form.touched.professorship && form.errors.professorship}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="job_title">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.job_title && form.touched.job_title}>
                <FormLabel>Job title</FormLabel>
                <Input {...field} placeholder="professor" />
                <FormErrorMessage>{form.errors.job_title}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="email">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.email && form.touched.email}>
                <FormLabel>Email</FormLabel>
                <Input {...field} placeholder="email" type="email" />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="system_note">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.system_note && form.touched.system_note}>
                <FormLabel>System note</FormLabel>
                <Input {...field} placeholder="system note" />
                <FormErrorMessage>{form.errors.system_note}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="source_urls">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.source_urls && form.touched.source_urls}>
                <FormLabel>Source urls</FormLabel>
                <Textarea {...field} placeholder="https://cau.ac.kr" resize="none" />
                <FormErrorMessage>{form.errors.source_urls}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="fast_track_status">
            {({ form }: FieldProps<{ id: string; name: string }, ReportDetailFormValues>) => {
              return (
                <FormControl isInvalid={!!form.errors.fast_track_status && !!form.touched.fast_track_status}>
                  <FormLabel>Fast-track Status</FormLabel>
                  <Select
                    size="sm"
                    value={form.values.fast_track_status || ''}
                    onBlur={() => form.setFieldTouched('fast_track_status')}
                    onChange={(e) => form.setFieldValue('fast_track_status', e.currentTarget.value)}
                    alignItems="center"
                  >
                    <option value={''}>NONE</option>
                    <option value="PREPARING">PREPARING</option>
                    <option value="READY" disabled={true}>
                      READY
                    </option>
                    <option value="COLD" disabled={true}>
                      COLD MAILED
                    </option>
                    <option value="IN_TRANSITION" disabled={true}>
                      IN_TRANSITION
                    </option>
                    <option value="JOINED" disabled={true}>
                      JOINED
                    </option>
                    <option value="HOLD" disabled={true}>
                      HOLD
                    </option>
                  </Select>
                  <FormErrorMessage>{form.touched.professorship && form.errors.professorship}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Button type="submit" colorScheme="teal" disabled={isSubmitting} mt={4}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ReportDetailForm;
