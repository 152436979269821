import { Box, FormLabel } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import { getAnalyticsList } from '../../api/reportGroup';
import { personalAnalyticsState, INITIAL_PERSONAL_ANALYTICS_STATE } from '../../atoms/personalAnalytics';

const AnalyticsSelect: FC = () => {
  const [{ analytics }, setPersonalAnalytics] = useRecoilState(personalAnalyticsState);
  const { data: analyticsResponse } = useQuery(['/admin/analytics'], () => getAnalyticsList({ page: 0 }));

  const analyticsList = analyticsResponse?.content;

  if (!analyticsList) return null;

  const options = analyticsList.map((analytics) => ({ value: analytics.id, label: analytics.title }));

  return (
    <Box>
      <FormLabel>Analytics</FormLabel>
      <Select
        onChange={(option) => {
          const selectedAnalytics = analyticsList.find((a) => a.id === option?.value) || null;
          setPersonalAnalytics(() => ({ ...INITIAL_PERSONAL_ANALYTICS_STATE, analytics: selectedAnalytics }));
        }}
        options={options}
        value={analytics ? { value: analytics.id, label: analytics.title } : null}
      />
    </Box>
  );
};

export default AnalyticsSelect;
