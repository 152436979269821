import { atom } from "recoil";

interface SignInModalState {
  isOpen: boolean;
}

export const signInModalState = atom<SignInModalState>({
  key: "signInModalState",
  default: { isOpen: false },
});
