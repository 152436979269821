import { Menu, MenuButton, MenuList, Button, MenuOptionGroup, MenuItemOption } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import { updateFastTrackStatus } from '../../api/fastTrack';
import produce from 'immer';
import { FastTrackStatus } from '../../models/report';
import { FastTrackReport } from '../../models/fastTrackReport';

const StatusMenuButton: FC<{ status: FastTrackStatus }> = ({ status }) => {
  switch (status) {
    case 'PREPARING':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" colorScheme="yellow">
          PREPARING
        </MenuButton>
      );

    case 'READY':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" colorScheme="green">
          READY
        </MenuButton>
      );

    case 'COLD':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" colorScheme="blue">
          COLD MAILED
        </MenuButton>
      );

    case 'IN_TRANSITION':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" colorScheme="orange">
          IN TRANSITION
        </MenuButton>
      );

    case 'JOINED':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" colorScheme="gray">
          JOINED
        </MenuButton>
      );

    case 'HOLD':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" colorScheme="purple">
          HOLD
        </MenuButton>
      );

    default: {
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" colorScheme="blackAlpha">
          N/A
        </MenuButton>
      );
    }
  }
};

const FastTrackStatusBadge: FC<{ status: FastTrackStatus; reportId: string | number }> = ({ status, reportId }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateStatus } = useMutation(updateFastTrackStatus, {
    onSuccess: (newFastTrackReport) => {
      if (!newFastTrackReport) return;
      const fastTrackReports = queryClient.getQueryData<FastTrackReport[]>([`/admin/fast-track/reports`]) ?? [];

      queryClient.setQueryData<FastTrackReport[]>([`/admin/fast-track/reports`], () => {
        return produce(fastTrackReports, (draft) => {
          const index = draft.findIndex((item) => item.report.id === newFastTrackReport.report.id);
          if (index > -1) {
            draft[index] = newFastTrackReport;
          }
        });
      });
    },
  });

  return (
    <Menu>
      <StatusMenuButton status={status} />
      <MenuList>
        <MenuOptionGroup
          defaultValue={status}
          type="radio"
          onChange={(status) => updateStatus({ reportId: reportId, status: status as FastTrackStatus })}
        >
          <MenuItemOption value="PREPARING">PREPARING</MenuItemOption>
          <MenuItemOption value="READY">READY</MenuItemOption>
          <MenuItemOption value="COLD">COLD MAILED</MenuItemOption>
          <MenuItemOption value="IN_TRANSITION">IN TRANSITION</MenuItemOption>
          <MenuItemOption value="JOINED">JOINED</MenuItemOption>
          <MenuItemOption value="HOLD">HOLD</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default FastTrackStatusBadge;
