import React, { FC, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { authorListAtPaper } from '../../atoms/authorListAtPaper';
import { useAuthorship } from '../../hooks/useAuthorship';

interface Props {
  paperId: string;
  onClickSave: () => Promise<void>;
}

const ControlBar: FC<Props> = ({ onClickSave, paperId }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [authorList] = useRecoilState(authorListAtPaper);
  const { authorship } = useAuthorship({ paperId });

  async function save() {
    setIsSaving(true);
    await onClickSave();
    setIsSaving(false);
  }

  async function handleClickSave() {
    const firstAuthors = authorship?.scrapped_authors.filter((a) => a.is_first);
    const corrAuthors = authorship?.scrapped_authors.filter((a) => a.is_corr);

    const selectedCorrAuthors = [];
    const selectedFirstAuthors = [];
    authorList?.authorList.forEach((author) => {
      if (author.is_corr_author) {
        selectedCorrAuthors.push(author);
      }
      if (author.is_first_author) {
        selectedFirstAuthors.push(author);
      }
    });

    if (corrAuthors && corrAuthors.length > 0 && corrAuthors.length !== selectedCorrAuthors.length) {
      if (window.confirm('corresponding author 숫자가 맞질 않습니다. 계속 진행하시겠습니까?')) {
        await save();
      }
    } else if (firstAuthors && firstAuthors.length > 0 && firstAuthors.length !== selectedFirstAuthors.length) {
      if (window.confirm('first author 숫자가 맞질 않습니다. 계속 진행하시겠습니까?')) {
        await save();
      }
    } else {
      await save();
    }
  }

  return (
    <Box d="flex" justifyContent="flex-end">
      <Button isLoading={isSaving} onClick={handleClickSave}>
        SAVE
      </Button>
    </Box>
  );
};

export default ControlBar;
