import Fetcher from './fetcher';
import { FastTrackStatus } from '../models/report';
import { FastTrackReport } from '../models/fastTrackReport';

interface UpdateFastTrackStatusParams {
  reportId: string | number;
  status: FastTrackStatus;
}

export async function updateFastTrackStatus({ reportId, status }: UpdateFastTrackStatusParams) {
  const res = await Fetcher.instance.patch<{ data: FastTrackReport }>(`/admin/fast-track/reports/${reportId}/status`, {
    status,
  });
  return res.data.data;
}

export async function issueFastTrackSampleToken(reportId: string | number) {
  const res = await Fetcher.instance.post<{ data: FastTrackReport }>(
    `/admin/fast-track/reports/${reportId}/sample-token`,
    null
  );
  return res.data.data;
}

export async function issueFastTrackSignUpToken(reportId: string | number) {
  const res = await Fetcher.instance.post<{ data: FastTrackReport }>(
    `/admin/fast-track/reports/${reportId}/signup-token`,
    null
  );
  return res.data.data;
}

export async function getFastTrackReports(size: number = 1000) {
  const res = await Fetcher.instance.get<{ data: { content: FastTrackReport[] } }>(`/admin/fast-track/reports`, {
    params: { size },
  });
  return res.data.data.content;
}

export interface FastTrackColdMailRequest {
  sender_email: string;
  sender_name: string;
  bcc_emails: string[];
  mail_type: FastTrackColdMailType;
  receiver_name: string;
  file_url: string;
}

export type FastTrackColdMailType = 'D0' | 'D7';

export async function sendFastTrackColdMail(request: FastTrackColdMailRequest & { reportId: string | number }) {
  await Fetcher.instance.post(`/admin/fast-track/reports/${request.reportId}/send-mail`, request);
}

export interface EmailHistory {
  email: string;
  category: string;
  sent_at: string;
}

export async function getColdMailSentLogs(reportId: string | number) {
  const res = await Fetcher.instance.get<{ data: EmailHistory[] }>(`/admin/fast-track/reports/${reportId}/mail-logs`);

  return res.data.data;
}
