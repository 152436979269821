import { Report } from '../models/report';

export function getReportName(report?: Report) {
  if (!report) return 'N/A';

  if (report.kor_name) {
    return `#${report.id} ${report.name}(${report.kor_name})`;
  }

  return `#${report.id} ${report.name}`;
}
