import { useQuery } from 'react-query';
import gtm from '../helpers/gtm';
import { checkAuthentication } from '../api/auth';

export function useCurrentUser() {
  const { data: user, isLoading, error } = useQuery(
    '/auth/check',
    async () => {
      const { user, is_logged_in, is_admin } = await checkAuthentication();
      gtm.setUserProperty(user);
      return { ...user, is_logged_in, is_admin };
    },
    { staleTime: 10 * 60 * 60 * 1000 }
  );

  return { user, error, isLoading };
}
