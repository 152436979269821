export function getCompleteProgress(
  mappedCount: number | null | undefined,
  totalCount: number | null | undefined
): number {
  if (mappedCount && totalCount) {
    return mappedCount / totalCount;
  }

  return 0;
}
