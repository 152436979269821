import React, { FC } from 'react';
import FastTrackReportTable from '../components/fastTrackReportTable';
import { Box } from '@chakra-ui/react';
import { Container } from '@chakra-ui/layout';
import CreateReportModal from '../components/createReportModal';
import { useQueryClient } from 'react-query';

const FastTrack: FC = () => {
  const queryClient = useQueryClient();
  return (
    <>
      <Container maxW="container.xl" py={4}>
        <Box d="flex" justifyContent="flex-end">
          <CreateReportModal onSubmit={() => queryClient.invalidateQueries([`/admin/fast-track/reports`])} />
        </Box>
      </Container>
      <Box p={8}>
        <FastTrackReportTable />
      </Box>
    </>
  );
};

export default FastTrack;
