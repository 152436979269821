import React, { FC, FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Box, Input } from '@chakra-ui/react';
import { searchAffiliation } from '../../api/scinapse/affiliation';

export interface SelectableAffiliation {
  affiliation_id: string;
  keyword: string;
  type: string;
}

interface DebouncedAffiliationInputProps {
  initialValue?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onSelect: (affiliation: SelectableAffiliation) => void;
}

const AffiliationInput: FC<DebouncedAffiliationInputProps> = ({ onSelect, onFocus, onBlur, initialValue }) => {
  const [searchAffiliationInput, setSearchAffiliationInput] = useState(initialValue ?? '');
  const [affiliations, setAffiliations] = useState<SelectableAffiliation[]>([]);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (searchAffiliationInput.length > 1) {
        const affiliationList = await searchAffiliation(searchAffiliationInput);
        setAffiliations(affiliationList || []);
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [searchAffiliationInput]);

  useEffect(() => {
    setAffiliations([]);
    setCurrentIdx(-1);
  }, [isOpen]);

  function handleSelect(affiliation: SelectableAffiliation) {
    onSelect(affiliation);
    setSearchAffiliationInput(affiliation.keyword);
    setIsOpen(false);
  }

  return (
    <>
      <Input
        value={searchAffiliationInput}
        onFocus={() => onFocus && onFocus()}
        onBlur={() => onBlur && onBlur()}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          setSearchAffiliationInput(e.currentTarget.value);
          setCurrentIdx(-1);
          if (e.currentTarget.value.length > 1) setIsOpen(true);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          switch (e.keyCode) {
            case 13: {
              // enter
              e.preventDefault();
              handleSelect(affiliations[currentIdx]);
              break;
            }

            case 38: {
              // up
              setCurrentIdx(currentIdx - 1 < -1 ? affiliations.length - 1 : currentIdx - 1);
              break;
            }

            case 40: {
              // down
              setCurrentIdx(currentIdx + 1 > affiliations.length - 1 ? -1 : currentIdx + 1);
              break;
            }
          }
        }}
        id="searchAffiliation"
        placeholder="Search Affiliation"
      />
      {isOpen && affiliations.length > 0 && (
        <Box pos="absolute" zIndex={2} border="1px solid #eee" borderTop={0} p={1} bgColor="white">
          {affiliations.map((affiliation, i) => (
            <Box
              py={2}
              borderBottom={i === affiliations.length - 1 ? 0 : '1px solid #eee'}
              key={affiliation.affiliation_id}
              cursor="pointer"
              backgroundColor={i === currentIdx ? 'rgba(0,0,0,0.05)' : 'transparent'}
              _hover={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
              onClick={() => handleSelect(affiliation)}
            >
              {affiliation.keyword}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default AffiliationInput;
