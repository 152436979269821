import React, { FC } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { signInModalState } from '../../atoms/signInModal';
import { Field, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import gtm, { EventName } from '../../helpers/gtm';
import { login } from '../../api/auth';

type FormValues = {
  email: string;
  password: string;
};

function validate(values: FormValues) {
  let error: FormikErrors<FormValues> = {};
  const keys = Object.keys(values);

  for (const key of keys) {
    if (!values[key as keyof FormValues]) {
      error[key as keyof FormValues] = `missing ${key} value `;
    }
  }
  return error;
}

const SignInModal: FC = () => {
  const [{ isOpen }, setModalState] = useRecoilState(signInModalState);
  const queryClient = useQueryClient();
  const { mutateAsync: logIn } = useMutation(
    '/auth/check',
    async ({ email, password }: FormValues) => {
      const { user, is_admin, is_logged_in } = await login({ email, password });

      gtm.setUserProperty(user);

      return { ...user, is_logged_in, is_admin };
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData('/auth/check', data);
        gtm.event(EventName.LOGIN, { method: 'password' });
      },
    }
  );

  return (
    <Modal isOpen={isOpen} onClose={() => setModalState({ isOpen: false })} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sign in</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik<FormValues>
            initialValues={{ email: '', password: '' }}
            validate={validate}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await logIn(values);
                setModalState({ isOpen: false });
              } catch (err) {
                setSubmitting(false);
                alert(err.message);
              }
            }}
          >
            {(props) => (
              <Form>
                <Field name="email">
                  {({ field, form }: FieldProps<string, FormValues>) => (
                    <FormControl isInvalid={!!form.errors.email && !!form.touched.email}>
                      <FormLabel htmlFor="email">First email</FormLabel>
                      <Input {...field} type="email" id="email" placeholder="email" />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }: FieldProps<string, FormValues>) => (
                    <FormControl isInvalid={!!form.errors.password && !!form.touched.password}>
                      <FormLabel htmlFor="password">First password</FormLabel>
                      <Input {...field} type="password" id="password" placeholder="password" />
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button mt={4} colorScheme="teal" isLoading={props.isSubmitting} type="submit">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SignInModal;
