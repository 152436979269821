import { Box, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';

const SimpleChapterPage: FC = () => {
  return (
    <PersonalAnalyticsLayout page={4}>
      <Box fontWeight="bold" fontSize="42px" lineHeight="42px" color="#536DFE">
        개인 보고서 개요
      </Box>
      <VStack spacing="24px" fontSize="12px" lineHeight="21px" mt="42px" maxW="417px">
        <Box whiteSpace="pre-wrap">
          {' '}
          Pluto의 개인 종합 분석 보고서는 개별 연구자 별로 각 분야에 관한 최신 연구 트렌드 및 연구 성과에 대한 세부
          분석을 제공합니다. 보고서를 작성하기 위해 사용된 데이터 분석 결과는 단순히 연구 기관 단위에서의 연구 실적
          비교만을 위한 것이 아니라, 각 소속 연구자들의 연구 실적을 효과적으로 발전시키는 데에도 기여합니다.
        </Box>
        <Box whiteSpace="pre-wrap">
          {' '}
          연구 기관의 소속 연구자들은 해당 리포트를 활용하여 연구 실적을 발전시킬 수 있을 뿐만 아니라, 글로벌한 유망
          세부 연구 아젠다를 빠르게 파악할 수 있습니다. 또한 본인 연구의 우수성을 입증하는 여러 보조자료를 활용해 연구
          과제에 보다 손쉽게 지원할 수 있습니다.
        </Box>
      </VStack>
      <Box mt="248px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default SimpleChapterPage;
