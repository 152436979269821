import React, { FC } from 'react';
import { Select, SelectProps } from '@chakra-ui/react';
import { DOC_TYPE } from '../../constants/articleType';

const AVAILABLE_TYPES: DOC_TYPE[] = ['BOOK_CHAPTER', 'BOOK', 'CONFERENCE', 'DATASET', 'PATENT', 'JOURNAL'];

const DocTypeSelector: FC<SelectProps & { currentDocType: DOC_TYPE | null }> = ({ currentDocType, ...props }) => {
  return (
    <Select {...props} placeholder="Select article's document type">
      {AVAILABLE_TYPES.map((type) => (
        <option key={type} value={type}>
          {type}
        </option>
      ))}
    </Select>
  );
};

export default DocTypeSelector;
