import Axios, { AxiosInstance } from 'axios';
import { ACCESS_TOKEN_KEY } from '../constants/localStorage';
import { Token } from '../models/token';
import { XENURE_API_ENDPOINT } from '../constants';
import qs from 'qs';

class Fetcher {
  private refreshPromise: Promise<Token | undefined> | undefined;
  private readonly _instance: AxiosInstance;

  constructor() {
    this._instance = Axios.create({
      baseURL: XENURE_API_ENDPOINT,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
    });

    this._instance.interceptors.request.use(async (config) => {
      const token = Fetcher.getAccessToken();

      if (this.refreshPromise) {
        await this.refreshPromise;
      }

      if (token) {
        config.headers['Authorization'] = `Bearer ${token.token}`;
      }

      return config;
    });

    this._instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalReq = error.config;
        const status = error.response.status;

        if ((status === 401 || status === 403) && this.hasAccessToken) {
          try {
            // try to refresh the access token
            if (!this.refreshPromise) {
              this.refreshPromise = this.refreshToken();
            }
            await this.refreshPromise;
            this.refreshPromise = undefined;

            return this.instance.request(originalReq);
          } catch (err) {
            this.removeAccessToken();
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private static getAccessToken(): Token | undefined {
    const rawToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!rawToken) return;

    try {
      return JSON.parse(rawToken);
    } catch (err) {}
  }

  private async refreshToken() {
    const refreshInstance = Axios.create({
      baseURL: XENURE_API_ENDPOINT,
      withCredentials: true,
    });

    const res = await refreshInstance.post<{ data: Token; success: boolean }>('/auth/exchange');
    this.setAccessToken(res.data.data);
    return res.data.data;
  }

  public removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  public setAccessToken(token: Token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(token));
  }

  public get hasAccessToken() {
    return !!localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  public get instance() {
    return this._instance;
  }
}

const fetcher = new Fetcher();

export default fetcher;
