import React, { FC, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { Conference, ConferenceInstance } from '../../models/xenurePaper';
import { useSetRecoilState } from 'recoil';
import ConferenceInstanceTable from './conferenceInstanceTable';
import { venueDraftState } from '../../atoms/venueDraft';

const ConferenceInstanceSelect: FC<{ conference: Conference }> = ({ conference }) => {
  const [isTableOpen, setIsTableOpen] = useState(false);
  const setVenueDraftState = useSetRecoilState(venueDraftState);

  function updateMappedInstance(instance: ConferenceInstance | null) {
    setVenueDraftState((prev) => ({ ...prev, conferenceInstance: instance }));
    setIsTableOpen(false);
  }

  return (
    <>
      <Box>Conference Instance Select</Box>
      <Button size="sm" onClick={() => setIsTableOpen(true)}>
        Instance 정보 가져오기
      </Button>
      <Button size="sm" ml={2} onClick={() => updateMappedInstance(null)}>
        Instance 정보 삭제하기
      </Button>
      {isTableOpen && <ConferenceInstanceTable conference={conference} onSelect={updateMappedInstance} />}
    </>
  );
};

export default ConferenceInstanceSelect;
