import { Box, Flex } from '@chakra-ui/layout';
import React, { FC } from 'react';
import WhitePlutoLogo from '../../../components/icons/whitePlutoLogo';
import { format } from 'date-fns';

interface Props {
  name: string;
}

const CoverPage: FC<Props> = ({ name }) => {
  return (
    <Box
      fontFamily="'Noto Sans KR', sans-serif;"
      w="794px"
      h="1123px"
      margin="0 auto"
      p="70px 80px"
      color="white"
      backgroundImage="url('/cover.png')"
    >
      <Flex justifyContent="space-between">
        <Box fontSize="14px">{`${format(new Date(), 'yyyy.MM.dd')} ver`}</Box>
        <Box w="72px" h="28px">
          <WhitePlutoLogo width="100%" height="100%" />
        </Box>
      </Flex>
      <Box mx="102px">
        <Box mt="230px" fontSize="56px" lineHeight="81px" fontWeight="bold">
          연구자 개인 분석 보고서
        </Box>
        <Box mt="24px" fontSize="21px" lineHeight="30.5px" fontWeight={500}>
          {name}
        </Box>
        <Box mt="8px" fontSize="12px" whiteSpace="pre-wrap" lineHeight="17px">
          {'Pohang University of Science and Technology (POSTECH)\nDivision of Enviromental Science and Engineering'}
        </Box>
        <Box mt="184px" fontWeight="bold" fontSize="24px" lineHeight="35px">
          Pluto, Inc.
        </Box>
        <Box mt="8px" fontSize="12px" lineHeight="17px">
          <Box>contact@pluto.im</Box>
          <Box>www.pluto.im</Box>
          <Box>+82) 070-4855-0120</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CoverPage;
