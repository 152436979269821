import React, { FC } from 'react';
import { Box, Flex, HStack, ListItem, UnorderedList } from '@chakra-ui/react';
import { useReportRawData } from '../../../hooks/useReportRawData';
import WordCloud from '../../../components/personalAnalytics/graphs/wordCloud';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { usePageComment } from '../../../hooks/usePageComment';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';

interface Props {
  personalReportId: number;
  dataId: string;
}

interface ChartData {
  fos_name: string;
  fos_freq: number;
}

const MainFOSWordCloudPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const chartData = parseStringifiedJSONRawData(rawData?.report_json_data) as ChartData[];

  const sortedChartData = chartData;
  sortedChartData.sort((a, b) => b.fos_freq - a.fos_freq).map((data) => data.fos_name);
  const predefinedComments = [
    `주요 연구 주제로는 ${sortedChartData[0].fos_name}를 대표로해서, ${sortedChartData[1].fos_name}, ${sortedChartData[2].fos_name}, ${sortedChartData[3].fos_name} 등과 같은 연구 주제가 논문에서 많이 확인되었습니다.`,
  ];
  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout page={17}>
      <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
        유사 연구자 분석
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
        대표 연구 키워드
      </Box>
      <Flex justifyContent="center" alignItems="center" mb="34px">
        <WordCloud data={chartData.map((datum) => ({ word: datum.fos_name, value: datum.fos_freq }))} />
      </Flex>
      <HStack spacing="16px" alignItems="flex-start" mt="42px">
        <Box>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px" whiteSpace="pre-wrap">
            {' '}
            대표 연구 키워드를 확인합니다. 데이터는 AI를 기반으로 한 논문 내 키워드 추출 방법을 사용했습니다. 빈도수와
            연구 주제의 글자 크기가 비례하도록 표현하였습니다.
          </Box>
          <UnorderedList
            p="13px 15px"
            fontSize="12px"
            lineHeight="21px"
            bg="#EBEEFD"
            padding="15px 13px"
            listStylePosition="inside"
            m="0"
          >
            {comments?.map((comment, i) => (
              <ListItem key={i}>{comment}</ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box
          pos="relative"
          w="201px"
          borderRadius="5px"
          p="26px 17px 22px 17px"
          fontSize="12px"
          lineHeight="21px"
          flex="1 0 auto"
          wordBreak="break-all"
        ></Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default MainFOSWordCloudPage;
