import React from 'react';
import { FC } from 'react';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import { FastTrackColdMailRequest, FastTrackColdMailType } from '../../../api/fastTrack';
import { Report } from '../../../models/report';

const FAST_TRACK_MAIL_TYPES: FastTrackColdMailType[] = ['D0', 'D7'];

const SendColdMailForm: FC<{ report: Report; onSubmit: (values: FastTrackColdMailRequest) => void }> = ({
  report,
  onSubmit,
}) => (
  <>
    <Formik<FastTrackColdMailRequest>
      onSubmit={async (values, { setSubmitting }) => {
        if (window.confirm(`Do you really want to send a cold email?\n\n${JSON.stringify(values, null, 4)}`)) {
          try {
            await onSubmit(values);
          } catch (err) {
            setSubmitting(false);
            alert(err.response?.data?.error?.message || err.message);
          }
        }
      }}
      initialValues={{
        sender_email: 'junseon@pluto.im',
        sender_name: '유준선',
        bcc_emails: ['team@pluto.im'],
        mail_type: 'D0',
        receiver_name: report.kor_name ?? '',
        file_url: 'https://assets.pluto.im/pluto_service_introduction_210830.pdf',
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <HStack mb={4}>
            <Heading size={'h5'}>Receiver Email</Heading>
            <Text fontSize={'md'}>{report.email}</Text>
          </HStack>
          <Field name="receiver_name">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.sender_name && form.touched.sender_name}>
                <FormLabel>
                  Receiver Name
                  <Text color="gray.500" fontSize="xs">
                    안녕하세요, OOO 연구자님.
                  </Text>
                </FormLabel>
                <Input {...field} placeholder="sender_name" size="sm" />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="sender_email">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.sender_email && form.touched.sender_email}>
                <FormLabel>Sender Email</FormLabel>
                <Input {...field} placeholder="sender_email" size="sm" />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="sender_name">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.sender_name && form.touched.sender_name}>
                <FormLabel>Sender Name</FormLabel>
                <Input {...field} placeholder="sender_name" size="sm" />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <FieldArray
            name="bcc_emails"
            render={(arrayHelpers) => (
              <>
                <FormLabel>bcc emails</FormLabel>
                {values?.bcc_emails.map((bccEmail, i) => (
                  <HStack key={i} mb={2}>
                    <Field name={`bcc_emails.${i}`}>
                      {({ field }: FieldProps<string, FastTrackColdMailRequest>) => (
                        <FormControl>
                          <Input {...field} id={`bcc_emails.${i}`} placeholder="bcc_emails" size="sm" />
                        </FormControl>
                      )}
                    </Field>
                    <Button size="sm" onClick={() => arrayHelpers.remove(i)}>
                      -
                    </Button>
                  </HStack>
                ))}
                <Button size="sm" onClick={() => arrayHelpers.push('')}>
                  +
                </Button>
              </>
            )}
          />
          <Field name="mail_type">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.mail_type && form.touched.mail_type}>
                <FormLabel>Mail Template Type</FormLabel>
                <Select {...field}>
                  {FAST_TRACK_MAIL_TYPES.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{form.errors.mail_type}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="file_url">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.file_url && form.touched.file_url}>
                <FormLabel>Introduction File Url</FormLabel>
                <Input {...field} placeholder="sender_name" size="sm" />
                <FormErrorMessage>{form.errors.file_url}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button type="submit" mt={4} colorScheme="teal" isFullWidth disabled={isSubmitting}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  </>
);

export default SendColdMailForm;
