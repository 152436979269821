import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import produce from 'immer';
import React, { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { updatePersonalReportCustomValue } from '../../../api/personalReport';
import { personalAnalyticsState } from '../../../atoms/personalAnalytics';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface FOSItem {
  fos_id: string;
  fos_name: string;
  fos_freq: number;
  major_journal: string[];
}

const FOSSelector: FC = () => {
  const [personalAnalytics, setPersonalAnalytics] = useRecoilState(personalAnalyticsState);
  const { data: rawData } = useReportRawData({
    personalReportId: personalAnalytics?.personalReport?.id,
    dataId: 'main_fos',
  });

  const { mutate: updateFOSList, isLoading: updatingFOSList } = useMutation(updatePersonalReportCustomValue, {
    onSuccess: (personalReport) => {
      setPersonalAnalytics(
        produce(personalAnalytics, (draft) => {
          draft.personalReport = personalReport;
        })
      );
    },
  });

  const [selectedFOSList, setSelectedFOSList] = useState<string[]>([]);

  const FOSList = parseStringifiedJSONRawData(rawData?.report_json_data) as FOSItem[];

  useEffect(() => {
    const mainFOSList = personalAnalytics.personalReport?.main_fos;
    if (mainFOSList) {
      setSelectedFOSList(mainFOSList);
    }
  }, [personalAnalytics.personalReport?.main_fos]);

  if (!FOSList) return null;

  FOSList.sort((a, b) => b.fos_freq - a.fos_freq);

  return (
    <Box mb={8}>
      <Text fontSize="2xl" fontWeight="bold">
        FOS Selector ({selectedFOSList?.length})
      </Text>
      <HStack spacing="4px" overflowX="auto">
        {FOSList.slice(0, 20).map((FOS) => (
          <Box
            key={FOS.fos_name}
            p="4px"
            border="1px solid #dedede"
            borderRadius="10px"
            textAlign="center"
            fontSize={FOS.fos_name && FOS.fos_name.length > 10 ? '12px' : '14px'}
            cursor="pointer"
            bg={selectedFOSList.includes(FOS.fos_id) ? 'blue.500' : 'transparent'}
            color={selectedFOSList.includes(FOS.fos_id) ? 'white' : '#333'}
            onClick={() => {
              const index = selectedFOSList.findIndex((fosId) => fosId === FOS.fos_id);
              if (index > -1) {
                setSelectedFOSList([...selectedFOSList.slice(0, index), ...selectedFOSList.slice(index + 1)]);
              } else {
                setSelectedFOSList([...selectedFOSList, FOS.fos_id]);
              }
            }}
          >
            <Box whiteSpace="nowrap">{FOS.fos_name}</Box>
            <Box>{FOS.fos_freq}</Box>
          </Box>
        ))}
      </HStack>
      <Flex justifyContent="flex-end">
        <Button
          colorScheme="blue"
          mt={4}
          onClick={() =>
            updateFOSList({ personalReportId: personalAnalytics.personalReport!.id, mainFOS: selectedFOSList })
          }
          disabled={updatingFOSList}
          isLoading={updatingFOSList}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default FOSSelector;
