import { Box, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';

interface DescriptionItemProps {
  title: string;
  desc: string;
}

const DescriptionItem: FC<DescriptionItemProps> = ({ title, desc }) => {
  return (
    <Box fontSize="12px" lineHeight="21px" p="8px 20px" bg="#E7EBFD" borderRadius="5px">
      <Box color="#1A237E" fontWeight="bold">
        {title}
      </Box>
      <Box>{desc}</Box>
    </Box>
  );
};

const Chapter1Page: FC = () => {
  return (
    <PersonalAnalyticsLayout page={5}>
      <ChapterJumbotron title="연구자의 연구 성과" chapterTitle="Chapter 01." />
      <Box fontSize="12px" lineHeight="21px" mt="24px" whiteSpace="pre-wrap">
        {' '}
        전체 연구 기간 동안의 연구 성과를 종합적으로 확인하는 섹션입니다. 대표 지표에는 출판수(Publications),
        피인용수(Citations), h-index 등이 포함됩니다. 전체 데이터를 <b>기여도 구분 없는 전체 데이터</b> 그리고{' '}
        <b>1 저자 혹은 교신저자로 구분된 데이터</b> <small>(이하 Lead Authored)</small>로 나누어 분석하였습니다.
      </Box>
      <Box pos="relative" mt="60px" border="1px solid #536DFE" borderRadius="10px" p="36px 18px 24px 18px">
        <Box
          pos="absolute"
          p="5px 15px"
          bg="#536DFE"
          borderRadius="5px"
          color="white"
          fontSize="16px"
          lineHeight="16px"
          left="18px"
          top="-13px"
        >
          지표 산출 방법
        </Box>
        <VStack spacing="20px">
          <DescriptionItem
            title="Publications"
            desc="연구자가 출판한 전체 논문수입니다. SCIE 또는 이에 준하는 저널에 출판한 논문을 기준으로 산출되었습니다. 기타 저널에 출판한 논문이나 conference, repository 등에 등록한 논문은 숫자에서 제외하였습니다."
          />
          <DescriptionItem
            title="Citations"
            desc="연구자가 출판한 논문이 인용된 횟수입니다. SCIE 또는 이에 준하는 저널에 출판된 논문을 기준으로 산출되었습니다. 기타 저널에 출판된 논문이나 conference, repository 등에 등록된 논문은 숫자에서 제외하였습니다."
          />
          <DescriptionItem
            title="h-index"
            desc="연구자를 평가하는데 가장 활발히 쓰이는 지표로써, 어떤 연구자의 h-index 값이 h 일 때, 이 연구자의 논문 중 h 편이 h 회 이상 인용되었다는 것을 의미합니다. 연구의 질과 양을 한번에 확인할 수 있는 지표입니다."
          />
          <DescriptionItem
            title="Personal Impact Factor"
            desc="저널의 Impact Factor와 유사하게, 개인 연구자의 영향력을 표현합니다. 연구자의 Impact Factor는 월별로 계산됩니다. 예를 들어, 2018년 5월 ~ 2020년 4월까지 연구자가 출판한 논문 수를 10편, 해당 논문 10편의 2020년 5월 ~ 2021년 4월 피인용 횟수를 100회라고 할 때, 2021년 5월 기준의 2 Years Impact Factor는 (100/10)인 10으로 계산됩니다."
          />
        </VStack>
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default Chapter1Page;
