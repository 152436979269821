import React, { FC } from 'react';
import { Conference, ConferenceInstance } from '../../models/xenurePaper';
import styled from '@emotion/styled';
import { getConferenceInformation } from './actions';
import useSWR from 'swr';

const TableDivision = styled.td`
  border: 1px solid #dedede;
`;

const ConferenceInstanceTable: FC<{
  conference: Conference;
  onSelect: (instance: ConferenceInstance) => void;
}> = ({ conference, onSelect }) => {
  const { data: detail, error } = useSWR(`/conferences/${conference.id}`, () =>
    getConferenceInformation(conference.id)
  );

  if (!error && !detail) {
    return null;
  }

  if (error) {
    const errorMessage = error.response?.data?.error?.message || error.message;
    alert(`Conference Instance 정보 조회에 실패했습니다. Conference ID: ${conference.id}, error: ${errorMessage}`);
    return null;
  }

  const instances = detail?.instances;
  if (!instances?.length) {
    alert(`Conference Instance 정보가 존재하지 않습니다. Conference ID: ${conference.id}`);
    return null;
  }

  return (
    <>
      <table style={{ border: '1px solid #dedede', width: '100%' }}>
        <thead style={{ backgroundColor: '#333', color: 'white ' }}>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>location</th>
            <th>official url</th>
          </tr>
        </thead>
        <tbody>
          {instances.map((i) => (
            <tr key={i.id} onClick={() => onSelect(i)} style={{ cursor: 'pointer' }}>
              <TableDivision>{i.id}</TableDivision>
              <TableDivision>{i.name}</TableDivision>
              <TableDivision>{i.location}</TableDivision>
              <TableDivision>{i.official_url}</TableDivision>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ConferenceInstanceTable;
