import { Box, FormLabel } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { getTemplates } from '../../api/personalReport';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import { personalAnalyticsState } from '../../atoms/personalAnalytics';

const TemplateSelect: FC = () => {
  const [{ template }, setPersonalAnalytics] = useRecoilState(personalAnalyticsState);
  const { data: templates } = useQuery(['/admin/personal-report/templates'], getTemplates);

  if (!templates) return null;

  const options = templates.map((template) => ({ value: template.id, label: template.name }));

  return (
    <Box>
      <FormLabel>Templates</FormLabel>
      <Select
        onChange={(option) => {
          const selectedTemplate = templates?.find((t) => t.id === option?.value)!;
          setPersonalAnalytics((prev) => ({ ...prev, template: selectedTemplate }));
        }}
        options={options}
        value={template ? { value: template.id, label: template.name } : null}
      />
    </Box>
  );
};

export default TemplateSelect;
