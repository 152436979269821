import { mutate } from 'swr';
import { DOC_TYPE, SUB_DOC_TYPE } from '../../constants/articleType';
import { SelectableAuthor } from '../../models/selectableAuthor';
import { VenueSearchResult } from '../../models/venue';
import { Conference } from '../../models/xenurePaper';
import Fetcher from '../../api/fetcher';

interface AuthorParams {
  affiliation_id: string | null;
  author_id: string;
  is_corr_author: boolean;
  is_first_author: boolean;
  is_myself: boolean;
  sequence: number;
}

interface PaperUpdateParams {
  authors: AuthorParams[];
  journal_id: string | null;
  conference_id: string | null;
  conference_instance_id: string | null;
  remove_journal: boolean;
  remove_conference: boolean;
  paper_id: string;
  doc_type: DOC_TYPE | null;
  sub_type: SUB_DOC_TYPE | null;
  update_authorship: boolean;
}

interface ISSNorIDSearchResponse {
  data: {
    id: string;
    issn: string | null;
    title: string;
    title_abbrev: string | null;
    web_page: string | null;
    main_image: string | null;
    impact_factor: number | null;
    paper_count: number | null;
    citation_count: number | null;
    categories: any[];
  };
}

export async function searchVenueByISSNorId(query: string): Promise<VenueSearchResult> {
  const res = await Fetcher.instance.get<ISSNorIDSearchResponse>(`/admin/journals/${query}`);

  const venue = res.data.data;

  return {
    type: 'JOURNAL',
    id: venue.id,
    title: venue.title,
    abbrev: venue.title_abbrev,
    issn: venue.issn ? [venue.issn] : [],
    impact_factor: venue.impact_factor,
    paper_count: venue.paper_count,
    citation_count: venue.citation_count,
  };
}

export async function searchVenueByTitle(query: string): Promise<VenueSearchResult[]> {
  const res = await Fetcher.instance.get<{ data: VenueSearchResult[] }>('/complete/venue', {
    params: { q: query },
  });

  return res.data.data;
}

export async function getConferenceInformation(conferenceId: string): Promise<Conference> {
  const res = await Fetcher.instance.get<{ data: Conference }>(`/conferences/${conferenceId}`);

  return res.data.data;
}

interface SavePaperParams {
  authors: SelectableAuthor[];
  journalId: string | null | undefined;
  conferenceId: string | null | undefined;
  conferenceInstanceId: string | null | undefined;
  removeJournal: boolean;
  removeConference: boolean;
  paperId: string;
  docType: DOC_TYPE | null;
  subType: SUB_DOC_TYPE | null;
  reportId: string;
  rawId?: number;
}

export async function savePaper({
  authors,
  journalId,
  conferenceId,
  conferenceInstanceId,
  removeJournal,
  removeConference,
  rawId,
  paperId,
  docType,
  subType,
  reportId,
}: SavePaperParams) {
  const authorParams: AuthorParams[] = authors.map((a, i) => {
    return {
      affiliation_id: a.affiliation?.id || null,
      author_id: a.id,
      is_corr_author: !!a.is_corr_author,
      is_first_author: !!a.is_first_author,
      is_myself: !!a.is_myself,
      sequence: i + 1,
    };
  });

  const params: PaperUpdateParams = {
    authors: authorParams,
    journal_id: journalId || null,
    conference_id: conferenceId || null,
    conference_instance_id: conferenceInstanceId || null,
    remove_journal: removeJournal,
    remove_conference: removeConference,
    paper_id: paperId,
    doc_type: docType || null,
    sub_type: subType || null,
    update_authorship: true,
  };

  if (rawId) {
    await saveMatchedPaper({ ...params, rawId, reportId });
  } else {
    await updateExistPaper({ ...params, reportId });
  }
}

async function updateExistPaper({ reportId, ...params }: PaperUpdateParams & { reportId: string }) {
  await Fetcher.instance.put(`/admin/reports/${reportId}/mapping`, params);

  // TODO: Remove mutate after remove SWR
  mutate(`/reports/${reportId}/papers`);
}

async function saveMatchedPaper({
  rawId,
  reportId,
  ...params
}: PaperUpdateParams & { rawId: number; reportId: string }) {
  await Fetcher.instance.post(`/admin/candidates/${rawId}/match`, params);
}
