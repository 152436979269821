import {
  Button,
  FormControl,
  Input,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  ModalFooter,
  Box,
  Flex,
} from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { importPapersFromAuthorId } from '../../api/reportGroup';
import { findAuthor, getAuthorById } from '../../api/scinapse/author';

interface SearchAuthor {
  id: string;
  name: string;
  affiliation?: {
    id?: string;
    name?: string;
  };
}

const AuthorItem: FC<{ author: SearchAuthor; onSelect: (authorId: string) => void }> = ({ author, onSelect }) => {
  return (
    <Flex p={4} justifyContent="space-between" alignItems="center">
      <Box>
        <Box>{`# ${author.id} ${author.name}`}</Box>
        {author.affiliation && <Box fontSize="sm">{`# ${author.affiliation.id} ${author.affiliation.name}`}</Box>}
      </Box>
      <Button onClick={() => onSelect(author.id)}>Select</Button>
    </Flex>
  );
};

const ImportPapersFromAuthorButton: FC<{ reportId: string }> = ({ reportId }) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const initialRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [foundAuthors, setFoundAuthors] = useState<SearchAuthor[]>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: importPapers } = useMutation(importPapersFromAuthorId, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(`/reports/${reportId}/papers`);
      }, 500);
    },
  });

  useEffect(() => {
    let isLoading = false;
    if (inputValue.length < 2) return;

    async function searchAuthors() {
      if (isNaN(parseInt(inputValue, 10))) {
        const foundAuthorsResponse = await findAuthor(inputValue);

        if (!isLoading) return;

        setFoundAuthors(
          foundAuthorsResponse.content.map((author) => ({
            ...author,
            affiliation: {
              id: author.last_known_affiliation?.id,
              name: author.last_known_affiliation?.name,
            },
          }))
        );
        isLoading = false;
      } else {
        const author = await getAuthorById(inputValue);
        if (!isLoading) return;
        setFoundAuthors([
          {
            ...author,
            affiliation: {
              id: author.last_known_affiliation?.id,
              name: author.last_known_affiliation?.name,
            },
          },
        ]);
        isLoading = false;
      }
    }

    isLoading = true;
    const timeout = setTimeout(() => {
      searchAuthors();
    }, 200);

    return () => {
      isLoading = false;
      clearTimeout(timeout);
    };
  }, [inputValue]);

  async function handleSelectAuthor(author: SearchAuthor) {
    if (
      window.confirm(
        `정말로 ${author.name}의 페이퍼를 추가하시겠습니까? (이미 Report에 등록된 paper가 하나라도 있다면 실패합니다.)`
      )
    ) {
      try {
        await importPapers({ authorIds: [author.id], reportId });
        onClose();
      } catch (err) {
        alert(err.response?.data?.error?.message || err.message);
      }
    }
  }

  return (
    <>
      <Button colorScheme="blue" onClick={onToggle}>
        import papers from Scinapse
      </Button>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create your account</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Seach author with [id] or [author name]</FormLabel>
              <Input
                ref={initialRef}
                onChange={(e) => setInputValue(e.currentTarget.value)}
                value={inputValue}
                placeholder="12345678 or John Smith"
              />
            </FormControl>
            {foundAuthors.map((author, i) => (
              <Box key={author.id} mt={i === 0 ? 0 : 2}>
                <AuthorItem onSelect={() => handleSelectAuthor(author)} author={author} />
              </Box>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportPapersFromAuthorButton;
