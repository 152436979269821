import { Container, Box, HStack, Flex, Button } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { personalAnalyticsState } from '../../atoms/personalAnalytics';
import AnalyticsSelect from '../../components/analyticsSelect';
import KeyValueBox from '../../components/keyValueBox';
import FOSSelector from '../../components/personalAnalytics/fosSelector';
import PageDescription from '../../components/personalAnalytics/pageDescription';
import PageItem from '../../components/personalAnalytics/pageItem';
import PersonalReportSelect from '../../components/personalReportSelect';
import TemplateSelect from '../../components/templateSelect';
import PersonalReportPage from '../personalReportPages';

const PersonalAnalytics: FC = () => {
  const [personalAnalytics] = useRecoilState(personalAnalyticsState);

  return (
    <>
      <Container maxW="container.xl" my={8}>
        <HStack border="1px solid #dedede" p={2} borderRadius="5px" spacing="16px">
          <Box w="100%">
            <AnalyticsSelect />
          </Box>
          <Box w="100%">
            <PersonalReportSelect />
          </Box>
          <Box w="100%">
            <TemplateSelect />
          </Box>
        </HStack>
        <Flex justifyContent="space-between" alignItems="center">
          {personalAnalytics.analytics && (
            <HStack spacing={12} my={4}>
              <KeyValueBox
                titleWidth={32}
                title="Analytics Period"
                value={personalAnalytics.personalReport?.analyzed_at}
              />
              <KeyValueBox titleWidth={32} title="Analytics Status" value={personalAnalytics.personalReport?.status} />
            </HStack>
          )}

          {personalAnalytics.template && (
            <HStack spacing="8px">
              <Button
                onClick={() =>
                  window.open(
                    `/personal-analytics-pages?pid=${personalAnalytics.personalReport!.id}&did=result-contents`
                  )
                }
                as="a"
                target="_blank"
                colorScheme="yellow"
                size="sm"
              >
                전체 출력 결과물 보기 CONTENTS
              </Button>
              <Button
                onClick={() =>
                  window.open(
                    `/personal-analytics-pages?pid=${personalAnalytics.personalReport!.id}&did=result-appendix`
                  )
                }
                as="a"
                target="_blank"
                colorScheme="yellow"
                size="sm"
              >
                전체 출력 결과물 보기 APPENDIX
              </Button>
              <Button
                onClick={() =>
                  window.open(`/personal-analytics-pages?pid=${personalAnalytics.personalReport!.id}&did=result`)
                }
                as="a"
                target="_blank"
                colorScheme="yellow"
                size="sm"
              >
                전체 출력 결과물 보기
              </Button>
            </HStack>
          )}
        </Flex>
        <FOSSelector />
        {personalAnalytics.template && (
          <HStack spacing={4} px={8} py={4} bg="gray.500" overflow="auto" alignItems="stretch" borderRadius="5px">
            {personalAnalytics.template.pages.map((page) => (
              <PageItem page={page} key={page.id} />
            ))}
          </HStack>
        )}
        {personalAnalytics.selectedPage && (
          <HStack spacing="16px" mt={4} align="flex-start">
            <Box w="30%">
              <PageDescription
                page={personalAnalytics.selectedPage}
                personalReportId={personalAnalytics.personalReport!.id}
              />
            </Box>
            <Box w="66%">
              <Box fontWeight="bold" fontSize="20px" mb={2}>
                Preview (Click to open the real size view)
              </Box>
              <Box
                border="1px solid #dedede"
                borderRadius="4px"
                p={2}
                maxH="500px"
                boxShadow="md"
                overflow="auto"
                onClick={() =>
                  window.open(
                    `/personal-analytics-pages?pid=${personalAnalytics.personalReport!.id}&did=${
                      personalAnalytics.selectedPage!.data_id
                    }&gtype=${personalAnalytics.selectedPage?.graph_type}`
                  )
                }
                cursor="pointer"
              >
                {personalAnalytics.personalReport?.id && (
                  <PersonalReportPage
                    targetDataId={personalAnalytics.selectedPage.data_id}
                    targetPersonalReportId={personalAnalytics.personalReport.id}
                    targetGraphType={personalAnalytics.selectedPage.graph_type}
                  />
                )}
              </Box>
            </Box>
          </HStack>
        )}
      </Container>
    </>
  );
};

export default PersonalAnalytics;
