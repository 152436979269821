import React, { FC } from 'react';
import { Badge, Box, Button, Spinner, Table, Tbody, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { Column, useSortBy, useTable } from 'react-table';
import DisplayProcessingStatusBadge from './displayProcessingStatusBadge';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getFastTrackReports } from '../../api/fastTrack';
import { FastTrackReport } from '../../models/fastTrackReport';
import IssueSignupTokenButton from './issueSignupTokenButton';
import IssueSampleTokenButton from './issueSampleTokenButton';
import FastTrackStatusBadge from './FastTrackStatusBadge';
import ReportEditModal from '../reportEditModal';

const FastTrackReportTable: FC = () => {
  const { data: reports, isLoading } = useQuery<FastTrackReport[]>(['/admin/fast-track/reports'], () =>
    getFastTrackReports()
  );

  const columns: Column<FastTrackReport>[] = React.useMemo(
    () => [
      {
        Header: 'Researcher',
        columns: [
          {
            Header: 'Report Id',
            accessor: (row) => (
              <Box as={Link} color="blue.500" to={`/reports/${row.report.id}`}>
                {row.report.id}
              </Box>
            ),
          },
          {
            Header: 'Name',
            accessor: (row) => row.report.first_name + ' ' + row.report.last_name,
          },
          {
            Header: 'Email',
            accessor: (row) => row.report.email,
          },
          {
            Header: 'Affiliation',
            accessor: (row) => `${row.report.affiliation?.name} (${row.report.affiliation?.name_abbrev ?? ''})`,
          },
          {
            Header: 'Source URL',
            accessor: 'report.sources_urls',
          },
          {
            Header: 'Note',
            accessor: 'report.system_note',
          },
          {
            Header: 'Fast Sign Up token',
            accessor: (row) => <IssueSignupTokenButton token={row.signup_token} fastTrackReport={row} />,
          },
          {
            Header: 'Sample Email token',
            accessor: (row) => <IssueSampleTokenButton token={row.sample_token} fastTrackReport={row} />,
          },
        ],
      },
      {
        Header: 'Status',
        columns: [
          {
            Header: 'Fast Track Status',
            accessor: (row) => <FastTrackStatusBadge status={row.report.fast_track_status} reportId={row.report.id} />,
          },
          {
            Header: 'Display Status',
            accessor: (row) => <DisplayProcessingStatusBadge status={row.report.display_processing_status} />,
          },
          {
            Header: 'Display Eligible',
            accessor: (row) => {
              if (!row) {
                return 'N/A';
              }

              if (!row.report.display_eligible) {
                return (
                  <VStack spacing={2} fontSize="xs">
                    <Badge size="sm" colorScheme="red">
                      Display Not Eligible
                    </Badge>
                    <Box as={Link} color="blue.500" to={`/reports/${row.report.id}`}>
                      Go to report page
                    </Box>
                  </VStack>
                );
              }

              return (
                <VStack spacing={2} fontSize="xs">
                  <Badge size="sm" colorScheme="green">
                    Display Eligible
                  </Badge>
                  <Button
                    as="a"
                    size="xs"
                    href={`https://www.xenure.com/overview?pri=${row.report.id}`}
                    colorScheme="green"
                    target="_blank"
                  >
                    Check production Page
                  </Button>
                </VStack>
              );
            },
          },
          {
            Header: 'User Id',
            accessor: 'user_id',
          },
        ],
      },
      {
        Header: 'Identification Data',
        columns: [
          {
            Header: 'User ID',
            accessor: (row) => row.report.user_id ?? 'N/A',
          },
        ],
      },
      {
        Header: 'Controller',
        columns: [
          {
            Header: 'Manage',
            accessor: (row) => <ReportEditModal report={row.report} />,
          },
        ],
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<FastTrackReport>(
    { columns, data: reports ?? [] },
    useSortBy
  );

  if (isLoading) {
    return (
      <Box d="flex" flexDir="column" alignItems="center" mt={16}>
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Table {...getTableProps()} size="sm" variant="striped" wordBreak="break-word">
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())} isNumeric={(column as any).isNumeric}>
                {column.render('Header')}
                <Box as="span" pl="4">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <GoTriangleDown aria-label="sorted descending" />
                    ) : (
                      <GoTriangleUp aria-label="sorted ascending" />
                    )
                  ) : null}
                </Box>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={(cell.column as any).isNumeric}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default FastTrackReportTable;
