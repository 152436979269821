import Fetcher from './fetcher';
import { AuthorShip } from '../models/authorship';
import { XenurePaper } from '../models/xenurePaper';

export async function getAuthorship(paperId: string) {
  const res = await Fetcher.instance.get<{ data: AuthorShip; error: any }>(`/admin/publications/${paperId}/authorship`);
  return res.data.data;
}

interface GetPaperParams {
  paperId: string;
  lu?: boolean;
  userId?: string;
}

export async function getPaper({ paperId, userId, lu = true }: GetPaperParams) {
  const res = await Fetcher.instance.get<{ data: XenurePaper }>(`/publications/${paperId}`, {
    params: { uid: userId, lu },
  });
  return res.data.data;
}

export async function getPublicationCitationsBulk(paperIds: string[]) {
  const res = await Fetcher.instance.post<{ data: { [paperId: string]: string } }>(
    `/admin/publications/citations/bulk`,
    { paper_ids: paperIds }
  );

  return res.data.data;
}

export async function getPublicationBulk(paperIds: string[]) {
  const res = await Fetcher.instance.post<{ data: XenurePaper[] }>(`/admin/publications/bulk`, {
    paper_ids: paperIds,
  });

  return res.data.data;
}
