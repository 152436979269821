import { Box } from '@chakra-ui/react';
import React, { CSSProperties, FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';

interface Props {
  title: string;
  subtitle: string;
}

const ThStyle: CSSProperties = {
  border: '1px solid #B9B9B9',
  padding: '10px 8px',
  background: '#536DFE',
  color: 'white',
  fontSize: '12px',
  lineHeight: '17px',
};

const TdStyle: CSSProperties = {
  padding: '10px 8px',
  border: '1px solid #B9B9B9',
  fontSize: '12px',
  lineHeight: '17px',
  fontWeight: 'bold',
};

const RadialGraphPage: FC<Props> = ({ title, subtitle }) => {
  return (
    <PersonalAnalyticsLayout>
      <Box fontWeight="bold" fontSize="42px" lineHeight="42px" color="#536DFE" mb="22px">
        {title}
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" mb="24px">
        {subtitle}
      </Box>
      <Box w="565px" h="428px" border="1px solid #dedede" mx="auto">
        대충 radial graph
      </Box>
      <table style={{ textAlign: 'center', width: '100%', marginTop: '30px', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={ThStyle}>대상 연구자</th>
            <th style={ThStyle}>Publication</th>
            <th style={ThStyle}>Citation</th>
            <th style={ThStyle}>Avg-if</th>
            <th style={ThStyle}>Personal-if</th>
            <th style={ThStyle}>h-index</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ ...TdStyle, fontWeight: 700, backgroundColor: '#E6EBFF' }}>최원용</td>
            <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>metres (m)</td>
            <td style={TdStyle}>0.91444</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
          </tr>
          <tr>
            <td style={{ ...TdStyle, fontWeight: 700, backgroundColor: '#E6EBFF', maxWidth: '113px' }}>
              전체 유사 연구자의 평균값
            </td>
            <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>metres (m)</td>
            <td style={TdStyle}>0.91444</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
            <td style={TdStyle}>yards</td>
          </tr>
        </tbody>
      </table>
      <Box p="12px 16px" bg="#EBEEFD" mt="20px" fontSize="12px" lineHeight="21px" listStylePos="outside">
        <li>459명의 연구자들의 평균 연구 지표입니다.</li>
        <li>유사 연구자들에 비해 평균적으로 20% 낮은 수의 논문을 출판하고 있습니다.</li>
        <li>하지만 출판한 논문들이 약 3배 가까이 인용되고 있습니다.</li>
        <li>
          논문의 질을 확인할 수 있는 평균 Impact Factor를 살펴보면 1.5배정도 뛰어나며 상대적으로 우수한 저널에 출판하고
          있음을 확인합니다.
        </li>
        <li>Personal Impact Factor 역시 1.5배 정도 높은 수치를 기록하고 있습니다.</li>
        <li>논문의 양과 질을 모두 평가하는 hindex의 경우 2배정도 큰 놀라운 수치를 보입니다.</li>
        <li>종합적으로 봤을 때, 비슷한 분야를 연구하는 연구자들에 비해 월등히 좋은 연구 성과를 보이고 있습니다.</li>
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default RadialGraphPage;
