import { Button, Flex, FormLabel, Textarea } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { postComment } from '../../../api/reportGroup';
import { usePageComment } from '../../../hooks/usePageComment';

interface Props {
  personalReportId: number;
  dataId: string;
}

interface FormValues {
  comment: string;
}

const CommentInput: FC<Props> = ({ dataId, personalReportId }) => {
  const queryClient = useQueryClient();
  const { data: comment } = usePageComment({ personalReportId, dataId });

  const { mutateAsync: requestPostComment } = useMutation(postComment, {
    onSuccess: (comment) => {
      queryClient.setQueryData(
        [`/admin/personal-report/${personalReportId}/comment`, { personalReportId, dataId }],
        comment
      );
    },
  });

  return (
    <>
      <FormLabel mb={1}>Comments</FormLabel>
      <Formik<FormValues>
        initialValues={{ comment: comment?.comment || '' }}
        enableReinitialize
        onSubmit={async ({ comment }, { setSubmitting }) => {
          try {
            await requestPostComment({ personalReportId, comment, dataId });
          } catch (err) {
            alert(err.response?.data?.error?.message || err.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, handleChange }) => {
          return (
            <Form>
              <Textarea id="comment" name="comment" label="comment" value={values.comment} onChange={handleChange} />
              <Flex justifyContent="flex-end" mt={2}>
                <Button type="submit" size="sm" isLoading={isSubmitting}>
                  Apply
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CommentInput;
