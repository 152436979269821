import { Template } from '../models/template';
import { PersonalReport } from '../models/personalReport';
import Fetcher from './fetcher';

export async function getTemplates() {
  const res = await Fetcher.instance.get<{ data: Template[] }>(`/admin/personal-report/templates`);
  return res.data.data;
}

interface UpdatePersonalReportCustomValueParams {
  personalReportId: number;
  mainFOS?: string[];
  mainJournals?: string[];
}
export async function updatePersonalReportCustomValue({
  personalReportId,
  mainFOS,
  mainJournals,
}: UpdatePersonalReportCustomValueParams) {
  const res = await Fetcher.instance.put<{ data: PersonalReport }>(
    `/admin/personal-report/${personalReportId}/custom-values`,
    { main_fos: mainFOS, major_journal: mainJournals }
  );
  return res.data.data;
}

export async function getPersonalReport(personalReportId: number) {
  const res = await Fetcher.instance.get<{ data: PersonalReport }>(`/admin/personal-report/${personalReportId}`);

  return res.data.data;
}

export async function updateGraphMetadata({
  personalReportId,
  graphMetaData,
}: {
  personalReportId: number;
  graphMetaData: string;
}) {
  const res = await Fetcher.instance.put<{ data: PersonalReport }>(
    `/admin/personal-report/${personalReportId}/graph-meta-data`,
    {
      graph_meta_data: graphMetaData,
    }
  );

  return res.data.data;
}
