import React, { FC, useEffect } from 'react';
import { useQuery, useQueryClient, QueryClient } from 'react-query';
import { XenurePaper } from '../../models/xenurePaper';
import {
  Box,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Spinner,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import ContaminatedBox from '../modifyPaperModal/contaminatedBox';
import VenueSelectBox from '../venueSelectBox';
import SimpleAuthorSelectBox from './simpleAuthorSelectBox';
import { Report } from '../../models/report';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authorListAtPaper } from '../../atoms/authorListAtPaper';
import { Author } from '../../models/author';
import { savePaper } from '../modifyPaperModal/actions';
import { venueDraftState } from '../../atoms/venueDraft';
import {
  INITIAL_SIMPLE_MATCH_CANDIDATE_PAPER_MODAL_STATE,
  simpleMatchCandidatePaperModalState,
} from '../../atoms/simpleMatchCandidatePaperModal';
import { CandidatePaper } from '../../models/paper';
import produce from 'immer';
import AnomalyWarning from '../modifyPaperModal/anomalyWarning';
import { mutate } from 'swr';
import { getPaper } from '../../api/publication';
import { useAnomalyDetection } from '../../hooks/useAnomalyDetection';

interface Props {
  candidatePaperId?: number;
  paperId: string;
  report: Report;
}

function updateCandidatePapers(queryClient: QueryClient, report: Report, candidatePaperId: number) {
  const candidatePapers = queryClient.getQueryData<CandidatePaper[]>(`/admin/reports/${report.id}/candidates`) ?? [];
  const editedCandidatePaperIndex = candidatePapers.findIndex(
    (candidatePaper) => candidatePaper.id === candidatePaperId
  );
  if (editedCandidatePaperIndex > -1) {
    queryClient.setQueryData(
      `/admin/reports/${report.id}/candidates`,
      produce(candidatePapers, (draft) => {
        draft[editedCandidatePaperIndex].match_confirmed = true;
      })
    );
  }
}

const SimpleEditPaperModal: FC<Props> = ({ candidatePaperId, paperId, report }) => {
  const [authorList, setAuthorListAtPaper] = useRecoilState(authorListAtPaper);
  const setSimpleMatchCandidatePaperModalState = useSetRecoilState(simpleMatchCandidatePaperModalState);
  const [venueDraft] = useRecoilState(venueDraftState);
  const queryClient = useQueryClient();

  const { data: paper, isLoading: isLoadingPaper } = useQuery<XenurePaper>(`/publications/${paperId}`, async () => {
    return await getPaper({ paperId });
  });
  const { data: anomalyDetection, isLoading: isLoadingAnomalyDetection } = useAnomalyDetection({
    paperId,
    reportId: report.id,
    onSuccess: (anomalyDetection) => {
      if (anomalyDetection?.matched_author?.id !== undefined) {
        setAuthorListAtPaper((prev) =>
          produce(prev, (draft) => {
            if (!draft) return;
            draft.authorList.get(anomalyDetection.matched_author.id)!.is_myself = true;
          })
        );
      }
    },
  });

  useEffect(() => {
    if (paper) {
      const authorMap = new Map<string, Author>();
      paper.authors.forEach((author) => authorMap.set(author.id, author));
      setAuthorListAtPaper({ paperId: paper.id, authorList: authorMap });
    }
  }, [paper, setAuthorListAtPaper]);

  if (isLoadingPaper) {
    return (
      <ModalContent>
        <ModalHeader textAlign="center">MODIFY PAPER</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={8} d="flex" justifyContent="center" alignItems="center">
            <Spinner />
          </Box>
        </ModalBody>
      </ModalContent>
    );
  }

  async function handleClickSave() {
    if (!authorList || !paper) return;

    await savePaper({
      authors: Array.from(authorList.authorList.values()),
      journalId: venueDraft.journal?.id || paper.journal?.id,
      conferenceId: venueDraft.conference?.id || paper.conference?.id,
      conferenceInstanceId: venueDraft.conferenceInstance?.id || paper.conference_instance?.id,
      removeJournal: venueDraft.removeJournal,
      removeConference: venueDraft.removeConference,
      rawId: candidatePaperId,
      paperId,
      docType: null,
      subType: null,
      reportId: report.id,
    });

    // clean up
    if (candidatePaperId) {
      updateCandidatePapers(queryClient, report, candidatePaperId);
      mutate(
        '/admin/candidates/pending',
        (rawPapers: CandidatePaper[] | undefined) => {
          if (!rawPapers) return [];
          const i = rawPapers.findIndex((raw) => raw.id.toString() === candidatePaperId.toString());
          return [...rawPapers.slice(0, i), { ...rawPapers[i], match_confirmed: true }, ...rawPapers.slice(i + 1)];
        },
        false
      );
    }
    setSimpleMatchCandidatePaperModalState(INITIAL_SIMPLE_MATCH_CANDIDATE_PAPER_MODAL_STATE);

    await queryClient.invalidateQueries(`/reports/${report.id}/papers`);
  }

  if (!paper) {
    return (
      <ModalContent>
        <ModalHeader textAlign="center">MODIFY PAPER</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={8} d="flex" justifyContent="center" alignItems="center">
            죄송합니다. 해당하는 Paper를 찾을 수 없습니다.
          </Box>
        </ModalBody>
      </ModalContent>
    );
  }

  return (
    <ModalContent>
      <ModalHeader textAlign="center">MODIFY PAPER</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box>
          {paper.title} ({paper.year}) - {paper.id}
        </Box>
        <Divider my={4} />
        <ContaminatedBox touched={!!paper.is_touched} doi={paper.doi} paperId={paper.id} />
        <Divider my={4} />
        <VenueSelectBox paper={paper} />
        <Divider my={4} />
        <Box my={4}>
          <AnomalyWarning anomalyDetection={anomalyDetection} />
        </Box>
        <Box
          d="flex"
          py={2}
          my={2}
          borderRadius="4px"
          position="sticky"
          justifyContent="flex-end"
          bg="green.50"
          alignItems="center"
        >
          <Button color="green" onClick={handleClickSave}>
            Save
          </Button>
        </Box>
        <SimpleAuthorSelectBox
          paper={paper}
          anomalyDetection={anomalyDetection}
          isLoadingAnomalyDetection={isLoadingAnomalyDetection}
        />
      </ModalBody>
      <ModalFooter>
        <Box
          d="flex"
          w="100%"
          py={2}
          my={2}
          borderRadius="4px"
          position="sticky"
          justifyContent="flex-end"
          bg="green.50"
          alignItems="center"
        >
          <Button color="green" onClick={handleClickSave}>
            Save
          </Button>
        </Box>
      </ModalFooter>
    </ModalContent>
  );
};

export default SimpleEditPaperModal;
