import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getAnalyticsReportGroups } from '../../api/reportGroup';
import { useCreateReportGroup } from '../../hooks/useCreateReportGroup';
import { useRemoveReportGroupFromAnalytics } from '../../hooks/useRemoveReportGroupFromAnalytics';
import { ReportGroup } from '../../models/reportGroup';
import ReportGroupItem from '../reportGroupItem';
import CreateReportGroupPopover from './createReportGroupPopover';
import ReportGroupList from './reportGroupList';

interface Props {
  analyticsId: string;
}
const ReportGroupModal: FC<Props> = ({ analyticsId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    isLoading: isLoadingRegisteredAnalyticsReportGroups,
    data: registeredAnalyticsReportGroups,
  } = useQuery(`/admin/analytics/${analyticsId}/groups`, () => getAnalyticsReportGroups(analyticsId));
  const { mutate: createGroup } = useCreateReportGroup();
  const { mutate: removeFromAnalytics, isLoading: isRemoving } = useRemoveReportGroupFromAnalytics({ analyticsId });

  return (
    <>
      <Button onClick={() => setIsOpen((prev) => !prev)} colorScheme="teal">
        Edit report group list
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit report group list</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReportGroupList
              analyticsId={analyticsId}
              excludeIds={registeredAnalyticsReportGroups?.content.map((arg) => arg.group.id) ?? []}
            />
            {(isLoadingRegisteredAnalyticsReportGroups || isRemoving) && (
              <Box d="flex" justifyContent="center" alignItems="center" p={8}>
                <Spinner />
              </Box>
            )}
            {!!registeredAnalyticsReportGroups?.content.length && (
              <Box mt={4}>
                {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                <Text py={4} fontSize="xl" textAlign="center" borderBottom="1px solid #dedede">
                  ⬇️ Currently selected report groups ⬇️
                </Text>
                {registeredAnalyticsReportGroups.content.map((arg) => (
                  <Box
                    key={arg.group.id}
                    onClick={() => {
                      if (window.confirm('Remove from selected list?')) {
                        removeFromAnalytics(arg.group.id);
                      }
                    }}
                    p={2}
                    _hover={{ backgroundColor: '#dedede' }}
                    cursor="pointer"
                    mt={2}
                  >
                    <ReportGroupItem reportGroup={arg.group} />
                  </Box>
                ))}
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="flex-start" borderTop="1px solid #dedede">
            <CreateReportGroupPopover
              onSubmit={async (values: Omit<ReportGroup, 'id' | 'mapped_report_count' | 'total_report_count'>) => {
                createGroup(values);
              }}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportGroupModal;
