import { Box, Text } from '@chakra-ui/react';
import React, { CSSProperties, FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePageComment } from '../../../hooks/usePageComment';
import { usePublicationBulk } from '../../../hooks/usePublicationBulk';
import { useReportRawData } from '../../../hooks/useReportRawData';
import { XenurePaper } from '../../../models/xenurePaper';
import _ from 'lodash';

interface CitationData {
  paper_id: string;
  self_cit_cnt: number;
  cit_cnt: number;
  self_by_total_cit_ratio: number;
}

interface Props {
  personalReportId: number;
  dataId: string;
}

const ThStyle: CSSProperties = {
  border: '1px solid #B9B9B9',
  padding: '4px 4px',
  background: '#536DFE',
  color: 'white',
  fontSize: '10px',
};

const TdStyle: CSSProperties = {
  padding: '4px 4px',
  border: '1px solid #B9B9B9',
  fontSize: '10px',
  lineHeight: '17px',
};

const AllCitationTablePage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });
  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as CitationData[];

  const { data: publications } = usePublicationBulk(data?.map((datum) => datum.paper_id));

  if (isLoading) {
    return <Box>Loading ...</Box>;
  }

  if (!rawData || !publications) return null;

  const publicationsMap = new Map<string, XenurePaper>();
  publications.forEach((publication) => publicationsMap.set(publication.id, publication));

  const citationData = data;
  const predefinedComments = [
    `출판했던 논문 중 자기 인용이 ${_.sumBy(data, 'self_cit_cnt')}회의 자기 인용이 있었습니다.`,
  ];
  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  citationData.sort((a, b) => b.cit_cnt - a.cit_cnt);

  return (
    <PersonalAnalyticsLayout page={14}>
      <Box fontSize="42px" lineHeight="42px" fontWeight="bold" color="#536DFE" mb="16px">
        연구자의 전체 논문에 대한 자기 인용 소개
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="16px">
        전체 논문에 대한 인용 및 자기 인용이 많이 된 논문들
      </Box>
      <table style={{ textAlign: 'center', width: '100%' }}>
        <thead>
          <tr>
            <th style={ThStyle}>제목</th>
            <th style={ThStyle}>저널</th>
            <th style={ThStyle}>발간연도</th>
            <th style={ThStyle}>전체 인용수</th>
            <th style={ThStyle}>자기 인용수</th>
            <th style={ThStyle}>자기 인용수 / 전체 인용수(%)</th>
          </tr>
        </thead>
        <tbody>
          {citationData.slice(0, 10).map((datum, i) => (
            <tr key={i}>
              <td style={{ ...TdStyle, textAlign: 'left', fontSize: '10px' }}>
                {publicationsMap.get(datum.paper_id)?.title || ''}
              </td>
              <td style={{ ...TdStyle, fontSize: '10px' }}>
                {publicationsMap.get(datum.paper_id)?.journal?.title || ''}
              </td>
              <td style={TdStyle}>
                {publicationsMap.get(datum.paper_id)
                  ? new Date(publicationsMap.get(datum.paper_id)!.published_date).getFullYear()
                  : ''}
              </td>
              <td style={TdStyle}>{datum.cit_cnt}</td>
              <td style={TdStyle}>{datum.self_cit_cnt}</td>
              <td style={TdStyle}>{(datum.self_by_total_cit_ratio * 100).toFixed(2)} %</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Text fontSize="18px" lineHeight="26px" fontWeight="bold" mt="24px" mb="4px">
        Purpose
      </Text>
      <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap">
        {' '}
        자기 인용이 된 적 있는 논문들에 대한 내용을 살펴봅니다. 전체 인용수와 자기 인용수, 전체 인용수 대비 자기 인용수
        비율을 살펴보며 연구자 내/외부 영향력을 살펴보고자합니다. 위 테이블에는 전체 인용수 기준 상위 10개의 데이터를
        보여주고 있습니다.
      </Box>
      {comments?.length && (
        <Box p="15px 12px" bg="#EBEEFD" fontSize="12px" lineHeight="21px" mt="10px" wordBreak="break-all">
          {comments.map((comment, i) => (
            <li key={i}>{comment}</li>
          ))}
        </Box>
      )}
    </PersonalAnalyticsLayout>
  );
};

export default AllCitationTablePage;
