import React, { FC } from 'react';
import { XenurePaper } from '../../models/xenurePaper';
import { Box, Spinner, Heading, Flex } from '@chakra-ui/react';
import PublicationItem from '../publicationItem';
import { useQuery } from 'react-query';
import ImportPapersFromAuthorButton from '../importPapersFromAuthorButton';
import { getPapers } from '../../api/report';

interface Props {
  reportId: string | undefined;
  onClickRemoveButton: (paper: XenurePaper) => void;
  onClickModifyButton: (paper: XenurePaper) => void;
}

const XenurePublicationList: FC<Props> = ({ onClickModifyButton, onClickRemoveButton, reportId }) => {
  const { data: papers, isLoading } = useQuery<XenurePaper[]>(
    `/reports/${reportId}/papers`,
    async () => getPapers({ size: 1000, lu: true, reportId: reportId! }),
    { enabled: !!reportId }
  );

  if (isLoading) {
    return (
      <Box d="flex" minH="400px" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  if (!papers?.length) {
    if (reportId) {
      return (
        <Flex mt={4} justifyContent="center">
          <ImportPapersFromAuthorButton reportId={reportId} />
        </Flex>
      );
    }
    return null;
  }

  return (
    <Box mt={4}>
      <Heading as="h2">the number of papers: {papers.length}</Heading>
      {papers.map((p, i) => (
        <Box key={p.id} mt={i === 0 ? 0 : 2}>
          <PublicationItem
            publication={p}
            onClickRemoveButton={onClickRemoveButton}
            onClickModifyButton={onClickModifyButton}
          />
        </Box>
      ))}
    </Box>
  );
};

export default XenurePublicationList;
