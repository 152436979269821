import { Box, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC } from 'react';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePaperListCitationText } from '../../../hooks/usePaperListCitationText';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface PaperInformation {
  rep_id: string;
  paper_id: string;
  paper_title: string;
  author_name: string;
}

interface Props {
  personalReportId: number;
  dataId: string;
  title: string;
}
const RepresentativePaperListPage: FC<Props> = ({ title, personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const rawPaperList = parseStringifiedJSONRawData(rawData?.report_json_data) as PaperInformation[];
  const paperIds = Array.from(new Set(rawPaperList?.map((paper) => paper.paper_id)));

  const { data: citationTextList, isLoading: isLoadingCitationText } = usePaperListCitationText(paperIds);

  if (isLoading || isLoadingCitationText) {
    return <Box>Loading ...</Box>;
  }

  if (!rawPaperList || !citationTextList) return null;

  const groupedPaperList = _.groupBy(rawPaperList, 'author_name');

  return (
    <Box fontFamily="'Noto Sans KR', sans-serif;" w="794px" margin="0 auto" p="70px 80px 0 80px">
      <Box fontWeight="bold" fontSize="21px" lineHeight="21px">
        {title}
      </Box>
      <Box mt="32px">
        {Object.keys(groupedPaperList).map((authorName, i) => {
          const paperInfoList = _.uniqBy(groupedPaperList[authorName], 'paper_id');

          const citationTextBoxes = paperInfoList.map((paperInfo, j) => (
            <li
              key={authorName + paperInfo.paper_id + j}
              style={{ pageBreakInside: 'avoid', position: 'relative', marginTop: j === 0 ? 0 : '8px' }}
            >
              {citationTextList[paperInfo.paper_id]}
            </li>
          ));

          return (
            <Box key={authorName + i} pos="relative" fontSize="12px" mt={i === 0 ? 0 : '24px'}>
              {authorName !== 'undefined' && (
                <Text fontSize="16px" fontWeight="bold" mb="8px">
                  {authorName}
                </Text>
              )}
              <Box>{citationTextBoxes}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RepresentativePaperListPage;
