import { atom } from 'recoil';
import { Author } from '../models/author';

export type AuthorListAtPaperState = {
  paperId: string;
  authorList: Map<string, Author>;
} | null;

export const authorListAtPaper = atom<AuthorListAtPaperState>({
  key: 'authorListAtPaper',
  default: null,
});
