import { Box, Divider, Flex, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import ScinapseLogo from '../../../components/icons/scinapseLogo';
import XenureLogo from '../../../components/icons/xenureLogo';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';

const CustomListItem: FC = ({ children }) => {
  return (
    <Box
      as="li"
      pos="relative"
      _before={{ content: '"·"', position: 'absolute', left: '-16px', fontWeight: 900 }}
      listStyleType="none"
      ml="16px"
      wordBreak="keep-all"
    >
      {children}
    </Box>
  );
};

const AboutPage: FC = () => {
  return (
    <PersonalAnalyticsLayout page={2}>
      <Box color="#000">
        <Box fontWeight="bold" fontSize="42px" lineHeight="42px" color="#536DFE">
          About Pluto, Inc.
        </Box>
        <HStack mt="74px" justifyContent="space-between" spacing="16px" alignItems="flex-start">
          <Box w="100%" fontSize="12px" fontWeight={500} lineHeight="21px" whiteSpace="pre-wrap">
            {' '}
            Pluto는 최신 IT 기술을 기반으로 연구 성과를 새로운 시각에서 분석하고자 하는 학술 데이터 서비스 회사입니다.
            {'\n '}
            Pluto는 학술-연구 분야의 전문인력과 함께 AI 기술을 바탕으로 연구 성과를 심층적으로 분석하고 있습니다.
          </Box>
          <Box w="100%" fontSize="12px" fontWeight={500} lineHeight="21px">
            Pluto에서는 Google Scholar 규모의 학술 논문 및 특허 관련 서지 데이터베이스를 자체 구축하여 보유하고 있으며,
            이를 토대로 연구 기관과 기업에서 요구하는 핵심 데이터를 분석/제공해 드리고 있습니다.
          </Box>
        </HStack>
        <HStack justifyContent="space-between" spacing="16px" alignItems="flex-start" mt="20px">
          <Box w="100%" fontSize="13px" lineHeight="28px">
            <Box fontWeight={700}>Pluto’s Data Coverage</Box>
            <CustomListItem>
              <Box as="span" fontWeight={500}>
                Number of Papers{' '}
              </Box>
              <Box as="b" fontWeight={700} color="#536DFE">
                260,556,625
              </Box>
            </CustomListItem>
            <CustomListItem>
              <Box as="span" fontWeight={500}>
                Number of Journals{' '}
              </Box>
              <Box as="b" fontWeight={700} color="#536DFE">
                49,027
              </Box>
            </CustomListItem>
            <CustomListItem>
              <Box as="span" fontWeight={500}>
                Number of Topics{' '}
              </Box>
              <Box as="b" fontWeight={700} color="#536DFE">
                713,648
              </Box>
            </CustomListItem>
            <CustomListItem>
              <Box as="span" fontWeight={500}>
                Number of Relations{' '}
              </Box>
              <Box as="b" fontWeight={700} color="#536DFE">
                10,326,803,126
              </Box>
            </CustomListItem>
          </Box>
          <Box w="100%" mt="20px" fontSize="13px" lineHeight="28px">
            <Text fontWeight="bold">Feature of Pluto’s Academic Database</Text>
            <CustomListItem>
              <Box fontWeight={500}>정확한 저자 판별 기술 보유</Box>
            </CustomListItem>
            <CustomListItem>
              <Box fontWeight={500}>기존의 저널로 연구 분야를 판별하는 방식이 아니라 논문 단위로 판단</Box>
            </CustomListItem>
            <CustomListItem>
              <Box fontWeight={500}>다양한 관점에서의 연구 실적 데이터 분석 가능</Box>
            </CustomListItem>
          </Box>
        </HStack>
        <Divider borderTop="1px solid rgba(0,0,0,0.3)" my="36px" />
        <Box fontWeight={500} fontSize="12px" lineHeight="21px" wordBreak="keep-all">
          Pluto는 현 연구자 전용 학술 검색엔진(Scinapse)과 개인용 연구 실적 분석 서비스(Xenure)도 제공하고 있습니다.
        </Box>
        <HStack
          justifyContent="space-between"
          spacing="16px"
          alignItems="flex-start"
          mt="36px"
          color="#536DFE"
          fontWeight="bold"
          fontSize="24px"
          lineHeight="24px"
        >
          <Box w="100%">Scinapse</Box>
          <Box w="100%">Xenure</Box>
        </HStack>
        <HStack justifyContent="space-between" spacing="16px" alignItems="flex-start" mt="64px" mb="48px">
          <Flex w="100%" justifyContent="center" alignItems="center">
            <XenureLogo />
          </Flex>
          <Flex w="100%" justifyContent="center" alignItems="center">
            <ScinapseLogo />
          </Flex>
        </HStack>
        <HStack
          justifyContent="space-between"
          spacing="16px"
          alignItems="flex-start"
          mt="48px"
          mb="70px"
          fontWeight={500}
          fontSize="12px"
          lineHeight="21px"
          wordBreak="break-all"
        >
          <Box w="100%" whiteSpace="pre-wrap">
            {' '}
            Scinapse는 차세대 학술 논문 검색 엔진으로, 5만 여종의 학술 저널과 이에 출판된 2.5억 편 이상의 논문을
            색인하고 있는, "연구자들을 위한 구글"입니다.
            {'\n '}
            키워드 기반의 검색만을 제공하는 기존 논문 검색 서비스들과는 차별화하여, 사용자와의 연관성 및 논문-저널
            영향력 등을 종합적으로 반영한 검색 결과를 제공합니다.
            {'\n '}
            2019년 한 해 동안 190개국 1,000여 곳 이상의 연구기관에서 매달 200,000명이 넘는 연구자가 Scinapse를
            사용하였습니다.
          </Box>
          <Box w="100%" whiteSpace="pre-wrap">
            {' '}
            Xenure는 연구자들을 위한 연구 성과 관리/분석 서비스입니다. Pluto만의 특별한 데이터 수집/분석 능력을 기반으로
            연구 성과에 대한 높은 수준의 통찰과 다각적인 분석 정보를 제공합니다. 20곳 이상의 국내 유수 대학에서 100명
            이상의 연구자들이 활발하게 사용하고 있습니다.
          </Box>
        </HStack>
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default AboutPage;
