import { useQuery } from 'react-query';
import { getPageRawData } from '../api/reportGroup';

interface Params {
  personalReportId: number | undefined;
  dataId: string | undefined;
}
export function useReportRawData({ personalReportId, dataId }: Params) {
  return useQuery(
    [`/admin/personal-report/${personalReportId}/data?data_id=${dataId}`],
    () => getPageRawData({ personalReportId: personalReportId!, dataId: dataId! }),
    { enabled: !!personalReportId && !!dataId }
  );
}
