import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import AboutPage from './layouts/aboutPage';
import TopJournalPage from './resultPages/topJournalPage';
import PerformanceTrendAvgIfPubCntH5Index from './resultPages/performanceTrendAvgIfPubCntH5Index';
import PerformanceTrendJVTopPubCnt from './resultPages/performanceTrendJVTopPubCnt';
import PerformanceCitationTrend from './resultPages/performanceCitationTrend';
import PerformanceAvgJVPercentileAvgIF from './resultPages/performanceAvgJVPercentileAvgIF';
import PerformanceTrendJCR1025PubCnt from './resultPages/performanceTrendJCR1025PubCnt';
import MainFOSWordCloudPage from './resultPages/mainFOSWordCloud';
import PrintResultPage from './resultPages';
import Chapter1Page from './layouts/chapter1Page';
import EvenTwoColumnGraphPage from './layouts/evenTwoColumnGraphPage';
import RadialGraphPage from './layouts/radialGraphPage';
import StatListPage from './layouts/statListPage';
import TableOfContents from './layouts/tableOfContents';
import TablePage from './layouts/tablePage';
import TwoColumnChapterPage from './layouts/twoColumnChapterPage';
import PerformanceListPage from './resultPages/performanceListPage';
import MultiIFTrendGraphPage from './resultPages/multiIFTrendGraphPage';
import AllCitationTablePage from './resultPages/allCitationTablePage';
import LeadCitationTablePage from './resultPages/leadCitationTablePage';
import ResearcherAnalysisPage from './resultPages/researcherAnalysisPage';
import CareerYearPage from './resultPages/careerYearPage';
import SimilarResearcherPerformanceListPage from './resultPages/similarResearcherPerformanceListPage';
import SimilarCareerResearcherTablePage from './resultPages/similarCareerResearcherTablePage';
import SimilarCareerResearcherPerformanceListPage from './resultPages/similarCareerResearcherPerformanceListPage';
import PerformanceRadarPage from './resultPages/performanceRadarPage';
import JournalFOSTrendPage from './resultPages/journalFOSTrendPage';
import GeoPage from './layouts/geoPage';
import RecommendPaperListPage from './layouts/recommendPaperListPage';
import RepresentativePaperListPage from './layouts/representativePaperListPage';
import RemarkablePaperListPage from './resultPages/remarkablePaperListPage';
import PrintResultAppendixPage from './resultPages/resultAppendix';
import PrintResultContentsPage from './resultPages/resultContents';

interface Props {
  targetDataId?: string;
  targetPersonalReportId?: number;
  targetGraphType?: string;
}

const PersonalReportPage: FC<Props> = ({ targetDataId, targetPersonalReportId, targetGraphType }) => {
  const location = useLocation();
  const { pid, did, gtype } = parse(location.search, { ignoreQueryPrefix: true });

  const personalReportId = (targetPersonalReportId || pid) as number;
  const dataId = targetDataId || did;
  const graphType = targetGraphType || gtype;

  if (personalReportId && dataId === 'result') {
    return <PrintResultPage personalReportId={personalReportId} />;
  }

  if (personalReportId && dataId === 'result-contents') {
    return <PrintResultContentsPage personalReportId={personalReportId} />;
  }

  if (personalReportId && dataId === 'result-appendix') {
    return <PrintResultAppendixPage personalReportId={personalReportId} />;
  }

  if (pid === 'about') return <AboutPage />;
  if (pid === 'table-of-contents') return <TableOfContents personalReportId={personalReportId} />;
  if (pid === 'chapter') return <Chapter1Page />;
  if (pid === 'two-column-chapter') return <TwoColumnChapterPage />;
  if (pid === 'table') return <TablePage />;
  if (pid === 'radial')
    return <RadialGraphPage title="유사 연구자 분석 - 비교 분석" subtitle="대상 연구자 vs 전체 유사 연구자" />;
  if (pid === 'even-two-column' && did === 'main_fos') {
    return <EvenTwoColumnGraphPage personalReportId={Number(pid as string)} dataId={did as string} />;
  }
  if (dataId === 'performance') {
    return <StatListPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'performance_trend_avg_jv_percent_rank_avg_if') {
    return <PerformanceAvgJVPercentileAvgIF personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'performance_trend_journal_pub_cnt_jcr_top10_pub_cnt_jcr_top20_pub_cnt') {
    return <PerformanceTrendJCR1025PubCnt personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'performance_trend_avg_6months_cit_cnt_avg_12months_cit_cnt') {
    return <PerformanceCitationTrend personalReportId={personalReportId} dataId={dataId} />;
  }
  if (dataId === 'performance_trend_journal_jv_top10_pub_cnt_jv_top20_pub_cnt') {
    return <PerformanceTrendJVTopPubCnt personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'performance_trend_avg_if_h5index_pub_cnt') {
    return <PerformanceTrendAvgIfPubCntH5Index personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'published_top_journal') {
    return <TopJournalPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'main_fos') {
    return <MainFOSWordCloudPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'personal_performance_total_and_lead') {
    return <PerformanceListPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'performance_trend_if_2y_5y_all_lead') {
    return <MultiIFTrendGraphPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'my_main_total_paper_info') {
    return <AllCitationTablePage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'my_main_lead_paper_info') {
    return <LeadCitationTablePage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'top10_similar_fields_authors_info') {
    return <ResearcherAnalysisPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'all_similar_author_geo_distribution') {
    return (
      <GeoPage
        personalReportId={personalReportId}
        dataId={dataId}
        title="유사 연구자 분석"
        subtitle="국가 분포"
        purpose={` 상위 1%의 유사성을 가진 연구자들의 국가, 기관의 분포에 대해서 시각적으로 확인해보겠습니다.`}
      />
    );
  }

  if (dataId === 'all_similar_fields_authors_career_distribution') {
    return <CareerYearPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'all_similar_author_avg_performance' && graphType === 'table') {
    return <SimilarResearcherPerformanceListPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'top10_similar_fields_same_career_authors_info') {
    return <SimilarCareerResearcherTablePage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'all_similar_same_career_author_geo_distribution') {
    return (
      <GeoPage
        personalReportId={personalReportId}
        dataId={dataId}
        title="비슷한 연구 나이의 유사 연구자 분석"
        subtitle="국가 분포"
        purpose={` 상위 1%의 유사성을 가진 연구자들의 국가, 기관의 분포에 대해서 시각적으로 확인해보겠습니다.`}
      />
    );
  }

  if (dataId === 'all_similar_same_career_author_avg_performance' && graphType === 'table') {
    return <SimilarCareerResearcherPerformanceListPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'all_similar_author_avg_performance' && graphType === 'radar') {
    return (
      <PerformanceRadarPage
        personalReportId={personalReportId}
        dataId={dataId}
        subtitle="대상 연구자 vs. 전체 유사 연구자"
      />
    );
  }

  if (dataId === 'top_similar_author_avg_performance') {
    return (
      <PerformanceRadarPage
        personalReportId={personalReportId}
        dataId={dataId}
        subtitle="대상 연구자 vs. 상위 30% 유사 연구자"
      />
    );
  }

  if (dataId === 'all_similar_same_career_author_avg_performance' && graphType === 'radar') {
    return (
      <PerformanceRadarPage
        personalReportId={personalReportId}
        dataId={dataId}
        subtitle="대상 연구자 vs. 전체 비슷한 연구 나이의 유사 연구자"
      />
    );
  }

  if (dataId === 'top_similar_same_career_author_avg_performance') {
    return (
      <PerformanceRadarPage
        personalReportId={personalReportId}
        dataId={dataId}
        subtitle="대상 연구자 vs. 상위 30% 비슷한 연구 나이 유사 연구자"
      />
    );
  }

  if (dataId === 'my_published_journal_main_fos_percent_by_year') {
    return (
      <JournalFOSTrendPage
        personalReportId={personalReportId}
        dataId={dataId}
        subtitle="출판 저널의 주요 연구 주제에 대한 트렌드"
      />
    );
  }

  if (dataId === 'major_journal_main_fos_percent_by_year') {
    return (
      <JournalFOSTrendPage
        personalReportId={personalReportId}
        dataId={dataId}
        subtitle="유명 저널의 주요 연구 주제에 대한 트렌드"
      />
    );
  }

  if (dataId === 'collaboration_info_by_coauthor') {
    return (
      <GeoPage
        personalReportId={personalReportId}
        dataId={dataId}
        title="연구자의 연구 영향력 분석"
        subtitle="공동저자 관계로 살펴본 연구 영향력 분석"
        purpose={` 연구자의 연구 영향력을 연구자와 함께 협력해본 적 있는 연구자, 연구 기관을 살펴봅니다. 이를 통해 어떤 국가와 어떤 기관에 영향력을 주고 받았는지 확인합니다.`}
      />
    );
  }

  if (dataId === 'collaboration_info_by_citation') {
    return (
      <GeoPage
        personalReportId={personalReportId}
        dataId={dataId}
        title="연구자의 연구 영향력 분석"
        subtitle="피인용 관계로 살펴본 연구 영향력 분석"
        purpose={` 연구자의 연구 영향력을 연구자와 함께 협력해본 적 있는 연구자, 연구 기관을 살펴봅니다. 이를 통해 어떤 국가와 어떤 기관에 영향력을 주고 받았는지 확인합니다.`}
      />
    );
  }

  if (dataId === 'remarkable_papers_with_main_fos' && graphType === 'table') {
    return <RecommendPaperListPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'remarkable_papers_with_main_fos' && graphType === 'list') {
    return <RemarkablePaperListPage personalReportId={personalReportId} dataId={dataId} />;
  }

  if (dataId === 'top10_similar_fields_authors_represent_papers') {
    return (
      <RepresentativePaperListPage
        title="유사 분야 연구자의 대표 논문"
        personalReportId={personalReportId}
        dataId={dataId}
      />
    );
  }

  if (dataId === 'top10_similar_same_career_authors_represent_papers') {
    return (
      <RepresentativePaperListPage
        title="유사 연구자 중 연구 나이가 비슷한 연구자의 대표 논문"
        personalReportId={personalReportId}
        dataId={dataId}
      />
    );
  }

  if (dataId === 'remarkable_data') {
    return <RemarkablePaperListPage personalReportId={personalReportId} dataId={dataId} />;
  }

  return null;
};

export default PersonalReportPage;
