import { Box, HStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { useReportRawData } from '../../../hooks/useReportRawData';

const ComparisonChapterPage: FC<{ personalReportId: number }> = ({ personalReportId }) => {
  const { data: similarCareerData } = useReportRawData({
    personalReportId,
    dataId: 'top10_similar_fields_same_career_authors_info',
  });

  return (
    <PersonalAnalyticsLayout page={25}>
      <Box fontWeight="bold" fontSize="36px" lineHeight="36px" color="#536DFE" mb="32px">
        대상 연구자와 다른 집단과의 주요 연구 지표 비교
      </Box>
      <HStack spacing="16px" alignItems="flex-start">
        <Box fontSize="12px" lineHeight="21px">
          <Box whiteSpace="pre-wrap">
            {' '}
            대상 연구자와 다른 집단과의 주요 연구 지표에 대해서 비교해보고자 합니다. 다른 집단은 크게 4가지 그룹이며
            다음과 같습니다.
          </Box>
          <Box mt="16px" fontWeight="bold">
            <Box>1. 앞서 살펴본 유사도 상위 1%의 연구 집단</Box>
            <Box>2. 앞서 살펴본 유사도 상위 1%의 연구 집단의 상위 30% 집단</Box>
            {!!similarCareerData?.report_json_data && (
              <>
                <Box>3. 앞서 살펴본 비슷한 연구 나이를 가진 유사도 상위 1%의 연구 집단</Box>
                <Box>4. 앞서 살펴본 비슷한 연구 나이를 가진 유사도 상위 1%의 연구 집단의 상위 30% 집단</Box>
              </>
            )}
          </Box>
          <Box mt="16px" whiteSpace="pre-wrap">
            {' '}
            이를 통해 대상 연구자의 연구 성과 위치를 확인합니다.
          </Box>
        </Box>
        <Box flexShrink={0}>
          <Box
            pos="relative"
            w="201px"
            bg="#FCEDF0"
            borderRadius="5px"
            p="26px 17px 22px 17px"
            fontSize="12px"
            lineHeight="21px"
            flex="1 0 auto"
            wordBreak="break-all"
          >
            <Box
              pos="absolute"
              left="16px"
              top="-14px"
              fontSize="15px"
              lineHeight="16px"
              p="6px 12px"
              borderRadius="5px"
              bg="#D3625C"
              color="white"
              wordBreak="break-all"
            >
              그래프 설명
            </Box>
            <Box whiteSpace="pre-wrap">
              <b>Min-Max Scaler</b>를 통해서 각각의 지표 데이터를 0과 100 사이의 값으로 환산한 후에 데이터를
              시각화하였습니다. 계산에 필요한 최솟값은 0, 최대값은 데이터의 최댓값으로 계산하였습니다.
            </Box>
          </Box>
        </Box>
      </HStack>
      <Box mt="117px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default ComparisonChapterPage;
