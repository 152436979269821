import React, { FC } from 'react';
import { ScrappedAuthor } from '../../models/authorship';
import { Box, Tag, Text, Heading, Spinner } from '@chakra-ui/react';

interface Props {
  authors: ScrappedAuthor[] | undefined;
  isLoading: boolean;
}

const ScrappedAuthorItem: FC<{ author: ScrappedAuthor }> = ({ author }) => {
  return (
    <Box p={3} border="1px solid #dedede" mt={3} _first={{ marginTop: 0 }}>
      <Tag size="sm" colorScheme={author.matched_author ? 'green' : 'red'}>
        {author.matched_author ? 'MATCHED' : 'UNMATCHED'}
      </Tag>
      {author.is_corr && (
        <Tag size="sm" ml={2}>
          CORRESPONDING
        </Tag>
      )}
      {author.is_first && (
        <Tag size="sm" ml={2}>
          FIRST
        </Tag>
      )}
      <Box>
        <Box>{author.scrapped_name}</Box>
      </Box>
      {author.matched_author && (
        <Box mt={3}>
          <Heading fontSize="16px">Matched Information</Heading>
          <Box>
            <Text>{author.matched_author.author_name}</Text>
            <Text>({author.matched_author.author_id})</Text>
            <Text>{author.matched_author.affiliation_name || ''}</Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};
const ScrappedAuthorList: FC<Props> = ({ authors, isLoading }) => {
  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  if (!authors) {
    return <>모종의 이유로 저자 스크랩을 실패하였습니다.</>;
  }

  return (
    <>
      {authors.map((author, i) => (
        <ScrappedAuthorItem author={author} key={i} />
      ))}
    </>
  );
};

export default ScrappedAuthorList;
