import React, { FC } from 'react';
import { Field, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { ReportGroup } from '../../models/reportGroup';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea } from '@chakra-ui/react';
import AffiliationInput, { SelectableAffiliation } from '../affiliationInput';
import { Affiliation } from '../../models/affiliation';

type FormValues = Omit<ReportGroup, 'id' | 'mapped_report_count' | 'total_report_count'>;

interface Props {
  onSubmit: (values: FormValues) => Promise<void>;
  initialValues?: Partial<ReportGroup>;
}

const ReportGroupForm: FC<Props> = ({ initialValues, onSubmit }) => {
  return (
    <Formik<FormValues>
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await onSubmit(values);
          resetForm();
        } catch (err) {
          setSubmitting(false);
          alert(err.message);
        }
      }}
      validate={({ name, type, affiliation }) => {
        const errors: FormikErrors<FormValues> = {};
        if (!name) {
          errors.name = 'Please fill the name.';
        }
        if (!type) {
          errors.type = 'Please fill the type.';
        }
        if (!affiliation?.id) {
          (errors as any).affiliation = 'Please fill the affiliation.';
        }
        return errors;
      }}
      initialValues={{
        affiliation: initialValues?.affiliation ?? null,
        name: initialValues?.name ?? '',
        type: initialValues?.type ?? '',
        note: initialValues?.note ?? '',
        source_urls: initialValues?.source_urls ?? '',
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field name="name">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.name && form.touched.name}>
                <FormLabel>Name</FormLabel>
                <Input {...field} placeholder="name" />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="affiliation">
            {({ form, field }: FieldProps<Affiliation, FormValues>) => {
              return (
                <FormControl isRequired>
                  <FormLabel htmlFor="searchAffiliation">Search Affiliation</FormLabel>
                  <Box pos="relative">
                    <AffiliationInput
                      initialValue={field.value?.name}
                      onFocus={() => form.setFieldTouched('affiliation.id')}
                      onSelect={(affiliation: SelectableAffiliation) => {
                        form.setFieldValue('affiliation', {
                          id: affiliation.affiliation_id,
                          name: affiliation.keyword,
                        });
                      }}
                    />
                  </Box>
                  <FormErrorMessage>{form.errors.affiliation}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="type">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.type && form.touched.type}>
                <FormLabel>Type</FormLabel>
                <Input {...field} placeholder="type" />
                <FormErrorMessage>{form.errors.type}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="note">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.note && form.touched.note}>
                <FormLabel>Note</FormLabel>
                <Input {...field} placeholder="note" />
                <FormErrorMessage>{form.errors.note}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="source_urls">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.source_urls && form.touched.source_urls}>
                <FormLabel>Source URLs</FormLabel>
                <Textarea {...field} placeholder="Please seperate each url with ENTER" />
                <FormErrorMessage>{form.errors.source_urls}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button type="submit" mt={4} colorScheme="teal" isFullWidth disabled={isSubmitting}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ReportGroupForm;
