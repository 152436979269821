import React, { FC } from 'react';
import { Box, Flex, HStack, ListItem, UnorderedList } from '@chakra-ui/react';
import { useReportRawData } from '../../../hooks/useReportRawData';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { usePageComment } from '../../../hooks/usePageComment';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import PerformanceTrendJCR1025PubCntHistogram, {
  ChartData,
} from '../../../components/personalAnalytics/graphs/performanceTrendJCR1025PubCntHistogram';
import _ from 'lodash';
import {
  PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH,
  PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH,
} from '../../../constants/personalReport';

interface Props {
  personalReportId: number;
  dataId: string;
}

const PerformanceTrendJCR1025PubCnt: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const chartData = parseStringifiedJSONRawData(rawData?.report_json_data) as ChartData[];

  if (!chartData) return null;
  const chartDataForComments = chartData.filter(
    ({ year }) => year >= PERSONAL_REPORT_MIN_YEAR_FOR_LINE_GRAPH && year <= PERSONAL_REPORT_MAX_YEAR_FOR_LINE_GRAPH
  );
  const predefinedComments = [
    `전체 출판물의 ${Math.round(
      (_.sumBy(chartDataForComments, 'jcr_top25_pub_cnt') * 100) / _.sumBy(chartDataForComments, 'pub_cnt')
    )}%가 JCR TOP 25% 안에 들어가는 저널에 출판되었습니다.`,
    `JCR TOP 10% 들어가는 출판물은 ${Math.round(
      (_.sumBy(chartDataForComments, 'jcr_top10_pub_cnt') * 100) / _.sumBy(chartDataForComments, 'pub_cnt')
    )}% 입니다.`,
    `JCR TOP 25% 중 ${Math.round(
      (_.sumBy(chartDataForComments, 'jcr_top10_pub_cnt') * 100) / _.sumBy(chartDataForComments, 'jcr_top25_pub_cnt')
    )}%의 논문이 TOP JCR 10% 안에 속하고 있습니다.`,
  ];

  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout page={9}>
      <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
        개인 연구 트랜드
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
        논문의 생산성 간접 비교 지표
      </Box>
      <Flex justifyContent="center" alignItems="center" mb="34px">
        <PerformanceTrendJCR1025PubCntHistogram chartData={chartData} />
      </Flex>
      <HStack spacing="16px" alignItems="flex-start" mt="42px">
        <Box>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px" whiteSpace="pre-wrap">
            {' '}
            연도별로 출판한 논문들이 얼마나 명성이 높은 저널에 출판되었는가를 나타내는 지표입니다. 본 지표는 각 논문이
            출판된 연도의 JCR (Journal Citation Report) Percentile Score를 기준으로 작성되었습니다. 즉, 출판 당시의
            저널의 명성을 기준으로 작성된 지표입니다.
            {'\n '}
            JCR 상위 10% 및 25%에 속하는 저널에 출판한 논문의 비율을 통해 작성된 논문들의 퀄리티를 간접 측정할 수
            있습니다.
          </Box>
          <UnorderedList
            p="13px 15px"
            fontSize="12px"
            lineHeight="21px"
            bg="#EBEEFD"
            padding="15px 13px"
            listStylePosition="inside"
            m="0"
          >
            {comments?.map((comment, i) => (
              <ListItem key={i}>{comment}</ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box
          pos="relative"
          w="201px"
          borderRadius="5px"
          p="26px 17px 22px 17px"
          fontSize="12px"
          lineHeight="21px"
          flex="1 0 auto"
          wordBreak="break-all"
        ></Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default PerformanceTrendJCR1025PubCnt;
