import React, { FC, useState } from 'react';
import PendingPaperForm, { PendingPaperFormValues } from './pendingPaperForm';
import { FormikHelpers } from 'formik/dist/types';
import { Box, Button } from '@chakra-ui/react';

interface Props {
  onSubmit: (params: PendingPaperFormValues, formikHelpers: FormikHelpers<PendingPaperFormValues>) => Promise<void>;
}

const PendingCandidatePaperButton: FC<Props> = ({ onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);

  async function handleSubmit(params: PendingPaperFormValues, formikHelpers: FormikHelpers<PendingPaperFormValues>) {
    await onSubmit(params, formikHelpers);
    setIsOpen(false);
  }

  return (
    <Box pos="relative">
      <Button onClick={() => setIsOpen((prev) => !prev)} colorScheme="yellow" mb={2}>
        보류하기
      </Button>
      {isOpen && <PendingPaperForm onSubmit={handleSubmit} onCancel={() => setIsOpen(false)} />}
    </Box>
  );
};

export default PendingCandidatePaperButton;
