import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import SelectableReportList from './seletableReportList';
import CreateReportModal from '../createReportModal';
import { ReportGroup } from '../../models/reportGroup';

interface Props {
  reportGroup: ReportGroup;
}

const GroupReportListModal: FC<Props> = ({ reportGroup }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen((prev) => !prev)} colorScheme="teal">
        Edit report list
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit report group list</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="flex-end" mb={2}>
              <CreateReportModal initialData={{ affiliation: reportGroup.affiliation ?? undefined }} />
            </Flex>
            <SelectableReportList reportGroupId={reportGroup.id} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GroupReportListModal;
