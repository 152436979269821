import { Box, Divider, Flex, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface Props {
  personalReportId: number;
  dataId: string;
}

interface ListItemProps {
  item: string;
  count: string | number;
}

interface PerformanceData {
  pub_cnt: number;
  cit_cnt: number;
  journal_pub_cnt: number;
  journal_cit_cnt: number;
  avg_if: number;
  yearmonth: string;
  row_num: number;
  hindex: number;
  year: number;
  h5index: number;
  jcr_top10_pub_cnt: number;
  jcr_top25_pub_cnt: number;
  jv_top10_pub_cnt: number;
  jv_top25_pub_cnt: number;
  personal_2y_if: number;
  personal_5y_if: number;
  main_fos: string[];
  major_journal: string[];
}

const ListItem: FC<ListItemProps> = ({ item, count }) => {
  return (
    <Flex fontSize="16px" lineHeight="16px">
      <Box fontWeight="500" p="8px 12px" bg="#EBEEFD" w="100%">
        {item}
      </Box>
      <Flex color="white" bg="#1A237E" w="114px" h="36px" justifyContent="center" alignItems="center" fontWeight="900">
        {count}
      </Flex>
    </Flex>
  );
};

const PerformanceListPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData } = useReportRawData({ personalReportId, dataId });

  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as PerformanceData[];

  if (!data) {
    return null;
  }

  return (
    <PersonalAnalyticsLayout page={6}>
      <Flex flexDir="column" height="100%">
        <Box fontSize="42px" lineHeight="42px" fontWeight="bold" color="#536DFE">
          연구자의 연구 성과
        </Box>
        <Flex flex="1 0 auto" flexDir="column" mt="50px" justifyContent="space-between">
          <Box>
            <VStack
              pos="relative"
              spacing="8px"
              alignItems="stretch"
              p="30px 15px 24px 15px"
              border="1px solid rgba(83, 109, 254, 0.5)"
              borderRadius="5px"
            >
              <Box
                pos="absolute"
                bg="#536DFE"
                borderRadius="5px"
                color="white"
                p="10px 26px"
                fontSize="15px"
                lineHeight="16px"
                fontWeight="bold"
                left="15px"
                top="-15.5px"
              >
                Total
              </Box>
              <ListItem item="Publications" count={data[0].pub_cnt.toLocaleString()} />
              <ListItem item="Citations" count={data[0].cit_cnt.toLocaleString()} />
              <ListItem item="h-index" count={data[0].hindex.toLocaleString()} />
              <ListItem item="h5-index" count={data[0].h5index.toLocaleString()} />
              <ListItem item="Personal 2 Years Impact Factor" count={data[0].personal_2y_if.toFixed(2)} />
              <ListItem item="Personal 5 Years Impact Factor" count={data[0].personal_5y_if.toFixed(2)} />
              <ListItem item="Avg Impact Factor" count={data[0].avg_if.toFixed(2)} />
            </VStack>
            <VStack
              pos="relative"
              spacing="8px"
              alignItems="stretch"
              mt="32px"
              p="30px 15px 24px 15px"
              border="1px solid rgba(83, 109, 254, 0.5)"
              borderRadius="5px"
            >
              <Box
                pos="absolute"
                bg="#536DFE"
                borderRadius="5px"
                color="white"
                p="10px 26px"
                fontSize="15px"
                lineHeight="16px"
                fontWeight="bold"
                left="15px"
                top="-15.5px"
              >
                Lead Authored
              </Box>
              <ListItem item="Publications" count={data[1].pub_cnt.toLocaleString()} />
              <ListItem item="Citations" count={data[1].cit_cnt.toLocaleString()} />
              <ListItem item="h-index" count={data[1].hindex.toLocaleString()} />
              <ListItem item="h5-index" count={data[1].h5index.toLocaleString()} />
              <ListItem item="Personal 2 Years Impact Factor" count={data[1].personal_2y_if.toFixed(2)} />
              <ListItem item="Personal 5 Years Impact Factor" count={data[1].personal_5y_if.toFixed(2)} />
              <ListItem item="Avg Impact Factor" count={data[1].avg_if.toFixed(2)} />
            </VStack>
          </Box>
          <Box mb="43px" fontSize="11px" lineHeight="16px">
            <Divider mb="14px" borderTop="1px solid rgba(0,0,0,0.3)" />
            <li>Lear Author는 1 저자 혹은 교신저자로 쓴 논문만을 포함합니다</li>
            <li>Personal Impact Factor는 2021.06.01를 기준으로 계산되었습니다.</li>
          </Box>
        </Flex>
      </Flex>
    </PersonalAnalyticsLayout>
  );
};

export default PerformanceListPage;
