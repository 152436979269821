import Fetcher from './fetcher';
import { PageableResponse } from '../types/pageableContent';
import { SignUpChannel, User, UserStatus } from '../models/user';

interface GetUsersParams {
  page: number;
  query: string | null;
}

export async function getUsers({ page = 1, query }: GetUsersParams) {
  const res = await Fetcher.instance.get<PageableResponse<User>>('/admin/users', {
    params: { page: page - 1, size: 20, query },
  });

  return res.data.data;
}

export async function getUser(userId: string) {
  const res = await Fetcher.instance.get<{ data: User }>(`/admin/users/${userId}`);
  return res.data.data;
}

export async function markTargetUser(userId: string) {
  const res = await Fetcher.instance.post<{ data: User }>(`/admin/users/${userId}/target`);
  return res.data.data;
}

export async function unmarkTargetUser(userId: string) {
  const res = await Fetcher.instance.delete<{ data: User }>(`/admin/users/${userId}/target`);
  return res.data.data;
}

export async function removeUser(user: User) {
  await Fetcher.instance.delete(`/admin/users/${user.id}`);
}

export async function getSigningData(userId: string) {
  const res = await Fetcher.instance.get<{
    data: { referrer: string; paper_source: string };
  }>(`/admin/users/${userId}/signing`);
  return res.data.data;
}

interface CreateUserParams {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  affiliation: { affiliationId: string; affiliationName: string };
  referrer: string;
}

export async function createUser({ affiliation, email, firstName, referrer, password, lastName }: CreateUserParams) {
  const res = await Fetcher.instance.post<{ data: User }>('/admin/users', {
    affiliation_id: affiliation.affiliationId,
    affiliation_name: affiliation.affiliationName,
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    referrer,
  });
  return res.data.data;
}

interface UpdateUserParams {
  userId: string;
  channel: SignUpChannel | null;
  email: string;
  first_name: string;
  last_name: string;
  status: UserStatus | null;
  system_note: string;
}

export async function updateUser({
  userId,
  channel,
  email,
  first_name,
  last_name,
  status,
  system_note,
}: UpdateUserParams) {
  const res = await Fetcher.instance.put<{ data: User }>(`/admin/users/${userId}`, {
    channel,
    email,
    first_name,
    last_name,
    status,
    system_note,
  });

  return res.data.data;
}

export async function assignUserReport({ reportId, userId }: { reportId: string; userId: string }) {
  const res = await Fetcher.instance.put<{ data: User }>(`/admin/users/${userId}/report`, { report_id: reportId });
  return res.data.data;
}

export async function unassignUserReport(userId: string) {
  const res = await Fetcher.instance.delete<{ data: User }>(`/admin/users/${userId}/report`);
  return res.data.data;
}

export async function updateUserStatus({ userId, status }: { userId: string; status: UserStatus }) {
  const res = await Fetcher.instance.patch<{ data: User }>(`/admin/users/${userId}/status`, {
    status,
  });
  return res.data.data;
}
