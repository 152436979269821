import { Box, HStack, ListItem, UnorderedList } from '@chakra-ui/react';
import React, { FC } from 'react';
import PieChart from '../../../components/personalAnalytics/graphs/pieChart';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePageComment } from '../../../hooks/usePageComment';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface Props {
  personalReportId: number;
  dataId: string;
}

interface PieData {
  journal_id: string;
  journal_name: string;
  impact_factor: number;
  pub_cnt: number;
}

const TopJournalPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const pieData = parseStringifiedJSONRawData(rawData?.report_json_data) as PieData[];
  const chartDataForComments = pieData;
  chartDataForComments.sort((a, b) => b.pub_cnt - a.pub_cnt);
  const predefinedComments = [
    `가장 많이 출판한 저널은 총 ${chartDataForComments[0].pub_cnt}편의 논문을 제출한 ${chartDataForComments[0].journal_name} 입니다.`,
    `그 뒤를 이어 ${chartDataForComments[1].journal_name}, ${chartDataForComments[2].journal_name} 저널에 자주 출판했습니다.`,
  ];

  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout>
      <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
        저널 관련 분석
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
        Overview
      </Box>
      <Box mb="34px">
        <PieChart data={pieData.map((datum) => ({ ...datum, category: datum.journal_name, value: datum.pub_cnt }))} />
      </Box>
      <HStack spacing="16px" alignItems="flex-start" mt="42px">
        <Box>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px" whiteSpace="pre-wrap">
            {' '}
            연구자가 현재까지 출판했던 저널들에 대한 비율을 보여줍니다. 상위 10개의 저널만 나타낸 결과입니다.
          </Box>
          {!!comments?.length && (
            <UnorderedList
              p="13px 15px"
              fontSize="12px"
              lineHeight="21px"
              bg="#EBEEFD"
              padding="15px 13px"
              listStylePosition="inside"
              m="0"
              mt="20px"
            >
              {comments.map((comment, i) => (
                <ListItem key={i}>{comment}</ListItem>
              ))}
            </UnorderedList>
          )}
        </Box>
        <Box
          flex="1 0 auto"
          pos="relative"
          borderRadius="5px"
          padding="24px 12px 16px 12px"
          fontSize="12px"
          lineHeight="21px"
          w="201px"
          wordBreak="break-word"
        ></Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default TopJournalPage;
