import { Box, Heading, Spinner } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import AuthorStackItem from './authorStackItem';
import { Author } from '../../models/author';
import { useAnomalyDetection } from '../../hooks/useAnomalyDetection';
import { DragObjectWithType, useDrop } from 'react-dnd';
import { AuthorDraggableItemTypes } from './draggableTypes';
import produce from 'immer';
import { Row } from 'react-table';
import { useRecoilState } from 'recoil';
import { authorListAtPaper } from '../../atoms/authorListAtPaper';
import { Report } from '../../models/report';
import { matchModalState } from '../../atoms/matchModalState';

const AuthorStackList: FC<{ report: Report; publicationId: string }> = ({ report, publicationId }) => {
  const [displayAuthorList, setDisplayAuthorList] = useState<{ author: Author; count?: number | null }[]>([]);
  const { isLoading, data: anomalyDetection } = useAnomalyDetection({ paperId: publicationId, reportId: report.id });
  const authorList = anomalyDetection?.report_mapping_summary.coauthor_summary;
  const [authorListAtPaperInfo, setAuthorListAtPaper] = useRecoilState(authorListAtPaper);
  const [{ selectedUser }] = useRecoilState(matchModalState);

  const [{ isOver }, drop] = useDrop({
    accept: AuthorDraggableItemTypes.AUTHOR,
    drop: (dropItem: DragObjectWithType & { row: Row<Author> }) => {
      const author = dropItem.row.original;
      setAuthorListAtPaper(
        produce(authorListAtPaperInfo, (draft) => {
          if (!draft?.authorList) return;
          draft.authorList.delete(author.id);
        })
      );
    },
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  });

  useEffect(() => {
    setDisplayAuthorList(authorList || []);
  }, [authorList]);

  if (isLoading) {
    return (
      <Box d="flex" p={4} justifyContent="center">
        <Spinner size="xl" color="blue.500" />
      </Box>
    );
  }

  return (
    <>
      <Box my={4}>
        <Heading fontSize="18px">Report Info</Heading>
        <Box p={2}>
          <Box>
            <b>{report.name}</b> (rid: {report.id} | uid: {report.user_id})
          </Box>
          <Box>{report.affiliation?.name}</Box>
          <Box>{report.system_note}</Box>
          {selectedUser && (
            <>
              <Box>
                {selectedUser.first_name} {selectedUser.last_name} | uid: {selectedUser.id}
              </Box>
              <Box>{selectedUser.email}</Box>
              <Box>{selectedUser.affiliation_name}</Box>
            </>
          )}
        </Box>
      </Box>
      <Box my={4}>
        <Heading fontSize="18px">Mapped Author</Heading>
        <Box>
          {anomalyDetection?.report_mapping_summary.connected_authors.map((authorInfo) => (
            <Box
              key={authorInfo.author.id}
              mt={2}
              borderColor="gray.200"
              borderTop="1px solid"
              p={2}
              _first={{ marginTop: 0, borderTop: 0 }}
            >
              <Box>
                <b>
                  {authorInfo.author.name} ({authorInfo.author.id})
                </b>
                {` (H.I: ${authorInfo.author.hindex}, P.C: ${authorInfo.author.paper_count})`}
              </Box>
              <Box>{authorInfo.author.affiliation?.name || ''}</Box>
              <Box>
                Bubble Stack: <b>{authorInfo.count || 0}</b>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box my={4}>
        <Heading fontSize="18px">Mapped Affiliations</Heading>
        <Box>
          {anomalyDetection?.report_mapping_summary.affiliation_summary.map((affiliationInfo, i) => (
            <Box
              mt={2}
              borderColor="gray.200"
              borderTop="1px solid"
              p={2}
              _first={{ marginTop: 0, borderTop: 0 }}
              key={i}
            >
              <Box>{affiliationInfo?.affiliation?.name}</Box>
              <Box>
                Bubble Stack: <b>{affiliationInfo?.count || 0}</b>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box my={4}>
        <Heading fontSize="18px">Coauthors</Heading>
        <Box ref={drop} minH="600px" maxH="800px" overflow="auto" pos="relative">
          {isOver && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 1,
                opacity: 0.5,
                backgroundColor: 'yellow',
              }}
            />
          )}
          {displayAuthorList.map((authorInformation) => {
            return <AuthorStackItem key={authorInformation.author.id} authorInformation={authorInformation} />;
          })}
        </Box>
      </Box>
      <Box my={4}>
        <Heading fontSize="18px">Mapped Fos List</Heading>
        <Box minH="600px" maxH="800px" overflow="auto" pos="relative">
          {anomalyDetection?.report_mapping_summary.fos_summary.map((fosInfo, i) => (
            <Box
              mt={2}
              borderColor="gray.200"
              borderTop="1px solid"
              p={2}
              _first={{ marginTop: 0, borderTop: 0 }}
              key={i}
            >
              <Box>{fosInfo?.fos.name}</Box>
              <Box>
                Bubble Stack: <b>{fosInfo?.count || 0}</b>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box my={4}>
        <Heading fontSize="18px">Mapped Journal List</Heading>
        <Box minH="600px" maxH="800px" overflow="auto" pos="relative">
          {anomalyDetection?.report_mapping_summary.journal_summary.map((journalInfo, i) => (
            <Box
              mt={2}
              borderColor="gray.200"
              borderTop="1px solid"
              p={2}
              _first={{ marginTop: 0, borderTop: 0 }}
              key={i}
            >
              <Box>{journalInfo?.journal.title}</Box>
              <Box>
                Bubble Stack: <b>{journalInfo?.count || 0}</b>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default AuthorStackList;
