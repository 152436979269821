import React, { useState } from 'react';
import { FC } from 'react';
import { FastTrackColdMailRequest, getColdMailSentLogs, sendFastTrackColdMail } from '../../api/fastTrack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Text,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Heading,
  Box,
} from '@chakra-ui/react';
import SendColdMailForm from './sendColdMailForm';
import { Report } from '../../models/report';

const SendColdMailButton: FC<{ report: Report }> = ({ report }) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);

  const { data: emailHistories, isLoading } = useQuery(
    [`/admin/fast-track/reports/${report.id}/mail-logs`],
    () => getColdMailSentLogs(report.id),
    { enabled: isOpen }
  );
  const { mutateAsync: sendColdMail } = useMutation(sendFastTrackColdMail, {
    onSuccess: () => {
      queryClient.invalidateQueries([`/admin/fast-track/reports`]);
    },
  });

  return (
    <>
      <Button mt={2} size="sm" colorScheme="yellow" onClick={() => setIsOpen((prev) => !prev)}>
        Send Email
      </Button>
      <Modal onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Cold Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4} backgroundColor={'green.50'}>
              <Heading mb={2} fontSize={'h6'}>
                Mail sent logs
              </Heading>
              {isLoading ? (
                <Spinner />
              ) : (
                emailHistories?.map((history, i) => (
                  <HStack key={i}>
                    <Text fontSize="sm" fontWeight="bold">
                      - {history.category}
                    </Text>
                    <Text fontSize="xs">{history.sent_at}</Text>
                  </HStack>
                ))
              )}
            </Box>
            <SendColdMailForm
              report={report}
              onSubmit={async (values: FastTrackColdMailRequest) => {
                try {
                  await sendColdMail({ ...values, reportId: report.id });
                  setIsOpen(false);
                } catch (err) {
                  alert(err.response?.data?.error?.message || err.message);
                }
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default SendColdMailButton;
