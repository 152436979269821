import React, { FC } from 'react';
import { Journal } from '../../models/xenurePaper';
import { Badge, Box } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

const JournalBox: FC<{ journal: Journal | null | undefined }> = ({ journal }) => {
  if (!journal) return <Box>선택된 저널이 없습니다. 가능하면 저널을 선택해주세요.</Box>;

  return (
    <Box>
      <Box fontSize="18px" d="flex" alignItems="center">
        <span>{journal.title}</span>
        {
          <Badge ml={1} fontSize="0.5rem" colorScheme="purple">
            JOURNAL
          </Badge>
        }
        {(journal as Journal).is_supported ? (
          <CheckIcon ml={1} size="20px" color="green.500" />
        ) : (
          <CloseIcon ml={1} size="20px" color="red.500" />
        )}
      </Box>
      <Box>
        <Box>Type: Journal</Box>
        <Box>ID: {journal.id}</Box>
        <Box>IF: {journal.impact_factor || '-'}</Box>
        <Box>PAPER_COUNT: {journal.paper_count}</Box>
      </Box>
    </Box>
  );
};

export default JournalBox;
