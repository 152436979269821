import { Box, Flex, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalImpactFactorGraph from '../../../components/personalAnalytics/graphs/personalImpactFactor';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePageComment } from '../../../hooks/usePageComment';
import { useReportRawData } from '../../../hooks/useReportRawData';
import { isAfter, isBefore } from 'date-fns';
import _ from 'lodash';
import {
  PERSONAL_REPORT_MAX_DATE_FOR_LINE_GRAPH,
  PERSONAL_REPORT_MIN_DATE_FOR_LINE_GRAPH,
} from '../../../constants/personalReport';

interface Props {
  personalReportId: number;
  dataId: string;
}

interface PerformanceData {
  date: string;
  report_id: number;
  if_2y_all: number;
  if_2y_lead: number;
  if_5y_all: number;
  if_5y_lead: number;
}

const MultiIFTrendGraphPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as PerformanceData[];

  if (isLoading) {
    return <Box>Loading ...</Box>;
  }

  if (!rawData) return null;
  const chartDataForComments = data.filter(
    ({ date }) =>
      isBefore(new Date(date), PERSONAL_REPORT_MAX_DATE_FOR_LINE_GRAPH) &&
      isAfter(new Date(date), PERSONAL_REPORT_MIN_DATE_FOR_LINE_GRAPH)
  );
  const chartDataYearLength = new Set(chartDataForComments.map(({ date }) => new Date(date).getFullYear())).size;
  const predefinedComments = [
    `최근 ${chartDataYearLength}년간 2년 평균 Impact Factor(전체 논문)가 평균적으로 ${_.meanBy(
      chartDataForComments,
      'if_2y_all'
    ).toFixed(2)}으로 계산됩니다.`,
    `최근 ${chartDataYearLength}년간 2년 평균 Impact Factor(Lead 논문)가 평균적으로 ${_.meanBy(
      chartDataForComments,
      'if_2y_lead'
    ).toFixed(2)}으로 계산됩니다.`,
    `최근 ${chartDataYearLength}년간 5년 평균 Impact Factor(전체 논문)가 평균적으로 ${_.meanBy(
      chartDataForComments,
      'if_5y_all'
    ).toFixed(2)}으로 계산됩니다.`,
    `최근 ${chartDataYearLength}년간 5년 평균 Impact Factor(Lead 논문)가 평균적으로 ${_.meanBy(
      chartDataForComments,
      'if_5y_lead'
    ).toFixed(2)}으로 계산됩니다.`,
  ];

  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);
  return (
    <PersonalAnalyticsLayout page={10}>
      <Box fontWeight="bold" fontSize="42px" lineHeight="42px" color="#536DFE" mb="22px">
        개인연구 트랜드
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" mb="16px">
        개인 연구자 Impact Factor
      </Box>
      <PersonalImpactFactorGraph performanceData={data} />
      <HStack spacing="16px" mt="16px" alignItems="flex-start">
        <Box w="100%">
          <Text fontSize="18px" lineHeight="26px" fontWeight="bold" mb="8px">
            Purpose
          </Text>
          <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap">
            {' '}
            연구자를 저널과 같이 간주하여 저널의 Impact Factor를 계산하는 방법으로 만든 지표입니다.
            {'\n '}
            해당 지표의 추이를 참고하여 연도별로 출판되는 논문들의 질을 가늠할 수 있습니다.
          </Box>
          <UnorderedList
            p="15px 12px"
            bg="#EBEEFD"
            fontSize="12px"
            lineHeight="21px"
            wordBreak="break-all"
            m="0"
            mt="20px"
            listStylePosition="inside"
          >
            {comments?.map((comment, i) => (
              <ListItem key={i}>{comment}</ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box w="100%">
          <Box
            flex="1 0 auto"
            pos="relative"
            background="#F8F4EA"
            borderRadius="5px"
            padding="24px 12px 13px 12px"
            fontSize="12px"
            lineHeight="21px"
            wordBreak="break-word"
          >
            <Box pos="absolute" top="-18px" left="15px" bg="#ebcd7b" borderRadius="5px" padding="3px 11px">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src="/bulb_icon.png" width="30px" height="29px" />
            </Box>
            <Box>
              본 지표는 개인 연구자가 작성한 논문들의 저널의 평균 Impact Factor가 아니라, 연구자가 작성한 전체 논문을
              하나의 저널과 같이 취급하여 실제 평균 인용수를 기반으로 계산한 지표입니다.
            </Box>
          </Box>
          <Box
            pos="relative"
            mt="20px"
            bg="#F9F0F2"
            borderRadius="5px"
            p="24px 16px 16px 16px"
            fontSize="12px"
            lineHeight="21px"
          >
            <Box
              pos="absolute"
              top="-13px"
              left="10px"
              bg="#C47268"
              borderRadius="5px"
              p="6px 10px"
              fontSize="15px"
              lineHeight="15px"
              color="white"
              fontWeight="bold"
            >
              지표설명
            </Box>
            <Text fontWeight={700}>2년 Impact Factor 계산 예시</Text>
            <Text whiteSpace="pre-wrap">
              2021년 5월의 Impact Factor는 아래와 같이 계산합니다.
              {'\n\n'}
              2018년 5월 ~ 2020년 4월까지 연구자가 출판한 논문 수를 10편이라고 가정.
              {'\n'}
              해당 논문 10편이 2020년 5월 ~ 2021년 4월까지 1년 동안 피인용된 횟수를 100회라고 가정.
            </Text>
            <Box mt="16px">
              <Flex alignItems="center" textAlign="center" justifyContent="space-between">
                <Box>
                  <Box>
                    <b>2년 Impact Factor</b>
                  </Box>
                  <Box>
                    (<small>2021년 5월 기준</small>)
                  </Box>
                </Box>
                <Box>=</Box>
                <Box textAlign="center">
                  <Box>
                    <Box>100</Box>
                    <small>(논문 피인용 횟수)</small>
                  </Box>
                  <Box borderTop="1px solid #333">
                    <Box>10</Box>
                    <small>(출판한 논문 수)</small>
                  </Box>
                </Box>
                <Box>=</Box>
                <Box>
                  <b>10</b>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default MultiIFTrendGraphPage;
