import React, { FC } from 'react';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { stringify } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';

const StatusFilter: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();

  return (
    <RadioGroup
      border="1px solid #dedede"
      p={2}
      borderRadius="4px"
      ml={2}
      onChange={(value: string) => {
        const newQueryParams = { ...queryParams, status: value, page: 1 };
        history.push({
          pathname: location.pathname,
          search: stringify(newQueryParams, { addQueryPrefix: true }),
        });
      }}
      value={queryParams.status}
    >
      <Stack direction="row">
        <Radio size="sm" value="REQUEST">
          REQUEST만 보기
        </Radio>
        <Radio size="sm" value="COMPLETE">
          COMPLETE만 보기
        </Radio>
        <Radio size="sm" value="PREPARING">
          PREPARING만 보기
        </Radio>
        <Radio size="sm" value="">
          모두 보기
        </Radio>
      </Stack>
    </RadioGroup>
  );
};

export default StatusFilter;
