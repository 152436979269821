import React, { FC, useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isBefore, isAfter } from 'date-fns';
import {
  PERSONAL_REPORT_MIN_DATE_FOR_LINE_GRAPH,
  PERSONAL_REPORT_MAX_DATE_FOR_LINE_GRAPH,
} from '../../../../constants/personalReport';

am4core.useTheme(am4themes_animated);

interface PerformanceData {
  date: string;
  report_id: number;
  if_2y_all: number;
  if_2y_lead: number;
  if_5y_all: number;
  if_5y_lead: number;
}

interface Props {
  performanceData: PerformanceData[];
}

const PersonalImpactFactorGraph: FC<Props> = ({ performanceData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    let data = performanceData.map((datum) => ({ ...datum, date: new Date(datum.date) }));
    data.sort((a, b) => {
      return isBefore(a.date, b.date) ? -1 : 1;
    });

    data = data.filter(
      (datum) =>
        isBefore(datum.date, PERSONAL_REPORT_MAX_DATE_FOR_LINE_GRAPH) &&
        isAfter(datum.date, PERSONAL_REPORT_MIN_DATE_FOR_LINE_GRAPH)
    );

    const chart = am4core.create(chartRef.current, am4charts.XYChart);
    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.legend.fontSize = '8px';

    chart.data = data;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.date = 'date';
    dateAxis.dateFormats.setKey('year', 'yyyy');
    dateAxis.title.text = 'YEARS';
    dateAxis.title.fontSize = '10px';
    dateAxis.fontSize = '10px';
    dateAxis.renderer.minGridDistance = 30;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Impact Factor';
    valueAxis.title.fontSize = '10px';
    valueAxis.fontSize = '10px';
    valueAxis.min = 0;

    const series = chart.series.push(new am4charts.LineSeries());
    series.name = '2 Year All Impact Factor';
    series.dataFields.valueY = 'if_2y_all';
    series.dataFields.dateX = 'date';
    series.tooltipText = '{dateX}: [b]{valueY}[/]';
    series.stroke = am4core.color('#536DFE');
    series.strokeWidth = 1;

    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.name = '2 Year Lead Impact Factor';
    series2.dataFields.valueY = 'if_2y_lead';
    series2.dataFields.dateX = 'date';
    series2.tooltipText = '{dateX}: [b]{valueY}[/]';
    series2.stroke = am4core.color('#1A237E');
    series2.strokeWidth = 1;

    const series3 = chart.series.push(new am4charts.LineSeries());
    series3.name = '5 Year All Impact Factor';
    series3.dataFields.valueY = 'if_5y_all';
    series3.dataFields.dateX = 'date';
    series3.tooltipText = '{dateX}: [b]{valueY}[/]';
    series3.stroke = am4core.color('#00BFA5');
    series3.strokeWidth = 1;

    const series4 = chart.series.push(new am4charts.LineSeries());
    series4.name = '5 Year Lead Impact Factor';
    series4.dataFields.valueY = 'if_5y_lead';
    series4.dataFields.dateX = 'date';
    series4.tooltipText = '{dateX}: [b]{valueY}[/]';
    series4.stroke = am4core.color('#7C4DFF');
    series4.strokeWidth = 1;

    return () => {
      chart.dispose();
    };
  }, [performanceData]);

  return <div ref={chartRef} style={{ width: '634px', height: '320px' }} />;
};

export default PersonalImpactFactorGraph;
