import React, { FC } from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { useReportRawData } from '../../../hooks/useReportRawData';

const MainItem: FC = ({ children }) => {
  return (
    <Box p="1px 8px" bg="#EBEEFD" color="#1A237E" fontSize="15px" lineHeight="22px" fontWeight="bold">
      {children}
    </Box>
  );
};

const SubItem: FC = ({ children }) => {
  return (
    <Box p="2px 8px 2px 48px" bg="#F4F5FE" color="#000" fontSize="13px" lineHeight="19px">
      {children}
    </Box>
  );
};

const TransparentItem: FC = ({ children }) => {
  return (
    <Box p="2px 8px 2px 88px" color="#000" fontSize="12px" lineHeight="17px">
      {children}
    </Box>
  );
};

const TableOfContents: FC<{ personalReportId: number }> = ({ personalReportId }) => {
  const { data: similarCareerData } = useReportRawData({
    personalReportId,
    dataId: 'top10_similar_fields_same_career_authors_info',
  });

  return (
    <PersonalAnalyticsLayout page={3}>
      <Text fontWeight="bold" fontSize="42px" lineHeight="61px" color="#536DFE">
        Table of Contents
      </Text>
      <VStack mt="40px" spacing="8px" alignItems="stretch">
        <MainItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>01.연구자의 연구 성과</Box>
          </Flex>
        </MainItem>
        <MainItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>02. 최근 10년 연도별 연구 성과 확인</Box>
          </Flex>
        </MainItem>
        <MainItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>03. 자기 논문에 대한 분석</Box>
          </Flex>
        </MainItem>
        <MainItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>04. 동일 분야 연구자 분석</Box>
          </Flex>
        </MainItem>
        <SubItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>대표 연구 키워드</Box>
          </Flex>
        </SubItem>
        <SubItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>유사 연구자</Box>
          </Flex>
        </SubItem>
        <TransparentItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>상위 10명 연구자</Box>
          </Flex>
        </TransparentItem>
        <TransparentItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>국가, 기관, 연구 커리어 분포</Box>
          </Flex>
        </TransparentItem>
        <TransparentItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>전체 유사 연구자의 주요 지표</Box>
          </Flex>
        </TransparentItem>
        {!!similarCareerData?.report_json_data && (
          <>
            <SubItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>유사 연구자이며 비슷한 연구 커리어 연구자</Box>
              </Flex>
            </SubItem>
            <TransparentItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>상위 10명 연구자</Box>
              </Flex>
            </TransparentItem>
            <TransparentItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>국가, 기관, 연구 커리어 분포</Box>
              </Flex>
            </TransparentItem>
            <TransparentItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>전체 유사 연구자의 주요 지표</Box>
              </Flex>
            </TransparentItem>
          </>
        )}
        <SubItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>유사 연구자와 대상 연구자와 비교 분석 그래프</Box>
          </Flex>
        </SubItem>
        <TransparentItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>대상 연구자 vs. 전체 유사 연구자</Box>
          </Flex>
        </TransparentItem>
        <TransparentItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>대상 연구자 vs. 상위 30% 유사성 유사 연구자</Box>
          </Flex>
        </TransparentItem>
        {!!similarCareerData?.report_json_data && (
          <>
            <TransparentItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>대상 연구자 vs. 전체 비슷한 연구 커리어 유사 연구자</Box>
              </Flex>
            </TransparentItem>
            <TransparentItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>대상 연구자 vs. 상위 30% 비슷한 연구 커리어 유사 연구자</Box>
              </Flex>
            </TransparentItem>
          </>
        )}
        <MainItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>05. 저널분석</Box>
          </Flex>
        </MainItem>
        <SubItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>Overview</Box>
          </Flex>
        </SubItem>
        <SubItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>출판 저널의 주요 연구 주제에 대한 트렌드</Box>
          </Flex>
        </SubItem>
        <SubItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>유명 저널의 주요 연구 주제에 대한 트렌드</Box>
          </Flex>
        </SubItem>
        <MainItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>06. 연구자의 연구 영향력 분석</Box>
          </Flex>
        </MainItem>
        <MainItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>07. 주요 연구 분야 내 주목 할 만한 논문</Box>
          </Flex>
        </MainItem>
        <MainItem>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>08. Appendix</Box>
          </Flex>
        </MainItem>
      </VStack>
    </PersonalAnalyticsLayout>
  );
};

export default TableOfContents;
