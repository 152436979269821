import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
  title: string;
  chapterTitle: string;
}

const ChapterJumbotron: FC<Props> = ({ title, chapterTitle }) => {
  return (
    <Box bg="linear-gradient(90deg, #C5D0FF 0%, #F4F5FE 98.6%);" p="70px 80px" color="#1A237E" ml="-80px">
      <Box fontSize="24px" fontWeight="bold" lineHeight="24px">
        {chapterTitle}
      </Box>
      <Box fontSize="42px" lineHeight="42px" fontWeight="bold" mt="24px">
        {title}
      </Box>
    </Box>
  );
};

export default ChapterJumbotron;
