import { Box, Flex } from '@chakra-ui/react';
import React, { CSSProperties, FC } from 'react';

const KeyValueBox: FC<{
  title: string;
  value: string | number | undefined | null | JSX.Element;
  titleWidth?: number;
  valueStyle?: CSSProperties;
}> = ({ title, value, titleWidth, valueStyle }) => {
  return (
    <Flex alignItems="center">
      <Box textColor="gray.600" w={titleWidth ?? 24}>
        {title}
      </Box>
      <Box style={valueStyle}>{value || 'N/A'}</Box>
    </Flex>
  );
};

export default KeyValueBox;
