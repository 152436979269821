import React, { FC, useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

export interface RadarData {
  pub_cnt: number;
  cit_cnt: number;
  journal_pub_cnt: number;
  journal_cit_cnt: number;
  avg_if: number;
  yearmonth: string;
  row_num: number;
  hindex: number;
  year: number;
  h5index: number;
  jcr_top10_pub_cnt: number;
  jcr_top25_pub_cnt: number;
  jv_top10_pub_cnt: number;
  jv_top25_pub_cnt: number;
  personal_2y_if: number;
  personal_5y_if: number;
  main_fos: string[];
  major_journal: string[];
  author_count?: number;
  pub_cnt_max?: number;
  pub_cnt_min?: number;
  cit_cnt_max?: number;
  cit_cnt_min?: number;
  journal_pub_cnt_max?: number;
  journal_pub_cnt_min?: number;
  journal_cit_cnt_max?: number;
  journal_cit_cnt_min?: number;
  avg_if_max?: number;
  avg_if_min?: number;
  personal_5y_if_max?: number;
  personal_5y_if_min?: number;
  personal_2y_if_max?: number;
  personal_2y_if_min?: number;
  hindex_max?: number;
  hindex_min?: number;
  h5index_max?: number;
  h5index_min?: number;
  jcr_top10_pub_cnt_max?: number;
  jcr_top10_pub_cnt_min?: number;
  jv_top10_pub_cnt_max?: number;
  jv_top10_pub_cnt_min?: number;
  jcr_top25_pub_cnt_max?: number;
  jcr_top25_pub_cnt_min?: number;
  jv_top25_pub_cnt_max?: number;
  jv_top25_pub_cnt_min?: number;
}

interface Props {
  radarData: RadarData[];
  targetName: string;
}

const displayName = {
  pub_cnt: 'Total Publication',
  cit_cnt: 'Total Citation',
  avg_if: 'Average Impact Factor',
  hindex: 'H-Index',
  h5index: 'H5-Index',
  personal_2y_if: '2 Years Personal Impact Factor',
  personal_5y_if: '5 Years Personal Impact Factor',
};

const targetKeys = ['pub_cnt', 'cit_cnt', 'hindex', 'h5index', 'personal_2y_if', 'personal_5y_if', 'avg_if'];

function getMinMaxScaledValue({ min, max, current }: { min: number; max: number; current: number }) {
  return ((current - 0) / (max - 0)) * 100;
}

function formatDataForRadarChart(radarData: RadarData[]) {
  const targetData = radarData[0];
  const othersData = radarData[1];

  return Object.keys(othersData)
    .filter((key) => targetKeys.includes(key))
    .map((key) => {
      const min: any = othersData[(key + '_min') as keyof typeof othersData];
      const max: any = othersData[(key + '_max') as keyof typeof othersData];

      return {
        category: (displayName as any)[key],
        target: getMinMaxScaledValue({ current: targetData[key as keyof typeof targetData] as number, min, max }),
        value: getMinMaxScaledValue({ current: othersData[key as keyof typeof othersData] as number, min, max }),
      };
    });
}

const AuthorRadarGraph: FC<Props> = ({ radarData, targetName }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;
    const chart = am4core.create(chartRef.current, am4charts.RadarChart);

    const data = formatDataForRadarChart(radarData);
    chart.data = data;

    chart.legend = new am4charts.Legend();
    chart.legend.fontSize = '10px';

    /* Create axes */
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis() as any);
    categoryAxis.renderer.labels.template.tooltipText = '{title}';
    categoryAxis.dataFields.category = 'category';
    categoryAxis.fontSize = '10px';

    chart.yAxes.push(new am4charts.ValueAxis() as any);

    const series = chart.series.push(new am4charts.RadarSeries());
    series.dataFields.valueY = 'target';
    series.dataFields.categoryX = 'category';
    series.name = targetName;
    series.strokeWidth = 2;
    series.stroke = am4core.color('#001FC9');
    series.zIndex = 2;
    series.fontSize = '12px';
    series.strokeOpacity = 0.9;

    const series2 = chart.series.push(new am4charts.RadarSeries());
    series2.dataFields.valueY = 'value';
    series2.dataFields.categoryX = 'category';
    series2.name = `Top ${radarData[1].author_count?.toLocaleString()} Authors`;
    series2.strokeWidth = 2;
    series2.stroke = am4core.color('#EF5C90');
    series2.fontSize = '12px';
    series2.strokeOpacity = 0.9;

    return () => {
      chart.dispose();
    };
  }, [radarData, targetName]);

  return <div ref={chartRef} style={{ width: '634px', height: '320px' }} />;
};

export default AuthorRadarGraph;
