import { Conference, ConferenceInstance, Journal } from '../models/xenurePaper';
import { atom } from 'recoil';

interface VenueDraftState {
  journal: Journal | null;
  conference: Conference | null;
  conferenceInstance: ConferenceInstance | null;
  removeJournal: boolean;
  removeConference: boolean;
}

export const INITIAL_VENUE_DRAFT_STATE: VenueDraftState = {
  journal: null,
  conference: null,
  conferenceInstance: null,
  removeJournal: false,
  removeConference: false,
};

export const venueDraftState = atom<VenueDraftState>({
  key: 'venueDraftState',
  default: INITIAL_VENUE_DRAFT_STATE,
});
