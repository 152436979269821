import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import { Box, Button, Input } from '@chakra-ui/react';
import { isNumber } from '../../helpers/isNumber';
import { searchVenueByISSNorId, searchVenueByTitle } from './actions';
import FoundVenueTable from './foundVenueTable';
import { VenueSearchResult } from '../../models/venue';

const VenueSearch: FC = () => {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [venues, setVenues] = useState<VenueSearchResult[]>([]);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!query) return alert('검색어를 입력하여 주십시오.');

    setIsLoading(true);

    try {
      if (isNumber(query) || /PLT\d+/.test(query) || /[\S]{4}-[\S]{4}/.test(query)) {
        const venue = await searchVenueByISSNorId(query);
        setVenues([venue]);
      } else {
        const venueList = await searchVenueByTitle(query);
        setVenues(venueList);
      }
    } catch (err) {
      alert(err.response?.data?.error?.message || err.message);
    }

    setIsLoading(false);
  }

  return (
    <>
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Box d="flex">
          <Input
            size="sm"
            value={query}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value)}
            placeholder="VENUE 이름, ID, ISSN으로 검색"
          />
          <Button size="sm" ml={2} isLoading={isLoading} type="submit">
            검색
          </Button>
        </Box>
      </form>
      <Box mt={4}>
        <FoundVenueTable venues={venues} onSelect={() => setVenues([])} />
      </Box>
    </>
  );
};

export default VenueSearch;
