import React, { FC, memo } from 'react';
import { Box, HStack, UnorderedList, ListItem } from '@chakra-ui/react';
import { useReportRawData } from '../../../hooks/useReportRawData';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import WorldMapChart from '../../../components/personalAnalytics/graphs/worldMapChart';
import { usePageComment } from '../../../hooks/usePageComment';

interface Props {
  personalReportId: number;
  dataId: string;
  title: string;
  subtitle: string;
  purpose: string;
  description?: string;
}

interface GeoData {
  affiliation_id: string;
  country: string;
  count?: number;
  collabo_count?: number;
  affiliation_name: string;
}

function getPredefinedComments(dataId: string, countryList: [string, number][]) {
  if (dataId.includes('similar')) {
    if (countryList.length > 5) {
      return [
        `연구자들이 ${countryList[0][0]}, ${countryList[1][0]}, ${countryList[2][0]}에 많이 분포하고 있습니다.`,
        `이외에도 ${countryList[3][0]}, ${countryList[4][0]} 등의 국가에서도 확인되고 있습니다.`,
      ];
    }

    return [`연구자들이 ${countryList.map((country) => country[0])}에 많이 분포하고 있습니다.`];
  }

  if (dataId === 'collaboration_info_by_coauthor') {
    return [
      `같은 논문에 기여한 연구자의 경우 ${countryList[0][0]}에 ${countryList[0][1]}회로 가장 많이 있습니다.`,
      `그 뒤를 이어 ${countryList[1][0]}, ${countryList[2][0]}에도 많이 있습니다.`,
    ];
  }

  if (dataId === 'collaboration_info_by_citation') {
    return [
      `연구자의 논문을 피인용한 다른 연구자의 분포는 ${countryList[0][0]}에 ${countryList[0][1]}회로 가장 많이 있습니다.`,
      `그 뒤를 이어 ${countryList[1][0]}에서 ${countryList[1][1]}회, ${countryList[2][0]}에서 ${countryList[2][1]}회 인용 했습니다.`,
    ];
  }

  return [];
}

const GeoPage: FC<Props> = memo(({ personalReportId, dataId, title, subtitle, purpose, description }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  if (isLoading) {
    return null;
  }

  if (!rawData) {
    return null;
  }

  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as GeoData[];

  const countryMap = new Map<string, number>();
  data.forEach((datum) => {
    countryMap.set(datum.country, (countryMap.get(datum.country) || 0) + (datum.count ?? datum.collabo_count ?? 1));
  });
  const countryList = Array.from(countryMap);
  countryList.sort((a, b) => b[1] - a[1]);

  const predefinedComments: string[] = getPredefinedComments(dataId, countryList);
  const inputComments = comment?.comment.split('\n') ?? [];
  const comments = [...predefinedComments, ...inputComments].filter((data) => data);

  return (
    <PersonalAnalyticsLayout page={23}>
      <Box fontSize="42px" lineHeight="61px" fontWeight="bold" color="#536DFE" mb="2rem">
        {title}
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="14px">
        {subtitle}
      </Box>
      <WorldMapChart
        geoData={data.map((datum) => ({
          country: datum.country,
          name: datum.affiliation_name,
          value: (datum.count ?? datum.collabo_count) || 0,
        }))}
      />
      <HStack spacing="16px" alignItems="flex-start" mt="42px">
        <Box>
          <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="16px">
            Purpose
          </Box>
          <Box fontSize="12px" lineHeight="21px" mb="13px" whiteSpace="pre-wrap">
            {purpose}
          </Box>
          {comments && (
            <UnorderedList
              p="13px 15px"
              fontSize="12px"
              lineHeight="21px"
              bg="#EBEEFD"
              padding="15px 13px"
              listStylePosition="inside"
              m="0"
            >
              {comments.map((comment, i) => (
                <ListItem key={i}>{comment}</ListItem>
              ))}
            </UnorderedList>
          )}
        </Box>
        {description && (
          <Box
            flex="1 0 auto"
            pos="relative"
            background="#F8F4EA"
            borderRadius="5px"
            padding="24px 12px 16px 12px"
            fontSize="12px"
            lineHeight="21px"
            maxW="201px"
            wordBreak="break-word"
          >
            <Box pos="absolute" top="-18px" left="15px" bg="#ebcd7b" borderRadius="5px" padding="3px 11px">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src="/bulb_icon.png" width="30px" height="29px" />
            </Box>
            <Box>{description}</Box>
          </Box>
        )}
      </HStack>
    </PersonalAnalyticsLayout>
  );
});

export default GeoPage;
