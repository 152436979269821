import React, { FC, useEffect, useRef } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import countries from 'i18n-iso-countries';
import _ from 'lodash';

const COUNT_FOR_TOP_COUNTRIES_TO_DISPLAY = 10;

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
am4core.useTheme(am4themes_animated);

interface Props {
  geoData: {
    country: string;
    value: number;
    name: string;
  }[];
}

const WorldMapChart: FC<Props> = ({ geoData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const legendRef = useRef<HTMLDivElement | null>(null);

  const codeData = geoData
    .map((datum) => ({
      ...datum,
      code: countries.getAlpha2Code(
        datum.country === 'United States' ? 'United States of America' : datum.country.toLowerCase(),
        'en'
      ),
    }))
    .map((datum) => {
      if (datum.country === 'Iran') {
        return { ...datum, code: 'IR' };
      } else if (datum.country === 'Czechia') {
        return { ...datum, code: 'CZ' };
      }
      return datum;
    });

  const data = _.chain(codeData)
    .groupBy('code')
    .map((data, key) => {
      const totalCollaboCount = data.reduce((prev, curr) => prev + curr.value, 0);
      return { id: key, value: totalCollaboCount, name: data[0].country };
    })
    .value();

  const allCountries = Array.from(new Set(data.map((datum) => datum.name)));

  useEffect(() => {
    if (!chartRef.current || !legendRef.current) return;
    const map = am4core.create(chartRef.current, am4maps.MapChart);
    // essential chart setting
    map.geodata = am4geodata_worldLow;
    map.projection = new am4maps.projections.Miller();

    // series setting
    const polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;
    polygonSeries.calculateVisualCenter = true;
    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: am4core.color('#D8DDFF'),
      max: am4core.color('#3251FF'),
      logarithmic: true,
    });

    map.series.push(polygonSeries);

    // label
    let labelSeries = map.series.push(new am4maps.MapImageSeries());
    let labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);
    labelTemplate.horizontalCenter = 'middle';
    labelTemplate.verticalCenter = 'middle';
    labelTemplate.fontSize = 10;
    labelTemplate.nonScaling = true;
    labelTemplate.interactionsEnabled = false;

    const copiedData = data;
    copiedData.sort((a, b) => b.value - a.value);
    const topCountries = copiedData.slice(0, COUNT_FOR_TOP_COUNTRIES_TO_DISPLAY);
    const topCountryIds = topCountries.map((country) => country.id);

    polygonSeries.events.on('inited', function () {
      polygonSeries.mapPolygons.each(function (polygon) {
        const datum = polygon.dataItem.dataContext as any;
        if (!topCountryIds.includes(datum.id)) return;
        if (!allCountries.includes(datum.name)) return;

        const countryName = `${datum.name}(${(datum.value as number).toLocaleString()})`;
        const label = labelSeries.mapImages.create();
        label.latitude = polygon.visualLatitude;
        label.longitude = polygon.visualLongitude;
        (label as any).children.getIndex(0).text = countryName;
      });
    });

    polygonSeries.data = data;

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}: {value}';
    polygonTemplate.propertyFields.fill = 'fill';
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    const legendContainer = am4core.create(legendRef.current, am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    map.legend = new am4charts.Legend();
    map.legend.parent = legendContainer;
    map.legend.fontSize = '10px';
    map.legend.align = 'center';
    map.legend.markers.template.width = 0;
    map.legend.markers.template.height = 0;
    map.legend.data = topCountries.map((country) => {
      return { name: `${country.name} (${country.value.toLocaleString()})` };
    });

    return () => {
      map.legend.dispose();
      map.dispose();
    };
  }, [allCountries, data]);

  return (
    <>
      <div ref={chartRef} style={{ width: '634px', height: '320px' }} />
      <div ref={legendRef} style={{ width: '634px', height: '120px' }} />
    </>
  );
};

export default WorldMapChart;
