import React, { FC } from 'react';
import { Box, Divider } from '@chakra-ui/react';
import VenueStatusBox from './venueStatusBox';
import VenueSearch from '../modifyPaperModal/venueSearch';
import ConferenceInstanceSelect from '../modifyPaperModal/conferenceInstanceSelect';
import RemoveVenue from '../modifyPaperModal/removeVenue';
import { useRecoilState } from 'recoil';
import { venueDraftState } from '../../atoms/venueDraft';
import { XenurePaper } from '../../models/xenurePaper';

const VenueSelectBox: FC<{ paper: XenurePaper }> = ({ paper }) => {
  const [{ journal, conference, conferenceInstance, removeJournal, removeConference }] = useRecoilState(
    venueDraftState
  );

  return (
    <>
      <Box mb={4} textAlign="center" fontWeight="bold" fontSize="18px">
        Select Venue
      </Box>
      <Box
        d="grid"
        gridTemplateColumns="0.6fr 1.4fr"
        gridTemplateRows="1fr"
        style={{ gap: '0px 16px' }}
        gridTemplateAreas=". ."
      >
        <VenueStatusBox
          journal={journal || paper.journal}
          conference={conference || paper.conference}
          conferenceInstance={conferenceInstance || paper.conference_instance}
          removeJournal={removeJournal}
          removeConference={removeConference}
        />
        <Box borderLeft="1px solid #dedede" px={4}>
          <Box mb={2}>Venue가 잘못 맵핑되어 있다면?</Box>
          <VenueSearch />
          {conference && (
            <>
              <Divider my={4} />
              <ConferenceInstanceSelect conference={conference} />
            </>
          )}
          <Divider my={4} />
          <Box>
            <RemoveVenue />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VenueSelectBox;
