import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';

interface Props {
  personalReportId: number;
  dataId: string;
}

const EvenTwoColumnGraphPage: FC<Props> = () => {
  return (
    <PersonalAnalyticsLayout>
      <Box fontWeight="bold" fontSize="42px" lineHeight="42px" color="#536DFE" mb="22px">
        개인연구 트랜드
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" mb="16px">
        개인 연구자 Impact Factor
      </Box>
      <Flex w="633px" h="248px" justifyContent="center" alignItems="center" border="1px solid #dedede">
        적당히 그래프
      </Flex>
      <HStack spacing="16px" mt="33px" alignItems="flex-start">
        <Box w="100%">
          <Text fontSize="18px" lineHeight="26px" fontWeight="bold" mb="8px">
            Purpose
          </Text>
          <Box fontSize="12px" lineHeight="21px">
            데이터로 확인한 결과 317편의 전체 논문이 있었고, 216편의 Lead-authored 논문이 확인되었습니다. 이 페이퍼들의
            인용-피인용관계를 시각적인 그래프로 확인해보겠습니다. 이를 통해 본인 논문 내에서 어떤 중심 논문이 있었으며,
            해당 논문들이 가진 힘에 대해서 쉽게 확인 가능합니다.
          </Box>
          <Box p="15px 12px" bg="#EBEEFD" fontSize="12px" lineHeight="21px" mt="20px" wordBreak="break-all">
            <li>시간이 지남에 따라 각 논문들이 받는 평균 인용수가 꾸준히 증가하고 있습니다.</li>
            <li>
              2015 ~ 2018년도는 2년 Impact Factor가 5년 Impact Factor보다 높게 형성된 것으로 보아 퀄리티 좋은 논문이
              다른 기간보다 많이 쓰여진 것으로 보입니다.
            </li>
          </Box>
        </Box>
        <Box w="100%">
          <Box
            flex="1 0 auto"
            pos="relative"
            background="#F8F4EA"
            borderRadius="5px"
            padding="24px 12px 13px 12px"
            fontSize="12px"
            lineHeight="21px"
            wordBreak="break-word"
          >
            <Box pos="absolute" top="-18px" left="15px" bg="#ebcd7b" borderRadius="5px" padding="3px 11px">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src="/bulb_icon.png" width="30px" height="29px" />
            </Box>
            <Box>
              본 지표는 개인 연구자가 작성한 논문들의 저널의 평균 Impact Factor가 아니라, 교수님이 작성하신 전체 논문을
              하나의 저널과 같이 취급하여 실제 평균 인용수를 기반으로 계산한 지표입니다.
            </Box>
          </Box>

          <Box
            pos="relative"
            mt="20px"
            bg="#F9F0F2"
            borderRadius="5px"
            p="24px 16px 16px 16px"
            fontSize="12px"
            lineHeight="21px"
          >
            <Box
              pos="absolute"
              top="-13px"
              left="10px"
              bg="#C47268"
              borderRadius="5px"
              p="6px 10px"
              fontSize="15px"
              lineHeight="15px"
              color="white"
              fontWeight="bold"
            >
              지표설명
            </Box>
            <Text fontWeight={700}>2년 Impact Factor 계산 예시</Text>
            <Text>
              예를 들어 2년 Impact Factor를 2021년 5월의 Impact Factor는 아래와 같이 계산합니다. 2018년 5월 ~ 2020년
              4월까지 연구자가 출판한 논문 수: 10 해당 논문 10편이 2020년 5월부터 2021년 4월(1년)동안의 피인용된 횟수:
              100 2021년 5월 기준 2년 Impact Factor는 100 ÷ 10인 10입니다.
            </Text>
            <Text fontWeight={700} mt="30px">
              5년 Impact Factor 계산 예시
            </Text>
            <Text>
              예를 들어 5년 Impact Factor를 2021년 5월의 Impact Factor는 아래와 같이 계산합니다. 2015년 5월 ~ 2020년
              4월까지 연구자가 출판한 논문 수: 40 해당 논문 10편이 2020년 5월부터 2021년 4월(1년)동안의 피인용된 횟수:
              1000 2021년 5월 기준 2년 Impact Factor는 1000 ÷ 40인 25입니다.
            </Text>
          </Box>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default EvenTwoColumnGraphPage;
