import { AxisBreakOption } from '../components/personalAnalytics/types';
import { PersonalReport } from '../models/personalReport';

export function getAxisBreakOptionFromPersonalReportMatadata(
  personalReport: PersonalReport,
  dataId: string
): AxisBreakOption | undefined {
  if (!personalReport?.graph_meta_data) {
    return undefined;
  }

  const personalReportMetadata: { [dataId: string]: any } = JSON.parse(personalReport.graph_meta_data);
  if (!personalReportMetadata[dataId]) {
    return undefined;
  }

  if (!personalReportMetadata[dataId].axisBreak) {
    return undefined;
  }

  return personalReportMetadata[dataId].axisBreak as AxisBreakOption;
}
