import { Box, Spinner } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { ScinapsePaper } from '../../../models/paper';

const Column: FC<{ title: string; content: string | undefined | null }> = ({ title, content }) => {
  if (!content) return null;

  return (
    <Box py={1} border="1px solid #dedede" borderLeft="0" borderTop="0">
      <Box fontWeight="bold" borderBottom="1px solid #dedede">
        <Box textAlign="center" px={1}>
          {title}
        </Box>
      </Box>
      <Box px={1} alignItems="center">
        {content}
      </Box>
    </Box>
  );
};

const FoundPaperItem: FC<{ paper: ScinapsePaper; onSelectPaper: (paper: ScinapsePaper) => void }> = ({
  paper,
  onSelectPaper,
}) => {
  const [isLoading] = useState(false);

  if (isLoading) {
    return (
      <Box p={6} d="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box
      borderRadius="4px"
      mt={2}
      _first={{ marginTop: 0 }}
      border="1px solid #dedede"
      _hover={{ backgroundColor: '#efefef' }}
      cursor="pointer"
      overflow="auto"
      onClick={() => onSelectPaper(paper)}
    >
      <Box d="flex">
        <Column title="Id" content={paper.id} />
        <Column title="Title" content={paper.title} />
        <Column title="Year" content={paper.year.toString() || paper.published_date} />
        <Column title="Journal" content={paper.journal?.title} />
        <Column title="Conference" content={paper.conference_instance?.conference_series.name} />
        <Column title="DOI" content={paper.doi} />
      </Box>
    </Box>
  );
};

export default FoundPaperItem;
