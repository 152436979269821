import React, { FC } from 'react';
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import UserForm from '../userForm';
import { useRecoilState } from 'recoil';
import { editUserModalState } from '../../atoms/editUserModal';
import { useMutation, useQueryClient } from 'react-query';
import { updateUser } from '../../api/user';
import produce from 'immer';
import { PageableData } from '../../types/pageableContent';
import { User } from '../../models/user';

interface Props {
  userListQueryKey?: any;
}

const EditUserModal: FC<Props> = ({ userListQueryKey }) => {
  const [{ isOpen, user }, setEditUserModalState] = useRecoilState(editUserModalState);
  const queryClient = useQueryClient();
  const { mutateAsync: requestUpdateUser } = useMutation(updateUser, {
    onSuccess: (user) => {
      const oldData = queryClient.getQueryData<PageableData<User>>(userListQueryKey);
      if (oldData && userListQueryKey) {
        queryClient.setQueryData(
          userListQueryKey,
          produce(oldData, (draft) => {
            const i = draft.content.findIndex((u) => u.id === user.id);
            if (i > -1) {
              draft.content[i] = user;
            }
          })
        );
      } else {
        queryClient.invalidateQueries(['/admin/users']);
      }
    },
  });

  if (!user) return null;

  return (
    <Modal isOpen={isOpen} onClose={() => setEditUserModalState({ isOpen: false, user: null })} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={4}>
            <UserForm
              onSubmit={async (values) => {
                await requestUpdateUser({
                  ...values,
                  userId: user.id,
                  status: values.status || null,
                  channel: values.channel || null,
                });
                setEditUserModalState({ isOpen: false, user: null });
              }}
              initialValues={user}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
