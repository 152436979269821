import { Box, Button, Flex, Input, Spinner, Text } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { searchReportGroup } from '../../api/reportGroup';
import { ReportGroup } from '../../models/reportGroup';
import ReportGroupItem from '../reportGroupItem';

const SearchReportGroupInput: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [reportGroups, setReportGroups] = useState<ReportGroup[]>([]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (value.length > 1) {
        setIsLoading(true);
        setReportGroups(await searchReportGroup(value));
        setIsLoading(false);
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
      setIsLoading(false);
    };
  }, [value]);

  return (
    <Box my={2} py={2}>
      <Text fontSize="xs" fontWeight="bold" mb={1}>
        Search report group
      </Text>
      <Box pos="relative">
        <Input value={value} onChange={(e) => setValue(e.currentTarget.value)} placeholder="Postech" />
        {value.length > 0 && !!reportGroups?.length && (
          <Box pos="absolute" bgColor="gray.50" zIndex={3} top="100%" left={0} right={0} p={4} shadow="lg">
            {isLoading && (
              <Flex justifyContent="center" alignItems="center" p={12}>
                <Spinner />
              </Flex>
            )}
            {reportGroups.map((rg) => (
              <Box key={rg.id} pos="relative" borderTop="1px solid #dedede" py={2}>
                <ReportGroupItem reportGroup={rg} />
                <Flex pos="absolute" right="0" top="0" h="100%" alignItems="center" zIndex={3}>
                  <Button as={Link} to={`/report-groups/${rg.id}`} size="sm" colorScheme="teal">
                    DETAIL
                  </Button>
                </Flex>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchReportGroupInput;
