import React, { FC } from 'react';
import { SignCandidate } from '../../models/authorship';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Divider,
} from '@chakra-ui/react';

interface Props {
  candidates: SignCandidate[] | undefined;
  buttonText: string;
}

const CollapsedCandidateList: FC<Props> = ({ candidates, buttonText }) => {
  if (!candidates || candidates?.length === 0) return null;

  const candidateList = candidates.map((candidate, i) => (
    <Box key={i} my={2} p={3} border="1px solid #dedede">
      <Box>
        적중범위: <b>{candidate.sign_type}</b>
      </Box>
      <Divider />
      <Box mb={1}>
        단서: <b>{candidate.sign}</b>
      </Box>
      <Box>{candidate.content}</Box>
    </Box>
  ));

  return (
    <Box>
      <Box mt={4}>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                {buttonText}
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{candidateList}</AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};

export default CollapsedCandidateList;
