import React, { FC, memo, useCallback, useState } from 'react';
import { Button, Table, Tbody, Td, Th, Thead, Tr, theme, HStack } from '@chakra-ui/react';
import { User } from '../../models/user';
import {
  assignUserReport,
  getUser,
  markTargetUser,
  removeUser as requestRemoveUser,
  unassignUserReport,
  unmarkTargetUser,
} from '../../api/user';
import { useMutation, useQueryClient } from 'react-query';
import { parseJSON, formatDistanceToNow } from 'date-fns';
import { useSetRecoilState } from 'recoil';
import { editUserModalState } from '../../atoms/editUserModal';
import SignInDataModal from '../signInDataModal';
import UserReportModal from '../userReportModal';
import UserStatusBadge from './userStatusBadge';

interface Props {
  users: User[];
  onUpdateUser: (user: User) => void;
}

const UserTable: FC<Props> = memo(({ users, onUpdateUser }) => {
  const queryClient = useQueryClient();
  const setEditUserModalState = useSetRecoilState(editUserModalState);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const { mutate: removeUser } = useMutation(requestRemoveUser, {
    onSuccess: () => queryClient.invalidateQueries('/admin/users'),
  });
  const { mutate: assignReportToUser } = useMutation(assignUserReport, { onSuccess: onUpdateUser });
  const { mutate: unassignReportFromUser } = useMutation(unassignUserReport, { onSuccess: onUpdateUser });
  const { mutate: toggleTargetUser } = useMutation(
    async (user: User) => {
      const hasConfirm = window.confirm(
        `${user.first_name} ${user.last_name}을/를 타겟 유저 ${user.is_target ? '해제' : '등록'} 하시겠습니까?`
      );

      if (!hasConfirm) return;

      if (user.is_target) {
        return await unmarkTargetUser(user.id);
      }
      return await markTargetUser(user.id);
    },
    {
      onSuccess: (user) => {
        if (user) {
          onUpdateUser(user);
        }
      },
    }
  );
  const { mutate: reloadUser } = useMutation(getUser, { onSuccess: onUpdateUser });

  const handleRemoveUser = useCallback(
    async (user: User) => {
      if (window.confirm(`Really delete ${user.first_name} ${user.last_name}?`)) {
        await removeUser(user);
      }
    },
    [removeUser]
  );

  return (
    <>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Report ID</Th>
            <Th>Name</Th>
            <Th>Affiliation</Th>
            <Th>Status</Th>
            <Th>Channel</Th>
            <Th>Last Login Time</Th>
            <Th>System Note</Th>
            <Th>추천인</Th>
            <Th>타겟</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user, i) => (
            <Tr key={user.id} backgroundColor={i % 2 === 0 ? 'transparent' : theme.colors.gray['50']}>
              <Td>{user.id}</Td>
              <Td>
                <UserReportModal
                  onAssignReport={(reportId) => {
                    assignReportToUser({ reportId, userId: user.id });
                  }}
                  onUnassignReport={() => {
                    unassignReportFromUser(user.id);
                  }}
                  onReloadUser={() => reloadUser(user.id)}
                  user={user}
                />
              </Td>
              <Td>{user.first_name + ' ' + user.last_name}</Td>
              <Td>{user.affiliation_name}</Td>
              <Td>
                <UserStatusBadge status={user.status} userId={user.id} />
              </Td>
              <Td>{user.channel}</Td>
              <Td>
                {user.last_login_at ? formatDistanceToNow(parseJSON(user.last_login_at), { addSuffix: true }) : 'N/A'}
              </Td>
              <Td>{user.system_note || 'N/A'}</Td>
              <Td>
                <Button onClick={() => setSelectedUser(user)} size="xs">
                  추천인/논문 목록
                </Button>
              </Td>
              <Td>
                <Button
                  onClick={() => toggleTargetUser(user)}
                  colorScheme={user.is_target ? 'pink' : 'green'}
                  ml={2}
                  size="xs"
                >
                  {user.is_target ? '타겟 유저 해제' : '타겟 유저 등록'}
                </Button>
              </Td>
              <Td>
                <HStack>
                  <Button
                    size="xs"
                    colorScheme="teal"
                    onClick={() => setEditUserModalState((prev) => ({ ...prev, isOpen: !prev.isOpen, user }))}
                  >
                    Edit
                  </Button>
                  <Button colorScheme="red" size="xs" onClick={() => handleRemoveUser(user)}>
                    Delete
                  </Button>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <SignInDataModal isOpen={!!selectedUser} onClose={() => setSelectedUser(null)} user={selectedUser} />
    </>
  );
});

export default UserTable;
