import {
  AnalysisLabelingLevel,
  AnalysisLabelingStatus,
  Report,
  ReportDataStatus,
  ReportFilterType,
} from '../models/report';
import { PageableResponse } from '../types/pageableContent';
import { ReportAssociatedData } from '../models/reportAssociatedData';
import { CandidatePaper } from '../models/paper';
import Fetcher from './fetcher';
import { XenurePaper } from '../models/xenurePaper';
import { ReportBasedAnomalyDetectionResponse } from '../models/anomalyDetection';

interface UpsertReportParams {
  affiliation_id: string;
  email: string;
  job_title: string;
  kor_name: string;
  name: string;
  source_urls: string;
  system_note: string;
  user_id?: string;
}

export async function createReport(params: UpsertReportParams) {
  const res = await Fetcher.instance.post<{ data: Report }>(`/admin/reports`, params);
  return res.data.data;
}

export async function removeReport(reportId: string) {
  await Fetcher.instance.delete(`/admin/reports/${reportId}`);
}

export interface GetReportsParams {
  size?: number;
  page?: number;
  query?: string | null;
  status?: ReportDataStatus;
  isConfirmedOnly?: ReportFilterType;
  userMapped?: ReportFilterType;
  groupMapped?: ReportFilterType;
}

export async function getReport(reportId: string | number) {
  const res = await Fetcher.instance.get<{ data: Report }>(`/admin/reports/${reportId}`);
  return res.data.data;
}

export async function getReports({
  size = 1000,
  page = 1,
  isConfirmedOnly,
  query = null,
  status,
  userMapped,
  groupMapped,
}: GetReportsParams) {
  const res = await Fetcher.instance.get<PageableResponse<Report>>(`/admin/reports`, {
    params: {
      size,
      page: page - 1,
      query,
      status,
      confirmed: isConfirmedOnly,
      user_mapped: userMapped,
      group_mapped: groupMapped,
    },
  });
  return res.data.data;
}

export async function searchReport(query: string) {
  const res = await Fetcher.instance.get<{ data: Report[] }>(`/admin/reports/search`, {
    params: { query },
  });
  return res.data.data;
}

export async function editReport(report: UpsertReportParams & { id: string }) {
  const res = await Fetcher.instance.put<{ data: Report }>(`/admin/reports/${report.id}`, report);
  return res.data.data;
}

export async function getReportAssociatedData(reportId: string) {
  const res = await Fetcher.instance.get<{ data: ReportAssociatedData }>(`/admin/reports/${reportId}/associated`);

  return res.data.data;
}

interface GetPapersParams {
  size: number;
  lu: boolean;
  reportId: string;
}

export async function getPapers({ size, lu, reportId }: GetPapersParams) {
  const res = await Fetcher.instance.get<{ data: { content: XenurePaper[] } }>(`/reports/${reportId}/papers`, {
    params: { size, lu },
  });

  return res.data.data.content;
}

export async function getCandidatePapers(reportId: string) {
  const res = await Fetcher.instance.get<{ data: CandidatePaper[] }>(`/admin/reports/${reportId}/candidates`);
  return res.data.data;
}

export async function confirmReportLabeling(reportId: string) {
  const res = await Fetcher.instance.put<{ data: Report }>(`/admin/reports/${reportId}/mark-confirm`, {
    rid: reportId,
  });
  return res.data.data;
}

interface RemovePaperFromReportParams {
  reportId: string;
  paperId: string;
}

export async function removePaperFromReport({ reportId, paperId }: RemovePaperFromReportParams) {
  await Fetcher.instance.delete(`/admin/reports/${reportId}/mapping`, {
    data: { paper_id: paperId },
  });
}

export async function markTargetReport(report: Report) {
  const res = await Fetcher.instance.put<{ data: Report }>(`/admin/reports/${report.id}/target`, null);
  return res.data.data;
}

export async function unmarkTargetReport(report: Report) {
  const res = await Fetcher.instance.delete<{ data: Report }>(`/admin/reports/${report.id}/target`);
  return res.data.data;
}

export async function updateReportDataStatus({ report, status }: { report: Report; status: ReportDataStatus }) {
  const res = await Fetcher.instance.put<{ data: Report }>(`/admin/reports/${report.id}/data-status`, { status });
  return res.data.data;
}

export async function invalidateAPICache() {
  await Fetcher.instance.get(`/admin/search-cache-refresh`);
}

export async function importCandidatePapers({ reportId, citationText }: { reportId: string; citationText: string }) {
  const res = await Fetcher.instance.post<{ data: CandidatePaper[] }>(`/admin/reports/${reportId}/candidates/import`, {
    raw: citationText,
  });
  return res.data.data;
}

export async function clearCandidatePapers(reportId: string) {
  await Fetcher.instance.delete(`/admin/reports/${reportId}/candidates/clear`);
}

interface DetectAnomalyDataParams {
  reportId: string;
  paperId: string;
}

export async function detectAnomalyData({ reportId, paperId }: DetectAnomalyDataParams) {
  const res = await Fetcher.instance.get<{ data: ReportBasedAnomalyDetectionResponse }>(
    `/admin/reports/${reportId}/anomaly-detection`,
    { params: { pid: paperId } }
  );
  return res.data.data;
}

export async function updateDisplayProcessingTarget({ reportId, enable }: { reportId: string; enable: boolean }) {
  const res = await Fetcher.instance.patch<{ data: Report }>(`/admin/reports/${reportId}/display-processing-target`, {
    enable,
  });
  return res.data.data;
}

export async function updateDisplayEligible({ reportId, enable }: { reportId: string; enable: boolean }) {
  const res = await Fetcher.instance.patch<{ data: Report }>(`/admin/reports/${reportId}/display-eligible`, {
    enable,
  });
  return res.data.data;
}

export async function markReportDisplayConfirm(reportId: string) {
  const res = await Fetcher.instance.put<{ data: Report }>(`/admin/reports/${reportId}/display-confirm`);
  return res.data.data;
}

interface UpdateAnalysisLabelingStatusParams {
  reportId: string;
  status: AnalysisLabelingStatus;
}

export async function updateAnalysisLabelingStatus({ reportId, status }: UpdateAnalysisLabelingStatusParams) {
  const res = await Fetcher.instance.patch<{ data: Report }>(`/admin/reports/${reportId}/analysis-labeling-status`, {
    status,
  });
  return res.data.data;
}

interface UpdateAnalysisLabelingLevelParams {
  reportId: string;
  level: AnalysisLabelingLevel;
}

export async function updateAnalysisLabelingLevel({ reportId, level }: UpdateAnalysisLabelingLevelParams) {
  const res = await Fetcher.instance.patch<{ data: Report }>(`/admin/reports/${reportId}/analysis-labeling-level`, {
    level,
  });
  return res.data.data;
}

export async function updateAnalysisMaintenanceTarget({ reportId, enable }: { reportId: string; enable: boolean }) {
  const res = await Fetcher.instance.patch<{ data: Report }>(`/admin/reports/${reportId}/analysis-maintenance-target`, {
    enable,
  });
  return res.data.data;
}

export async function updateAnalysisEligible({ reportId, enable }: { reportId: string; enable: boolean }) {
  const res = await Fetcher.instance.patch<{ data: Report }>(`/admin/reports/${reportId}/analysis-eligible`, {
    enable,
  });
  return res.data.data;
}

export async function markVIPReport(reportId: string) {
  const res = await Fetcher.instance.put<{ data: Report }>(`/admin/reports/${reportId}/vip-mark`);
  return res.data.data;
}
