import { Container, Box, Spinner, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useQueryParams } from '../../hooks/useQueryParams';
import { parsePage } from '../../helpers/parsePage';
import { getUsers } from '../../api/user';
import { useQuery, useQueryClient } from 'react-query';
import UserTable from '../../components/userTable';
import Pagination from '../../components/pagination';
import { PageableData } from '../../types/pageableContent';
import { User } from '../../models/user';
import produce from 'immer';
import SearchUserForm from '../../components/searchUserForm';
import EditUserModal from '../../components/editUserModal';

const UsersPage: FC = () => {
  const { page, query } = useQueryParams();
  const currentPage = parsePage(page);
  const queryClient = useQueryClient();
  const userListQueryKey = ['/admin/users', { page: currentPage, query: query || null }];
  const { data: usersResponse, isLoading } = useQuery(
    userListQueryKey,
    async () => await getUsers({ page: currentPage, query: query || null })
  );

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" py={16}>
        <Spinner />
      </Flex>
    );
  }

  if (!usersResponse) {
    return null;
  }

  return (
    <>
      <Container maxW="container.xl" py={2}>
        <Box fontSize="3xl">Total {usersResponse.total_elements} Users</Box>
        <Box w={1 / 3} my={4}>
          <SearchUserForm />
        </Box>
      </Container>
      <Box p={8}>
        <UserTable
          users={usersResponse.content}
          onUpdateUser={(user) => {
            queryClient.setQueryData<PageableData<User> | undefined>(userListQueryKey, (oldData) => {
              if (!oldData) return;
              return produce(oldData, (draft) => {
                const index = draft.content.findIndex((someUser) => someUser.id === user.id);
                if (index > -1) {
                  draft.content[index] = user;
                }
              });
            });
          }}
        />
        <Box mt={4} d="flex" justifyContent="center" alignItems="center">
          <Pagination
            isFirst={usersResponse.first}
            isLast={usersResponse.last}
            lastPage={usersResponse.total_pages}
            currentPage={currentPage}
            makeLinkAddress={(page: number) => `/users?page=${page}`}
          />
        </Box>
        <EditUserModal userListQueryKey={userListQueryKey} />
      </Box>
    </>
  );
};

export default UsersPage;
