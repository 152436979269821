import { Box, HStack, Text } from '@chakra-ui/react';
import React, { CSSProperties, FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePersonalReport } from '../../../hooks/usePersonalReport';
import { useReportRawData } from '../../../hooks/useReportRawData';
import _ from 'lodash';

const ThStyle: CSSProperties = {
  border: '1px solid #B9B9B9',
  padding: '8px 6px',
  background: '#536DFE',
  color: 'white',
  fontSize: '10px',
  lineHeight: '17px',
  fontWeight: 'bold',
};

const TdStyle: CSSProperties = {
  padding: '6px 4px',
  border: '1px solid #B9B9B9',
  fontSize: '12px',
  lineHeight: '17px',
  fontWeight: 'bold',
};

interface Props {
  personalReportId: number;
  dataId: string;
}

interface ResearcherData {
  pub_cnt: number;
  cit_cnt: number;
  journal_pub_cnt: number;
  journal_cit_cnt: number;
  avg_if: number;
  yearmonth: string;
  row_num: number;
  hindex: number;
  year: number;
  h5index: number;
  jcr_top10_pub_cnt: number;
  jcr_top25_pub_cnt: number;
  jv_top10_pub_cnt: number;
  jv_top25_pub_cnt: number;
  personal_2y_if: number;
  personal_5y_if: number;
  main_fos: string[];
  major_journal: string[];
  rep_id: string;
  author_display_name: string;
  affiliation_display_name: string;
}

const ResearcherAnalysisPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: personalReport } = usePersonalReport(personalReportId);

  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as ResearcherData[];

  if (isLoading) {
    return <Box>Loading ...</Box>;
  }

  if (!rawData) return null;

  return (
    <PersonalAnalyticsLayout page={18}>
      <Box fontSize="42px" lineHeight="42px" fontWeight="bold" color="#536DFE" mb="24px">
        유사 연구자 분석
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" color="#000" mb="24px">
        유사 분야 연구자에 대한 정보
      </Box>
      <Box fontWeight="bold" fontSize="18px" lineHeight="26px" mb="12px">
        Purpose
      </Box>
      <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap">
        {' '}
        유사도 기준 최상위 연구자 5명과 hindex 기준 최상위 연구자 5명에 대한 데이터입니다.
      </Box>
      <table style={{ textAlign: 'center', width: '100%', marginTop: '16px', tableLayout: 'auto' }}>
        <thead>
          <tr>
            <th style={ThStyle}>이름</th>
            <th style={ThStyle}>연구 기관</th>
            <th style={ThStyle}>출판 논문 수</th>
            <th style={ThStyle}>피인용수</th>
            <th style={ThStyle}>h-index</th>
            <th style={ThStyle}>h5-index</th>
            <th style={ThStyle}>Avg-IF</th>
            <th style={ThStyle}>2 Years Personal Impact Factor</th>
            <th style={ThStyle}>5 Years Personal Impact Factor</th>
          </tr>
        </thead>
        <tbody>
          {_.uniqBy(data, 'author_display_name').map((datum, i) => (
            <tr key={i} style={{ background: i === 0 ? '#EBEEFD' : 'transparent' }}>
              <td style={{ ...TdStyle, fontWeight: 500 }}>
                {i === 0 ? personalReport?.report.name : datum.author_display_name}
              </td>
              <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>
                {i === 0 ? personalReport?.report.affiliation?.name : datum.affiliation_display_name}
              </td>
              <td style={TdStyle}>{datum.pub_cnt.toLocaleString()}</td>
              <td style={TdStyle}>{datum.cit_cnt.toLocaleString()}</td>
              <td style={TdStyle}>{datum.hindex.toLocaleString()}</td>
              <td style={TdStyle}>{datum.h5index.toLocaleString()}</td>
              <td style={TdStyle}>{datum.avg_if?.toFixed(2)}</td>
              <td style={TdStyle}>{datum.personal_2y_if?.toFixed(2) ?? 'N/A'}</td>
              <td style={TdStyle}>{datum.personal_5y_if?.toFixed(2) ?? 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <HStack alignItems="flex-start" mt="32px">
        <Box
          pos="relative"
          background="#F8F4EA"
          borderRadius="5px"
          padding="24px 12px 13px 12px"
          fontSize="12px"
          lineHeight="21px"
          wordBreak="break-word"
          w="100%"
        >
          <Box pos="absolute" top="-18px" left="15px" bg="#ebcd7b" borderRadius="5px" padding="3px 11px">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src="/bulb_icon.png" width="30px" height="29px" />
          </Box>
          <Box>최상위 연구자는 10이 넘는 h-index를 가진 연구자들 중에서 계산하였습니다.</Box>
        </Box>
        <Box
          w="100%"
          pos="relative"
          bg="#FCEDF0"
          borderRadius="5px"
          p="16px 12px 12px 12px"
          fontSize="11px"
          lineHeight="21px"
        >
          <Box
            pos="absolute"
            top="-13px"
            left="10px"
            bg="#D3625C"
            borderRadius="5px"
            p="6px 10px"
            fontSize="15px"
            lineHeight="15px"
            color="white"
            fontWeight="bold"
          >
            유사도 산출 방법
          </Box>
          <Text whiteSpace="pre-wrap">
            Pluto가 가진 Fields of Study 데이터를 이용하여 연구자의 세부 분야를 확인합니다.
            {'\n'}
            유사성을 크게 빈도수와 비율, 두 부분으로 나누어서 200점 만점의 유사 점수(Similarity Score)를 계산하였습니다.
            {'\n'}
            이후 상위 1%의 유사성을 가진 연구자 집단을 추출하였습니다.
          </Text>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default ResearcherAnalysisPage;
