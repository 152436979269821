import React, { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from '../header';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Box, Heading, Spinner, VStack, Text } from '@chakra-ui/react';
import CatsPage from '../../pages/cats';
import AnalyticsPage from '../../pages/analytics';
import AnalyticsDetail from '../../pages/analytics/[id]';
import ReportGroupDetail from '../../pages/report-groups/[id]';
import ReportDetail from '../../pages/reports/[id]';
import ReportGroupListPage from '../../pages/report-groups';
import ReportListPage from '../../pages/reports';
import UserListPage from '../../pages/users';
import PersonalAnalytics from '../../pages/personalAnalytics';
import PersonalReportPage from '../../pages/personalReportPages';
import FastTrack from '../../pages/fastTrack';

const PrivateRoute: FC = ({ children }) => {
  const { user, isLoading } = useCurrentUser();

  if (isLoading) {
    return (
      <Box d="flex" flexDir="column" alignItems="center" mt={16}>
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!user?.is_admin) {
    return (
      <>
        <Header />
        <Box d="flex" flexDir="column" alignItems="center" mt={16}>
          <Heading mb={4}>Please Sign In first</Heading>
          <img width={800} height={600} src={process.env.PUBLIC_URL + '/need_sign_in.jpg'} alt="sign in is needed" />
        </Box>
      </>
    );
  }
  return (
    <>
      <Header />
      {children}
    </>
  );
};

const App: FC = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <Switch>
          <Route path="/personal-analytics-pages" exact>
            <PersonalReportPage />
          </Route>
          <Route path="/analytics/:analyticsId">
            <PrivateRoute>
              <AnalyticsDetail />
            </PrivateRoute>
          </Route>
          <Route path="/analytics" exact>
            <PrivateRoute>
              <AnalyticsPage />
            </PrivateRoute>
          </Route>
          <Route path="/report-groups/:reportGroupId">
            <PrivateRoute>
              <ReportGroupDetail />
            </PrivateRoute>
          </Route>
          <Route path="/report-groups" exact>
            <PrivateRoute>
              <ReportGroupListPage />
            </PrivateRoute>
          </Route>
          <Route path="/reports/:reportId">
            <PrivateRoute>
              <ReportDetail />
            </PrivateRoute>
          </Route>
          <Route path="/reports" exact>
            <PrivateRoute>
              <ReportListPage />
            </PrivateRoute>
          </Route>
          <Route path="/personal-analytics">
            <PrivateRoute>
              <PersonalAnalytics />
            </PrivateRoute>
          </Route>
          <Route path="/cats">
            <CatsPage />
          </Route>
          <Route path="/fast-track">
            <PrivateRoute>
              <FastTrack />
            </PrivateRoute>
          </Route>
          <Route path="/users">
            <PrivateRoute>
              <UserListPage />
            </PrivateRoute>
          </Route>
          <Route path="/" exact>
            <PrivateRoute>
              <VStack>
                <img src="/funny_cat.gif" alt="cat" className="cat" />
                <Text fontSize="3xl" fontWeight="bold">
                  Travel with Pluto Universe
                </Text>
                <img src="/universe1.jpg" alt="universe" />
                <img src="/universe2.jpg" alt="universe" />
              </VStack>
            </PrivateRoute>
          </Route>
        </Switch>
      </BrowserRouter>
    </DndProvider>
  );
};

export default App;
