import { useQuery } from 'react-query';
import { detectAnomalyData } from '../api/report';
import { ReportBasedAnomalyDetectionResponse } from '../models/anomalyDetection';

interface UseAnomalyDetectionParams {
  paperId: string;
  reportId: string;
  onSuccess?: (anomalyDetected: ReportBasedAnomalyDetectionResponse) => void;
}
export function useAnomalyDetection({ paperId, reportId, onSuccess }: UseAnomalyDetectionParams) {
  return useQuery(
    `/admin/reports/${reportId}/anomaly-detection`,
    async () => detectAnomalyData({ reportId, paperId }),
    { enabled: !!reportId, onSuccess: (res) => onSuccess?.(res) }
  );
}
