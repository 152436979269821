import { Box, ListItem, UnorderedList } from '@chakra-ui/react';
import React, { CSSProperties, FC } from 'react';
import AuthorRadarGraph, { RadarData } from '../../../components/personalAnalytics/graphs/authorRadarGraph';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePageComment } from '../../../hooks/usePageComment';
import { usePersonalReport } from '../../../hooks/usePersonalReport';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface Props {
  personalReportId: number;
  dataId: string;
  subtitle: string;
}

const ThStyle: CSSProperties = {
  border: '1px solid #B9B9B9',
  padding: '10px 6px',
  background: '#536DFE',
  color: 'white',
  fontSize: '12px',
  lineHeight: '17px',
  fontWeight: 'normal',
};

const TdStyle: CSSProperties = {
  padding: '10px 8px',
  border: '1px solid #B9B9B9',
  fontSize: '12px',
  lineHeight: '17px',
};

const PerformanceRadarPage: FC<Props> = ({ personalReportId, dataId, subtitle }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });
  const { data: personalReport } = usePersonalReport(personalReportId);

  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as RadarData[];
  const comments = comment?.comment.split('\n');

  if (isLoading) {
    return <Box>Loading ...</Box>;
  }

  if (!rawData) return null;

  const targetData = data[0];
  const othersData = data[1];

  return (
    <PersonalAnalyticsLayout>
      <Box fontWeight="bold" fontSize="42px" lineHeight="42px" color="#536DFE" mb="22px">
        유사 연구자 분석 - 비교 분석
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" mb="24px">
        {subtitle}
      </Box>
      <AuthorRadarGraph radarData={data} targetName={personalReport?.report.name ?? ''} />
      <table style={{ textAlign: 'center', width: '100%', marginTop: '30px', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={ThStyle}>대상 연구자</th>
            <th style={ThStyle}>Publication</th>
            <th style={ThStyle}>Citation</th>
            <th style={ThStyle}>Avg if</th>
            <th style={ThStyle}>2 years personal if</th>
            <th style={ThStyle}>5 years personal if</th>
            <th style={ThStyle}>h-index</th>
            <th style={ThStyle}>h5-index</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ ...TdStyle, fontWeight: 700, backgroundColor: '#E6EBFF' }}>
              {personalReport?.report.name ?? ''}
            </td>
            <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>
              {targetData.pub_cnt.toLocaleString()}
            </td>
            <td style={TdStyle}>{targetData.cit_cnt.toLocaleString()}</td>
            <td style={TdStyle}>{targetData.avg_if.toFixed(2).toLocaleString()}</td>
            <td style={TdStyle}>{targetData.personal_2y_if.toFixed(2)}</td>
            <td style={TdStyle}>{targetData.personal_5y_if.toFixed(2)}</td>
            <td style={TdStyle}>{targetData.hindex.toFixed(2).toLocaleString()}</td>
            <td style={TdStyle}>{targetData.h5index.toFixed(2).toLocaleString()}</td>
          </tr>
          <tr>
            <td style={{ ...TdStyle, fontWeight: 700, backgroundColor: '#E6EBFF' }}>
              {`${othersData.author_count}명의 연구자 평균`}
            </td>
            <td style={{ ...TdStyle, fontSize: '10px', lineHeight: '14px', fontWeight: 'normal' }}>
              {Math.round(othersData.pub_cnt).toLocaleString()}
            </td>
            <td style={TdStyle}>{Math.round(othersData.cit_cnt).toLocaleString()}</td>
            <td style={TdStyle}>{othersData.avg_if.toFixed(2).toLocaleString()}</td>
            <td style={TdStyle}>{othersData.personal_2y_if.toFixed(2)}</td>
            <td style={TdStyle}>{othersData.personal_5y_if.toFixed(2)}</td>
            <td style={TdStyle}>{othersData.hindex.toFixed(2).toLocaleString()}</td>
            <td style={TdStyle}>{othersData.h5index.toFixed(2).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
      {!!comments?.length && (
        <UnorderedList
          p="13px 15px"
          fontSize="12px"
          lineHeight="21px"
          bg="#EBEEFD"
          padding="15px 13px"
          listStylePosition="inside"
          m="0"
          mt="20px"
        >
          {comments.map((comment, i) => (
            <ListItem key={i}>{comment}</ListItem>
          ))}
        </UnorderedList>
      )}
    </PersonalAnalyticsLayout>
  );
};

export default PerformanceRadarPage;
