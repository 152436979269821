import React, { FC } from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import SignInModal from '../signInModal';
import { useSetRecoilState } from 'recoil';
import { signInModalState } from '../../atoms/signInModal';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useMutation, useQueryClient } from 'react-query';
import gtm, { EventName } from '../../helpers/gtm';
import { logout } from '../../api/auth';

const MenuItems: FC = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
);

const Header: FC = (props) => {
  const { user } = useCurrentUser();
  const [show, setShow] = React.useState(false);
  const setModalState = useSetRecoilState(signInModalState);
  const handleToggle = () => setShow(!show);
  const queryClient = useQueryClient();
  const { mutate: requestLogout } = useMutation(logout, {
    onSuccess: () => {
      queryClient.setQueryData('/auth/check', {
        is_logged_in: false,
        is_admin: false,
      });
      gtm.event(EventName.LOGOUT);
    },
  });

  async function handleLogout() {
    const confirmLogout = window.confirm('want to logout?');
    if (!confirmLogout) return;

    requestLogout();
  }

  return (
    <>
      <Flex
        position="sticky"
        top={0}
        left={0}
        right={0}
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        p={4}
        bg="black"
        color="white"
        zIndex={30}
        {...props}
      >
        <Flex align="center" mr={5}>
          <Heading as="h1" size="lg" letterSpacing={'-.1rem'}>
            XENURE ADMIN
          </Heading>
        </Flex>
        <Box display={{ sm: 'block', md: 'none' }} onClick={handleToggle}>
          <svg fill="white" width="12px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </Box>
        {user && (
          <Box
            display={{ sm: show ? 'block' : 'none', md: 'flex' }}
            width={{ sm: 'full', md: 'auto' }}
            alignItems="center"
            flexGrow={1}
          >
            <MenuItems>
              <Link to="/analytics">Analytics</Link>
            </MenuItems>
            <MenuItems>
              <Link to="/report-groups">Report Groups</Link>
            </MenuItems>
            <MenuItems>
              <Link to="/reports">Report List</Link>
            </MenuItems>
            <MenuItems>
              <Link to="/users">User List</Link>
            </MenuItems>
            <MenuItems>
              <Link to="/fast-track">Fast Track</Link>
            </MenuItems>
            <MenuItems>
              <Link to="/personal-analytics">Personal Analytics</Link>
            </MenuItems>
          </Box>
        )}
        <Box display={{ sm: show ? 'block' : 'none', md: 'block' }} mt={{ base: 4, md: 0 }}>
          {!user?.is_logged_in && (
            <Button bg="transparent" border="1px" onClick={() => setModalState({ isOpen: true })}>
              Sign in
            </Button>
          )}
          {user?.is_logged_in && <Button onClick={handleLogout} variant="outline">{`Hello ${user.first_name}`}</Button>}
        </Box>
      </Flex>
      <SignInModal />
    </>
  );
};

export default Header;
