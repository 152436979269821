import { Box, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { personalAnalyticsState } from '../../../atoms/personalAnalytics';
import { Page } from '../../../models/page';

interface Props {
  page: Page;
}
const PageItem: FC<Props> = ({ page }) => {
  const [{ selectedPage }, setPersonalAnalytics] = useRecoilState(personalAnalyticsState);

  return (
    <Box
      key={page.id}
      textAlign="center"
      p={4}
      bg="white"
      cursor="pointer"
      onClick={() => setPersonalAnalytics((prev) => ({ ...prev, selectedPage: page }))}
      border={selectedPage === page ? '4px solid #333' : 'none'}
      minW="120px"
      flex="0 1 auto"
    >
      <VStack spacing={2} fontSize="14px">
        <Box fontWeight="bold" fontSize="24px">
          {page.sequence}
        </Box>
        <Box whiteSpace="pre-wrap" wordBreak="break-all" fontWeight="bold">
          {page.data_id}
        </Box>
        <Box>{page.graph_type}</Box>
        <Box>{page.layout_type}</Box>
      </VStack>
    </Box>
  );
};

export default PageItem;
