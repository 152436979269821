import React from 'react';
import { FC } from 'react';
import { User, UserStatus } from '../../models/user';
import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import produce from 'immer';
import { updateUserStatus } from '../../api/user';
import { useQueryParams } from '../../hooks/useQueryParams';
import { parsePage } from '../../helpers/parsePage';
import { PageableData } from '../../types/pageableContent';

const StatusMenuButton: FC<{ status: UserStatus | null }> = ({ status }) => {
  switch (status) {
    case 'SAMPLE':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs" colorScheme="cyan">
          {status}
        </MenuButton>
      );
    case 'IN_TRANSITION':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs" colorScheme="yellow">
          {status}
        </MenuButton>
      );
    case 'PENDING':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs" colorScheme="blue">
          {status}
        </MenuButton>
      );
    case 'CONFIRMED':
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs" colorScheme="green">
          {status}
        </MenuButton>
      );
    default:
      return (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs" colorScheme="gray">
          N/A
        </MenuButton>
      );
  }
};

const UserStatusBadge: FC<{ status: UserStatus | null; userId: string }> = ({ status, userId }) => {
  const queryClient = useQueryClient();
  const { page, query } = useQueryParams();
  const currentPage = parsePage(page);

  const userListQueryKey = ['/admin/users', { page: currentPage, query: query || null }];
  const { mutateAsync: updateStatus } = useMutation(updateUserStatus, {
    onSuccess: (newUser) => {
      if (!newUser) return;
      const users = queryClient.getQueryData<PageableData<User>>(userListQueryKey);
      if (users && userListQueryKey) {
        queryClient.setQueryData<PageableData<User>>(userListQueryKey, () => {
          return produce(users!!, (draft) => {
            const index = draft.content.findIndex((item) => item.id === newUser.id);
            if (index > -1) {
              draft.content[index] = newUser;
            }
          });
        });
      } else {
        queryClient.invalidateQueries(userListQueryKey);
      }
    },
  });

  return (
    <Menu>
      <StatusMenuButton status={status} />
      <MenuList>
        <MenuOptionGroup
          defaultValue={status ?? undefined}
          type="radio"
          onChange={(status) => updateStatus({ userId: userId, status: status as UserStatus })}
        >
          <MenuItemOption value="SAMPLE">SAMPLE</MenuItemOption>
          <MenuItemOption value="IN_TRANSITION">IN_TRANSITION</MenuItemOption>
          <MenuItemOption value="PENDING">PENDING</MenuItemOption>
          <MenuItemOption value="CONFIRMED">CONFIRMED</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default UserStatusBadge;
