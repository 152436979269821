import React, { FC } from 'react';
import { Flex, Box, FormControl, FormLabel, Textarea, FormErrorMessage, Button } from '@chakra-ui/react';
import { Formik, Form, Field, FieldProps, FormikHelpers } from 'formik';

export interface ImportPublicationFromCitationTextFormValues {
  citationStr: string;
}

function validate(value: string) {
  let error;
  if (!value) {
    error = 'You should enter citation text';
  }
  return error;
}

const ImportPublicationFromCitationTextForm: FC<{
  onSubmit: (
    values: ImportPublicationFromCitationTextFormValues,
    actions: FormikHelpers<ImportPublicationFromCitationTextFormValues>
  ) => Promise<void>;
}> = ({ onSubmit }) => {
  return (
    <Flex justify="center">
      <Box w="xl" rounded="lg">
        <Formik<ImportPublicationFromCitationTextFormValues> initialValues={{ citationStr: '' }} onSubmit={onSubmit}>
          {({ isSubmitting }) => {
            return (
              <Form>
                <Field name="citationStr" validate={validate}>
                  {({ field, form }: FieldProps<string, ImportPublicationFromCitationTextFormValues>) => (
                    <FormControl isInvalid={!!form.errors.citationStr && form.touched.citationStr}>
                      <FormLabel htmlFor="citationStr">Citation Text</FormLabel>
                      <Textarea {...field} id="citationStr" placeholder="Please enter citation text" />
                      <FormErrorMessage>{form.errors.citationStr}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Box d="flex" justifyContent="flex-end">
                  <Button mt={4} colorScheme="teal" isLoading={isSubmitting} type="submit">
                    Submit
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Flex>
  );
};

export default ImportPublicationFromCitationTextForm;
