import { Box, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React, { FC } from 'react';
import CareerYearHistogram from '../../../components/personalAnalytics/graphs/careerYearHistogram';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import { parseStringifiedJSONRawData } from '../../../helpers/parseStringifiedJSONRawData';
import { usePageComment } from '../../../hooks/usePageComment';
import { useReportRawData } from '../../../hooks/useReportRawData';

interface Props {
  personalReportId: number;
  dataId: string;
}

interface ResearcherData {
  rep_id: string;
  active_career_years: number;
}

const CareerYearPage: FC<Props> = ({ personalReportId, dataId }) => {
  const { data: rawData, isLoading } = useReportRawData({ personalReportId, dataId });
  const { data: comment } = usePageComment({ personalReportId, dataId });

  const data = parseStringifiedJSONRawData(rawData?.report_json_data) as ResearcherData[];
  const comments = comment?.comment.split('\n');

  if (isLoading) {
    return <Box>Loading ...</Box>;
  }

  if (!rawData) return null;

  return (
    <PersonalAnalyticsLayout page={20}>
      <Box fontWeight="bold" fontSize="42px" lineHeight="42px" color="#536DFE" mb="22px">
        유사 연구자 분석
      </Box>
      <Box fontSize="21px" lineHeight="30px" fontWeight="bold" mb="16px">
        연구 나이 분포
      </Box>
      <CareerYearHistogram researcherData={data} />
      <HStack spacing="16px" mt="33px" alignItems="flex-start">
        <Box w="100%">
          <Text fontSize="18px" lineHeight="26px" fontWeight="bold" mb="8px">
            Purpose
          </Text>
          <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap" mb="16px">
            {' '}
            상위 1%의 유사도를 가진 연구자들의 연구 나이를 확인합니다.
          </Box>
          {comments && (
            <UnorderedList
              p="13px 15px"
              fontSize="12px"
              lineHeight="21px"
              bg="#EBEEFD"
              padding="15px 13px"
              listStylePosition="inside"
              m="0"
            >
              {comments.map((comment, i) => (
                <ListItem key={i}>{comment}</ListItem>
              ))}
            </UnorderedList>
          )}
        </Box>
        <Box w="100%">
          <Box
            pos="relative"
            mt="20px"
            bg="#FCEDF0"
            borderRadius="5px"
            p="24px 16px 16px 16px"
            fontSize="12px"
            lineHeight="21px"
          >
            <Box
              pos="absolute"
              top="-13px"
              left="10px"
              bg="#D3625C"
              borderRadius="5px"
              p="6px 10px"
              fontSize="15px"
              lineHeight="15px"
              color="white"
              fontWeight="bold"
            >
              연구 나이란?
            </Box>
            <Text whiteSpace="pre-wrap">
              연구 나이란? 첫 논문 출판 연도 마지막 연구 출판 연도를 가지고 계산한 나이입니다. 예를 들어 첫 논문의 출판
              연도가 1990년이고, 마지막 논문의 출판 연도가 2021년의 경우 31년의 연구나이라고 계산합니다.
              {'\n'}
              은퇴 연구자를 제외하기 위해 현 보고서 기준 2021년에 논문 출판이 없는 교수님의 경우 데이터에서
              제외되었습니다.
            </Text>
          </Box>
        </Box>
      </HStack>
    </PersonalAnalyticsLayout>
  );
};

export default CareerYearPage;
