import { Select, SelectProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { SUB_DOC_TYPE } from '../../constants/articleType';

const AVAILABLE_SUB_DOC_TYPE: SUB_DOC_TYPE[] = ['ORIGINAL', 'REVIEW', 'ETC'];

const SubTypeSelector: FC<SelectProps & { currentDocType: SUB_DOC_TYPE | null }> = ({ currentDocType, ...props }) => {
  return (
    <Select {...props} placeholder="Select article's sub type">
      {AVAILABLE_SUB_DOC_TYPE.map((type) => (
        <option key={type} value={type}>
          {type}
        </option>
      ))}
    </Select>
  );
};

export default SubTypeSelector;
