import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ReportBasedAnomalyDetectionResponse } from '../../models/anomalyDetection';
import { CheckIcon } from '@chakra-ui/icons';

interface AnomalyWarningProps {
  anomalyDetection: ReportBasedAnomalyDetectionResponse | undefined;
}

const AnomalyWarning: FC<AnomalyWarningProps> = ({ anomalyDetection }) => {
  if (!anomalyDetection) return null;

  return (
    <>
      <div>
        <CheckIcon mr={2} size="20px" color={anomalyDetection?.valid ? 'green.500' : 'red.500'} />
        <Box as="span" color={anomalyDetection?.valid ? 'green.500' : 'red.500'}>
          {anomalyDetection?.valid ? '감지된 이상치가 없습니다.' : '이상치를 감지했습니다.'}
        </Box>
      </div>
      <div>
        <CheckIcon
          name="check"
          mr={2}
          size="20px"
          color={anomalyDetection?.found_matched_author ? 'green.500' : 'red.500'}
        />
        <Box as="span" color={anomalyDetection?.found_matched_author ? 'green.500' : 'red.500'}>
          {anomalyDetection?.found_matched_author
            ? '저자 매치에 성공했습니다.'
            : '현재 선택된 유저를 찾을 수 없습니다.'}
        </Box>
      </div>
      <div>
        <CheckIcon mr={2} size="20px" color={anomalyDetection?.anomaly_detection_activated ? 'green.500' : 'red.500'} />
        <Box as="span" color={anomalyDetection?.anomaly_detection_activated ? 'green.500' : 'red.500'}>
          {anomalyDetection?.anomaly_detection_activated
            ? '이상치 감지가 활성화되어 있습니다.'
            : '아직 활성화되지 않은 유저입니다. 좀 더 맵핑해주세요.'}
        </Box>
      </div>
      {anomalyDetection?.anomaly_detection_activated && (
        <>
          <div>
            <CheckIcon
              mr={2}
              size="20px"
              color={anomalyDetection?.detected_coauthor_anomaly ? 'red.500' : 'green.500'}
            />
            <Box as="span" color={anomalyDetection?.detected_coauthor_anomaly ? 'red.500' : 'green.500'}>
              {anomalyDetection?.detected_coauthor_anomaly
                ? 'coauthor들이 이전에 본 적 없던 사람들입니다!'
                : 'coauthor 이상 없음'}
            </Box>
          </div>
          <div>
            <CheckIcon
              mr={2}
              size="20px"
              color={anomalyDetection?.detected_affiliation_anomaly ? 'red.500' : 'green.500'}
            />
            <Box as="span" color={anomalyDetection?.detected_affiliation_anomaly ? 'red.500' : 'green.500'}>
              {anomalyDetection?.detected_affiliation_anomaly
                ? '저자의 affiliation이 이전과 일치하지 않습니다!'
                : 'affiliation 이상 없음'}
            </Box>
          </div>
          <div>
            <CheckIcon
              mr={2}
              size="20px"
              color={anomalyDetection?.detected_journal_anomaly ? 'red.500' : 'green.500'}
            />
            <Box as="span" color={anomalyDetection?.detected_journal_anomaly ? 'red.500' : 'green.500'}>
              {anomalyDetection?.detected_journal_anomaly
                ? '논문의 journal이 이전에 저자가 투고한 적 없는 곳입니다.'
                : 'journal 이상 없음'}
            </Box>
          </div>
          <div>
            <CheckIcon mr={2} size="20px" color={anomalyDetection?.detected_fos_anomaly ? 'red.500' : 'green.500'} />
            <Box as="span" color={anomalyDetection?.detected_fos_anomaly ? 'red.500' : 'green.500'}>
              {anomalyDetection?.detected_fos_anomaly
                ? '논문의 fos가 저자의 이전 논문들과 일치하지 않습니다.'
                : 'fos 이상 없음'}
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default AnomalyWarning;
