import React, { FC } from 'react';
import useSWR from 'swr';
import { useRecoilState } from 'recoil';
import { selectedUser as selectedUserState } from '../../atoms/selectedAuthorUser';
import { CandidatePaper } from '../../models/paper';
import RawPaperItem from '../rawPaperItem';
import { Box, Spinner } from '@chakra-ui/react';
import { getCandidatePapers } from '../../api/report';
import { removeCandidatePaper } from '../../api/candidates';
import { Report } from '../../models/report';

/**
 * @deprecated Use candidatePaperTable
 */
interface Props {
  report?: Report;
}
const RawPaperTable: FC<Props> = ({ report }) => {
  const [selectedUser] = useRecoilState(selectedUserState);
  const targetReport = report ? report : selectedUser?.report;
  const { data: rawPapers, error, mutate } = useSWR<CandidatePaper[]>(
    () => (targetReport ? `/admin/reports/${targetReport.id}/candidates` : null),
    async () => {
      const candidatePapers = await getCandidatePapers(targetReport!.id);
      candidatePapers.sort((a, b) => b.id - a.id);
      return candidatePapers;
    }
  );

  function handleClickRemove(candidatePaper: CandidatePaper) {
    if (window.confirm('ARE YOU SURE?')) {
      mutate(async (papers: CandidatePaper[] | undefined) => {
        try {
          await removeCandidatePaper(candidatePaper.id);
          return (papers || []).filter((p) => p.id.toString() !== candidatePaper.match_paper?.id?.toString());
        } catch (err) {
          alert(err.message);
          return papers;
        }
      });
    }
  }

  if (!rawPapers && !error) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" mt={8}>
        <Spinner />
      </Box>
    );
  }

  if (!rawPapers || rawPapers.length === 0) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center" mt={8}>
        No publication list. Please insert publication list
      </Box>
    );
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>raw_id</th>
            <th>{`확인여부 ${rawPapers.length}개`}</th>
            <th>제목</th>
            <th>원문</th>
            <th>제목/원문 일치도</th>
            <th>저널</th>
            <th>Matching Error</th>
            <th>매치하기</th>
          </tr>
        </thead>
        <tbody>
          {rawPapers.map((p, i) => {
            return <RawPaperItem onClickRemove={handleClickRemove} raw={p} key={i} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default RawPaperTable;
