import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import History from 'history';

/*
 **************************************
 * PAGINATION POLICY * IMPORTANT *
 **************************************
 * DEFINITION*
 * **
 * PAGE: starts from 1. User(Human) readable.
 * INDEX: starts from 0. User(Human) shouldn't know about this number. use it with API connection.
 *
 * */

interface Props {
  isFirst: boolean;
  isLast: boolean;
  lastPage: number;
  currentPage: number;
  makeLinkAddress: (targetPage: number) => string | History.LocationDescriptor;
}

const PaginationItem: FC<{ isActive: boolean }> = ({ isActive, children }) => {
  return (
    <Box
      py={1}
      px={2}
      border="1px solid #dedede"
      borderRadius="4px"
      ml={2}
      cursor="pointer"
      backgroundColor={isActive ? '#3182ce' : 'transparent'}
      color={isActive ? 'white' : '#333'}
      _hover={{ backgroundColor: isActive ? '#4299e1' : 'rgba(0,0,0,0.05)' }}
    >
      {children}
    </Box>
  );
};

const Pagination: FC<Props> = ({ lastPage, currentPage, makeLinkAddress, isFirst, isLast }) => {
  const pages = [];
  const minPage = Math.max(currentPage - 4, 1);
  const maxPage = Math.min(minPage + 9, lastPage);
  for (let i = minPage; i <= maxPage; i++) {
    pages.push(i);
  }

  return (
    <Flex ml={-2}>
      {!isFirst && (
        <Link to={makeLinkAddress(1)}>
          <PaginationItem isActive={false}>{'<<'}</PaginationItem>
        </Link>
      )}
      {!isFirst && (
        <Link to={makeLinkAddress(currentPage - 1)}>
          <PaginationItem isActive={false}>{'<'}</PaginationItem>
        </Link>
      )}
      {pages.map((page) => (
        <Link to={makeLinkAddress(page)} key={page}>
          <PaginationItem isActive={page === currentPage}>{page}</PaginationItem>
        </Link>
      ))}
      {!isLast && (
        <Link to={makeLinkAddress(lastPage)}>
          <PaginationItem isActive={false}>{'>>'}</PaginationItem>
        </Link>
      )}
    </Flex>
  );
};

export default Pagination;
