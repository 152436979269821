import { PageableResponse } from '../../types/pageableContent';
import { ScinapseAuthor } from '../../types/scinapse/author';
import Fetcher from '../fetcher';

export async function findAuthor(query: string) {
  const res = await Fetcher.instance.get<PageableResponse<ScinapseAuthor>>(
    `https://api.scinapse.io/search/authors?q=${query}&sort=RELEVANCE&page=0`
  );

  return res.data.data;
}

export async function getAuthorById(id: string) {
  const res = await Fetcher.instance.get<{ data: ScinapseAuthor }>(`https://api.scinapse.io/authors/${id}`);
  return res.data.data;
}
