import { Box, HStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';

const TwoColumnChapterPage: FC = () => {
  return (
    <PersonalAnalyticsLayout>
      <ChapterJumbotron title="자기 논문에 대한 분석" chapterTitle="Chapter 00." />
      <HStack mt="40px" spacing="16px" alignItems="flex-start">
        <Box fontSize="12px" lineHeight="21px">
          데이터로 확인한 결과 317편의 전체 논문이 있었고, 216편의 Lead-authored 논문이 확인되었습니다. 이 페이퍼들의
          인용-피인용관계를 시각적인 그래프로 확인해보겠습니다. 이를 통해 본인 논문 내에서 어떤 중심 논문이 있었으며,
          해당 논문들이 가진 힘에 대해서 쉽게 확인 가능합니다. 그래프는 두가지 집단 - 전체 논문과 Lead-authored - 에
          대한 그래프입니다.
        </Box>
        <Box
          pos="relative"
          w="201px"
          bg="#F9F0F2"
          borderRadius="5px"
          p="26px 17px 22px 17px"
          fontSize="12px"
          lineHeight="21px"
          flex="1 0 auto"
          wordBreak="break-all"
        >
          <Box
            pos="absolute"
            left="16px"
            top="-14px"
            fontSize="15px"
            lineHeight="16px"
            p="6px 12px"
            borderRadius="5px"
            bg="#C47268"
            color="white"
            wordBreak="break-all"
          >
            지표 설명
          </Box>
          <Box fontWeight="bold">저널 내 연도별 주요 연구 주제 출판 비율</Box>
          출판사의 전체 출판 논문들 중 특정 연구 주제(Fields of Study)가 포함된 논문의 비율을 계산한다. 수치는 백분율로
          표시한다.
        </Box>
      </HStack>
      <Box mt="117px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default TwoColumnChapterPage;
