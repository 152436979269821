import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { DragObjectWithType, useDrag } from 'react-dnd';
import { AuthorDraggableItemTypes } from './draggableTypes';
import { Author } from '../../models/author';

interface Props {
  authorInformation: { author: Author; count?: number | null };
}

const AuthorStackItem: FC<Props> = ({ authorInformation }) => {
  const { author, count } = authorInformation;
  const [{ isDragging }, drag] = useDrag<DragObjectWithType & { author: Author }, any, any>({
    item: { type: AuthorDraggableItemTypes.BUBBLE_AUTHOR, author: authorInformation.author },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  return (
    <Box
      key={author.id}
      mt={2}
      borderColor="gray.200"
      borderTop="1px solid"
      p={2}
      _first={{ marginTop: 0, borderTop: 0 }}
      opacity={isDragging ? 0.5 : 1}
      cursor="move"
      ref={drag}
    >
      <Box>
        <b>
          {author.name} ({author.id})
        </b>
        {` (H.I: ${author.hindex}, P.C: ${author.paper_count})`}
      </Box>
      <Box>
        Bubble Stack: <b>{count}</b>
      </Box>
    </Box>
  );
};

export default AuthorStackItem;
