import { Badge, Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ReportGroup } from '../../models/reportGroup';

const ReportGroupItem: FC<{ reportGroup: ReportGroup }> = ({ reportGroup }) => {
  return (
    <Box>
      <Box as="span">{reportGroup.id} </Box>
      <Box as="span" fontWeight="bold">
        {reportGroup.name}
      </Box>
      {reportGroup.affiliation && <Box as="span"> ({reportGroup.affiliation.name})</Box>}
      <Badge ml={2} fontSize="0.6rem">
        {reportGroup.type}
      </Badge>
      {reportGroup.note && <Box fontSize="sm">note: {reportGroup.note}</Box>}
      <Box fontSize="sm">
        Reports status: {reportGroup.mapped_report_count ?? 0} / {reportGroup.total_report_count ?? 0} (mapped / total)
      </Box>
    </Box>
  );
};

export default ReportGroupItem;
