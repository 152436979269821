import React, { FC } from 'react';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { stringify } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';

const GroupMappedFilter: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();

  return (
    <RadioGroup
      border="1px solid #dedede"
      p={2}
      borderRadius="4px"
      onChange={(value: string) => {
        const newQueryParams = { ...queryParams, gm: value, page: 1 };
        history.push({
          pathname: location.pathname,
          search: stringify(newQueryParams, { addQueryPrefix: true }),
        });
      }}
      value={queryParams.gm ?? 'ALL'}
    >
      <Stack direction="row">
        <Radio size="sm" value="INCLUDE">
          그룹과 연결된 리포트만 보기
        </Radio>
        <Radio size="sm" value="EXCLUDE">
          그룹과 연결되지 않은 리포트만 보기
        </Radio>
        <Radio size="sm" value="ALL">
          필터 없음
        </Radio>
      </Stack>
    </RadioGroup>
  );
};

export default GroupMappedFilter;
