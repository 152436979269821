import { Box, FormLabel, Input, Spinner } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import produce from 'immer';
import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { addReportGroupToAnalytics, searchReportGroup } from '../../api/reportGroup';
import { useReportGroups } from '../../hooks/useReportGroups';
import { AnalyticsGroup, ReportGroup } from '../../models/reportGroup';
import { PageableData } from '../../types/pageableContent';
import ReportGroupItem from '../reportGroupItem';

const ReportGroupList: FC<{ analyticsId: string | number; excludeIds: (number | string)[] }> = ({
  analyticsId,
  excludeIds,
}) => {
  const queryClient = useQueryClient();
  const [value, setValue] = useState('');
  const [searchedReportGroups, setSearchedReportGroups] = useState<ReportGroup[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const { isLoading: isLoadingAllReportGroups, data: allReportGroups } = useReportGroups();
  const { mutate: addToAnalytics, isLoading: isAdding } = useMutation(
    `/admin/analytics/${analyticsId}/groups`,
    (reportGroup: ReportGroup) => addReportGroupToAnalytics({ analyticsId, reportGroupId: reportGroup.id }),
    {
      onSuccess: (analyticsGroup) => {
        const oldData = queryClient.getQueryData<PageableData<AnalyticsGroup>>(
          `/admin/analytics/${analyticsId}/groups`
        );
        queryClient.setQueryData<PageableData<AnalyticsGroup>>(
          `/admin/analytics/${analyticsId}/groups`,
          produce(oldData!, (draft) => {
            draft.content.unshift(analyticsGroup);
          })
        );
      },
      onError: (err: AxiosError) => {
        alert(err.response?.data?.error?.message || err.message);
      },
    }
  );

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (value.length > 1) {
        setIsSearching(true);
        setSearchedReportGroups(await searchReportGroup(value));
        setIsSearching(false);
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
      setIsSearching(false);
    };
  }, [value]);

  const reportGroupMap = new Map<string | number, ReportGroup>();
  searchedReportGroups.forEach((rg) => reportGroupMap.set(rg.id, rg));
  allReportGroups?.content.forEach((rg) => reportGroupMap.set(rg.id, rg));

  return (
    <Box>
      <FormLabel>Search report group</FormLabel>
      <Input value={value} onChange={(e) => setValue(e.currentTarget.value)} placeholder="POSTECH" />
      {(isLoadingAllReportGroups || isAdding || isSearching) && (
        <Box d="flex" justifyContent="center" alignItems="center" p={8}>
          <Spinner />
        </Box>
      )}
      {reportGroupMap.size === 0 && <Box>There is no report group yet.</Box>}
      {reportGroupMap.size > 0 &&
        Array.from(reportGroupMap.values())
          .filter((rg) => !excludeIds.includes(rg.id))
          .map((rg, i) => (
            <Box
              key={rg.id}
              onClick={() => addToAnalytics(rg)}
              color={i % 2 !== 0 ? '#333' : 'gray.700'}
              bgColor={i % 2 !== 0 ? 'white' : 'gray.200'}
              p={2}
              _hover={{ backgroundColor: '#dedede' }}
              cursor="pointer"
              mt={2}
            >
              <ReportGroupItem reportGroup={rg} />
            </Box>
          ))}
    </Box>
  );
};

export default ReportGroupList;
