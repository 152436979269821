import { Progress, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { FC } from 'react';
import { getCompleteProgress } from '../../helpers/getCompleteProgress';
import { ReportGroupStat } from '../../models/reportGroup';

function getColor(first: number | undefined = 0, last: number | undefined = 0) {
  if (!first && !last) {
    return 'gray';
  }

  if (first === last) {
    return 'blue';
  }

  return 'red';
}

const ReportGroupStatTable: FC<{ reportGroupStat: ReportGroupStat }> = ({ reportGroupStat }) => {
  return (
    <Table size="sm">
      <Thead>
        <Tr>
          {Object.keys(reportGroupStat).map((statType) => {
            const type = statType as keyof ReportGroupStat;
            return <Th key={type}>{type}</Th>;
          })}
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          {Object.keys(reportGroupStat).map((statType) => {
            const type = statType as keyof ReportGroupStat;
            const current = reportGroupStat[type]?.mapped_report_count ?? 0;
            const max = reportGroupStat[type]?.total_report_count ?? 0;
            const percent = current && max ? `${((current / max) * 100).toFixed(2)}%` : '0%';

            return (
              <Td key={type}>
                <Progress
                  size="sm"
                  max={1}
                  min={0}
                  value={getCompleteProgress(current, max)}
                  colorScheme={getColor(current, max)}
                  hasStripe
                />
                {`${current} / ${max} (${percent})`}
              </Td>
            );
          })}
        </Tr>
      </Tbody>
    </Table>
  );
};

export default ReportGroupStatTable;
