import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const PlutoLogo: FC<Props> = ({ children, ...svgProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="13" fill="none" viewBox="0 0 32 13" {...svgProps}>
      <path fill="#536DFE" d="M0 4.313L7.68 0v4.941L0 9.245V4.313z"></path>
      <path fill="#C0CFFF" d="M0 10.098L4.268 7.71v4.904L0 10.098z"></path>
      <mask id="mask0" style={{ maskType: 'alpha' }} width="5" height="6" x="0" y="7" maskUnits="userSpaceOnUse">
        <path fill="#fff" fillRule="evenodd" d="M0 10.1l4.269-2.393-.002 4.907L0 10.1z" clipRule="evenodd"></path>
      </mask>
      <g mask="url(#mask0)">
        <path fill="#A0B8FF" d="M0 10.1l4.269-2.393-.003 1.814L0 10.1z" opacity="0.725"></path>
      </g>
      <path
        fill="#1A237E"
        d="M12.63 4.88c.436 0 .828.096 1.173.287.346.191.616.458.808.8.199.343.298.739.298 1.188 0 .45-.1.848-.298 1.196a2.04 2.04 0 01-.808.8c-.345.191-.737.287-1.173.287-.323 0-.621-.062-.893-.186a1.854 1.854 0 01-.68-.539v2.308h-.817V4.923h.782v.708c.182-.248.412-.433.69-.556.277-.13.583-.194.918-.194zm-.068 3.85c.289 0 .547-.065.774-.194.232-.134.414-.32.544-.556.136-.241.204-.516.204-.825 0-.309-.068-.581-.204-.817a1.36 1.36 0 00-.544-.556 1.536 1.536 0 00-.774-.194c-.284 0-.542.068-.774.203a1.45 1.45 0 00-.544.547 1.66 1.66 0 00-.196.817c0 .309.065.584.196.825.13.236.311.422.544.556.232.13.49.194.774.194zM17.406 9.438c-.436 0-.774-.118-1.012-.354-.238-.242-.357-.579-.357-1.01V3.136h.817v4.869c0 .241.056.426.17.556.119.129.294.193.527.193a.963.963 0 00.4-.084l.042.665a1.719 1.719 0 01-.587.102zM22.97 4.923v4.464h-.773v-.674c-.165.23-.383.41-.655.54a2.057 2.057 0 01-.876.185c-.601 0-1.074-.163-1.42-.489-.346-.331-.52-.817-.52-1.457V4.923h.817v2.476c0 .433.105.761.315.986.21.219.51.328.901.328.431 0 .772-.129 1.021-.387.25-.259.374-.624.374-1.095V4.923h.817zM26.898 9.126c-.12.101-.267.18-.442.236a1.96 1.96 0 01-.545.076c-.453 0-.805-.121-1.054-.363-.25-.241-.375-.586-.375-1.036V3.946h.817v.977h1.293v.665h-1.293v2.418c0 .241.06.426.178.556.125.129.3.193.528.193a.99.99 0 00.638-.21l.255.58zM29.661 9.438c-.448 0-.85-.099-1.207-.295a2.189 2.189 0 01-.842-.809 2.338 2.338 0 01-.298-1.179c0-.438.1-.828.298-1.17a2.11 2.11 0 01.842-.81c.357-.196.76-.294 1.207-.294.448 0 .848.098 1.2.295.357.19.634.46.833.808.204.343.306.733.306 1.171 0 .438-.102.831-.306 1.18a2.128 2.128 0 01-.834.808 2.415 2.415 0 01-1.199.295zm0-.708c.29 0 .547-.065.774-.194.233-.134.414-.32.545-.556a1.71 1.71 0 00.195-.825 1.66 1.66 0 00-.195-.817 1.36 1.36 0 00-.545-.556 1.536 1.536 0 00-.774-.194c-.289 0-.55.065-.782.194-.227.13-.408.315-.544.556a1.66 1.66 0 00-.196.817c0 .309.065.584.196.825.136.236.317.422.544.556.232.13.493.194.782.194z"
      ></path>
    </svg>
  );
};

export default PlutoLogo;
