import { ModalContent, ModalHeader, Box, ModalCloseButton, ModalBody, Button, Input } from '@chakra-ui/react';
import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import useSWR from 'swr';
import { CandidatePaper, ScinapsePaper } from '../../models/paper';
import { findPapers } from './actions';
import InformationBox from './components/informationBox';
import PaperSearchResult from './components/paperSearchResult';

export interface SelectPaperModalProps {
  candidatePaper: CandidatePaper;
  onSelectPaper: (paper: ScinapsePaper) => void;
}

const SelectPaperModal: FC<SelectPaperModalProps> = ({ candidatePaper, onSelectPaper }) => {
  const [query, setQuery] = useState('');
  const { data: foundPapers, error, mutate } = useSWR(
    candidatePaper.source ? ['/search', candidatePaper.source] : null,
    async () => {
      if (candidatePaper.source) {
        return await findPapers(candidatePaper.ner_title || candidatePaper.source);
      }
    }
  );

  async function handleSubmitSearch(e: FormEvent) {
    e.preventDefault();
    if (!query) return;
    const foundPapers = await findPapers(query);
    mutate(foundPapers, false);
  }

  const isLoading = !foundPapers && !error;

  return (
    <ModalContent>
      <ModalHeader textAlign="center">SELECT PAPER</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box my={6}>
          <Box mb={3} fontWeight="bold" fontSize="18px">
            Raw Paper Information
          </Box>
          <Box d="flex">
            <InformationBox title="매치된 타이틀" content={candidatePaper.ner_title} />
            <InformationBox title="원본 텍스트" content={candidatePaper.source.slice(0, 500)} />
          </Box>
        </Box>
        <PaperSearchResult isLoading={isLoading} foundPapers={foundPapers} onSelectPaper={onSelectPaper} />
        <Box my={6}>
          <Box mb={3} fontWeight="bold" fontSize="18px">
            수동으로 페이퍼를 찾으시려면...
          </Box>
          <form style={{ width: '100%' }} onSubmit={handleSubmitSearch}>
            <Box d="flex" paddingY={2}>
              <Input
                value={query}
                placeholder="제목 혹은 ID로 검색"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value)}
              />
              <Button isLoading={isLoading} type="submit" ml={2}>
                검색
              </Button>
            </Box>
          </form>
        </Box>
      </ModalBody>
    </ModalContent>
  );
};

export default SelectPaperModal;
