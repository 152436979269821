import React, { FC, useEffect } from 'react';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  INITIAL_SIMPLE_MATCH_CANDIDATE_PAPER_MODAL_STATE,
  MATCH_STEP,
  simpleMatchCandidatePaperModalState,
} from '../../atoms/simpleMatchCandidatePaperModal';
import SelectPaperModal from '../selectPaperModal';
import { ScinapsePaper } from '../../models/paper';
import SimpleEditPaperModal from '../simpleEditPaperModal';
import { Report } from '../../models/report';
import { INITIAL_VENUE_DRAFT_STATE, venueDraftState } from '../../atoms/venueDraft';
import { authorListAtPaper } from '../../atoms/authorListAtPaper';

const SimpleMatchCandidatePaperModal: FC<{ report: Report }> = ({ report }) => {
  const setVenueDraftState = useSetRecoilState(venueDraftState);
  const setAuthorListState = useSetRecoilState(authorListAtPaper);
  const [{ step, isOpen, candidatePaper, originalPaperId }, setSimpleMatchCandidatePaperModalState] = useRecoilState(
    simpleMatchCandidatePaperModalState
  );

  useEffect(() => {
    if (!isOpen) {
      // when modal is closed
      setSimpleMatchCandidatePaperModalState(INITIAL_SIMPLE_MATCH_CANDIDATE_PAPER_MODAL_STATE);
      setVenueDraftState(INITIAL_VENUE_DRAFT_STATE);
      setAuthorListState(null);
    }
  }, [isOpen, setVenueDraftState, setSimpleMatchCandidatePaperModalState, setAuthorListState]);

  function handleSelectPaper(scinapsePaper: ScinapsePaper) {
    setSimpleMatchCandidatePaperModalState((prev) => ({
      ...prev,
      step: MATCH_STEP.EDIT_PAPER,
      originalPaperId: scinapsePaper.id,
    }));
  }

  return (
    <Modal
      onClose={() => setSimpleMatchCandidatePaperModalState(INITIAL_SIMPLE_MATCH_CANDIDATE_PAPER_MODAL_STATE)}
      isOpen={isOpen}
      size="full"
      scrollBehavior="outside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {step === MATCH_STEP.SELECT_PAPER && candidatePaper && (
        <SelectPaperModal onSelectPaper={handleSelectPaper} candidatePaper={candidatePaper} />
      )}
      {step === MATCH_STEP.EDIT_PAPER && originalPaperId && (
        <SimpleEditPaperModal paperId={originalPaperId} report={report} candidatePaperId={candidatePaper?.id} />
      )}
    </Modal>
  );
};

export default SimpleMatchCandidatePaperModal;
