import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import PersonalAnalyticsLayout from '../../../components/personalAnalytics/layout';
import ChapterJumbotron from '../../../components/personalReportComponents/chapterJumbotron';

const Chapter3Page: FC = () => {
  return (
    <PersonalAnalyticsLayout page={13}>
      <ChapterJumbotron title="자기 논문에 대한 분석" chapterTitle="Chapter 03." />
      <Box fontSize="12px" lineHeight="21px" whiteSpace="pre-wrap" maxW="417px" mt="46px">
        {' '}
        연구자가 출판했던 논문들에 대하여 자기 인용, 외부 피인용이 얼마나 되고 있는지 확인합니다.
        {'\n '}
        추출된 데이터는 모두 자기 인용이 최소 1회 이상 일어난 데이터입니다. 이를 통해서 연구자의 논문 내에서의 중심
        논문을 찾아보고 외부적으로 어떤 영향력을 발휘하고 있는지 피인용수를 통해 확인합니다.
        {'\n '}
        데이터는 기여도 구분 없는 전체 데이터 그리고 1 저자 혹은 교신저자로 구분된 데이터(이하 Lead Authored)로 나누어
        분석하였습니다.
      </Box>
      <Box mt="117px">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/dot-background.png" />
      </Box>
    </PersonalAnalyticsLayout>
  );
};

export default Chapter3Page;
