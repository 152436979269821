import { useQuery } from 'react-query';
import { getComment } from '../api/reportGroup';

interface Params {
  personalReportId: number;
  dataId: string;
}

export function usePageComment({ personalReportId, dataId }: Params) {
  return useQuery([`/admin/personal-report/${personalReportId}/comment`, { personalReportId, dataId }], () =>
    getComment({ personalReportId, dataId })
  );
}
