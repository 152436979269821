import React, { FC } from 'react';
import { Report } from '../../models/report';
import { useMutation, useQueryClient } from 'react-query';
import { editReport } from '../../api/report';
import ReportDetailForm, { ReportDetailFormValues } from '../reportDetailForm';

const ReportEditForm: FC<{ report: Report; onSubmit?: () => void }> = ({ report, onSubmit }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: requestEditReport } = useMutation(editReport, {
    onSuccess: () => {
      queryClient.invalidateQueries('/admin/reports');
      queryClient.invalidateQueries([`/admin/reports/${report.id}`]);
    },
  });

  return (
    <ReportDetailForm
      report={report}
      onSubmit={async (values: ReportDetailFormValues) => {
        try {
          await requestEditReport({ ...values, id: report.id, affiliation_id: values.affiliation.id });
          onSubmit?.();
        } catch (err) {
          alert(err.response?.data?.error?.message || err.message);
        }
      }}
    />
  );
};

export default ReportEditForm;
