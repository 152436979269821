import { Box, Divider, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import PlutoLogo from '../../icons/plutoLogo';

// Browser 96 DPI
// 794 X 1123 FOR A4 SIZE

interface Props {
  page?: number;
}

const PersonalAnalyticsLayout: FC<Props> = ({ page, children }) => {
  return (
    <Box fontFamily="'Noto Sans KR', sans-serif;" w="794px" h="1123px" margin="0 auto" p="70px 80px 0 80px">
      <VStack spacing={0} alignItems="stretch" h="full" w="100%">
        {/* HEADER START */}
        <Box>
          <Box width="36px" height="14px">
            <PlutoLogo width="100%" height="100%" />
          </Box>
          <Divider mt="13px" mb="23px" borderTop="1px solid rgba(0,0,0,0.3)" />
        </Box>
        {/* HEADER END */}
        <Box flex="1 0 auto">{children}</Box>
        {/* FOOTER START */}
        <Box justifySelf="flex-end" borderTop="1px solid rgba(0,0,0,0.3)">
          {/* <Box mt="19px" mb="24px" textAlign="center" fontSize="13px" lineHeight="9px" fontWeight="300">
            {page ?? 0}
          </Box> */}
        </Box>
        {/* FOOTER END */}
      </VStack>
    </Box>
  );
};

export default PersonalAnalyticsLayout;
