import React, { FC, useState } from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Report } from '../../models/report';
import ReportEditForm from '../reportEditForm';

const ReportEditModal: FC<{ report: Report }> = ({ report }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button colorScheme="teal" onClick={() => setIsOpen((prev) => !prev)} mt={2}>
        Edit
      </Button>
      <Modal onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReportEditForm report={report} onSubmit={() => setIsOpen(false)} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportEditModal;
