import React, { FC, useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4plugins_wordCloud from '@amcharts/amcharts4/plugins/wordCloud';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const MAX_COUNT_FOR_WORD_CLOUD = 30;

const WordCloud: FC<{ data: { word: string; value: number }[] }> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;
    const chart = am4core.create(chartRef.current, am4plugins_wordCloud.WordCloud);

    const series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());

    const copiedData = data;
    copiedData.sort((a, b) => b.value - a.value);
    const sortedFilteredData = copiedData.slice(0, MAX_COUNT_FOR_WORD_CLOUD);

    chart.data = sortedFilteredData;

    series.dataFields.word = 'word';
    series.dataFields.value = 'value';
    series.heatRules.push({
      target: series.labels.template,
      property: 'fill',
      min: am4core.color('#536DFE'),
      max: am4core.color('#0019A1'),
      dataField: 'value',
    });

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      <div ref={chartRef} style={{ width: '634px', height: '320px' }} />
    </>
  );
};

export default WordCloud;
