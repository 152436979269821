import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import { removeReportGroupFromAnalytics } from '../api/reportGroup';
import { AnalyticsGroup } from '../models/reportGroup';
import { PageableData } from '../types/pageableContent';

interface Params {
  analyticsId: string | number;
}
export function useRemoveReportGroupFromAnalytics({ analyticsId }: Params) {
  const queryClient = useQueryClient();
  return useMutation(
    `/admin/analytics/${analyticsId}/groups`,
    async (reportGroupId: string | number) => {
      await removeReportGroupFromAnalytics({ analyticsId, reportGroupId });
      return reportGroupId;
    },
    {
      onSuccess: (reportGroupId) => {
        const oldData = queryClient.getQueryData<PageableData<AnalyticsGroup>>(
          `/admin/analytics/${analyticsId}/groups`
        );
        queryClient.setQueryData<PageableData<AnalyticsGroup>>(
          `/admin/analytics/${analyticsId}/groups`,
          produce(oldData!, (draft) => {
            const index = draft.content.findIndex(
              (analyticsReportGroup) => analyticsReportGroup.group.id === reportGroupId
            );
            console.log(index);
            if (index > -1) {
              draft.content.splice(index, 1);
            }
          })
        );
      },
    }
  );
}
